<h1>Inschrijving bevestigen</h1>
<div class="loading" *ngIf="!messageType && !messageString">Afspraak wordt bevestigd...</div>
<div class="message" *ngIf="messageType && messageString">
  <ng-container *ngIf="messageType == 'success'">
    <p>Bedankt voor het bevestigen van uw bezoek aan een woning tijdens de Nationale Duurzame Huizen Route.</p>
    <p *ngIf="hasAccount">U kunt met uw bestaande account <a routerLink="/">inloggen</a> om uw inschrijvingen in te zien.</p>
    <p *ngIf="!hasAccount">Wilt u uw inschrijvingen makkelijk terug vinden? Maak dan eenvoudig een <a routerLink="/registreren">nieuw account</a> aan.</p>
  </ng-container>
  <ng-container *ngIf="messageType == 'warning' || messageType == 'error'">
    <p>{{messageString}}</p>
  </ng-container>
</div>
