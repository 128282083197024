<form [formGroup]="form">
  <section>
    <h6>Persoonlijke gegevens</h6>
    <div class="columns">
      <div><input type="text" fullWidth nbInput status="basic" placeholder="Voornaam" formControlName="field_given_name"
          [status]="fieldStatus('field_given_name')"></div>
      <div><input type="text" fullWidth nbInput status="basic" placeholder="Achternaam"
          formControlName="field_family_name" [status]="fieldStatus('field_family_name')"></div>
      <div><input type="text" fullWidth nbInput status="basic" placeholder="Voorletters"
          formControlName="field_initials" [status]="fieldStatus('field_initials')"></div>
    </div>
    <div class="columns">
      <div><input type="text" fullWidth nbInput status="basic" placeholder="Straatnaam"
          formControlName="field_address_line1" [status]="fieldStatus('field_address_line1')"></div>
      <div class="two-fields">
        <input type="text" nbInput status="basic" placeholder="Huisnummer" formControlName="field_address_line2"
          [status]="fieldStatus('field_address_line2')">
        <input type="text" nbInput status="basic" placeholder="Postcode" formControlName="field_postal_code"
          [status]="fieldStatus('field_postal_code')">
      </div>
      <div><input type="text" fullWidth nbInput status="basic" placeholder="Plaats" formControlName="field_locality"
          [status]="fieldStatus('field_locality')"></div>
    </div>
    <div class="columns">
      <div><input type="text" fullWidth nbInput status="basic" placeholder="E-mailadres" formControlName="field_email"
          [status]="fieldStatus('field_email')" readonly></div>
      <div><input type="text" fullWidth nbInput status="basic" placeholder="Telefoonnummer"
          formControlName="field_phone_number" [status]="fieldStatus('field_phone_number')"></div>
    </div>
  </section>

  <section>
    <h6>Uitbetalingsgegevens</h6>
    <div class="columns">
      <div><input type="text" fullWidth nbInput status="basic" placeholder="IBAN rekeningnummer"
          formControlName="field_iban" [status]="fieldStatus('field_iban')"></div>
      <div><input type="text" fullWidth nbInput status="basic" placeholder="Ten name van"
          formControlName="field_iban_tnv" [status]="fieldStatus('field_iban_tnv')"></div>
      <div></div>
    </div>
  </section>

  <section>
    <nb-checkbox formControlName="field_18_years_or_older" [status]="fieldStatusCheck('field_18_years_or_older')">Ik ben 18 jaar of ouder</nb-checkbox><br>
  </section>

  <section class="nav">
    <button nbButton nbStepperPrevious>
      <nb-icon icon="arrow-ios-back-outline"></nb-icon> Vorige
    </button>
    <button nbButton nbStepperNext [disabled]="!form.valid">Volgende <nb-icon icon="arrow-ios-forward-outline">
      </nb-icon></button>
  </section>
</form>