import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import { range } from 'lodash-es';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {
  SubsidyRequest,
  SubsidyRequestVdStatus,
  SubsidyRequestStatusLabels,
  SubsidyRequestVdStatusLabels,
  SubsidyRequestMunicipalityStatusLabels,
  SubsidyRequestMunicipalityLabels,
  SubsidyRequestTypeLabels,
  SubsidyRequestStatus
  } from 'src/app/models/subsidy.models';
import {AuthService} from 'src/app/services/auth/auth.service';
import {SubsidyService} from 'src/app/services/subsidy/subsidy.service';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';

@Component({
  selector: 'app-edit-subsidy-request-dialog',
  templateUrl: './edit-subsidy-request-dialog.component.html',
  styleUrls: ['./edit-subsidy-request-dialog.component.scss', '../../../shared/shared.scss']
})
export class EditSubsidyRequestDialogComponent implements OnInit, OnDestroy {

  sub = new Subscription();

  request: SubsidyRequest;
  status?: SubsidyRequestStatus;

  isSaving$ = new BehaviorSubject(false);
  statusModel$ = new BehaviorSubject(undefined);
  vdStatusModel$ = new BehaviorSubject(undefined);
  municipalityStatusModel$ = new BehaviorSubject(undefined);
  yearModel$ = new BehaviorSubject(2021);

  statusDisabled$ = combineLatest([this.vdStatusModel$, this.municipalityStatusModel$]).pipe(
    map(([VdStatus, MunicipalityStatus]) => VdStatus !== null || MunicipalityStatus !== null)
  );

  status$ = combineLatest([this.statusModel$, this.vdStatusModel$, this.municipalityStatusModel$]).pipe(
    map(([status, vdStatus, municipalityStatus]) => {
      if (municipalityStatus !== null) return SubsidyRequestStatus.Afgehandeld;
      if (vdStatus !== null) return SubsidyRequestStatus.Beoordeeld;
      return status;
    })
  );

  canSave$ = combineLatest([this.isSaving$, this.status$, this.vdStatusModel$, this.municipalityStatusModel$]).pipe(
    map(([isSaving, status, vdStatus, municipalityStatus]) => {
      if (isSaving) return false;
      if (status === SubsidyRequestStatus.Afgehandeld && (vdStatus === null || municipalityStatus === null)) return false;
      if (status === SubsidyRequestStatus.Beoordeeld && vdStatus === null) return false;
      return true;
    })
  );

  canSubmit$ = combineLatest([this.isSaving$, this.status$, this.vdStatusModel$, this.municipalityStatusModel$]).pipe(
    map(([isSaving, status, vdStatus, municipalityStatus]) => {
      if (isSaving) return false;
      if (this.request.field_type_aanvraag !== 'definitief') return false;
      return true;
    })
  );

  SubsidyRequestStatusLabels = SubsidyRequestStatusLabels;
  SubsidyRequestVdStatusLabels = SubsidyRequestVdStatusLabels;
  SubsidyRequestMunicipalityStatusLabels = SubsidyRequestMunicipalityStatusLabels;
  SubsidyRequestStatusKeys = Object.keys(SubsidyRequestStatusLabels);
  SubsidyRequestVdStatusKeys = Object.keys(SubsidyRequestVdStatusLabels);
  SubsidyRequestMunicipalityStatusKeys = Object.keys(SubsidyRequestMunicipalityStatusLabels);

  SubsidyRequestMunicipalityLabels = SubsidyRequestMunicipalityLabels;
  SubsidyRequestTypeLabels = SubsidyRequestTypeLabels;

  get showStatus() {
    return this.authService.identityClaims['roles'].includes('administrator');
  }

  get showVdStatus() {
    return this.authService.identityClaims['roles'].includes('administrator');
  }

  get showMunicipalityStatus() {
    return this.authService.identityClaims['roles'].includes('administrator') ||
           this.authService.identityClaims['roles'].includes('gemeente_medewerker');
  }

  get showYear() {
    return this.authService.identityClaims['roles'].includes('administrator') ||
           this.authService.identityClaims['roles'].includes('gemeente_medewerker');
  }

  get showSaveButton() {
    return this.authService.identityClaims['roles'].includes('administrator') ||
           this.authService.identityClaims['roles'].includes('gemeente_medewerker');
  }

  get showSubmitButton() {
    return !this.authService.identityClaims['roles'].includes('administrator') &&
           !this.authService.identityClaims['roles'].includes('gemeente_medewerker');
  }

  get years() {
    return range(2021, new Date().getFullYear() + 1);
  }

  constructor(
    protected ref: NbDialogRef<EditSubsidyRequestDialogComponent>,
    private authService: AuthService,
    private subsidyService: SubsidyService,
    private majadaToastrService: MajadaToastrService
  ) {
    this.sub.add(this.status$.subscribe(status => this.status = status));
  }

  ngOnInit(): void {
    this.statusModel$.next(this.request.field_aanvraag_status);
    this.vdStatusModel$.next(this.request.field_status_beoordeling_vd);
    this.municipalityStatusModel$.next(this.request.field_status_afhandeling_gem);
    this.yearModel$.next(parseFloat(this.request.field_jaar as any));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  save() {
    this.isSaving$.next(true);
    this.subsidyService.updateRequestStatus(
      this.request,
      this.status,
      this.vdStatusModel$.value,
      this.municipalityStatusModel$.value,
      this.yearModel$.value,
    ).subscribe(() => {
      this.isSaving$.next(false);
      this.ref.close();
      this.majadaToastrService.success('Aanvraag is bijgewerkt', 'Bijgewerkt');
    }, err => {
      console.error(err);
      this.isSaving$.next(false);
      this.majadaToastrService.error('Aanvraag kon niet bijgewerkt worden');
    });
  }

  submit() {
    this.isSaving$.next(true);
    this.subsidyService.updateRequestStatus(
      this.request,
      SubsidyRequestStatus.WordtGecontroleerd,
      this.request.field_status_beoordeling_vd,
      this.request.field_status_afhandeling_gem,
      this.yearModel$.value
    ).subscribe(() => {
      this.isSaving$.next(false);
      this.ref.close();
      this.majadaToastrService.success('Aanvraag is ingediend', 'Ingediend');
    }, err => {
      console.error(err);
      this.isSaving$.next(false);
      this.majadaToastrService.error('Aanvraag kon niet ingediend worden');
    });
  }

  close() {
    this.ref.close();
  }

}
