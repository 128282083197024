<div class="card closable-dialog">
  <nb-card>
    <nb-card-header>{{isNewAssignment ? 'Aanvraag toewijzen' : 'Aanvraag bewerken'}}</nb-card-header>
    <nb-card-body [nbSpinner]="showSpinner$ | async" nbSpinnerStatus="basic">
      <div *ngIf="!(isLoading$ | async)" [class.saving]="isSaving$ | async">
        <label class="label" for="input-date-range">Kies start- en einddatum</label>
        <input id="input-date-range" nbInput [nbDatepicker]="ngmodel" [ngModel]="dateRangeModel$ | async"
          (ngModelChange)="dateRangeModel$.next($event)" [disabled]="!isNewAssignment">
        <nb-rangepicker format="DD-MM-YYYY" #ngmodel></nb-rangepicker>

        <div>
          <label class="label" for="select-status">Status</label>
          <nb-select id="select-status" placeholder="Status" [ngModel]="statusModel$ | async" (ngModelChange)="statusModel$.next($event)">
            <nb-option *ngFor="let i of [1,5,2,3,4]" [value]="i.toString()">
              {{EnergyLibraryAssignmentStatusLabels[i.toString()]}}</nb-option>
          </nb-select>
        </div>

        <div *ngIf="isNewAssignment" class="field-container">
          <label class="label" for="select-product">Product toewijzen</label>
          <nb-select id="select-product" placeholder="Kies een product" [ngModel]="productModel$ | async" (ngModelChange)="productModel$.next($event)">
            <nb-option *ngFor="let product of paginatedProducts$ | async" [value]="product.field_inventarisnummer">
              {{product.field_inventarisnummer}} {{product.title}}</nb-option>
          </nb-select>
        </div>

        <div *ngIf="!isNewAssignment" class="field-container">
          <label class="label" for="select-product">Toegewezen product</label>
          <nb-select id="select-product-fixed" ngModel="1" disabled="true">
            <nb-option value="1">{{fixedProduct}}</nb-option>
          </nb-select>
        </div>

        <h6>Aanvraaggegevens</h6>
        <dl>
          <dt>Datum:</dt><dd>{{request.aanvraag_created}}</dd>
          <dt>Naam:</dt><dd>{{request.naam}}</dd>
          <dt>E-mailadres:</dt><dd>{{request.emailadres}}</dd>
          <dt>Gemeente:</dt><dd>{{request.gemeente}}</dd>
        </dl>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="card--actions">
        <button *ngIf="isAllowedToDelete" nbButton [disabled]="showSpinner$ | async" status="basic" outline (click)="delete()">Verwijderen</button>
        <button nbButton [disabled]="!(canSubmit$ | async)" status="success" outline (click)="save()">Opslaan</button>
      </div>
    </nb-card-footer>
  </nb-card>
  <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
</div>
