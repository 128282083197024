import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {SubsidyRequestLogsService} from 'src/app/services/subsidy-request-logs/subsidy-request-logs.service';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';
import {SubsidyLog} from '../../../../models/subsidy.models';

@Component({
  selector: 'app-add-subsidy-request-log',
  templateUrl: './add-subsidy-request-log.component.html',
  styleUrls: ['./add-subsidy-request-log.component.scss']
})
export class AddSubsidyRequestLogComponent implements OnInit {

	@Input() subsidyId: string;
  @Input() internalDrupalId: number;
  @Output() newLogEvent = new EventEmitter<SubsidyLog>();

  constructor(
  	private subsidyRequestLogsService: SubsidyRequestLogsService,
  	private majadaToastrService: MajadaToastrService
  	) { }

  ngOnInit(): void {
  }

  onSubmit(event): void {
    var logText = event.message;
    var logTitle = 'Status aanpassing logging voor aanvraag #'+this.internalDrupalId;
    this.subsidyRequestLogsService.postLog(this.subsidyId, logTitle, logText).subscribe((result) => {
      this.newLogEvent.emit(result);
      this.majadaToastrService.success('Notitie is toegevoegd', 'Toegevoegd');
    }, err => {
      console.error(err);
      this.majadaToastrService.error('Notitie kon niet toegevoegd worden');
    });
  }

}
