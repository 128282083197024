import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NbDialogRef, NbDateService} from '@nebular/theme';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {some, ary} from 'lodash-es';

import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {EnergyLibraryService} from '../../../services/energy-library/energy-library.service';
import {AuthService} from '../../../services/auth/auth.service';
import {
  EnergyLibraryProductStatusLabels,
  EnergyLibraryAssignmentStatus,
  EnergyLibraryAssignmentStatusLabels,
  EnergyLibraryProduct,
  EnergyLibraryRequestView,
  EnergyLibraryAssignment } from '../../../models/energy-library.models';
import * as moment from 'moment';


@Component({
  selector: 'app-assign-product-dialog',
  templateUrl: './assign-product-dialog.component.html',
  styleUrls: ['./assign-product-dialog.component.scss', '../../../shared/shared.scss']
})
export class AssignProductDialogComponent implements OnInit, OnDestroy {

  private pageSize = 10;

  products$ = new BehaviorSubject<EnergyLibraryProduct[]>(null);
  assignment$ = new BehaviorSubject<EnergyLibraryAssignment>(null);

  request: EnergyLibraryRequestView;
  productType: string;
  assignmentId?: string;
  fixedProduct: string;

  currentPage$ = new BehaviorSubject(1);
  isSaving$ = new BehaviorSubject(false);
  dateRangeModel$ = new BehaviorSubject({ start: moment(), end: moment() });
  productModel$ = new BehaviorSubject(undefined);
  statusModel$ = new BehaviorSubject(undefined);

  EnergyLibraryProductStatusLabels = EnergyLibraryProductStatusLabels;
  EnergyLibraryAssignmentStatusLabels = EnergyLibraryAssignmentStatusLabels;

  paginatedProducts$ = combineLatest(this.products$, this.currentPage$).pipe(
    map(([products, currentPage]) => {
      const start = this.pageSize * (currentPage - 1);
      const end = Math.min(products.length, start + this.pageSize);
      return products.slice(start, end);
    })
  );

  isLoading$ = combineLatest(this.products$, this.assignment$).pipe(
    map(([products, assignment]) => {
      if (this.assignmentId && !assignment) return true;
      return !products;
    })
  );

  showSpinner$ = combineLatest(this.isLoading$, this.isSaving$).pipe(map(ary(some, 1)));
  numPages$ = this.products$.pipe(map(products => Math.ceil(products.length / this.pageSize)));

  canSubmit$ = combineLatest(this.isLoading$, this.isSaving$, this.dateRangeModel$, this.statusModel$, this.productModel$).pipe(
    map(([isLoading, isSaving, dateRange, statusModel, productModel]) => {
      const isProductValid = statusModel === EnergyLibraryAssignmentStatus.Geannuleerd || !!productModel;
      return !isLoading && !isSaving && !!dateRange.start && !!dateRange.end && isProductValid;
    })
  );

  private formatDate = (date: moment.Moment) => date.format('YYYY-MM-DD');

  public get isNewAssignment() {
    return !this.assignmentId;
  }

  public get isAllowedToDelete() {
    return this.authService.canDeleteEnergyLibraryRequests();
  }

  constructor(
    protected ref: NbDialogRef<AssignProductDialogComponent>,
    private dateService: NbDateService<Date>,
    private energyLibraryService: EnergyLibraryService,
    private majadaToastrService: MajadaToastrService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.loadAvailableProducts();
    this.loadAssignment();

    if (!this.assignmentId) this.updateForm();
  }

  ngOnDestroy(): void {
    this.products$.complete();
    this.assignment$.complete();
    this.productModel$.complete();
    this.statusModel$.complete();
    this.isSaving$.complete();
    this.currentPage$.complete();
    this.dateRangeModel$.complete();
  }

  private loadAvailableProducts() {
    this.energyLibraryService.getAvailableProducts(this.productType, this.request.locatie_uuid).subscribe(products => {
      this.products$.next(products);
    });
  }

  private loadAssignment() {
    if (!this.assignmentId) return;
    this.energyLibraryService.getAssignmentById(this.assignmentId).subscribe(assignment => {
      this.assignment$.next(assignment);
      this.updateForm(assignment);
    });
  }

  private updateForm(assignment?: EnergyLibraryAssignment) {
    if (assignment) {
      this.statusModel$.next(assignment.field_toewijzing_status);
      this.productModel$.next(assignment.field_toegewezen_product?.field_inventarisnummer);
      this.fixedProduct = assignment.field_toegewezen_product ?
        `${assignment.field_toegewezen_product.field_inventarisnummer} ${assignment.field_toegewezen_product.title}` :
        `Geen product toegewezen`;
      this.dateRangeModel$.next({
        start: moment(assignment.field_start_en_einddatum.value),
        end: moment(assignment.field_start_en_einddatum.end_value),
      });
    } else {
      this.statusModel$.next(EnergyLibraryAssignmentStatus.ProductInGebruik);
      this.dateRangeModel$.next({
        start: moment(),
        end: moment().add(7, 'days')
      });
    }
  }

  save() {
    if (this.isNewAssignment) {
      const selectedProduct = this.products$.value.find(x => x.field_inventarisnummer === this.productModel$.value);
      this.assign(selectedProduct);
    } else {
      this.update();
    }
  }

  assign(product: EnergyLibraryProduct) {
    this.isSaving$.next(true);
    this.energyLibraryService.assignProductToRequest(
      product,
      this.request.aanvraag_id?.toString(),
      this.request.aanvraag_uuid,
      this.formatDate(this.dateRangeModel$.value.start),
      this.formatDate(this.dateRangeModel$.value.end),
      this.statusModel$.value
    ).subscribe(() => {
      this.isSaving$.next(false);
      this.ref.close();
      this.majadaToastrService.success('Product is toegewezen', 'Toegewezen');
    }, err => {
      console.error(err);
      this.isSaving$.next(false);
      this.majadaToastrService.error('Product kon niet worden toegewezen');
    });
  }

  update() {
    this.isSaving$.next(true);
    const update = {
      status: this.statusModel$.value
    };
    this.energyLibraryService.updateAssignment(this.assignment$.value, update).subscribe(res => {
      this.isSaving$.next(false);
      this.ref.close();
      this.majadaToastrService.success('Toewijzing is bijgewerkt', 'Bijgewerkt');
    }, err => {
      console.error(err);
      this.isSaving$.next(false);
      this.majadaToastrService.error('Toewijzingen kon niet bijgewerkt worden');
    });
  }

  delete() {
    this.isSaving$.next(true);
    this.energyLibraryService.deleteRequestAndAssignment(this.request, this.assignment$.value).subscribe(res => {
      this.isSaving$.next(false);
      this.ref.close();
      this.majadaToastrService.success('Aanvraag is verwijderd', 'Verwijderd');
    }, err => {
      console.error(err);
      this.isSaving$.next(false);
      this.majadaToastrService.error('Aanvraag kon niet worden verwijderd');
    });
  }

  close() {
    this.ref.close();
  }
}
