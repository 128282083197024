<form [formGroup]="replyForm" (ngSubmit)="submitReply()" class="comment-form">
  <div class="form-item">
    <label class="label" for="subject">Onderwerp</label>
    <input type="text" nbInput id="subject" name="subject" formControlName="subject" fullWidth>
  </div>

  <div class="form-item">
    <label class="label required" for="comment">Bericht</label>
    <textarea id="comment" formControlName="comment" nbInput fullWidth></textarea>
  </div>
  <div class="form-actions">
    <input type="submit" nbButton status="success" [value]="'Reactie plaatsen'" [disabled]="replyForm.invalid">
  </div>
</form>
