import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {MajadaToastrService} from '../../../../services/toastr/majada-toastr.service';
import {CommentService} from '../../../../comment.service';

@Component({
  selector: 'app-comment-reply',
  templateUrl: './comment-reply.component.html',
  styleUrls: ['./comment-reply.component.scss']
})
export class CommentReplyComponent implements OnInit {

  replyForm: FormGroup;
  @Input()
  repliedComment;

  constructor(private http: HttpClient, private majadaToastrService: MajadaToastrService, private commentService: CommentService) {
  }

  ngOnInit(): void {
    //console.log('repliedComment', this.repliedComment);
    this.replyForm = new FormGroup({
      subject: new FormControl(),
      comment: new FormControl('', [Validators.required])
    });
  }

  submitReply() {
    const body = {
      data: {
        type: 'comment--reactie',
        attributes: {
          subject: this.replyForm.get('subject').value,
          comment_body: {
            value: this.replyForm.get('comment').value,
            format: 'plain_text'
          },
        }
      }
    };

    this.commentService.createCommentReply(body, this.repliedComment.links.reply.href).subscribe(result => {
      this.majadaToastrService.success('Uw reactie is geplaatst.', 'Opgeslagen');
      this.commentService.commentsChanged();
    }, error => {
      this.majadaToastrService.error('Uw reactie kon niet worden geplaatst', 'Fout');
    });
  }

}
