<div class="card closable-dialog">
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <nb-card>
      <nb-card-header>{{isNewProduct ? 'Product toevoegen' : 'Product bewerken'}}</nb-card-header>
        <nb-card-body [nbSpinner]="showSpinner" nbSpinnerStatus="basic">
          <div *ngIf="!isLoading" [class.saving]="isSaving">
            <div>
              <label class="label" for="input-title">Titel</label>
              <input id="input-title" type="text" nbInput placeholder="Titel" formControlName="title">
            </div>
            <div>
              <label class="label" for="select-category">Productcategorie</label>
              <nb-select id="select-category" placeholder="Productcategorie" formControlName="category">
                <nb-option *ngFor="let category of categories" [value]="category.id">
                  {{category.value}}</nb-option>
              </nb-select>
            </div>
            <div>
              <label class="label" for="select-status">Status</label>
              <nb-select id="select-status" placeholder="Status" formControlName="status">
                <nb-option *ngFor="let i of [1,2,3,4]" [value]="i.toString()">
                  {{EnergyLibraryProductStatusLabels[i.toString()]}}</nb-option>
              </nb-select>
            </div>
            <div>
              <label class="label" for="select-municipality">Gemeente</label>
              <nb-select id="select-municipality" placeholder="Gemeente" formControlName="municipality">
                <nb-option *ngFor="let municipality of municipalities" [value]="municipality.id">
                  {{municipality.value}}</nb-option>
              </nb-select>
            </div>
            <div>
              <label class="label" for="select-location">Energiebieb locatie</label>
              <nb-select id="select-location" placeholder="Energiebieblocatie" formControlName="location">
                <nb-option *ngFor="let location of locations" [value]="location.__uuid">
                  {{location.title}}</nb-option>
              </nb-select>
            </div>
            <div>
              <label class="label" for="input-number">Inventarisnummer</label>
              <input id="input-number" type="text" nbInput placeholder="Inventarisnummer" formControlName="number">
            </div>
            <div>
              <label class="label" for="text-damage">Schade</label>
              <textarea id="text-damage" nbInput fullWidth placeholder="Schade" formControlName="damage"></textarea>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="card--actions">
            <button *ngIf="!isNewProduct" nbButton [disabled]="!canSubmit" status="basic" outline (click)="delete()">Product verwijderen</button>
            <button type="submit" outline status="success" [disabled]="!canSubmit" nbButton>Opslaan</button>
          </div>
        </nb-card-footer>
      </nb-card>
    </form>
    <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
  </div>