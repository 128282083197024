import {Component, OnInit, OnDestroy} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {debounceTime, tap, distinctUntilChanged, flatMap, map, startWith, filter} from 'rxjs/operators';
import {includes, isDate, zip, mapValues, omit, zipObject, trim, isArray, isNumber, isNil, range, isString} from 'lodash-es';
import {SubsidyService} from '../../../services/subsidy/subsidy.service';
import {FileChangedEvent} from '../../widgets/upload-dropzone/upload-dropzone.component';
import {SubsidyFormState, getDisplayFlags} from './subsidy-form-state';
import {parseSubsidyRequestAPIResult, SubsidyRequest, subsidyRequestStep2Fields} from 'src/app/models/subsidy.models';

@Component({
  selector: 'app-subsidy-request-form-step2',
  templateUrl: './subsidy-request-form-step2.component.html',
  styleUrls: ['./subsidy-request-form.component.scss']
})
export class SubsidyRequestFormStep2Component implements OnInit, OnDestroy {

  subs: Subscription[] = [];

  formState$ = new BehaviorSubject<SubsidyFormState>({});
  municipality$ = this.formState$.pipe(map(state => state.step1FormData?.field_gemeente));

  displayFlags: any = {};

  images: { [field: string]: string[] } = {};

  form: FormGroup;
  part1: FormGroup;
  part2: FormGroup;
  part3: FormGroup;
  part4: FormGroup;
  part5: FormGroup;
  part6: FormGroup;

  isLoading: boolean;

  trees$: Observable<any>;

  private municipalitiesWithUpdatedRules = ['elburg', 'hattem', 'harderwijk'];

  constructor(
    protected subsidyService: SubsidyService,
    protected formBuilder: FormBuilder
  ) {
      this.subs.push(this.formState$.subscribe(this.setDisplayFlags.bind(this)));
  }

  ngOnInit(): void {

    this.isLoading = false;

    this.createPart1Form();
    this.createPart2Form();
    this.createPart3Form();
    this.createPart4Form();
    this.createPart5Form();
    this.createPart6Form();

    this.form = this.formBuilder.group({
      vooraanvraag: [undefined, Validators.required],
      part1: this.part1,
      part2: this.part2,
      part3: this.part3,
      part4: this.part4,
      part5: this.part5,
      part6: this.part6,
    });

    this.trees$ = this.part4.get('field_boom_oppervlakte_tuin').valueChanges.pipe(
      startWith(undefined),
      distinctUntilChanged(),
      map(val => {
        const res = (trees: string[]) => [{ key: 0, val: '-Kies een boom-' }, ...trees.map(x => ({ key: x, val: x }))];
        switch (val) {
          case 'minder_dan_50':
            return res([
              'Prieelberk',
              'Judasboom',
              'Meidoorn',
              'Sierappel',
              'Winterbloeiende kers',
              'Wilgbladige treurpeer',
            ]);
          case '50_200':
            return res([
              'Veldesdoorn',
              'Trompetboom',
              'Meidoorn',
              'Gele Valse Christusdoorn',
              'Chinese Vernisboom',
              'Japanse Magnolia / Beverboom',
              'Moerbei',
              'Perzisch Ijzerhout',
              'Kers',
              'Sierkers',
              'Sierpeer',
              'Japanse Storaxboom',
              'Bijenboom',
              'Winterlinde / Kleinbladige Linde (Böhlje)',
              'Japanse Schijniep',
            ]);
          case 'meer_dan_200':
            return res([
              'Esdoorn',
              'Rode Esdoorn',
              'Rode Paardenkastanje',
              'Witte Paardenkastanje',
              'Dubbelbloemige Paardenkastanje',
              'Hartbladige Els',
              'Gewone Els',
              'Goudberk',
              'Zwarte Berk',
              'Ruwe Berk',
              'Haagbeuk',
              'Tamme Kastanje',
              'Gewone Beuk',
              'Bruine Beuk',
              'Walnoot',
              'Tulpenboom',
              'Hopbeuk',
              'Zwarte populier',
              'Kaukasische vleugelnoot',
              'Moseik',
              'Zomereik',
              'Acacia',
              'Honingboom',
              'Winterlinde / Kleinbladige Linde',
              'Winterlinde / Kleinbladige Linde (Greenspire)',
              'Keizerlinde',
              'Zomerlinde',
              'Zilverlinde',
              'Iep',
              'Fruitbomen hoogstam (Malus / Pyrus / Prunus)',
            ]);
          default:
            return [{ key: -1, val: '-Kies eerst de oppervlakte tuin-' }];
        }
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private setDisplayFlags(state: SubsidyFormState) {
    if (!state.step1FormData) return;
    this.displayFlags = getDisplayFlags(state);
  }

  fillForm(req: SubsidyRequest, images: { [field: string]: string[] }) {
    req = parseSubsidyRequestAPIResult(req);
    range(1, 7).forEach(i => subsidyRequestStep2Fields[`part${i}`].forEach(field => {
      const val = field.includes('datum') ? (req[field] ? moment(req[field]) : undefined) : req[field];
      this[`part${i}`].get(field).setValue(val);
    }));

    // Trigger form logic on next cycle
    setTimeout(() => {
      const treeValues = [this.part4.get('field_boom_een').value, this.part4.get('field_boom_twee').value];
      this.part4.get('field_boom_oppervlakte_tuin').updateValueAndValidity({ emitEvent: true });
      this.part4.get('field_boom_een').setValue(treeValues[0]);
      this.part4.get('field_boom_twee').setValue(treeValues[1]);
    }, 1);

    this.images = images;
  }

  private getCombinedForm() {
    return {
      ...this.part1.getRawValue(),
      ...this.part2.getRawValue(),
      ...this.part3.getRawValue(),
      ...this.part4.getRawValue(),
      ...this.part5.getRawValue(),
      ...this.part6.getRawValue(),
    };
  }

  public exportForm(includeAuxFields?: boolean) {
    const formatDate = (val: string, key: string) => {
      if (moment.isMoment(val)) return val.format('YYYY-MM-DD');
      return val;
    };
    const form = includeAuxFields ? this.getCombinedForm() :
      omit(this.getCombinedForm(), [
        'regenton_subsidie',
        'regenschutting_subsidie',
        'regenpijp_doorgezaagd_subsidie',
        'infiltratiekrat_subsidie',
        'aanleggen_infiltratieveld_subsidie',
        'afkoppelen_m3_subsidie',
        'vergroenen_subsidie',
        'hemelwatervoorziening_subsidie',
        'boom_een_subsidie',
        'boom_twee_subsidie',
        'groendak_subsidie',
        'groendak_laagdikte_8_subsidie',
        'groendak_laagdikte_20_subsidie',
        'groendak_laagdikte_40_subsidie',
        'groenblauwdak_subsidie',
        'groenblauwdak_laagdikte_8_subsidie',
        'groenblauwdak_laagdikte_20_subsidie',
        'groenblauwdak_laagdikte_40_subsidie',
        'waterberging_18_liter_m2_subsidie',
        'waterberging_30_liter_m2_subsidie',
        'waterberging_50_liter_m2_subsidie',
        'waterberging_30l_grassen_subsidie',
        'waterberging_50l_grassen_subsidie',
        'dak_keuringskosten_subsidie',
        'hemelwatervoorziening_subsidie',
        'field_regenton_foto_bypass',
        'field_regenton_bon_bypass',
        'field_regenton_foto_uitvoeren_bypass',
        'field_infiltratiekrat_foto_bypass',
        'field_afkoppelen_foto_bypass',
        'field_afkoppelen_schets_bypass',
        'field_afkoppelen_bon_bypass',
        'field_afkoppelen_foto_uitvoeren_bypass',
        'field_vergroenen_foto_bypass',
        'field_vergroenen_schets_bypass',
        'field_vergroenen_bon_bypass',
        'field_vergroenen_foto_uitvoeren_bypass',
        'field_boom_foto_bypass',
        'field_boom_bon_bypass',
        'field_boom_foto_uitvoeren_bypass',
        'field_groendak_foto_bypass',
        'field_groendak_schets_bypass',
        'field_groendak_bon_bypass',
        'field_groendak_foto_uitvoeren_bypass',
        'field_hemelwater_foto_bypass',
        'field_hemelwater_schets_bypass',
        'field_hemelwater_bon_bypass',
        'field_hemelwater_foto_uitvoeren_bypass'
      ]
    );
    return mapValues(form, formatDate);
  }

  private createSubsidyComputation(
    valueFields: string[],
    resultFields: string[],
    totalResultField: string,
    measures: string[],
    part: number,
    municipalityFilter?: string[],
  ) {

    const group = this[`part${part}`] as FormGroup;
    const calculationChanged$ = combineLatest([
      this.municipality$.pipe(distinctUntilChanged()),
      group.statusChanges.pipe(
        map(() => !!group.errors?.subsidy),
        startWith(false),
        distinctUntilChanged(),
      ),
      ...valueFields.map(field => group.get(field).valueChanges.pipe(startWith(0))),
    ]).pipe(
      distinctUntilChanged(),
      map(vals => ({
        municipality: vals[0],
        hasError: vals[1],
        fields: zipObject(measures, vals.slice(2)),
      })),
      filter(({ hasError }) => !hasError),
      filter(({ municipality }) => !municipalityFilter || includes(municipalityFilter, municipality)),
      debounceTime(1000),
      tap(() => this.isLoading = true),
      flatMap(({ municipality, fields }) => this.subsidyService.calculateSubsidy(municipality, fields, part)),
      tap(() => this.isLoading = false),
    );
    this.subs.push(
      calculationChanged$.subscribe(calc => {
        const fieldsWithMeasures = zip(resultFields, measures);
        fieldsWithMeasures.forEach(([field, measure]) => {
          if (!group.get(field)) { console.log('err', field); return; }
          group.get(field).setValue(calc[measure].value || 0);
        });
        group.get(totalResultField).setValue(calc.total_value || 0);
      })
    );
    return calculationChanged$;
  }

  fileChanged(event: FileChangedEvent) {
    this[event.form].get(event.field).setValue(event.ids);
  }

  private parseAmount(val: string) {
    if (!isString(val)) return 0;
    if (trim(val) === '') return 0;
    return parseFloat(val.replace(',', '.'));
  }

  fieldStatus(control: AbstractControl) {
    return !control.valid ? 'danger' : 'basic';
  }

  private requiredIfValidator(pred) {
    return formControl => formControl.parent && pred() ? Validators.required(formControl) : null;
  }

  private requiredAndMinimumIfValidator(pred, minimum: number) {
    return formControl => formControl.parent && pred() ? (formControl.value < minimum ? {test: {value: formControl.value}} : null) : null;
  }

  private requiredAndMinimumDynamicIfValidator(pred, minimum: () => number) {
    return formControl => formControl.parent && pred() ? (formControl.value < minimum() ? {test: {value: formControl.value}} : null) : null;
  }

  private requiredTrueIfValidator(pred) {

    return formControl => formControl.parent && pred() ? Validators.requiredTrue(formControl) : null;
  }

  private linkControls(form: FormGroup, srcs: string[], dsts: string[]) {
    srcs.forEach(src => this.subs.push(
      form.get(src).valueChanges.subscribe(() => {
        dsts.forEach(dst => form.get(dst).updateValueAndValidity());
      })
    ));
  }

  get formValid() {
    return this.part1.valid && this.part2.valid && this.part3.valid &&
           this.part4.valid && this.part5.valid && this.part6.valid;
  }

  // --------------------------------------------------------------------------------------------------------------

  private part1SubsidyValidator(control: AbstractControl): {[key: string]: any} | null {
    const val1 = this.parseAmount(control.get('field_regenton')?.value);
    const val2 = this.parseAmount(control.get('field_regenschutting')?.value);
    const val3 = this.parseAmount(control.get('field_regenton_aanschafkosten')?.value);
    if (val1 + val2 > 2) return { subsidy: { valid: false }};
    if (val1 === 0 && val3 > 0) return { subsidy: { valid: false }};
    return null;
  }

  private createPart1Form() {

    const isActiveA = () => this.parseAmount(this.part1.get('field_regenton').value) > 0 ||
                            this.parseAmount(this.part1.get('field_regenton_aanschafkosten').value) > 0;
    const isActive = () => isActiveA() || this.parseAmount(this.part1.get('field_regenschutting').value) > 0;
    const isActiveDef = () => isActive() && this.displayFlags.showDefinitiveRequestFields;
    const isImageRequired = (field: string) => () => isActive() && !this.part1.get(`${field}_bypass`).value;
    const isImageRequiredDef = (field: string) => () => isActiveDef() && !this.part1.get(`${field}_bypass`).value;

    this.part1 = this.formBuilder.group({
      field_regenton: [0, Validators.max(2)],
      field_regenton_aanschafkosten: [0],
      field_regenschutting: [0, Validators.max(2)],
      regenton_subsidie: [0],
      regenschutting_subsidie: [0],
      field_regenton_totale_subsidie: [0],
      field_regenton_min_m2: [false, this.requiredTrueIfValidator(isActiveA)],
      field_regenton_minimaal_inhoud: [false, this.requiredTrueIfValidator(isActive)],
      field_regenton_uitvoeren_in: [undefined, this.requiredIfValidator(isActive)],
      field_regenton_foto: [undefined, this.requiredIfValidator(isImageRequired('field_regenton_foto'))],
      field_regenton_schets: [undefined],
      field_regenton_bon: [undefined, this.requiredIfValidator(isImageRequiredDef('field_regenton_bon'))],
      field_regenton_datum_van_aankoop: [undefined, this.requiredIfValidator(isActiveDef)],
      field_regenton_foto_uitvoeren: [undefined, this.requiredIfValidator(isImageRequiredDef('field_regenton_foto_uitvoeren'))],
      field_regenton_datum_uitvoering: [undefined, this.requiredIfValidator(isActiveDef)],
      field_regenton_foto_bypass: [false],
      field_regenton_bon_bypass: [false],
      field_regenton_foto_uitvoeren_bypass: [false],
    }, { validators: this.part1SubsidyValidator.bind(this) });


    this.linkControls(this.part1,
      ['field_regenton', 'field_regenton_aanschafkosten', 'field_regenschutting', 'field_regenton_foto_bypass',
       'field_regenton_bon_bypass', 'field_regenton_foto_uitvoeren_bypass'],
      ['field_regenton_min_m2', 'field_regenton_minimaal_inhoud', 'field_regenton_uitvoeren_in',
       'field_regenton_foto', 'field_regenton_schets', 'field_regenton_bon', 'field_regenton_foto_uitvoeren',
       'field_regenton_datum_van_aankoop', 'field_regenton_datum_uitvoering']
      );

    this.createSubsidyComputation(
      ['field_regenton', 'field_regenschutting'],
      ['regenton_subsidie', 'regenschutting_subsidie'], 'field_regenton_totale_subsidie',
      ['regenton', 'regenschutting'],
      1, ['ermelo', 'harderwijk', 'hattem', 'nunspeet', 'oldebroek', 'putten']
    );
    this.createSubsidyComputation(
      ['field_regenton_aanschafkosten', 'field_regenschutting'],
      ['regenton_subsidie', 'regenschutting_subsidie'], 'field_regenton_totale_subsidie',
      ['regenton_aanschafkosten', 'regenschutting'],
      1, ['elburg']
    );
  }

  // --------------------------------------------------------------------------------------------------------------


  public minimumInfiltratiekrat() {
    return includes(this.municipalitiesWithUpdatedRules, this.formState$.getValue().step1FormData.field_gemeente) ? 100 : 410;
  }

  public minimumVerhardOppervlak() {
    return includes(this.municipalitiesWithUpdatedRules, this.formState$.getValue().step1FormData.field_gemeente) ? 6 : 20;
  }

  private part2SubsidyValidator(control: AbstractControl): {[key: string]: any} | null {

    const val1 = this.parseAmount(control.get('field_regenpijp_doorgezaagd')?.value);
    if (val1 === 0 || val1 === 1) return null;
    return { subsidy: { valid: false }};
  }

  private createPart2Form() {

    const isActiveA = () => this.parseAmount(this.part2.get('field_regenpijp_doorgezaagd').value) > 0 ||
                            this.parseAmount(this.part2.get('field_infiltratiekrat').value) > 0 ||
                            this.parseAmount(this.part2.get('field_aanleggen_infiltratieveld').value) > 0;
    const isActiveB = () => this.parseAmount(this.part2.get('field_afkoppelen_m3').value) > 0;
    const isActive = () => isActiveA() || isActiveB();
    const isActiveDef = () => isActive() && this.displayFlags.showDefinitiveRequestFields;
    const isActiveInfiltratiekrat = () => this.parseAmount(this.part2.get('field_infiltratiekrat').value) > 0;
    const isActiveInfiltratiekratDef = () => isActiveDef() && isActiveInfiltratiekrat();
    const isImageRequired = (field: string) => () => isActive() && !this.part2.get(`${field}_bypass`).value;
    const isImageRequiredDef = (field: string) => () => isActiveDef() && !this.part2.get(`${field}_bypass`).value;
    const isImageRequiredInfiltratiekratDef = (field: string) =>
      () => isActiveInfiltratiekratDef() && !this.part2.get(`${field}_bypass`).value;

    this.part2 = this.formBuilder.group({
      field_regenpijp_doorgezaagd: [0],
      field_infiltratiekrat: [0],
      field_aanleggen_infiltratieveld: [0],
      field_afkoppelen_m3: [0],
      regenpijp_doorgezaagd_subsidie: [0],
      infiltratiekrat_subsidie: [0],
      aanleggen_infiltratieveld_subsidie: [0],
      afkoppelen_m3_subsidie: [0],
      field_afkoppelen_totale_subsidie: [0],

      field_infiltratiekrat_liters: [0, this.requiredAndMinimumDynamicIfValidator(isActiveInfiltratiekrat, this.minimumInfiltratiekrat.bind(this))],
      field_afkoppelen_aantal_m2: [0, this.requiredAndMinimumDynamicIfValidator(isActiveA, this.minimumVerhardOppervlak.bind(this))],
      field_minimale_berging: [false, this.requiredTrueIfValidator(isActive)],
      field_afgekoppelde_oppervlakte: [ false, this.requiredTrueIfValidator(isActiveB)],
      field_niet_aangesloten_drukriool: [false, this.requiredTrueIfValidator(isActiveB)],
      field_afkoppelen_uitvoeren_in: [undefined, this.requiredIfValidator(isActive)],
      field_infiltratiekrat_foto: [undefined, this.requiredIfValidator(isImageRequiredInfiltratiekratDef('field_infiltratiekrat_foto'))],
      field_afkoppelen_foto: [undefined, this.requiredIfValidator(isImageRequired('field_afkoppelen_foto'))],
      field_afkoppelen_schets: [undefined, this.requiredIfValidator(isImageRequired('field_afkoppelen_schets'))],
      field_afkoppelen_bon: [undefined, this.requiredIfValidator(isImageRequiredDef('field_afkoppelen_bon'))],
      field_afkoppelen_datum_aankoop: [undefined, this.requiredIfValidator(isActiveDef)],
      field_afkoppelen_foto_uitvoeren: [undefined, this.requiredIfValidator(isImageRequiredDef('field_afkoppelen_foto_uitvoeren'))],
      field_afkoppelen_datum_uitvoeren: [undefined, this.requiredIfValidator(isActiveDef)],
      field_infiltratiekrat_foto_bypass: [false],
      field_afkoppelen_foto_bypass: [false],
      field_afkoppelen_schets_bypass: [false],
      field_afkoppelen_bon_bypass: [false],
      field_afkoppelen_foto_uitvoeren_bypass: [false],
    }, { validators: this.part2SubsidyValidator.bind(this) });

    this.linkControls(this.part2,
      ['field_regenpijp_doorgezaagd', 'field_infiltratiekrat', 'field_aanleggen_infiltratieveld', 'field_afkoppelen_m3',
       'field_infiltratiekrat_foto_bypass', 'field_afkoppelen_foto_bypass', 'field_afkoppelen_schets_bypass',
       'field_afkoppelen_bon_bypass', 'field_afkoppelen_foto_uitvoeren_bypass'],
      ['field_minimale_berging', 'field_afgekoppelde_oppervlakte', 'field_afkoppelen_aantal_m2', 'field_infiltratiekrat_liters',
       'field_afkoppelen_uitvoeren_in', 'field_niet_aangesloten_drukriool', 'field_afkoppelen_foto', 'field_afkoppelen_schets',
       'field_afkoppelen_bon', 'field_afkoppelen_foto_uitvoeren', 'field_infiltratiekrat_foto', 'field_afkoppelen_datum_aankoop',
       'field_afkoppelen_datum_uitvoeren'
      ]);

    this.createSubsidyComputation(
      ['field_regenpijp_doorgezaagd', 'field_infiltratiekrat', 'field_aanleggen_infiltratieveld'],
      ['regenpijp_doorgezaagd_subsidie', 'infiltratiekrat_subsidie', 'aanleggen_infiltratieveld_subsidie'],
       'field_afkoppelen_totale_subsidie',
      ['regenpijp', 'infiltratiekrat', 'infiltratieveld'],
      2, ['ermelo', 'harderwijk', 'hattem', 'elburg', 'oldebroek', 'putten']
    );
    this.createSubsidyComputation(
      ['field_afkoppelen_m3'],
      ['afkoppelen_m3_subsidie'], 'field_afkoppelen_totale_subsidie',
      ['afkoppelen'],
      2, ['nunspeet']
    );
  }

  // --------------------------------------------------------------------------------------------------------------

  private part3SubsidyValidator(control: AbstractControl): {[key: string]: any} | null {
    const val1 = this.parseAmount(control.get('field_vergroenen')?.value);
    if (val1 === 0 || val1 >= 20) return null;
    return { subsidy: { valid: false }};
  }

  private createPart3Form() {
    const isActive = () => this.parseAmount(this.part3.get('field_vergroenen').value) > 0;
    const isActiveDef = () => isActive() && this.displayFlags.showDefinitiveRequestFields;
    const isImageRequired = (field: string) => () => isActive() && !this.part3.get(`${field}_bypass`).value;
    const isImageRequiredDef = (field: string) => () => isActiveDef() && !this.part3.get(`${field}_bypass`).value;

    this.part3 = this.formBuilder.group({
      field_vergroenen: [0],
      vergroenen_subsidie: [0],
      field_vergroenen_totale_subsidie: [0],
      field_vergroenen_verwijderd: [false, this.requiredTrueIfValidator(isActive)],
      field_vergroenen_foto: [undefined, this.requiredIfValidator(isImageRequired('field_vergroenen_foto'))],
      field_vergroenen_schets: [undefined, this.requiredIfValidator(isImageRequired('field_vergroenen_schets'))],
      field_vergroenen_bon: [undefined, this.requiredIfValidator(isImageRequiredDef('field_vergroenen_bon'))],
      field_vergroenen_datum_aankoop: [undefined, this.requiredIfValidator(isActiveDef)],
      field_vergroenen_foto_uitvoeren: [undefined, this.requiredIfValidator(isImageRequiredDef('field_vergroenen_foto_uitvoeren'))],
      field_vergoenen_datum_uitvoering: [undefined, this.requiredIfValidator(isActiveDef)],
      field_vergroenen_foto_bypass: [false],
      field_vergroenen_schets_bypass: [false],
      field_vergroenen_bon_bypass: [false],
      field_vergroenen_foto_uitvoeren_bypass: [false],
    }, { validators: this.part3SubsidyValidator.bind(this) });

    this.linkControls(this.part3,
      ['field_vergroenen', 'field_vergroenen_foto_bypass', 'field_vergroenen_schets_bypass', 'field_vergroenen_bon_bypass',
       'field_vergroenen_foto_uitvoeren_bypass'],
      ['field_vergroenen_verwijderd', 'field_vergroenen_foto', 'field_vergroenen_schets',
       'field_vergroenen_bon', 'field_vergroenen_foto_uitvoeren', 'field_vergroenen_datum_aankoop', 'field_vergoenen_datum_uitvoering']);

    this.createSubsidyComputation(
      ['field_vergroenen'],
      ['vergroenen_subsidie'], 'field_vergroenen_totale_subsidie',
      ['vergroenen'],
      3
    );
  }

  // --------------------------------------------------------------------------------------------------------------

  private createPart4Form() {
    const isActiveA = () => this.part4.get('field_boom_oppervlakte_tuin').value &&
                            this.parseAmount(this.part4.get('field_boom_een').value) !== 0 &&
                            this.parseAmount(this.part4.get('field_boom_een').value) !== -1;
    const isActiveB = () => this.part4.get('field_boom_oppervlakte_tuin').value &&
                            this.parseAmount(this.part4.get('field_boom_twee').value) !== 0 &&
                            this.parseAmount(this.part4.get('field_boom_twee').value) !== -1;
    const isActive = () => isActiveA() || isActiveB();
    const isActiveDef = () => isActive() && this.displayFlags.showDefinitiveRequestFields;
    const isImageRequired = (field: string) => () => isActive() && !this.part4.get(`${field}_bypass`).value;
    const isImageRequiredDef = (field: string) => () => isActiveDef() && !this.part4.get(`${field}_bypass`).value;

    this.part4 = this.formBuilder.group({
      field_boom_oppervlakte_tuin: [undefined],
      field_boom_een: [-1],
      field_boom_twee: [-1],
      boom_een_subsidie: [undefined],
      boom_twee_subsidie: [undefined],
      field_boom_totale_subsidie: [0],
      field_erfgrensregels: [undefined, this.requiredTrueIfValidator(isActive)],
      field_min_plantmaat: [undefined, this.requiredTrueIfValidator(isActive)],
      field_boom_foto: [undefined, this.requiredIfValidator(isImageRequired('field_boom_foto'))],
      field_boom_schets: [undefined],
      field_boom_bon: [undefined, this.requiredIfValidator(isImageRequiredDef('field_boom_bon'))],
      field_boom_datum_aankoop: [undefined, this.requiredIfValidator(isActiveDef)],
      field_boom_foto_uitvoeren: [undefined, this.requiredIfValidator(isImageRequiredDef('field_boom_foto_uitvoeren'))],
      field_boom_datum_uitvoering: [undefined, this.requiredIfValidator(isActiveDef)],
      field_boom_foto_bypass: [false],
      field_boom_bon_bypass: [false],
      field_boom_foto_uitvoeren_bypass: [false],
    });

    this.linkControls(this.part4,
      ['field_boom_een', 'field_boom_twee', 'field_boom_foto_bypass', 'field_boom_bon_bypass',
       'field_boom_foto_uitvoeren_bypass'],
      ['field_erfgrensregels', 'field_min_plantmaat', 'field_boom_foto', 'field_boom_schets', 'field_boom_bon', 'field_boom_foto_uitvoeren', 'field_boom_datum_aankoop',
       'field_boom_datum_uitvoering']
    );

    this.subs.push(
      this.part4.get('field_boom_oppervlakte_tuin').valueChanges.subscribe(() => {
        this.part4.get('field_boom_een').setValue(0);
        this.part4.get('field_boom_twee').setValue(0);
      })
    );

    this.createSubsidyComputation(
      ['field_boom_een', 'field_boom_twee'],
      ['boom_een_subsidie', 'boom_twee_subsidie'], 'field_boom_totale_subsidie',
      ['boom_een', 'boom_twee'],
      4
    );
  }

  // --------------------------------------------------------------------------------------------------------------

  public get minimumGroendak() {
    return includes(this.municipalitiesWithUpdatedRules, this.formState$.getValue().step1FormData.field_gemeente) ? 6 : 20;
  }

  public get showGroendakDetailFields() {
    if (!this.part5) return false;
    const val1 = this.parseAmount(this.part5.get('field_groendak')?.value);
    if (!val1) return false;
    return includes(this.municipalitiesWithUpdatedRules, this.formState$.getValue().step1FormData.field_gemeente);
  }

  public get showGroenblauwdakDetailFields() {
    if (!this.part5) return false;
    const val2 = this.parseAmount(this.part5.get('field_groenblauwdak')?.value);
    if (!val2) return false;
    return includes(this.municipalitiesWithUpdatedRules, this.formState$.getValue().step1FormData.field_gemeente);
  }

  private part5SubsidyValidator(control: AbstractControl): {[key: string]: any} | null {

    if (this.displayFlags.showPart5TypeA) {
      const val1 = this.parseAmount(control.get('field_groendak')?.value);
      const val2 = this.parseAmount(control.get('field_groenblauwdak')?.value);
      if (val1 > 0 && val1 < this.minimumGroendak) return { subsidy: { valid: false }};
      if (val2 > 0 && val2 < this.minimumGroendak) return { subsidy: { valid: false }};
    }
    if (this.displayFlags.showPart5TypeB) {
      const val1 = this.parseAmount(control.get('field_waterberging_18_liter_m2')?.value);
      const val2 = this.parseAmount(control.get('field_waterberging_30_liter_m2')?.value);
      const val3 = this.parseAmount(control.get('field_waterberging_50_liter_m2')?.value);
      const val4 = this.parseAmount(control.get('field_waterberging_30l_grassen')?.value);
      const val5 = this.parseAmount(control.get('field_waterberging_50l_grassen')?.value);
      const sum = val1 + val2 + val3 + val4 + val5;
      if (sum > 0 && (sum < 6 || sum > 250)) return { subsidy: { valid: false }};
    }
    if (this.showGroendakDetailFields) {
      const val1 = this.parseAmount(control.get('field_groendak')?.value);
      const val1a = this.parseAmount(control.get('field_groendak_laagdikte_8')?.value);
      const val1b = this.parseAmount(control.get('field_groendak_laagdikte_20')?.value);
      const val1c = this.parseAmount(control.get('field_groendak_laagdikte_40')?.value);
      if (val1 !== val1a + val1b + val1c) return { sum1: { valid: false }};
    }
    if (this.showGroenblauwdakDetailFields) {
      const val2 = this.parseAmount(control.get('field_groenblauwdak')?.value);
      const val2a = this.parseAmount(control.get('field_groenblauwdak_laagdikte_8')?.value);
      const val2b = this.parseAmount(control.get('field_groenblauwdak_laagdikte_20')?.value);
      const val2c = this.parseAmount(control.get('field_groenblauwdak_laagdikte_40')?.value);
      if (val2 !== val2a + val2b + val2c) return { sum2: { valid: false }};
    }
    return null;
  }

  private createPart5Form() {

    const isActiveA = () => this.parseAmount(this.part5.get('field_groendak').value) > 0 ||
                            this.parseAmount(this.part5.get('field_groenblauwdak').value) > 0;
    const isActiveB = () => this.parseAmount(this.part5.get('field_waterberging_18_liter_m2').value) > 0 ||
                            this.parseAmount(this.part5.get('field_waterberging_30_liter_m2').value) > 0 ||
                            this.parseAmount(this.part5.get('field_waterberging_50_liter_m2').value) > 0 ||
                            this.parseAmount(this.part5.get('field_waterberging_30l_grassen').value) > 0 ||
                            this.parseAmount(this.part5.get('field_waterberging_50l_grassen').value) > 0 ||
                            this.parseAmount(this.part5.get('field_dak_keuringskosten').value) > 0;
    const isActive = () => isActiveA() || isActiveB();
    const isActiveDef = () => isActive() && this.displayFlags.showDefinitiveRequestFields;
    const isImageRequired = (field: string) => () => isActive() && !this.part5.get(`${field}_bypass`).value;
    const isImageRequiredDef = (field: string) => () => isActiveDef() && !this.part5.get(`${field}_bypass`).value;

    this.part5 = this.formBuilder.group({
      field_groendak: [0],
      field_groendak_laagdikte_8: [0],
      field_groendak_laagdikte_20: [0],
      field_groendak_laagdikte_40: [0],
      field_groenblauwdak: [0],
      field_groenblauwdak_laagdikte_8: [0],
      field_groenblauwdak_laagdikte_20: [0],
      field_groenblauwdak_laagdikte_40: [0],
      field_waterberging_18_liter_m2: [0],
      field_waterberging_30_liter_m2: [0],
      field_waterberging_50_liter_m2: [0],
      field_waterberging_30l_grassen: [0],
      field_waterberging_50l_grassen: [0],
      field_dak_keuringskosten: [0],
      groendak_subsidie: [0],
      groendak_laagdikte_8_subsidie: [0],
      groendak_laagdikte_20_subsidie: [0],
      groendak_laagdikte_40_subsidie: [0],
      groenblauwdak_subsidie: [0],
      groenblauwdak_laagdikte_8_subsidie: [0],
      groenblauwdak_laagdikte_20_subsidie: [0],
      groenblauwdak_laagdikte_40_subsidie: [0],
      waterberging_18_liter_m2_subsidie: [0],
      waterberging_30_liter_m2_subsidie: [0],
      waterberging_50_liter_m2_subsidie: [0],
      waterberging_30l_grassen_subsidie: [0],
      waterberging_50l_grassen_subsidie: [0],
      dak_keuringskosten_subsidie: [0],
      field_groendak_totale_subsidie: [0],
      field_groendak_minimum_oppervlak: [undefined, this.requiredTrueIfValidator(isActive)],
      field_groendak_foto: [undefined, this.requiredIfValidator(isImageRequired('field_groendak_foto'))],
      field_groendak_schets: [undefined, this.requiredIfValidator(isImageRequired('field_groendak_schets'))],
      field_groendak_bon: [undefined, this.requiredIfValidator(isImageRequiredDef('field_groendak_bon'))],
      field_groendak_datum_aankoop: [undefined, this.requiredIfValidator(isActiveDef)],
      field_groendak_foto_uitvoeren: [undefined, this.requiredIfValidator(isImageRequiredDef('field_groendak_foto_uitvoeren'))],
      field_groendak_datum_uitvoering: [undefined, this.requiredIfValidator(isActiveDef)],
      field_groendak_foto_bypass: [false],
      field_groendak_schets_bypass: [false],
      field_groendak_bon_bypass: [false],
      field_groendak_foto_uitvoeren_bypass: [false],
    }, { validators: this.part5SubsidyValidator.bind(this) });

    this.linkControls(this.part5,
      ['field_groendak', 'field_groenblauwdak', 'field_waterberging_18_liter_m2', 'field_waterberging_30_liter_m2',
       'field_waterberging_50_liter_m2', 'field_waterberging_30l_grassen', 'field_waterberging_50l_grassen', 'field_dak_keuringskosten',
       'field_groendak_foto_bypass', 'field_groendak_schets_bypass', 'field_groendak_bon_bypass', 'field_groendak_foto_uitvoeren_bypass'
      ],
      ['field_groendak_minimum_oppervlak', 'field_groendak_foto', 'field_groendak_schets',
        'field_groendak_bon', 'field_groendak_foto_uitvoeren', 'field_groendak_datum_aankoop', 'field_groendak_datum_uitvoering']);

    this.createSubsidyComputation(
      ['field_groendak', 'field_groenblauwdak'],
      ['groendak_subsidie', 'groenblauwdak_subsidie'], 'field_groendak_totale_subsidie',
      ['groendak', 'groenblauwdak'],
      5, ['ermelo', 'oldebroek', 'putten']
    );

    const comp$ = this.createSubsidyComputation(
      ['field_groendak_laagdikte_8', 'field_groendak_laagdikte_20', 'field_groendak_laagdikte_40',
       'field_groenblauwdak_laagdikte_8', 'field_groenblauwdak_laagdikte_20', 'field_groenblauwdak_laagdikte_40'],
      ['groendak_laagdikte_8_subsidie', 'groendak_laagdikte_20_subsidie', 'groendak_laagdikte_40_subsidie',
       'groenblauwdak_laagdikte_8_subsidie', 'groenblauwdak_laagdikte_20_subsidie', 'groenblauwdak_laagdikte_40_subsidie'],
      'field_groendak_totale_subsidie',
      ['groendak_laagdikte_8', 'groendak_laagdikte_20', 'groendak_laagdikte_40',
      'groenblauwdak_laagdikte_8', 'groenblauwdak_laagdikte_20', 'groenblauwdak_laagdikte_40'],
      5, ['elburg', 'harderwijk', 'hattem']
    );

    this.createSubsidyComputation(
      ['field_waterberging_18_liter_m2', 'field_waterberging_30_liter_m2',
       'field_waterberging_50_liter_m2', 'field_waterberging_30l_grassen',
       'field_waterberging_50l_grassen', 'field_dak_keuringskosten'],
      ['waterberging_18_liter_m2_subsidie',
       'waterberging_30_liter_m2_subsidie', 'waterberging_50_liter_m2_subsidie',
       'waterberging_30l_grassen_subsidie', 'waterberging_50l_grassen_subsidie',
       'dak_keuringskosten_subsidie'], 'field_groendak_totale_subsidie',
      ['waterberging_18', 'waterberging_30', 'waterberging_50',
       'waterberging_30_grassen', 'waterberging_50_grassen', 'bouwkundige_keuring'
      ],
      5, ['nunspeet']
    );

    this.subs.push(this.part5.get('field_groendak').valueChanges.subscribe(val => {
      if (!val || parseFloat(val) === 0 || !isNumber(parseFloat(val))) {
        this.part5.get('field_groendak_laagdikte_8').setValue(0);
        this.part5.get('field_groendak_laagdikte_20').setValue(0);
        this.part5.get('field_groendak_laagdikte_40').setValue(0);
      }
    }));

    this.subs.push(this.part5.get('field_groenblauwdak').valueChanges.subscribe(val => {
      if (!val || parseFloat(val) === 0 || !isNumber(parseFloat(val))) {
        this.part5.get('field_groenblauwdak_laagdikte_8').setValue(0);
        this.part5.get('field_groenblauwdak_laagdikte_20').setValue(0);
        this.part5.get('field_groenblauwdak_laagdikte_40').setValue(0);
      }
    }));

    this.subs.push(comp$.subscribe(res => {
      const groendak = res.groendak_laagdikte_8.value + res.groendak_laagdikte_20.value
        + res.groendak_laagdikte_40.value;
      const groenblauwdak = res.groenblauwdak_laagdikte_8.value + res.groenblauwdak_laagdikte_20.value
        + res.groenblauwdak_laagdikte_40.value;
      this.part5.get('groendak_subsidie').setValue(groendak);
      this.part5.get('groenblauwdak_subsidie').setValue(groenblauwdak);
    }));
  }

  // --------------------------------------------------------------------------------------------------------------

  private createPart6Form() {

    const isActive = () => this.parseAmount(this.part6.get('field_hemelwatervoorziening').value) > 0;
    const isActiveB = () => this.displayFlags.showPart6TypeB && isActive();
    const isActiveDef = () => isActive() && this.displayFlags.showDefinitiveRequestFields;
    const isImageRequired = (field: string) => () => isActive() && !this.part6.get(`${field}_bypass`).value;
    const isImageRequiredDef = (field: string) => () => isActiveDef() && !this.part6.get(`${field}_bypass`).value;

    this.part6 = this.formBuilder.group({
      field_hemelwatervoorziening: [undefined],
      hemelwatervoorziening_subsidie: [0],
      field_hemelwater_m2_afgekoppeld: [0, this.requiredAndMinimumIfValidator(isActive, 20)],
      field_inhoud_voorziening_liters: [0, this.requiredIfValidator(isActive)],
      field_hemelwater_totale_subsidie: [0],
      field_hemelwater_gebruik: [false, this.requiredTrueIfValidator(isActive)],
      field_hemelwater_afkoppel_riool: [false, this.requiredTrueIfValidator(isActiveB)],
      field_minimaal_1000l_gebuffer: [false, this.requiredTrueIfValidator(isActiveB)],
      field_hemelwater_uitvoeren_in: [undefined, this.requiredIfValidator(isActive)],
      field_hemelwater_foto: [undefined, this.requiredIfValidator(isImageRequired('field_hemelwater_foto'))],
      field_hemelwater_schets: [undefined, this.requiredIfValidator(isImageRequired('field_hemelwater_schets'))],
      field_hemelwater_bon: [undefined, this.requiredIfValidator(isImageRequiredDef('field_hemelwater_bon'))],
      field_hemelwater_datum_aankoop: [undefined, this.requiredIfValidator(isActiveDef)],
      field_hemelwater_foto_uitvoeren: [undefined, this.requiredIfValidator(isImageRequiredDef('field_hemelwater_foto_uitvoeren'))],
      field_hemelwater_datum_uitvoeren: [undefined, this.requiredIfValidator(isActiveDef)],
      field_hemelwater_foto_bypass: [false],
      field_hemelwater_schets_bypass: [false],
      field_hemelwater_bon_bypass: [false],
      field_hemelwater_foto_uitvoeren_bypass: [false],
    });

    this.linkControls(this.part6,
      ['field_hemelwatervoorziening', 'field_hemelwater_foto_bypass', 'field_hemelwater_schets_bypass',
       'field_hemelwater_bon_bypass', 'field_hemelwater_foto_uitvoeren_bypass'],
      ['field_hemelwater_m2_afgekoppeld', 'field_inhoud_voorziening_liters', 'field_hemelwater_gebruik',
       'field_hemelwater_afkoppel_riool', 'field_minimaal_1000l_gebuffer', 'field_hemelwater_uitvoeren_in',
       'field_hemelwater_foto', 'field_hemelwater_schets', 'field_hemelwater_bon', 'field_hemelwater_foto_uitvoeren',
       'field_hemelwater_datum_aankoop', 'field_hemelwater_datum_uitvoeren']);

    this.createSubsidyComputation(
      ['field_hemelwatervoorziening'],
      ['hemelwatervoorziening_subsidie'], 'field_hemelwater_totale_subsidie',
      ['hemelwatervoorziening'],
      6
    );
  }

}

