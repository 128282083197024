<h1>Inschrijving annuleren</h1>
<ng-container *ngIf="!missingParameter else missingParameterTpl">

  <div class="confirm-message" *ngIf="!canceled && !error"><p>Weet je zeker dat je je inschrijving wilt annuleren?</p>
    <div class="buttons">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
  </div>
  <div class="message">
    <ng-container *ngIf="!error && canceled">
      <div class="success"><p>
        <strong>Je inschrijving is geannuleerd.</strong></p>
      </div>
      <div class="message" *ngIf="pdb">
        <div><p>Schrijf je in voor een ander bedrijf of bekijk de ervaringsverhalen van anderen.</p></div>
        <div class="buttons">
          <a class="button button-primary" nbButton status="primary"
             href="https://platformduurzamebedrijven.nl/bedrijven">
            Bekijk andere bedrijven
          </a>
          <a class="link" nbButton ghost status="primary" href="https://platformduurzamebedrijven.nl">
            Ga naar www.platformduurzamebedrijven.nl
          </a>
        </div>
      </div>
      <div class="message" *ngIf="ndhr">
        <p>Schrijf je in voor een andere locatie of bekijk de ervaringsverhalen van anderen.</p>
        <div class="buttons"><a class="button button-primary" nbButton status="primary"
                                href="https://duurzamehuizenroute.nl/locaties">Bekijk
          andere woningen</a>
          <a class="link" nbButton ghost status="primary" href="https://duurzamehuizenroute.nl">Ga naar
            www.duurzamehuizenroute.nl</a>
        </div>
      </div>
      <div class="message" *ngIf="tkr">
        <p>Schrijf je in voor een andere locatie of bekijk de ervaringsverhalen van anderen.</p>
        <div class="buttons">
          <a class="button button-primary" nbButton status="primary" href="https://tuinenklimaatroute.nl/locaties">
            Bekijk andere tuinen/projecten
          </a>
          <a class="link" nbButton ghost status="primary" href="https://tuinenklimaatroute.nl">
            Ga naar www.tuinenklimaatroute.nl
          </a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!canceled && error">
      <div class="warning"><p>Je inschrijving kon niet worden geannuleerd, probeer het later nog eens.</p></div>
      <div class="buttons">
        <ng-container *ngTemplateOutlet="buttons"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-template #missingParameterTpl>
  <p>Ongeldige/ontbrekende parameter.</p>
</ng-template>
<ng-template #buttons>
  <button nbButton status="warning" (click)="cancelRegistration()">Ja, afspraak
    annuleren
  </button>
  <div><a class="link" nbButton ghost status="primary" [href]="environment.wordpressUrl">Nee, afspraak niet
    annuleren</a></div>
</ng-template>
