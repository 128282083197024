<nb-card class="nodes">
  <nb-card-header>
    <h3>Recente reacties</h3>
  </nb-card-header>
  <nb-card-body>
    <table *ngIf="themeTerms && nodes" class="nodes--latest-comments">
      <tr>
        <th>Naam</th>
        <th>Geplaatst op</th>
        <th>Laatste reactie op</th>
        <th>Categorie</th>
      </tr>
      <tr *ngFor="let node of nodes">
        <td><a [routerLink]="node.path[0].alias">{{node.title[0].value}}</a></td>
        <td>{{node.created[0].value | date: 'd MMMM y - H:mm': 'Europe/Amsterdam': 'nl-NL'}}</td>
        <td>{{node.field_comments[0].last_comment_timestamp * 1000 | date: 'd MMMM y - H:mm': 'Europe/Amsterdam': 'nl-NL'}}</td>
        <td>{{getTermByUuid(node.field_themas[0].target_uuid)}}</td>
      </tr>
    </table>
  </nb-card-body>
</nb-card>
