import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NbDialogService} from '@nebular/theme';
import {BehaviorSubject, combineLatest, of, Subscription} from 'rxjs';
import {concatMap, debounceTime, flatMap, map, shareReplay, tap} from 'rxjs/operators';
import {values} from 'lodash-es';
import * as XLSX from 'xlsx';

import {SubsidyService} from '../../../services/subsidy/subsidy.service';
import {
  SubsidyRequest,
  SubsidyRequestMunicipality,
  SubsidyRequestMunicipalityControlStatuses,
  SubsidyRequestMunicipalityLabels,
  SubsidyRequestMunicipalityStatusDescriptions,
  SubsidyRequestMunicipalityStatusIcons,
  SubsidyRequestMunicipalityStatusLabels,
  SubsidyRequestStatus,
  SubsidyRequestStatusLabels,
  SubsidyRequestType,
  SubsidyRequestTypeLabels,
  SubsidyRequestVdControlStatuses,
  SubsidyRequestVdStatus,
  SubsidyRequestVdStatusDescriptions,
  SubsidyRequestVdStatusLabels,
} from '../../../models/subsidy.models';
import {EditSubsidyRequestDialogComponent} from '../edit-subsidy-request-dialog/edit-subsidy-request-dialog.component';
import {AuthService} from 'src/app/services/auth/auth.service';
import {CancelSubsidyRequestDialogComponent} from '../cancel-subsidy-request-dialog/cancel-subsidy-request-dialog.component';
import {ViewSubsidyRequestDialogComponent} from '../view-subsidy-request-dialog/view-subsidy-request-dialog.component';
import {SubsidyRequestLogsDialogComponent} from '../subsidy-request-logs-dialog/subsidy-request-logs-dialog.component';
import {SubmitSubsidyRequestDialogComponent} from '../submit-subsidy-request-dialog/submit-subsidy-request-dialog.component';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-my-subsidy-requests',
  templateUrl: './my-subsidy-requests.component.html',
  styleUrls: ['./my-subsidy-requests.component.scss']
})
export class MySubsidyRequestsComponent implements OnDestroy {

  private pageSize = 10;

  SubsidyRequestMunicipalityKeys = values(SubsidyRequestMunicipality);
  SubsidyRequestStatus = SubsidyRequestStatus;
  SubsidyRequestTypeLabels = SubsidyRequestTypeLabels;
  SubsidyRequestStatusLabels = SubsidyRequestStatusLabels;
  SubsidyRequestVdStatusLabels = SubsidyRequestVdStatusLabels;
  SubsidyRequestMunicipalityStatusLabels = SubsidyRequestMunicipalityStatusLabels;
  SubsidyRequestMunicipalityLabels = SubsidyRequestMunicipalityLabels;

  SubsidyRequestVdControlStatuses = SubsidyRequestVdControlStatuses;
  SubsidyRequestVdStatusDescriptions = SubsidyRequestVdStatusDescriptions;
  SubsidyRequestMunicipalityControlStatuses = SubsidyRequestMunicipalityControlStatuses;
  SubsidyRequestMunicipalityStatusDescriptions = SubsidyRequestMunicipalityStatusDescriptions;
  SubsidyRequestMunicipalityStatusIcons = SubsidyRequestMunicipalityStatusIcons;
  MunicipalityStatus = 'basic';

  subs = new Subscription();

  userMunicipality?: string;
  statusFilterModel = null;
  municipalityFilterModel = null;
  nameFilterModel = null;
  emailFilterModel = null;
  addressFilterModel = null;

  numPages = 0;

  statusFilterModel$ = new BehaviorSubject<SubsidyRequestStatus>(this.statusFilterModel);
  municipalityFilterModel$ = new BehaviorSubject<SubsidyRequestMunicipality>(this.municipalityFilterModel);
  nameFilterModel$ = new BehaviorSubject<string>(this.nameFilterModel);
  emailFilterModel$ = new BehaviorSubject<string>(this.emailFilterModel);
  addressFilterModel$ = new BehaviorSubject<string>(this.addressFilterModel);
  filters$ = combineLatest([this.statusFilterModel$, this.municipalityFilterModel$, this.nameFilterModel$, this.emailFilterModel$, this.addressFilterModel$]);

  currentPage$ = new BehaviorSubject(1);
  isLoadingRequests$ = new BehaviorSubject(false);

  requestResults$ = combineLatest([this.currentPage$, this.filters$]).pipe(
    debounceTime(500),
    tap(() => this.isLoadingRequests$.next(true)),
    concatMap(([page, [status, municipality, name, email, address]]) =>
      this.subsidyService.getRequests(page - 1, status, municipality, name, email, address)),
    tap(() => this.isLoadingRequests$.next(false)),
    shareReplay()
  );

  numPages$ = this.requestResults$.pipe(
    map(res => Math.ceil(res.num / this.pageSize)),
  );

  requests$ = this.requestResults$.pipe(
    map(res => res.results || [])
  );

  isExportingPDF$ = new BehaviorSubject<boolean>(false);
  isLoading$ = combineLatest([this.isLoadingRequests$, this.isExportingPDF$]).pipe(map(([reqs, exportingPDF]) => reqs || exportingPDF));
  isFiltering$ = this.filters$.pipe(map(([status]) => !!(status !== null)));

  get showAllMunicipalitiesFilter() {
    return !this.authService.identityClaims['roles'].includes('gemeente_medewerker');
  }

  get filteredMunicipalityKeys() {
    if (this.authService.identityClaims['roles'].includes('gemeente_medewerker')) {
      return this.SubsidyRequestMunicipalityKeys.filter(x => x === this.userMunicipality);
    }
    return this.SubsidyRequestMunicipalityKeys;
  }

  showDefinitiveRequestInstructions$ = this.requests$.pipe(
    map(reqs => {
      if (this.authService.identityClaims['roles'].includes('administrator') ||
        this.authService.identityClaims['roles'].includes('gemeente_medewerker')) {
        return false;
      }

      return !!reqs.find(req => req.field_type_aanvraag === SubsidyRequestType.Vooraanvraag &&
        req.field_status_beoordeling_vd === SubsidyRequestVdStatus.Volledig &&
        req.uid.__uuid === this.authService.uuid);
    })
  );

  get subsidyRequestStatusKeys() {
    if (this.authService.identityClaims['roles'].includes('administrator')) {
      return values(SubsidyRequestStatus);
    } else {
      return [
        SubsidyRequestStatus.InBehandelingBijGemeente,
        SubsidyRequestStatus.Afgehandeld
      ];
    }
  }

  get showAdminFilter() {
    return this.authService.identityClaims['roles'].includes('administrator') ||
      this.authService.identityClaims['roles'].includes('gemeente_medewerker');
  }

  get showStatusFilter() {
    return this.authService.identityClaims['roles'].includes('administrator') ||
      this.authService.identityClaims['roles'].includes('gemeente_medewerker') ||
      this.authService.identityClaims['roles'].includes('veluwe_duurzaam_beheerder');
  }

  get canViewLogs() {
    return this.authService.identityClaims['roles'].includes('administrator') ||
      this.authService.identityClaims['roles'].includes('gemeente_medewerker') ||
      this.authService.identityClaims['roles'].includes('veluwe_duurzaam_beheerder');
  }

  canSubmitRequest(req: SubsidyRequest) {
    const isAdmin = this.authService.identityClaims['roles'].includes('administrator');
    if (this.authService.uuid !== req.uid.__uuid && !isAdmin) {
      return false;
    }

    if (req.field_aanvraag_status !== SubsidyRequestStatus.Beoordeeld) {
      return false;
    }
    return req.field_type_aanvraag === SubsidyRequestType.Definitief && req.field_status_beoordeling_vd !== null;
  }

  canEditRequest(req: SubsidyRequest) {
    if (this.authService.identityClaims['roles'].includes('administrator')) {
      return true;
    }
    if (this.authService.uuid !== req.uid.__uuid) {
      return false;
    }

    if (req.field_aanvraag_status === SubsidyRequestStatus.Beoordeeld) {
      return true;
    }
    if (req.field_aanvraag_status !== SubsidyRequestStatus.Opgeslagen) {
      return false;
    }
    return req.field_type_aanvraag === SubsidyRequestType.Vooraanvraag || req.field_status_beoordeling_vd === null;
  }

  canCancelRequest(req: SubsidyRequest) {
    if (req.field_aanvraag_status === SubsidyRequestStatus.Geannuleerd) {
      return false;
    }

    if (this.authService.identityClaims['roles'].includes('administrator')) {
      return true;
    }

    if (this.authService.uuid !== req.uid.__uuid) {
      return false;
    }
    return req.field_aanvraag_status === SubsidyRequestStatus.Opgeslagen || req.field_aanvraag_status === SubsidyRequestStatus.InBehandelingBijGemeente || req.field_aanvraag_status === SubsidyRequestStatus.WordtGecontroleerd;
  }

  canEditRequestStatus(req: SubsidyRequest) {
    if (this.authService.identityClaims['roles'].includes('administrator')) {
      return true;
    }

    if (this.authService.identityClaims['roles'].includes('gemeente_medewerker')) {
      return req.field_aanvraag_status === SubsidyRequestStatus.InBehandelingBijGemeente;
    }

    return false;
  }

  get canExport() {
    return this.authService.identityClaims['roles'].includes('administrator');
  }

  getTotal(req: any) {
    return parseFloat(req.field_hemelwater_totale_subsidie) +
      parseFloat(req.field_groendak_totale_subsidie) +
      parseFloat(req.field_boom_totale_subsidie) +
      parseFloat(req.field_vergroenen_totale_subsidie) +
      parseFloat(req.field_afkoppelen_totale_subsidie) +
      parseFloat(req.field_regenton_totale_subsidie);
  }

  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private dialogService: NbDialogService,
    private subsidyService: SubsidyService,
    private majadaToastrService: MajadaToastrService
  ) {
    this.subs.add(this.subsidyService.requestChanged$.subscribe(() => this.currentPage$.next(1)));
    this.subs.add(this.statusFilterModel$.subscribe(val => this.statusFilterModel = val));
    this.subs.add(
      this.municipalityFilterModel$.subscribe(val => {
        this.municipalityFilterModel = val;
      })
    );
    this.subs.add(this.filters$.subscribe(() => this.currentPage$.next(1)));
    this.userService.getUser().pipe(
      flatMap(user => user.field_ref_gemeente ?
        this.subsidyService.getMunicipalityKeyByTermId(user.field_ref_gemeente) : of(undefined))
    ).subscribe(municipality => {
      this.userMunicipality = municipality;
      if (this.filteredMunicipalityKeys.includes(municipality)) {
        this.municipalityFilterModel$.next(municipality);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.currentPage$.complete();
    this.isLoadingRequests$.complete();
    this.statusFilterModel$.complete();
    this.nameFilterModel$.complete();
    this.municipalityFilterModel$.complete();
    this.addressFilterModel$.complete();
    this.emailFilterModel$.complete();
  }

  cancel(request: SubsidyRequest) {
    this.dialogService.open(CancelSubsidyRequestDialogComponent, {
      context: {
        request,
      }
    });
  }

  submit(request: SubsidyRequest) {
    this.dialogService.open(SubmitSubsidyRequestDialogComponent, {
      context: {
        request,
      }
    });
  }

  edit(request: SubsidyRequest) {
    this.router.navigate([`/subsidies/aanvraagformulier/${request.__uuid}`]);
  }

  viewLogs(request: SubsidyRequest) {
    this.dialogService.open(SubsidyRequestLogsDialogComponent, {
      context: {
        request,
      }
    });
  }

  view(request: SubsidyRequest) {
    this.dialogService.open(ViewSubsidyRequestDialogComponent, {
      context: {
        request,
      }
    });
  }

  editStatus(request: SubsidyRequest) {
    this.dialogService.open(EditSubsidyRequestDialogComponent, {
      context: {
        request,
      }
    });
  }

  exportSelection() {
    if (this.canExport) {
      const element = document.getElementById('requests') as HTMLTableElement;
      element.insertRow(0);
      const workSheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element, {raw: true});

      XLSX.utils.sheet_add_aoa(workSheet, [
        ['', '', '', '', 'NAW', '', '', '', '', '', '', '', '', '', 'Afkoppelen regenwater', '', '', '', '', '', 'Nuttig gebruik regenwater', '', '', '', ' Regenton, -zuil of - schutting', '', '', 'Vergroenen', '', 'Bomen', '', '', 'Groene daken']
      ], {origin: 'A1'});

      const workBook: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Exported Data');

      XLSX.writeFile(workBook, 'exported-subsidies.xlsx');
    }
  }

  exportPDF(request: SubsidyRequest) {
    this.isExportingPDF$.next(true);
    this.subsidyService.exportPDF(request).subscribe(result => {
      this.isExportingPDF$.next(false);
    }, error => {
      console.error(error);
      this.isExportingPDF$.next(false);
      this.majadaToastrService.error('Uw PDF export kon niet worden aangemaakt.', 'Fout');
    });
  }
}

