<nb-card *ngIf="state == 'request'">
  <nb-card-header>Wachtwoord vergeten</nb-card-header>
  <nb-card-body >
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="submitReset()" *ngIf="!resetSuccess">
      <div class="form-item">
        <label for="reset-username" class="label">Gebruikersnaam of e-mailadres</label>
        <input nbInput type="text" formControlName="resetUsername" id="reset-username" >
        <div class="description">
          <p>Vul uw gebruikersnaam of e-mailadres in.</p>
        </div>
      </div>
      <div class="form-item">
        <input nbButton type="submit" value="Wachtwoord herstellen" [disabled]="forgotPasswordForm.invalid">
      </div>
    </form>
    <p><a routerLink="/" nbButton appearance="ghost" size="small" status="primary">Terug naar inloggen</a></p>
    <div class="reset-success" *ngIf="resetSuccess">
      <p>Er is een mail verstuurd naar het e-mailadres dat bij dit account hoort. Heeft u geen mail ontvangen? Controleer dan de spelling óf er is geen account bekend op dit e-mailadres.</p>
    </div>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="state == 'reset'">
  <nb-card-header>Nieuw wachtwoord instellen</nb-card-header>
  <nb-card-body>
    <form [formGroup]="newPasswordForm" (ngSubmit)="submitNewPassword()" *ngIf="!updateSuccess">
      <div class="form-item">
        <label for="password" class="label">Nieuw wachtwoord</label>
        <input nbInput type="password" formControlName="password" id="password" >
      </div>
      <div class="form-item">
        <label for="password-confirm" class="label">Nieuw wachtwoord herhalen</label>
        <input nbInput type="password" formControlName="passwordConfirm" id="password-confirm">
      </div>
      <div class="form-item">
        <input nbButton type="submit" value="Opslaan" [disabled]="newPasswordForm.invalid">
      </div>
    </form>
    <div class="update-success-message" *ngIf="updateSuccess">
      <p>Uw wachtwoord is bijgewerkt, u kunt nu <a routerLink="/">inloggen</a></p>
    </div>
  </nb-card-body>
</nb-card>
