import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {EnergyLibraryUser, EnergyLibraryLocation, EnergyLibraryProductStatusLabels} from 'src/app/models/energy-library.models';
import {EnergyLibraryService} from 'src/app/services/energy-library/energy-library.service';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';

@Component({
  selector: 'app-edit-location-dialog',
  templateUrl: './edit-location-dialog.component.html',
  styleUrls: ['./edit-location-dialog.component.scss', '../../../shared/shared.scss']
})
export class EditLocationDialogComponent implements OnInit {

  isLoading: boolean;
  isSaving: boolean;

  isNewLocation = true;
  location: EnergyLibraryLocation;
  locationEmployees: EnergyLibraryUser[];

  employees: EnergyLibraryUser[];

  form: FormGroup;

  EnergyLibraryProductStatusLabels = EnergyLibraryProductStatusLabels;

  get canSubmit() {
    return !this.isLoading && !this.isSaving && this.form.valid;
  }

  get showSpinner() {
    return this.isLoading || this.isSaving;
  }

  constructor(
    private formBuilder: FormBuilder,
    protected ref: NbDialogRef<EditLocationDialogComponent>,
    private majadaToastrService: MajadaToastrService,
    private energyLibraryService: EnergyLibraryService
  ) { }


  ngOnInit(): void {
    this.createForm();
    this.updateForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      active: [true],
      name: ['', Validators.required],
      address: ['', Validators.required],
      postalCode: ['', Validators.required],
      locality: ['', Validators.required],
      type: ['afhalen', Validators.required],
      employees: [[], Validators.required],
    });
  }

  private updateForm() {
    if (this.isNewLocation) return;
    this.form.get('active').setValue(this.location.field_actief);
    this.form.get('name').setValue(this.location.title);
    this.form.get('address').setValue(this.location.field_energiebieb_adres?.address_line1);
    this.form.get('postalCode').setValue(this.location.field_energiebieb_adres?.postal_code);
    this.form.get('locality').setValue(this.location.field_energiebieb_adres?.locality);
    this.form.get('type').setValue(this.location.field_type_locatie);
    this.form.get('employees').setValue(this.locationEmployees?.map(x => x?.__uuid));
  }

  delete() {
    this.isSaving = true;
    this.energyLibraryService.deleteLocation(this.location).subscribe(res => {
      this.isSaving = false;
      this.majadaToastrService.success('Locatie is verwijderd', 'Verwijderd');
      this.ref.close();
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Locatie kon niet verwijderd worden');
    });
  }

  onSubmit(form: FormGroup) {
    const fields = {
      active: this.form.value.active,
      name: this.form.value.name,
      address: this.form.value.address,
      postalCode: this.form.value.postalCode,
      locality: this.form.value.locality,
      type: this.form.value.type,
      employees: this.form.value.employees.map(x => this.employees.find(y => y.__uuid === x)),
    };
    return this.isNewLocation ? this.create(fields) : this.update(fields);
  }

  private create(fields) {
    this.isSaving = true;
    this.energyLibraryService.addOrUpdateLocation(fields).subscribe(res => {
      this.isSaving = false;
      this.majadaToastrService.success('Locatie is toegevoegd', 'Toegevoegd');
      this.ref.close();
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Locatie kon niet toegevoegd worden');
    });
  }

  private update(fields) {
    this.isSaving = true;
    this.energyLibraryService.addOrUpdateLocation(fields, this.location, this.locationEmployees).subscribe(res => {
      this.isSaving = false;
      this.majadaToastrService.success('Locatie is bijgewerkt', 'Bijgewerkt');
      this.ref.close();
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Locatie kon niet bijgewerkt worden');
    });
  }

  close() {
    this.ref.close();
  }
}
