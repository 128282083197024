import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-confirm-route-registration',
  templateUrl: './confirm-route-registration.component.html',
  styleUrls: ['./confirm-route-registration.component.scss']
})
export class ConfirmRouteRegistrationComponent implements OnInit, OnDestroy {

  hasAccount = false;
  messageType = null;
  messageString = null;
  registrationId;
  status: string;
  subscription = new Subscription();
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private toastrService: MajadaToastrService, private router: Router, private authService: AuthService, @Inject('drupalUrl') private drupalUrl: string) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.registrationId = params.get('registrationId');
      if (this.registrationId) {
        const updateSub = this.http.post(`${this.drupalUrl}/api/route/confirm-registration?_format=json`, {registration_id: this.registrationId}).subscribe(result => {
          console.log('verify result', result);
          this.showToast(result);
        }, error => {
          this.showToast(error.error);
          console.log('error', error);
        });
        this.subscription.add(updateSub);
      }
    });
  }

  showToast(message) {
    if (message.status && message.message) {
      if (message.status == 'warning') {
        this.toastrService.warning(message.message);
      } else if (message.status == 'success') {
        this.toastrService.success(message.message);
      } else if (message.status == 'error') {
        this.toastrService.error(message.message);
      }
      this.hasAccount = message.has_account;
    }
    if(this.authService.isAuthenticated$ && this.authService.hasValidToken()) {
      this.router.navigateByUrl('/route/mijn-inschrijvingen');
    } else {
      this.messageType = message.status;
      this.messageString = message.message;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
