import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subscription} from 'rxjs';
import {map, distinctUntilChanged} from 'rxjs/operators';
import {pick, omit, isDate, mapValues} from 'lodash-es';

import {SubsidyRequest} from 'src/app/models/subsidy.models';
import {AuthService} from 'src/app/services/auth/auth.service';
import {SubsidyService} from '../../../services/subsidy/subsidy.service';
import {SubsidyFormState} from './subsidy-form-state';

@Component({
  selector: 'app-subsidy-request-form-step3',
  templateUrl: './subsidy-request-form-step3.component.html',
  styleUrls: ['./subsidy-request-form.component.scss']
})
export class SubsidyRequestFormStep3Component implements OnInit, OnDestroy {

  formState$ = new BehaviorSubject<SubsidyFormState>({});
  emailAddress$ = this.formState$.pipe(map(state => {
    return state.emailAddress;
  }), distinctUntilChanged());

  subs: Subscription[] = [];
  form: FormGroup;

  constructor(
    protected authService: AuthService,
    protected subsidyService: SubsidyService,
    protected formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      field_given_name: [undefined, Validators.required],
      field_family_name: [undefined, Validators.required],
      field_initials: [undefined, Validators.required],
      field_address_line1: [undefined, Validators.required],
      field_address_line2: [undefined, Validators.required],
      field_postal_code: [undefined, Validators.required],
      field_locality: [undefined, Validators.required],
      field_email: [undefined, Validators.required],
      field_phone_number: [undefined, Validators.required],
      field_18_years_or_older: [false, Validators.requiredTrue],
      field_iban: [undefined, Validators.required],
      field_iban_tnv: [undefined, Validators.required],
    });
    this.subs.push(
      this.emailAddress$.subscribe(emailAddress => {
        this.form.get('field_email').setValue(emailAddress);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  fieldStatus(fieldName) {
    const field = this.form.get(fieldName);
    return field.invalid && (field.dirty || field.touched) ? 'danger' : 'basic';
  }

  fieldStatusCheck(fieldName) {
    const field = this.form.get(fieldName);
    return field.invalid ? 'danger' : 'basic';
  }

  fillForm(req: SubsidyRequest) {
    this.form.get('field_given_name').setValue(req.uid.field_given_name);
    this.form.get('field_family_name').setValue(req.uid.field_family_name);
    this.form.get('field_initials').setValue(req.uid.field_initials);
    this.form.get('field_address_line1').setValue(req.uid.field_address_line1);
    this.form.get('field_address_line2').setValue(req.uid.field_address_line2);
    this.form.get('field_postal_code').setValue(req.uid.field_postal_code);
    this.form.get('field_locality').setValue(req.uid.field_locality);
    this.form.get('field_email').setValue(req.uid.mail);
    this.form.get('field_phone_number').setValue(req.uid.field_phone_number);
    this.form.get('field_18_years_or_older').setValue(req.uid.field_18_years_or_older);
    this.form.get('field_iban').setValue(req.field_iban);
    this.form.get('field_iban_tnv').setValue(req.field_iban_tnv);
  }

  public exportUserForm() {
    const formatDate = (val: string) => {
      if (isDate(val)) return `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`;
      return val;
    };
    return mapValues(omit(this.form.getRawValue(), 'field_iban', 'field_iban_tnv', 'field_email'), formatDate) as any;
  }

  public exportForm() {
    return {
      ...pick(this.form.getRawValue(), 'field_iban', 'field_iban_tnv'),
      field_voornaam: this.form.get('field_given_name').value,
      field_achternaam: this.form.get('field_family_name').value,
    };
  }
}
