<ng-container *ngIf="data">
  
  <section>
    <h6>Situatie gegevens</h6>
    <div class="columns">
      <div><span>Aanvraag type</span><span>{{ SubsidyRequestTypeLabels[data?.field_type_aanvraag] }}</span></div>
      <div><span>Ligging</span><span>{{ data?.field_binnen_bebouwde_kom ? 'Binnen bebouwde kom' : 'Buiten bebouwde kom' }}</span></div>
    </div>
    <div class="columns">
      <div><span>Gemeente</span><span>{{ data.municipality }}</span></div>
      <div><span>Bouwjaar</span><span>{{ data?.field_gebouwd_voor_2015 ? 'Voor 2015' : 'Na 2015'}}</span></div>
    </div>    
    <div class="columns">
      <div><span>Situatie</span><span>{{ SubsidyRequestSituationLabels[data?.field_situatie] }}</span></div>
      <div><span>Opmerkingen</span><span>{{ data?.field_extra_opmerkingen }}</span></div>
    </div>    
  </section>

  <hr>

  <ng-template #images let-images="images">
    <div class="review-images">
        <a (click)="openImage(image.url)" *ngFor="let image of images" target="_blank"><img [src]="image.data" height="100" /></a>
        <b *ngIf="!images || images.length === 0">- Geen afbeeldingen toegevoegd</b>
    </div>
  </ng-template>

  <section class="review">
    <h6>Subsidie onderdelen</h6>
    <nb-accordion>

      <nb-accordion-item *ngIf="displayFlags.showPart2">
        <nb-accordion-item-header>
          Afkoppelen regenwater <span>Subsidiebedrag:</span><em>{{ parseAmount(data?.field_afkoppelen_totale_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</em>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <table class="review-table">
            <tbody *ngIf="displayFlags.showPart2TypeA">
              <tr>
                <td>Regenpijp doorgezaagd zonder voorziening</td>
                <td><b>{{ data?.field_regenpijp_doorgezaagd }}</b></td>
                <td><b>{{ parseAmount(data?.regenpijp_doorgezaagd_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr>
                <td>Infiltratiekrat</td>
                <td><b>{{ data?.field_infiltratiekrat }}</b></td>
                <td><b>{{ parseAmount(data?.infiltratiekrat_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr>
                <td>Aanleggen infiltratieveld, wadi of regenwatervijver (aantal m³ afgegraven grond)</td>
                <td><b>{{ data?.field_aanleggen_infiltratieveld }}m³</b></td>
                <td><b>{{ parseAmount(data?.aanleggen_infiltratieveld_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>              
            </tbody>
            <tbody *ngIf="displayFlags.showPart2TypeB">
              <tr>
                <td>Afkoppelen</td>
                <td><b>{{ data?.field_afkoppelen_m3 }}m³</b></td>
                <td><b>{{ parseAmount(data?.afkoppelen_m3_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
            </tbody>            
          </table>
          <ng-container *ngIf="displayFlags.showPart2TypeA">
            <p *ngIf="data?.field_infiltratiekrat > 0">Totaal aantal liters per infiltratie krat: <b>{{ data?.field_infiltratiekrat_liters }}</b></p>
            <p>Aantal m² verhard oppervlakte dat wordt afgekoppeld: <b>{{ data?.field_afkoppelen_aantal_m2 }}</b></p>
            <p *ngIf="data?.field_minimale_berging"><b>✓</b> Er is minimaal 20 mm (=20 liter per afgekoppelde m²) berging op eigen terrein gerealiseerd.</p>
          </ng-container>
          <ng-container *ngIf="displayFlags.showPart2TypeB">
            <p>Aantal m² verhard oppervlakte dat wordt afgekoppeld: <b>{{ data?.field_afkoppelen_aantal_m2 }}</b></p>
            <p *ngIf="data?.field_niet_aangesloten_drukriool"><b>✓</b> Mijn pand/woning is niet aangelosten op een drukriool.</p>
          </ng-container>
          <p *ngIf="data?.field_afkoppelen_uitvoeren_in">Ik voer deze maatregel uit in mijn <b>{{ SubsidyGardenLocationLabels[data?.field_afkoppelen_uitvoeren_in] }}</b></p>
          <p>Upload foto van locatie inclusief een foto van de regenpijp op dit moment.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_afkoppelen_foto)"></ng-container>
          <p>Laat zien waar de afkoppelvoorziening komt en hoe groot deze wordt.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_afkoppelen_schets)"></ng-container>
          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <p>Upload foto van plaatsing infiltratie kratten.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_infiltratiekrat_foto)"></ng-container>
            <p>Bon uploaden.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_afkoppelen_bon)"></ng-container>
            <p>Datum van aankoop producten: <b>{{ formatDate(data?.field_afkoppelen_datum_aankoop) }}</b></p>
            <p>Upload een foto waarop de voorziening goed te zien is.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_afkoppelen_foto_uitvoeren)"></ng-container>
            <p>Datum van uitvoering: <b>{{ formatDate(data?.field_afkoppelen_datum_uitvoeren) }}</b></p>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>    
      
      <nb-accordion-item *ngIf="displayFlags.showPart6">
        <nb-accordion-item-header>
          Voorziening nuttig gebruik regenwater <span>Subsidiebedrag:</span><em>{{ parseAmount(data?.field_hemelwater_totale_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</em>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <table class="review-table">
            <tbody>
              <tr>
                <td>Regenwatervoorziening</td>
                <td><b>{{ data?.field_hemelwatervoorziening }}</b></td>
                <td><b>{{ parseAmount(data?.field_hemelwater_totale_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
            </tbody>
          </table>

          <p *ngIf="data?.field_hemelwater_gebruik"><b>✓</b> Het regenwater wordt alleen gebruikt voor watervoeding van toilet en/of wasmachines en/of tuinbesproeiing.</p>
          <ng-container *ngIf="displayFlags.showPart6TypeB">
            <p *ngIf="data?.field_hemelwater_afkoppel_riool"><b>✓</b> Er wordt minimaal 20 m² verhard dakoppervlak afgekoppeld van de gemengde riolering.</p>
            <p *ngIf="data?.field_minimaal_1000l_gebuffer"><b>✓</b> Er wordt minimaal 1000 liter regenwater gebufferd ter vervanging van het gebruik van leidingwater.</p>
          </ng-container>

          <p>Aantal m² verhard oppervlakte dat wordt afgekoppeld: <b>{{ data?.field_hemelwater_m2_afgekoppeld }}</b></p>
          <p>Inhoud voorziening in liters: <b>{{ data?.field_inhoud_voorziening_liters }}</b></p>
          <p *ngIf="data?.field_hemelwater_uitvoeren_in">Ik voer deze maatregel uit in mijn <b>{{ SubsidyGardenLocationLabels[data?.field_hemelwater_uitvoeren_in] }}</b></p>

          <p>Voeg een foto toe van de locatie waar de regenwatervoorziening wordt geplaatst.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_hemelwater_foto)"></ng-container>
          <p>Voeg een schets toe waarop te zien is hoe de regenwatervoorziening wordt geplaatst in de tuin.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_hemelwater_schets)"></ng-container>
          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <p>Bon uploaden.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_hemelwater_bon)"></ng-container>
            <p>Datum van aankoop producten: <b>{{ formatDate(data?.field_hemelwater_datum_aankoop) }}</b></p>
            <p>Voeg een foto toe van de locatie waar de regenwatervoorziening geplaatst is en een foto van tijdens de plaatsing van de voorziening.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_hemelwater_foto_uitvoeren)"></ng-container>
            <p>Datum van uitvoering: <b>{{ formatDate(data?.field_hemelwater_datum_uitvoeren) }}</b></p>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>           

      <nb-accordion-item *ngIf="displayFlags.showPart1">
        <nb-accordion-item-header>
          Regenton, -zuil of -schutting <span>Subsidiebedrag:</span><em>{{ parseAmount(data?.field_regenton_totale_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</em>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <table class="review-table">
            <tbody>
              <tr>
                <td>Regenton</td>
                <td>
                  <b>{{ data?.field_regenton }}</b> &nbsp; 
                  <ng-container *ngIf="displayFlags.showPart1TypeB"><b>{{ parseAmount(data?.field_regenton_aanschafkosten) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></ng-container>
                </td>
                <td><b>{{ parseAmount(data?.regenton_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr>
                <td>Regenschutting</td>
                <td><b>{{ data?.field_regenschutting }}</b></td>
                <td><b>{{ parseAmount(data?.regenschutting_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="data?.field_regenton_min_m2"><b>✓</b> Er is minimaal 5m² dak aangesloten op de regenton.</p>
          <p *ngIf="data?.field_regenton_minimaal_inhoud"><b>✓</b> De regenton/zuil/schutting heeft een inhoud van minimaal 100 liter.</p>
          <p *ngIf="data?.field_regenton_uitvoeren_in">Ik voer deze maatregel uit in mijn <b>{{ SubsidyGardenLocationLabels[data?.field_regenton_uitvoeren_in] }}</b></p>
          <p>Upload foto van locatie inclusief een foto van de regenpijp op dit moment.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_regenton_foto)"></ng-container>
          <p>Laat zien waar de regenton geplaatst gaat worden.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_regenton_schets)"></ng-container>
          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <p>Bon uploaden.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_regenton_bon)"></ng-container>
            <p>Datum van aankoop producten: <b>{{ formatDate(data?.field_regenton_datum_van_aankoop) }}</b></p>
            <p>Foto uploaden van na de aanpassingen waarop duidelijk is te zien dat de regenton is aangesloten.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_regenton_foto_uitvoeren)"></ng-container>
            <p>Datum van uitvoering: <b>{{ formatDate(data?.field_regenton_datum_uitvoering) }}</b></p>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>

      <nb-accordion-item *ngIf="displayFlags.showPart3">
        <nb-accordion-item-header>
          Vergroenen <span>Subsidiebedrag:</span><em>{{ parseAmount(data?.field_vergroenen_totale_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</em>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <table class="review-table">
            <tbody>
              <tr>
                <td>Vergroenen</td>
                <td><b>{{ data?.field_vergroenen }}</b></td>
                <td><b>{{ parseAmount(data?.vergroenen_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
            </tbody>
          </table>
          <p *ngIf="data?.field_vergroenen_verwijderd"><b>✓</b> Er wordt minimaal 20m² verhard oppervlakte verwijderd.</p>
          <p>Upload een foto van de locatie waarop te zien is dat deze bestraat is.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_vergroenen_foto)"></ng-container>
          <p>Voeg een schets toe waarop je laat zien hoe groot het vergroende stuk gaat worden.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_vergroenen_schets)"></ng-container>
          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <p>Bon uploaden.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_vergroenen_bon)"></ng-container>
            <p>Datum van aankoop producten: <b>{{ formatDate(data?.field_vergroenen_datum_aankoop) }}</b></p>
            <p>Upload een foto van de locatie waarop te zien is dat deze is vergroend en een foto met een meetlint waarop te zien is hoe groot het vergroende deel is.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_vergroenen_foto_uitvoeren)"></ng-container>
            <p>Datum van uitvoering: <b>{{ formatDate(data?.field_vergoenen_datum_uitvoering) }}</b></p>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>         

      <nb-accordion-item *ngIf="displayFlags.showPart4">
        <nb-accordion-item-header>
          Planten van een boom <span>Subsidiebedrag:</span><em>{{ parseAmount(data?.field_boom_totale_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</em>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <p>Oppervlakte van de tuin: <b>{{ SubsidyGardenAreaLabels[data?.field_boom_oppervlakte_tuin] }}</b></p>
          <table class="review-table">
            <tbody>
              <tr *ngIf="data?.field_boom_een !== 0 && data?.field_boom_een !== -1">
                <td>Boom één</td>
                <td><b>{{ data?.field_boom_een }}</b></td>
                <td><b>{{ parseAmount(data?.boom_een_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr *ngIf="data?.field_boom_twee !== 0 && data?.field_boom_twee !== -1">
                <td>Boom twee</td>
                <td><b>{{ data?.field_boom_twee }}</b></td>
                <td><b>{{ parseAmount(data?.boom_twee_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>              
            </tbody>
          </table>
          <p>Upload een foto van de locatie waar de boom/ bomen geplaatst word(t)(en).</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_boom_foto)"></ng-container>
          <p>Voeg een schets toe waarop je aangeeft waar de boom wordt/bomen worden geplaatst.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_boom_schets)"></ng-container>
          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <p>Bon uploaden.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_boom_bon)"></ng-container>
            <p>Datum van aankoop producten: <b>{{ formatDate(data?.field_boom_datum_aankoop) }}</b></p>
            <p>Upload een foto van de locatie waar de boom/ bomen geplaatst is/zijn.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_boom_foto_uitvoeren)"></ng-container>
            <p>Datum van uitvoering: <b>{{ formatDate(data?.field_boom_datum_uitvoering) }}</b></p>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>       
      
      <nb-accordion-item *ngIf="displayFlags.showPart5">
        <nb-accordion-item-header>
          Groene daken <span>Subsidiebedrag:</span><em>{{ parseAmount(data?.field_groendak_totale_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</em>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <table class="review-table">
            <tbody *ngIf="displayFlags.showPart5TypeA">
              <tr>
                <td>Groendak</td>
                <td><b>{{ data?.field_groendak }}</b></td>
                <td><b>{{ parseAmount(data?.groendak_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr class="review-table-sub-field" *ngIf="displayFlags.showGroendakDetailFields">
                <td>Laagdikte 8-20cm</td>
                <td>{{ data?.field_groendak_laagdikte_8 }}</td>
                <td>{{ parseAmount(data?.groendak_laagdikte_8_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</td>
              </tr>
              <tr class="review-table-sub-field" *ngIf="displayFlags.showGroendakDetailFields">
                <td>Laagdikte 20-40cm</td>
                <td>{{ data?.field_groendak_laagdikte_20 }}</td>
                <td>{{ parseAmount(data?.groendak_laagdikte_20_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</td>
              </tr>
              <tr class="review-table-sub-field" *ngIf="displayFlags.showGroendakDetailFields">
                <td>Laagdikte meer dan 40cm</td>
                <td>{{ data?.field_groendak_laagdikte_40 }}</td>
                <td>{{ parseAmount(data?.groendak_laagdikte_40_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</td>
              </tr>
              <tr>
                <td>Groenblauwdak</td>
                <td><b>{{ data?.field_groenblauwdak }}</b></td>
                <td><b>{{ parseAmount(data?.groenblauwdak_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr class="review-table-sub-field" *ngIf="displayFlags.showGroendakDetailFields">
                <td>Laagdikte 8-20cm</td>
                <td>{{ data?.field_groenblauwdak_laagdikte_8 }}</td>
                <td>{{ parseAmount(data?.groenblauwdak_laagdikte_8_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</td>
              </tr>
              <tr class="review-table-sub-field" *ngIf="displayFlags.showGroendakDetailFields">
                <td>Laagdikte 20-40cm</td>
                <td>{{ data?.field_groenblauwdak_laagdikte_20 }}</td>
                <td>{{ parseAmount(data?.groenblauwdak_laagdikte_20_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</td>
              </tr>
              <tr class="review-table-sub-field" *ngIf="displayFlags.showGroendakDetailFields">
                <td>Laagdikte meer dan 40cm</td>
                <td>{{ data?.field_groenblauwdak_laagdikte_40 }}</td>
                <td>{{ parseAmount(data?.groenblauwdak_laagdikte_40_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</td>
              </tr>
            </tbody>
            <tbody *ngIf="displayFlags.showPart5TypeB">
              <tr>
                <td>Waterberging van 18 liter/m²</td>
                <td><b>{{ data?.field_waterberging_18_liter_m2 }}</b></td>
                <td><b>{{ parseAmount(data?.waterberging_18_liter_m2_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr>
                <td>Waterberging van 30 liter/m²</td>
                <td><b>{{ data?.field_waterberging_30_liter_m2 }}</b></td>
                <td><b>{{ parseAmount(data?.waterberging_30_liter_m2_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr>
                <td>Waterberging van 50 liter/m²</td>
                <td><b>{{ data?.field_waterberging_50_liter_m2 }}</b></td>
                <td><b>{{ parseAmount(data?.waterberging_50_liter_m2_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr>
                <td>Waterberging van minimaal 30 liter/m² met meer dan 50% grassen, heesters en of inheemse soorten gebruikt worden en minder dan 50% sedum</td>
                <td><b>{{ data?.field_waterberging_30l_grassen }}</b></td>
                <td><b>{{ parseAmount(data?.waterberging_30l_grassen_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>
              <tr>
                <td>Waterberging van minimaal 50 liter/m² met meer dan 50% grassen, heesters en of inheemse soorten gebruikt worden en minder dan 50% sedum</td>
                <td><b>{{ data?.field_waterberging_50l_grassen }}</b></td>
                <td><b>{{ parseAmount(data?.waterberging_50l_grassen_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>              
              <tr>
                <td>Bouwkundige keuring dak keuringskosten</td>
                <td><b>{{ data?.field_dak_keuringskosten }}</b></td>
                <td><b>{{ parseAmount(data?.dak_keuringskosten_subsidie) | currency:'EUR':'symbol':undefined:'nl-NL' }}</b></td>
              </tr>         
            </tbody>            
          </table>

          <p *ngIf="displayFlags.showPart5TypeA && data?.field_groendak_minimum_oppervlak"><b>✓</b> Het groen(blauw)dak heeft een aaneengesloten oppervlakte van minimaal 20m².</p>
          <p *ngIf="displayFlags.showPart5TypeB && data?.field_groendak_minimum_oppervlak"><b>✓</b> Het groen(blauw)dak heeft een aaneengesloten oppervlakte van minimaal 6m².</p>

          <p>Upload een foto van het dak zoals voordat deze is vergroend.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_groendak_foto)"></ng-container>
          <p>Voeg een schets toe waarop te zien is hoe groot het groendak wordt en waar deze wordt geplaatst.</p>
          <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_groendak_schets)"></ng-container>
          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <p>Bon uploaden.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_groendak_bon)"></ng-container>
            <p>Datum van aankoop producten: <b>{{ formatDate(data?.field_groendak_datum_aankoop) }}</b></p>
            <p>Upload een foto van het dak dat vergroend is.</p>
            <ng-container *ngTemplateOutlet="images; context:imageContext(data?.field_groendak_foto_uitvoeren)"></ng-container>
            <p>Datum van uitvoering: <b>{{ formatDate(data?.field_groendak_datum_uitvoering) }}</b></p>
          </ng-container>
        </nb-accordion-item-body>
      </nb-accordion-item>       
      
    </nb-accordion>

    <div class="total">
      <span>Totaal subsidiebedrag:</span> <em>{{ parseAmount(data.totalSubsidy) | currency:'EUR':'symbol':undefined:'nl-NL' }}</em>
    </div>
  </section>  

  <hr>
  
  <section>
    <h6>Algemene gegevens</h6>
    <div class="columns">
      <div><span>Voorletter(s)</span><span>{{ data?.user?.field_initials }}</span></div>
      <div><span>Gemeente</span><span>{{ data.municipality }}</span></div>
      <div><span>IBAN</span><span>{{ data?.field_iban }}</span></div>
    </div>
    <div class="columns">
      <div><span>Voornaam</span><span>{{ data?.user?.field_given_name }}</span></div>
      <div><span>Straatnaam</span><span>{{ data?.user?.field_address_line1 }}</span></div>
      <div><span>Ten name van</span><span>{{ data?.field_iban_tnv }}</span></div>
    </div>
    <div class="columns">
      <div><span>Achternaam</span><span>{{ data?.user?.field_family_name }}</span></div>
      <div><span>Huisnummer</span><span>{{ data?.user?.field_address_line2 }}</span></div>
      <div></div>
    </div>
    <div class="columns">
      <div><span>E-mailadres</span><span>{{ data?.emailAddress }}</span></div>
      <div><span>Postcode</span><span>{{ data?.user?.field_postal_code }}</span></div>
      <div></div>
    </div>
    <div class="columns">
      <div><span>Telefoonnummer</span><span>{{ data?.user?.field_phone_number }}</span></div>
      <div><span>Plaats</span><span>{{ data?.user?.field_locality }}</span></div>
      <div></div>
    </div>
  </section>

  <hr>

</ng-container>