import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {NbDialogRef, NbDateService} from '@nebular/theme';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {some, ary} from 'lodash-es';

import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {EnergyLibraryService} from '../../../services/energy-library/energy-library.service';
import {AuthService} from '../../../services/auth/auth.service';
import {
  EnergyLibraryProductStatusLabels,
  EnergyLibraryAssignmentStatus,
  EnergyLibraryAssignmentStatusLabels,
  EnergyLibraryProduct,
  EnergyLibraryRequestView,
  EnergyLibraryAssignment } from '../../../models/energy-library.models';


@Component({
  selector: 'app-view-energy-library-request-dialog',
  templateUrl: './view-energy-library-request-dialog.component.html',
  styleUrls: ['./view-energy-library-request-dialog.component.scss', '../../../shared/shared.scss']
})
export class ViewEnergyLibraryRequestDialogComponent implements OnInit, OnDestroy {

  products$ = new BehaviorSubject<EnergyLibraryProduct[]>(null);
  assignment$ = new BehaviorSubject<EnergyLibraryAssignment>(null);

  request: EnergyLibraryRequestView;
  productType: string;
  assignmentId?: string;
  fixedProduct: string;

  currentPage$ = new BehaviorSubject(1);
  isSaving$ = new BehaviorSubject(false);
  dateRangeModel$ = new BehaviorSubject({ start: new Date(), end: new Date() });
  productModel$ = new BehaviorSubject(undefined);
  statusModel$ = new BehaviorSubject(undefined);

  constructor(
    protected ref: NbDialogRef<ViewEnergyLibraryRequestDialogComponent>,
    private dateService: NbDateService<Date>,
    private energyLibraryService: EnergyLibraryService,
    private majadaToastrService: MajadaToastrService,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.loadAvailableProducts();
    this.loadAssignment();
    console.log('viewEnergyLibraryRequest dialog request', this.request);
  }

  ngOnDestroy(): void {
    this.products$.complete();
    this.assignment$.complete();
    this.productModel$.complete();
    this.statusModel$.complete();
    this.isSaving$.complete();
    this.currentPage$.complete();
    this.dateRangeModel$.complete();
  }

  private loadAvailableProducts() {
    this.energyLibraryService.getAvailableProducts(this.productType, this.request.locatie_uuid).subscribe(products => {
      this.products$.next(products);
    });
  }

  private loadAssignment() {
    if (!this.assignmentId) return;
    this.energyLibraryService.getAssignmentById(this.assignmentId).subscribe(assignment => {
      this.assignment$.next(assignment);
    });
  }

  close() {
    this.ref.close();
  }
}
