<div class="card closable-dialog">
  <nb-card>
    <nb-card-header>Aanvraaggegevens</nb-card-header>
    <nb-card-body>
      <div>
        <dl>
          <dt>Aanvraag datum:</dt><dd>Placeholder</dd>
          <dt>Product:</dt><dd>{{request.product_categorie}}</dd>
          <dt>Energiebieb locatie:</dt><dd>{{request.locatie}}</dd>
        </dl>
        <dl>
          <dt *ngIf="request.naam">Naam:</dt><dd *ngIf="request.naam">{{request.naam}}</dd>
          <dt *ngIf="request.adres_raw.address_line1 != '' ">Straatnaam + huisnr:</dt><dd *ngIf="request.adres_raw.address_line1 != ''">{{request.adres_raw.address_line1}}</dd>
          <dt *ngIf="request.adres_raw.postal_code">Postcode:</dt><dd *ngIf="request.adres_raw.postal_code">{{request.adres_raw.postal_code}}</dd>
          <dt *ngIf="request.adres_raw.locality">Woonplaats:</dt><dd *ngIf="request.adres_raw.locality">{{request.adres_raw.locality}}</dd>
          <dt *ngIf="request.gemeente">Gemeente:</dt><dd *ngIf="request.gemeente">{{request.gemeente}}</dd>
          <dt *ngIf="request.emailadres">E-mailadres:</dt><dd *ngIf="request.emailadres">{{request.emailadres}}</dd>
          <dt *ngIf="request.telefoonnummer">Telefoonnummer:</dt><dd *ngIf="request.telefoonnummer">{{request.telefoonnummer}}</dd>
          <dt *ngIf="request.opmerking">Opmerking:</dt><dd *ngIf="request.opmerking">{{request.opmerking}}</dd>
        </dl>
      </div>
    </nb-card-body>
  </nb-card>
  <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
</div>
