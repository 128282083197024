import {Component, Inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {every} from 'lodash-es';
import {ApiService} from '../../../services/api/api.service';
import {NbDialogService} from '@nebular/theme';
import {MyVisitorInformationComponent} from '../my-visitor-information/my-visitor-information.component';

@Component({
  selector: 'app-object-visitors',
  templateUrl: './object-visitors.component.html',
  styleUrls: ['./object-visitors.component.scss']
})
export class ObjectVisitorsComponent implements OnInit {

  get pdb() {
    return this.project === 'platformduurzamebedrijven';
  }

  get tkr() {
    return this.project === 'tuinenklimaatroute';
  }

  get vd() {
    return this.project === 'vd';
  }

  get ndhr() {
    return this.project === 'ndhr';
  }

  @Input()
  objectId;

  @Input()
  registrations;

  subscription: Subscription;
  dates;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialogService: NbDialogService,
    @Inject('project') private project: string) {
  }

  get isEmpty() {
    return !this.registrations || every(this.registrations, reg => !reg?.times?.length);
  }

  ngOnInit(): void {
    if (this.objectId && !this.registrations) {
      this.subscription = new Subscription();
      const registrationsSub = this.apiService.get(`/api/route/get-object-registrations/${this.objectId}`).subscribe(registrations => {
        this.dates = registrations;
      });
      this.subscription.add(registrationsSub);
    }
  }

  show(registrationData, dateId, timeId) {
    this.router.navigate([`/mijn-bezoekers/inschrijving/${registrationData.id}/${dateId}/${timeId}`]);
  }

  showDialog(registrationData, time, date) {
    this.dialogService.open(MyVisitorInformationComponent, {context: {registrationData, time, date}});
  }
}
