import {Component} from '@angular/core';
import {AuthService} from './services/auth/auth.service';
import {environment} from '../environments/environment';
import {NbMenuItem} from '@nebular/theme';
import {OAuthService} from 'angular-oauth2-oidc';
import {LocationsService} from './services/locations/locations.service';
import * as jsonApiUtils from './utils/json-api.utils';
import {Observable} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  environment = environment;
  title = 'mijn-veluweduurzaam';
  items: NbMenuItem[] = [];

  constructor(private authService: AuthService, private oAuthService: OAuthService, private locationsService: LocationsService) {
    this.authService.runInitialLoginSequence().then(result => {
      if (this.authService.hasValidToken()) {
        oAuthService.loadUserProfile().then(userProfileResult => {
          this.setMenuItems(userProfileResult);
          // Set active domain
          this.authService.setActiveDomains(userProfileResult);
        });
      } else {
        localStorage.clear();
      }
    });
    this.authService.isAuthenticated$.subscribe(result => {
      if (result === true) {
        this.setMenuItems(this.authService.identityClaims);
        this.authService.setActiveDomains(this.authService.identityClaims);
      } else {
        this.items = [];
      }
    });
  }


  // constructor(private authService: AuthService, private oAuthService: OAuthService, private locationsService: LocationsService) {
  //   this.authService.runInitialLoginSequence().then(result => {
  //     if (this.authService.hasValidToken()) {
  //       oAuthService.loadUserProfile().then(userProfileResult => {
  //         this.setMenuItems(userProfileResult);
  //       });
  //     }
  //     //const identityClaims = oAuthService.getIdentityClaims();
  //   });
  //   this.authService.isAuthenticated$.subscribe(result => {
  //     if (result === true) {
  //       this.setMenuItems(this.authService.identityClaims);
  //     } else {
  //       this.items = [];
  //     }
  //   });
  // }

  setMenuItems(identityClaims: object) {
    if (identityClaims && identityClaims['roles']) {
      this.items = [];
      const energyLibraryItems = [];

      if (identityClaims['roles'].includes('gemeente') || identityClaims['roles'].includes('administrator') || identityClaims['roles'].includes('energiecoach') || identityClaims['roles'].includes('tuincoach')) {
        this.items.push({
            title: 'Dashboard',
            link: '/dashboard',
            icon: 'home-outline',
            pathMatch: 'full'
          },
          {
            title: 'Nieuws',
            link: '/dashboard/nieuws',
            icon: 'menu-outline',
            pathMatch: 'full'
          },
          {
            title: 'Inbox',
            link: '/inbox',
            icon: 'inbox-outline',
            pathMatch: 'full'
          },
        );
      }

      if (!identityClaims['roles'].includes('gemeente') && !identityClaims['roles'].includes('administrator') && !identityClaims['roles'].includes('energiecoach') && (identityClaims['roles'].includes('procesbegeleider') || identityClaims['roles'].includes('adviseur'))) {
        this.items.push(
          {
            title: 'Dashboard',
            link: '/dashboard',
            icon: 'home-outline',
            pathMatch: 'full'
          },
          {
            title: 'Nieuws',
            link: '/dashboard/nieuws',
            icon: 'menu-outline',
            pathMatch: 'full'
          });
      }
      if (identityClaims['roles'].includes('adviseur') || identityClaims['roles'].includes('administrator')) {
        //console.log('identityClaims appComponent', identityClaims);
        this.items.push(
          {
            title: 'Energieadviesgesprekken',
            link: '/mijn-afspraken',
            icon: 'calendar-outline'
          });
      }
      if (identityClaims['roles'].includes('administrator') || identityClaims['roles'].includes('bibliotheek_medewerker') || identityClaims['roles'].includes('energiebieb_beheerder')) {
        //console.log('identityClaims appComponent', identityClaims);
        energyLibraryItems.push({
          title: 'Energiebieb aanvragen',
          link: '/energiebieb/aanvragen',
          icon: 'book-outline'
        });
      }
      if (identityClaims['roles'].includes('administrator') || identityClaims['roles'].includes('energiebieb_beheerder')) {
        energyLibraryItems.push({
          title: 'Energiebieb producten',
          link: '/energiebieb/producten',
          icon: 'cube-outline'
        });
        energyLibraryItems.push({
          title: 'Energiebieb locaties',
          link: '/energiebieb/locaties',
          icon: 'navigation-2-outline'
        });
      }

      const subsidyItems = [];

      if (identityClaims['roles'].includes('authenticated')) {
        subsidyItems.push({
          title: 'Mijn subsidieaanvragen',
          link: '/subsidies/mijn-aanvragen',
          icon: 'credit-card-outline'
        });
        if (!identityClaims['roles'].includes('gemeente_medewerker')) {
          subsidyItems.push({
            title: 'Subsidie aanvraagformulier',
            link: '/subsidies/aanvraagformulier',
            icon: 'file-text-outline'
          });
        }
        if (identityClaims['roles'].includes('gemeente_medewerker') || identityClaims['roles'].includes('administrator') || identityClaims['roles'].includes('veluwe_duurzaam_beheerder')) {
          subsidyItems.push({
            title: 'Statistieken',
            link: '/subsidies/statistieken',
            icon: 'bar-chart-outline'
          });
        }
      }

      if (energyLibraryItems.length > 0) {
        this.items.push({
          title: 'Energiebieb',
          expanded: false,
          icon: 'book-outline',
          children: energyLibraryItems
        });
      }

      if (subsidyItems.length > 0) {
        this.items.push({
          title: 'Subsidies',
          expanded: false,
          icon: 'credit-card-outline',
          children: subsidyItems
        });
      }

      /*if (identityClaims['roles'].includes('administrator')) {
        this.items.push(
          {
            title: 'Mijn locaties',
            link: '/mijn-locaties',
            icon: 'pin-outline'
          });
      }*/

      this.items.push({
        title: 'Mijn account',
        icon: 'person-outline',
        children: [
          {
            title: 'Profiel bewerken',
            icon: 'edit-outline',
            link: '/profiel'
          }
        ]
      });

      if (!identityClaims['roles'].includes('administrator')) {
        /*const sub = this.locationsService.get().subscribe(result => {
          const locations = jsonApiUtils.parseResponseMany(result);
          console.log('locations', locations);
          if (locations.length > 0) {
            this.items.push({
                title: 'Mijn locaties',
                link: '/mijn-locaties',
                icon: 'pin-outline'
              }
            );
          }
        });*/
      }
      if (identityClaims['roles'].includes('administrator')) {
        //console.log('identityClaims appComponent', identityClaims);
        this.items.push();
      }
    }
  }
}
