import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../api/api.service';
import {map} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  constructor(private http: HttpClient, private apiService: ApiService) {
  }


  private appointmentsChangedSubj = new Subject();
  appointmentsChanged$ = this.appointmentsChangedSubj.asObservable();

  getProcessedEvents(): Observable<any> {
    return this.apiService.get('/api/reservations/appointments?_format=json').pipe(map(result => {

        const calendarEvents = [];

        result.products.forEach(product => {
          const dates = product.reservations;
          Object.entries(dates).forEach(([key, times]) => {
            Object.entries(times).forEach(([time, reservations]) => {
              reservations.forEach(reservation => {
                reservation.booked_times.forEach(bookedTime => {
                  calendarEvents.push({
                    title: product.label,
                    start: bookedTime,
                    extendedProps: {
                      submissionData: reservation.submission_data,
                      reservationId: reservation.reservation_id,
                    }
                  });
                });
              });
            });
          });
        });
        return calendarEvents;
      }
    ));
  }

  appointmentsChanged() {
    this.appointmentsChangedSubj.next();
  }

}
