import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class MajadaToastrService {

  constructor(private toastrService: NbToastrService) {
  }

  success(message: string, title = 'Opgeslagen') {
    this.toastrService.show(
      message,
      title,
      {status: 'success', icon: 'checkmark-outline'}
    );
  }

  warning(message: string, title = 'Waarschuwing') {
    this.toastrService.show(
      message,
      title,
      {status: 'warning', icon: 'alert-triangle-outline'}
    );
  }

  info(message: string, title = 'Info') {
    this.toastrService.show(
      message,
      title,
      {status: 'info', icon: 'alert-triangle-outline'}
    );
  }

  /**
   * Wrapper for the danger status with a different default title.
   */
  error(message: string, title = 'Fout') {
    this.danger(message, title);
  }

  danger(message: string, title = $localize`:@@toastDanger:Danger`) {
    this.toastrService.show(
      message,
      title,
      {status: 'danger', icon: 'close-outline'}
    );
  }
}
