<nb-layout>
  <nb-layout-column>
    <h1>Inschrijven bezoek <span *ngIf="object">aan {{object.title[0].value}}</span></h1>
    <div class="row">
      <div class="col-12">
        <nb-card [nbSpinner]="!object" class="registration-form-card">
          <nb-card-body *ngIf="object && objectAttributes else invalidData">
            <div class="object-preview" *ngIf="object">
              <h4>{{object.title[0].value}}</h4>
              <div class="row">
                <div class="col-md-9 left">
                  <div class="description" [innerHTML]="object.field_content[0].value"
                       *ngIf="object.field_content && object.field_content[0]"></div>
                  <div class="properties">
                    <div class="year" *ngIf="objectAttributes.field_bouwjaar && objectAttributes.field_bouwjaar[0]">
                      <strong>Bouwjaar:</strong> {{objectAttributes.field_bouwjaar[0]?.value}}
                    </div>
                    <ng-container *ngIf="termProperties">
                      <div class="property" *ngIf="termProperties.energy_label && termProperties.energy_label[0]">
                        <strong>Energielabel:</strong> {{termProperties.energy_label[0]}}
                      </div>
                      <div class="property" *ngIf="termProperties.type_home && termProperties.type_home[0]">
                        <strong>Soort:</strong> {{termProperties.type_home[0]}}
                      </div>
                      <div class="property" *ngIf="termProperties.type_eco_home && termProperties.type_eco_home[0]">
                        <strong>Type duurzame woning:</strong>
                        <ng-container *ngFor="let type of termProperties.type_eco_home; let last = last"> {{type}}<span
                          *ngIf="!last">, </span></ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="col-md-3 right">
                  <img [src]="imageUrl">
                  <a nbButton status="primary" ghost [href]="wordpressUrl + object.path[0].alias"
                     target="_blank"
                     *ngIf="location && ndhr">Bekijk woning</a>
                  <a nbButton status="primary" ghost [href]="wordpressUrl + object.path[0].alias"
                     target="_blank"
                     *ngIf="location && pdb">Bekijk bedrijf</a>
                  <a nbButton status="primary" ghost [href]="wordpressUrl + object.path[0].alias"
                     target="_blank"
                     *ngIf="location && tkr">Bekijk tuin of project</a>
                </div>
              </div>
            </div>
            <hr/>
            <form [formGroup]="visitorRegistrationForm" (ngSubmit)="submitRegistration()"
                  *ngIf="dateOptions.length > 0 && !registrationSubmitted">
              <div class="form-item">
                <label class="label required">Datum</label>
                <nb-radio-group formControlName="date" placeholder="Datum" id="date" name="date">
                  <nb-radio *ngFor="let dateOption of dateOptions"
                            [value]="dateOption.id">{{dateOption.label | date: 'd MMMM y'}}</nb-radio>
                </nb-radio-group>
              </div>
              <div class="opening-time-additional-information">
                <p class="company" *ngIf="this.currentDateHasCompany">Bij deze woning is een bedrijf aanwezig.</p>
                <div class="company" *ngIf="this.currentDateRemark">
                  <span *ngIf="ndhr" class="label">Opmerking van huiseigenaar:</span>
                  <span *ngIf="pdb" class="label">Opmerking van bedrijf:</span>
                  <div>{{currentDateRemark}}</div>
                </div>
              </div>
              <div class="form-item">
                <label class="label required">Tijd</label>
                <ng-container *ngIf="availableTimes.length > 0; else selectDate">
                  <nb-radio-group formControlName="time" id="time" name="time">
                    <nb-radio *ngFor="let availableTime of availableTimes"
                              [value]="availableTime.id" [disabled]="availableTime.availablePlaces <= 0"><span
                      [innerHTML]="availableTime.label"></span></nb-radio>
                  </nb-radio-group>
                </ng-container>
                <ng-template #selectDate>Selecteer eerst een datum.</ng-template>
              </div>
              <div class="form-item">
                <label class="label required">Naam</label>
                <input nbInput type="text" placeholder="Naam" formControlName="name">
              </div>
              <!--<div class="form-item" *ngIf="!pdb">
                <label class="label">Gemeente</label>
                <input #autoInput nbInput type="text" (input)="onChangeMunicipality($event)" placeholder="Gemeente"
                       [nbAutocomplete]="auto"/>
                <div *ngIf="isSearchingMunicipalities" [nbSpinner]="true" nbSpinnerStatus="primary"
                     nbSpinnerSize="small"
                     class="spinner"></div>
                <nb-autocomplete #auto (selectedChange)="onSelectMunicipality($event)"
                                 [handleDisplayFn]="municipalityViewHandle">
                  <nb-option *ngFor="let option of filteredMunicipalities$ | async" [value]="option">
                    {{ option.value }}
                  </nb-option>
                </nb-autocomplete>
              </div>
              <div class="form-item" *ngIf="!pdb">
                <nb-checkbox formControlName="unlistedMunicipality">Mijn gemeente staat er niet tussen</nb-checkbox>
              </div>-->
              <div class="form-item">
                <label class="label required">Woonplaats</label>
                <input type="text" nbInput placeholder="Woonplaats" formControlName="locality">
              </div>
              <div class="form-item form-item-inline">
                <label class="label required">E-mailadres</label>
                <input nbInput type="email" placeholder="E-mailadres" formControlName="email">
                <input nbInput type="email" placeholder="E-mailadres bevestigen" formControlName="emailConfirm">
              </div>
              <div class="form-item">
                <label class="label">Telefoonnummer</label>
                <input nbInput type="text" placeholder="Telefoonnummer" formControlName="phone">
              </div>
              <div class="form-item">
                <label class="label required">Aantal personen (maximaal 3)</label>
                <input nbInput type="number" placeholder="Aantal personen" formControlName="visitors">
              </div>
              <div class="form-item">
                <label *ngIf="ndhr" class="label">Welke vragen heeft u aan de huiseigenaar? Deze kunt u hier alvast
                  stellen</label>
                <label *ngIf="pdb" class="label">Welke vragen heeft u aan het bedrijf? Deze kunt u hier alvast
                  stellen</label>
                <textarea formControlName="questionsOwner" nbInput></textarea>
              </div>
              <div class="form-item">
                <label *ngIf="ndhr" class="label">Overige opmerkingen voor de huiseigenaar</label>
                <label *ngIf="pdb" class="label">Overige opmerkingen voor het bedrijf</label>
                <textarea formControlName="generalRemarks" nbInput></textarea>
              </div>
              <div class="form-item">
                <nb-checkbox *ngIf="ndhr" formControlName="privacy">Ik ga akkoord met de <a
                  href="https://duurzamehuizenroute.nl/privacyvoorwaarden">privacyvoorwaarden</a>.
                  Mijn gegevens worden bijvoorbeeld gedeeld met de woningeigenaar ter voorbereiding op de Nationale
                  Duurzame Huizen Route.
                </nb-checkbox>
                <nb-checkbox *ngIf="tkr" formControlName="privacy">Ik ga akkoord met de <a
                  href="https://tuinenklimaatroute.nl/privacyvoorwaarden">privacyvoorwaarden</a>.
                  Mijn gegevens worden bijvoorbeeld gedeeld met de tuin-/projecteigenaar ter voorbereiding op de
                  Nationale Tuin & Klimaat Route.
                </nb-checkbox>
                <nb-checkbox *ngIf="pdb" formControlName="privacy">Ik ga akkoord met de <a
                  href="https://platformduurzamebedrijven.nl/privacyvoorwaarden">privacyvoorwaarden</a>.
                  Mijn gegevens worden bijvoorbeeld gedeeld met de bedrijfeigenaar ter voorbereiding op de Duurzame
                  Bedrijven Route.
                </nb-checkbox>
              </div>
              <div class="form-item">
                <nb-checkbox *ngIf="ndhr" formControlName="newsletter">Ik ontvang graag de nieuwsbrief van de Nationale
                  Duurzame Huizen Route.
                </nb-checkbox>
                <nb-checkbox *ngIf="tkr" formControlName="newsletter">Ik ontvang graag de nieuwsbrief van de Nationale
                  Tuin & Klimaat Route.
                </nb-checkbox>
                <nb-checkbox *ngIf="pdb" formControlName="newsletter">Ik ontvang graag de nieuwsbrief van Platform
                  Duurzame Bedrijven.
                </nb-checkbox>
              </div>
              <div class="form-actions">
                <re-captcha (resolved)="recaptchaResolved($event)" [siteKey]="recpatchaKey"
                            required formControlName="captcha"></re-captcha>
                <input type="submit" nbButton status="success" value="Aanmelden"
                       [disabled]="visitorRegistrationForm.invalid">
              </div>
            </form>
            <div class="" *ngIf="registrationSubmitted">
              <p *ngIf="ndhr">Bedankt voor je inschrijving voor een bezoek tijdens de Nationale Duurzame Huizen
                Route.</p>
              <p *ngIf="pdb">Bedankt voor je inschrijving voor een bezoek tijdens Duurzame Bedrijven Route.</p>
              <p *ngIf="tkr">Bedankt voor je inschrijving voor een bezoek tijdens Tuin en Klimaat Route.</p>
            </div>
            <ng-container *ngIf="showError">
              <p>Je kan je niet (meer) inschrijven voor een bezoek aan dit object.</p>
            </ng-container>
          </nb-card-body>
          <ng-template #invalidData>
            <nb-card-body *ngIf="doneLoading">
              <div [innerHTML]="errorMessage">
              </div>
            </nb-card-body>
          </ng-template>
        </nb-card>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
