import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbThemeModule,
  NbToastrModule,
  NbTreeGridModule,
  NbAutocompleteModule,
  NbDatepickerModule,
  NbSpinnerModule,
  NbAccordionModule,
  NbFormFieldModule,
  NbSidebarModule,
  NbSidebarService,
  NbMenuModule,
  NbContextMenuModule,
  NbSelectModule,
  NbStepperModule,
  NbTooltipModule,
  NbRadioModule, NbChatModule, NbTabsetModule
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';

import {NgSelectModule} from '@ng-select/ng-select';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {SafeHtmlPipe} from '../pipes/safe-html.pipe';
import {TruncatePipe} from '../pipes/truncate.pipe';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from '../components/layout/header/header.component';
import {UploadDropzoneComponent} from '../components/widgets/upload-dropzone/upload-dropzone.component';
import {UploadDropzonePreviewComponent} from '../components/widgets/upload-dropzone/upload-dropzone-preview.component';
import {StatusMessagesComponent} from '../components/status-messages/status-messages.component';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    TruncatePipe,
    HeaderComponent,
    UploadDropzoneComponent,
    UploadDropzonePreviewComponent,
    StatusMessagesComponent
  ],
  providers: [
    NbSidebarService
  ],
  imports: [
    NgxDropzoneModule,
    CommonModule,
    NbTabsetModule,
    NgSelectModule,
    NbThemeModule.forRoot({name: 'majada'}),
    NbLayoutModule,
    NbEvaIconsModule,
    NbCardModule,
    NbButtonModule,
    NbInputModule,
    NbFormFieldModule,
    NbIconModule,
    NbActionsModule,
    NbListModule,
    NbToastrModule.forRoot({duration: 10000}),
    NbCheckboxModule,
    NbSpinnerModule,
    NbAccordionModule,
    NbTreeGridModule,
    NbAutocompleteModule,
    NbDatepickerModule.forRoot(),
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbSelectModule,
    NbStepperModule,
    NbTooltipModule,
    NbRadioModule,
    NbSidebarModule.forRoot(),
    NbChatModule,
    FormsModule,
    ReactiveFormsModule,
  ], exports: [
    HeaderComponent,
    NbTabsetModule,
    NgSelectModule,
    NbIconModule,
    SafeHtmlPipe,
    NbEvaIconsModule,
    NbCardModule,
    NbThemeModule,
    NbButtonModule,
    NbInputModule,
    NbFormFieldModule,
    NbIconModule,
    NbLayoutModule,
    NbActionsModule,
    NbListModule,
    NbToastrModule,
    TruncatePipe,
    NbCheckboxModule,
    NbSpinnerModule,
    NbAccordionModule,
    NbTreeGridModule,
    NbAutocompleteModule,
    NbDatepickerModule,
    NbSidebarModule,
    NbMenuModule,
    NbContextMenuModule,
    NbSelectModule,
    NbStepperModule,
    NbTooltipModule,
    NbRadioModule,
    NbChatModule,
    FormsModule,
    ReactiveFormsModule,
    UploadDropzoneComponent,
    UploadDropzonePreviewComponent,
    StatusMessagesComponent
  ]
})
export class SharedModule {
}
