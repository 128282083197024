import {Inject, Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {filter, map} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StatusMessagesService {

  constructor(private apiService: ApiService, private router: Router, @Inject('domainUuid') private domainUuid: string) {

  }

  getStatusMessages(uuid: string, type: string, path: string) {
    return this.apiService.get(`/api/messages/${uuid}/${type}?_format=json&path=${path}`).pipe(
      map(data => {
        if (data && data.messages) {
          return data.messages;
        }
      })
    );
  }
}
