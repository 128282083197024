import {Inject, Injectable} from '@angular/core';
import {flatMap, map, tap} from 'rxjs/operators';
import * as jsonApiUtils from '../../utils/json-api.utils';
import {User} from '../../models/drupal-datatypes.models';
import {AuthService} from '../auth/auth.service';
import {ApiService} from '../api/api.service';
import {of} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private apiService: ApiService,
    @Inject('drupalUrl') private drupalUrl: string
  ) {
  }

  getUser() {
    const uri = `/api/user/${this.authService.uuid}`;
    return this.apiService.get(uri);
  }

  getUsersByRoles(roles: string[]) {
    const uri = `/api/userlist?roles=${roles.join(';')}`;
    return this.apiService.get(uri);
  }

  updateCurrentUser(fields: any, profilePhotoFile?: File) {
    return this.updateUser(this.authService.uuid, fields, profilePhotoFile);
  }

  updateUser(userId: string, fields: any, profilePhotoFile?: File) {
    const maybePostImage$ = profilePhotoFile ?
      this.apiService.postFile('/jsonapi/node/locatie/field_afbeelding', profilePhotoFile) : of({});

    const updateUser$ = (imageId?: string) => {
      fields.field_profielfoto = imageId;
      return this.http.post<any>(this.drupalUrl + `/api/user/${userId}?_format=json`, fields);
    };

    return maybePostImage$.pipe(
      map(res => res?.data?.id),
      flatMap(updateUser$)
    );

  }
}
