<nb-card>
  <nb-card-header>
    <h3>Energiebieb producten</h3>
  </nb-card-header>
  <nb-card-body>
    <button (click)="addNewProduct()" size="small" status="success" outline nbButton [disabled]="!canEdit">Product toevoegen</button>
    <section class="filters">
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input id="input-name-filter" fieldSize="small" type="text" fullWidth placeholder="Naam" shape="round" nbInput
          [ngModel]="nameFilterModel$ | async" (ngModelChange)="nameFilterModel$.next($event)">
      </nb-form-field>
      <nb-select id="select-status" placeholder="Status" [ngModel]="statusFilterModel$ | async" (ngModelChange)="statusFilterModel$.next($event)">
        <nb-option>Alle</nb-option>
        <nb-option *ngFor="let i of [1,2,3,4]" [value]="i.toString()">
          {{EnergyLibraryProductStatusLabels[i.toString()]}}</nb-option>
      </nb-select>
    </section>
    <section class="products" [nbSpinner]="isLoading$ | async" nbSpinnerStatus="basic">
      <ng-container *ngIf="!(isLoading$ | async)">
        <ng-container *ngIf="products && products.length > 0; else noProducts">
          <table>
            <thead>
              <tr>
                <th class="col-id">ID</th>
                <th class="col-status">Status</th>
                <th class="col-name">Productnaam</th>
                <th class="col-location">Energiebieb locatie</th>
                <th class="col-municipality">Gemeente</th>
                <th class="col-category">Categorie</th>
                <th class="col-number">Inventarisnummer</th>
                <th class="col-actions"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let product of products">
                <td>{{product.drupal_internal__id}}</td>
                <td>{{EnergyLibraryProductStatusLabels[product.field_status]}}</td>
                <td>{{product.title}}</td>
                <td>{{product.field_ref_energiebieb_locatie?.title}}</td>
                <td [nbSpinner]="!municipalities" nbSpinnerSize="tiny" nbSpinnerStatus="basic">{{municipalities && municipalities[product.field_gemeente]}}</td>
                <td [nbSpinner]="!types" nbSpinnerSize="tiny" nbSpinnerStatus="basic">{{types && types[product.field_product_categorie]}}</td>
                <td>{{product.field_inventarisnummer}}</td>
                <td><button (click)="editProduct(product)" size="small" status="basic" outline nbButton [disabled]="!canEdit">Bewerken</button></td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </ng-container>
      <ng-template #noProducts>
        <p class="no-results">Er zijn geen resultaten voor de huidige zoekopdracht.</p>
      </ng-template>
    </section>
  </nb-card-body>
