import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {compact, flatten, isArray, isString, memoize, values} from 'lodash-es';

import {SubsidyService} from '../../../services/subsidy/subsidy.service';
import {SubsidyFormState, getDisplayFlags} from './subsidy-form-state';
import {
  SubsidyRequestMunicipalityLabels,
  SubsidyRequestTypeLabels,
  SubsidyGardenAreaLabels,
  SubsidyGardenLocationLabels,
  SubsidyRequestSituationLabels,
  SubsidyRequest
} from '../../../models/subsidy.models';
import {ApiService} from 'src/app/services/api/api.service';

@Component({
  selector: 'app-subsidy-request-review',
  templateUrl: './subsidy-request-review.component.html',
  styleUrls: ['./subsidy-request-form.component.scss']
})
export class SubsidyRequestReviewComponent implements OnChanges {

  @Input() data: any;

  subs: Subscription[] = [];
  images = [];

  SubsidyRequestTypeLabels = SubsidyRequestTypeLabels;
  SubsidyRequestSituationLabels = SubsidyRequestSituationLabels;
  SubsidyRequestMunicipalityLabels = SubsidyRequestMunicipalityLabels;
  SubsidyGardenLocationLabels = SubsidyGardenLocationLabels;
  SubsidyGardenAreaLabels = SubsidyGardenAreaLabels;

  getImagePreviews = memoize((uuids: string[]) => {
    return compact(uuids.map(uuid => {
      const image = (this.images || []).find(x => x.uuid === uuid);
      let thumbUrl = image?.thumbUrl;
      console.log('thumbUrl', thumbUrl);
      try {
        if (thumbUrl) {
          const url = new URL(thumbUrl);
          if (url && url.pathname.endsWith('.pdf')) {
            thumbUrl = '/assets/pdf_icon.png';
          }
        }
      } finally {
        console.log('invalid thumbnail url', thumbUrl);
      }
      if (image) {
        return {data: thumbUrl, url: image.largeUrl};
      }
      return this.apiService.getFile(uuid);
    }));
  }, x => JSON.stringify(x));

  constructor(
    protected apiService: ApiService,
    protected subsidyService: SubsidyService,
    protected formBuilder: FormBuilder) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.data?.currentValue) {
      this.images = flatten(values(changes.data.currentValue.images));
      return;
    }
  }

  get displayFlags() {
    return this.data?.displayFlags;
  }

  imageContext(uuid: string | string[]) {
    const uuids = isArray(uuid) ? uuid : [uuid];
    return {images: this.getImagePreviews(uuids)};
  }

  openImage(url: string) {
    window.open(url);
  }

  parseAmount(amount: string) {
    if (!amount) return 0;
    if (!isString(amount)) return amount;
    return parseFloat(amount.replace(',', '.'));
  }

  formatDate(val?: string) {
    if (!val) {
      return 'niet ingevuld';
    }
    const date = new Date(val);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }

}
