import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../environments/environment';

export const authConfig: AuthConfig = {
  // Url of the provider
  issuer: environment.issuerUrl + '/',
  skipIssuerCheck: true,
  redirectUri: window.location.origin + '/',
  scope: 'openid profile email offline_access',
  requireHttps: false,
  oidc: false,
  responseType: 'token id_token',
  requestAccessToken: true,
  silentRefreshTimeout: 1000,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  timeoutFactor: 0.5,
  clientId: 'majada_oauth',
  dummyClientSecret: 'placeholderSecret',
  silentRefreshShowIFrame: false,
  /*tokenEndpoint: environment.issuerUrl + '/oauth2/token',*/
};
