import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {defaultIfEmpty, expand, flatMap, map, reduce, take, tap} from 'rxjs/operators';
import {forkJoin, Observable, EMPTY} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private fileUrls = {};
  private files = {};

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/vnd.api+json',
      Accept: 'application/vnd.api+json'
    })
  };

  constructor(private http: HttpClient, @Inject('drupalUrl') private drupalUrl: string) {
  }

  get(path: string, followNext: boolean = true) {
    return this.http.get<any>(this.drupalUrl + path).pipe(
      expand(response => {
        if (followNext && response && response.jsonapi && response.links && response.links.next) {
          return response.links?.next ? this.http.get<any>(response.links.next.href) : EMPTY;
        }
        return EMPTY;
      }),
      reduce((accData: any, data: any) => {
        if (accData.length === 0) {
          accData = data;
        } else {
          accData.data = [...accData.data, ...data.data];
          if (accData.included && data.included) {
            accData.included = [...accData.included, ...data.included];
          } else if (!accData.included && data.included) {
            accData.included = data.included;
          }
        }
        if (accData) {
          return accData;
        }
      }, []),
      map(response => response),
      take(1),
    );
  }

  patch(path: string, body: any) {
    return this.http.patch<any>(this.drupalUrl + path, body, this.httpOptions).pipe(take(1));
  }

  post(path: string, body: any) {
    return this.http.post<any>(this.drupalUrl + path, body, this.httpOptions).pipe(take(1));
  }

  postFile(path: string, file: File) {
    const sanitizedFilename = file.name.replace(/[^a-zA-Z0-9\_\ \.]/g, '');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.api+json',
        'Content-Disposition': `file; filename="${sanitizedFilename}"`
      })
    };
    return this.http.post<any>(this.drupalUrl + path, file, httpOptions).pipe(
      take(1),
      tap(res => this.fileUrls[res.data.id] = this.drupalUrl + res.data.attributes.uri.url),
      flatMap(res => new Observable((observer) => {
        const reader = new FileReader();
        reader.onload = () => observer.next({res, data: reader.result});
        reader.onloadend = () => observer.complete();
        reader.onerror = e => observer.error(e);
        reader.onabort = e => observer.error(e);
        reader.readAsDataURL(file);
      }).pipe(take(1))),
      tap(({res, data}) => this.files[res.data.id] = data),
      map(({res}) => res)
    );
  }

  getAbsolute(path: string) {
    return this.http.get<any>(path).pipe(map(result => {
      return result;
    }));
  }

  delete(path: string) {
    return this.http.delete(this.drupalUrl + path, this.httpOptions).pipe(map(result => {
      return result;
    }));
  }

  deleteObjects(objects: Map<string, string>) {
    const deletes$ = Array.from(objects).map(([uuid, type]) => {
      const [ bundle, ent ] = type.split('--');
      console.log('delete ', `/jsonapi/${bundle}/${ent}/${uuid}`);
      return this.delete(`/jsonapi/${bundle}/${ent}/${uuid}`);
    });
    return forkJoin(deletes$).pipe(defaultIfEmpty([]));
  }

  getFile(uuid: string) {
    if (!uuid) {
      return undefined;
    }
    if (this.files[uuid].startsWith('data:application/pdf')) {
      return {url: this.fileUrls[uuid], data: '/assets/pdf_icon.png'};
    } else {
      return {url: this.fileUrls[uuid], data: this.files[uuid]};
    }
  }
}
