import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DrupalNodeComponent} from './drupal-node/drupal-node.component';
import {DrupalNodeResolver} from '../services/node/drupal-node-resolver.service';


const routes: Routes = [
  {
    path: '**',
    component: DrupalNodeComponent,
    resolve: {
      node: DrupalNodeResolver
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NodeRoutingModule {
}
