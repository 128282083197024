import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {EnergyLibraryLocation, EnergyLibraryProduct, EnergyLibraryProductStatusLabels} from 'src/app/models/energy-library.models';
import {EnergyLibraryService} from 'src/app/services/energy-library/energy-library.service';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';

@Component({
  selector: 'app-edit-product-dialog',
  templateUrl: './edit-product-dialog.component.html',
  styleUrls: ['./edit-product-dialog.component.scss', '../../../shared/shared.scss']
})
export class EditProductDialogComponent implements OnInit {

  isLoading: boolean;
  isSaving: boolean;

  isNewProduct = true;
  product: EnergyLibraryProduct;

  categories: { id: string, value: string }[];
  municipalities: { id: string, value: string }[];
  locations: EnergyLibraryLocation[];

  form: FormGroup;

  EnergyLibraryProductStatusLabels = EnergyLibraryProductStatusLabels;

  get canSubmit() {
    return !this.isLoading && !this.isSaving && this.form.valid;
  }

  get showSpinner() {
    return this.isLoading || this.isSaving;
  }

  constructor(
    private formBuilder: FormBuilder,
    protected ref: NbDialogRef<EditProductDialogComponent>,
    private majadaToastrService: MajadaToastrService,
    private energyLibraryService: EnergyLibraryService
  ) { }


  ngOnInit(): void {
    this.createForm();
    this.loadData();
  }

  private loadData() {
    this.isLoading = true;
    this.energyLibraryService.getLocations().subscribe(locations => {
      this.locations = locations;
      this.isLoading = false;
      if (!this.isNewProduct) this.updateForm();
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      title: ['', Validators.required],
      category: ['', Validators.required],
      status: ['', Validators.required],
      municipality: ['', Validators.required],
      location: ['', Validators.required],
      number: ['', Validators.required],
      damage: ''
    });
  }

  private updateForm() {
    this.form.get('title').setValue(this.product.title);
    this.form.get('category').setValue(this.product.field_product_categorie);
    this.form.get('status').setValue(this.product.field_status);
    this.form.get('municipality').setValue(this.product.field_gemeente);
    this.form.get('location').setValue(this.product.field_ref_energiebieb_locatie.__uuid);
    this.form.get('number').setValue(this.product.field_inventarisnummer);
    this.form.get('damage').setValue(this.product.field_schade?.value);
  }

  delete() {
    this.isSaving = true;
    this.energyLibraryService.deleteProduct(this.product).subscribe(res => {
      this.isSaving = false;
      this.majadaToastrService.success('Product is verwijderd', 'Verwijderd');
      this.ref.close();
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Product kon niet verwijderd worden');
    });
  }

  onSubmit(form: FormGroup) {
    const fields = {
      title: this.form.value.title,
      categoryId: this.form.value.category,
      status: this.form.value.status,
      municipalityId: this.form.value.municipality,
      locationId: this.form.value.location,
      number: this.form.value.number,
      damage: this.form.value.damage
    };
    return this.isNewProduct ? this.create(fields) : this.update(fields);
  }

  private create(fields) {
    this.isSaving = true;
    this.energyLibraryService.addOrUpdateProduct(fields).subscribe(res => {
      this.isSaving = false;
      this.majadaToastrService.success('Product is toegevoegd', 'Toegevoegd');
      this.ref.close();
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Product kon niet toegevoegd worden');
    });
  }

  private update(fields) {
    this.isSaving = true;
    this.energyLibraryService.addOrUpdateProduct(fields, this.product).subscribe(res => {
      this.isSaving = false;
      this.majadaToastrService.success('Product is bijgewerkt', 'Bijgewerkt');
      this.ref.close();
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Product kon niet bijgewerkt worden');
    });
  }

  close() {
    this.ref.close();
  }
}
