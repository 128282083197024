<h1>Mijn openingstijden</h1>
<p>Hieronder kunt u doorgeven of u uw {{formattedObjectTypes}} openstelt tijdens de {{routeName}}.</p>
<ng-container *ngIf="isDoneLoading$ | async; else loading">
  <div class="locations" *ngIf="locations">
    <nb-card *ngFor="let location of locations">
      <nb-card-header>{{ location.object.title[0].value }}</nb-card-header>
      <nb-card-body>
        <table>
          <tr class="opening-time" *ngFor="let date of availableDates[location.object.field_object_soort[0].value]">
            <td><p>{{date.date | date: 'dd MMMM yyyy'}}</p></td>
            <ng-container *ngIf="location.opening_times && location.opening_times[date.date_raw] else openingTimesNotSpecified">
              <td>
                <ng-container [ngTemplateOutlet]="renderedOpeningTimes"
                              [ngTemplateOutletContext]="{$implicit: location, openingTime: location.opening_times[date.date_raw].entity}"></ng-container>
              </td>
              <td>
                <a nbButton status="primary" ghost
                   [routerLink]="'/route/openingstijden/bewerken/' + location.opening_times[date.date_raw].entity.id[0].value" [queryParams]="{redirectUrl: redirectUrl}">Openingstijden bewerken</a>
              </td>
            </ng-container>
            <ng-template #openingTimesNotSpecified>
              <td>
                <p>Openingstijden nog niet opgegeven</p>
              </td>
              <td><a nbButton status="primary" ghost
                     [routerLink]="'/route/openingstijden/doorgeven/'+ location.object.id[0].value +'/' + date.id" [queryParams]="{redirectUrl: redirectUrl}">Openingstijden
                doorgeven</a></td>
            </ng-template>
          </tr>
        </table>
      </nb-card-body>
    </nb-card>
  </div>
</ng-container>

<ng-template #renderedOpeningTimes let-openingTime="openingTime">
  <ng-container *ngIf="openingTime.field_niet_geopend[0].value == true">Niet geopend</ng-container>
  <ng-container *ngIf="!openingTime.field_niet_geopend[0].value == true">
  <span class="type">
    <ng-container
      *ngIf="openingTime.field_voorkeur_bezoek[0].value == 'vaste_tijdstippen'">Vaste tijdstippen</ng-container>
    <ng-container *ngIf="openingTime.field_voorkeur_bezoek[0].value == 'vrije_inloop'">Vrije inloop</ng-container>
  </span>
  </ng-container>
</ng-template>

<ng-template #loading>
  <div class="loading"><p>Openingstijden worden geladen...</p></div>
</ng-template>
