import {Inject, Injectable} from '@angular/core';
import {CurrencyPipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {map, flatMap, tap, defaultIfEmpty} from 'rxjs/operators';
import {compact, every, flatten, fromPairs, includes, isArray, isString,
  isUndefined, keys, mapValues, omit, pad, pick, pickBy, range, values} from 'lodash-es';
import jsPDF from 'jspdf';
import * as moment from 'moment';

import {AuthService} from '../auth/auth.service';
import {ApiService} from '../api/api.service';
import {UserFields} from '../../models/drupal-datatypes.models';
import {
  SubsidyRequestFields,
  SubsidyRequest,
  SubsidyRequestStatus,
  subsidyRequestImageFields,
  SubsidyRequestVdStatus,
  SubsidyRequestMunicipalityStatus,
  subsidyRequestNumberFields,
  SubsidyGardenAreaLabels,
  SubsidyGardenLocationLabels,
  SubsidyRequestMunicipality
} from '../../models/subsidy.models';
import * as jsonApiUtils from '../../utils/json-api.utils';
import {environment} from '../../../environments/environment';
import {SubsidyRequestLogsService} from '../subsidy-request-logs/subsidy-request-logs.service';

import {getDisplayFlags} from 'src/app/components/subsidy/subsidy-request-form/subsidy-form-state';
import {loadPDFAsImage} from 'src/app/utils/pdf.utils';
import { UserService } from '../user/user.service';

const fieldsToInclude = [
  'subsidieaanvraag_type',
  'uid',
  'field_afkoppelen_bon',
  'field_afkoppelen_foto',
  'field_afkoppelen_foto_uitvoeren',
  'field_afkoppelen_schets',
  'field_akkoord_verhuurder',
  'field_boom_bon',
  'field_boom_foto',
  'field_boom_foto_uitvoeren',
  'field_boom_schets',
  'field_groendak_bon',
  'field_groendak_foto',
  'field_groendak_foto_uitvoeren',
  'field_groendak_schets',
  'field_hemelwater_bon',
  'field_hemelwater_foto',
  'field_hemelwater_foto_uitvoeren',
  'field_hemelwater_schets',
  'field_infiltratiekrat_foto',
  'field_regenton_bon',
  'field_regenton_foto',
  'field_regenton_foto_uitvoeren',
  'field_regenton_schets',
  'field_vergroenen_bon',
  'field_vergroenen_foto',
  'field_vergroenen_foto_uitvoeren',
  'field_vergroenen_schets',
  'field_vergunning',
];


@Injectable({
  providedIn: 'root'
})
export class SubsidyService {

  private requestChangedSubj = new Subject();
  requestChanged$ = this.requestChangedSubj.asObservable();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private subsidyRequestLogsService: SubsidyRequestLogsService,
    private currencyPipe: CurrencyPipe,
    private userService: UserService,
    private http: HttpClient,
    @Inject('drupalUrl') private drupalUrl: string
  ) {
  }

  getMunicipalityKeyByTermId(uuid: string) {
    return this.http.get<any>(this.drupalUrl + '/jsonapi/taxonomy_term/gemeente/' + uuid).pipe(map(res => {
      return res?.data?.attributes?.name?.toLowerCase() as SubsidyRequestMunicipality;
    }));
  }

  requestChanged() {
    this.requestChangedSubj.next();
  }

  getRequests(page: number,  status: string, municipality: string, name: string, email: string, address: string) {
    let filters = '';
    if (status) filters += `&status=${status}`;
    if (municipality) filters += `&municipality=${municipality}`;
    if (name) filters += `&name=${name}`;
    if (email) filters += `&email=${email}`;
    if (address) filters += `&address=${address}`;
    const uri = `/api/subsidy/requests?page=${page || 0}${filters}`;
    return this.apiService.get(uri).pipe(
      map(res => res),
    );
  }

  getRequestById(id: string) {
    const uri = `/jsonapi/subsidieaanvraag/aanvraag/${id}?include=${fieldsToInclude.join(',')}`;
    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseSingle<SubsidyRequest>(res))
    );
  }

  loadImages(drupalInternalId: number) {
    const uri = `/api/subsidy/images?_format=json&request_id=${drupalInternalId}`;
    return this.apiService.get(uri);
  }

  calculateSubsidy(
    municipality: string,
    measure: { [key in string]?: number | string },
    part: number
  ) {
    const postData = {
      municipality: municipality || 'elburg',
      measure: JSON.stringify({[`onderdeel_${part}`]: measure})
    };
    return this.http.post<any>(this.drupalUrl + '/api/subsidy/calculate?_format=json', postData);
  }

  addOrUpdateRequest(id: string | undefined, fields: SubsidyRequestFields, userId: string, userFields: UserFields) {

    const saveRequest$ = () => {
      const title = `Subsidieaanvraag van ${userFields.field_given_name} ${userFields.field_family_name}`;

      let attributes = {
        ...omit(fields, subsidyRequestImageFields),
        title
      } as any;
      attributes = mapValues(attributes, (val, field) => {
        if (includes(subsidyRequestNumberFields, field)) {
          if (!isString(val)) return val;
          return parseFloat(val.toString().replace(',', '.'));
        }
        return val;
      });
      const nonEmptyImages = pickBy(pick(fields, subsidyRequestImageFields), x => x && isArray(x) && x.length > 0);
      const relations = mapValues(nonEmptyImages,
        (imageIds: string[], field) => jsonApiUtils.createRelation(imageIds, 'file--file'));
      const body = jsonApiUtils.createBody(id, 'subsidieaanvraag--aanvraag', attributes, relations);

      if (id) {
        return this.apiService.patch(`/jsonapi/subsidieaanvraag/aanvraag/${id}`, body);
      } else {
        return this.apiService.post(`/jsonapi/subsidieaanvraag/aanvraag`, body);
      }
    };

    const updateUser$ = (res: any) => {
      const requestId = res.data.id;
      return this.userService.updateUser(userId, userFields).pipe(map(() => requestId));
    };

    const maybeAddLogEntry$ = (requestId: string) => {
      if (fields.field_aanvraag_status !== SubsidyRequestStatus.WordtGecontroleerd) return of(requestId);
      const f = x => this.currencyPipe.transform(x, 'EUR', 'symbol', undefined, 'nl-NL');
      const total =
        parseFloat(fields.field_afkoppelen_totale_subsidie) +
        parseFloat(fields.field_hemelwater_totale_subsidie) +
        parseFloat(fields.field_regenton_totale_subsidie) +
        parseFloat(fields.field_vergroenen_totale_subsidie) +
        parseFloat(fields.field_boom_totale_subsidie) +
        parseFloat(fields.field_groendak_totale_subsidie);
      const log = '' +
        `Afkoppelen regenwater: ${f(fields.field_afkoppelen_totale_subsidie)}\n` +
        `Voorziening nuttig gebruik regenwater: ${f(fields.field_hemelwater_totale_subsidie)}\n` +
        `Regenton, -zuil of -schutting: ${f(fields.field_regenton_totale_subsidie)}\n` +
        `Vergroenen: ${f(fields.field_vergroenen_totale_subsidie)}\n` +
        `Planten van een boom: ${f(fields.field_boom_totale_subsidie)}\n` +
        `Groene daken: ${f(fields.field_groendak_totale_subsidie)}\n` +
        `Totaal: ${f(total)}`;
      return this.subsidyRequestLogsService.postLog(requestId, 'Ingediend', log).pipe(map(() => requestId));
    };

    return saveRequest$().pipe(
      flatMap(updateUser$),
      flatMap(maybeAddLogEntry$)
    );
  }

  updateRequestStatus(
    request: SubsidyRequest,
    status: SubsidyRequestStatus,
    vdStatus: SubsidyRequestVdStatus,
    municipalityStatus: SubsidyRequestMunicipalityStatus,
    year: number
  ) {
    const patchRequest$ = () => {
      const attributes = {
        field_aanvraag_status: status,
        field_status_beoordeling_vd: vdStatus,
        field_status_afhandeling_gem: municipalityStatus,
        field_jaar: year
      };
      const body = jsonApiUtils.createBody(request.__uuid, 'subsidieaanvraag--aanvraag', attributes);
      return this.apiService.patch(`/jsonapi/subsidieaanvraag/aanvraag/${request.__uuid}`, body);
    };

    return patchRequest$().pipe(
      tap(this.requestChanged.bind(this))
    );
  }

  getRequestComputations(request: SubsidyRequest) {
    const computations = [
      {
        part: 1,
        fields: {
          regenton: request.field_regenton,
          regenton_aanschafkosten: request.field_regenton_aanschafkosten,
          regenschutting: request.field_regenschutting,
        }
      },
      {
        part: 2,
        fields: {
          regenpijp: request.field_regenpijp_doorgezaagd,
          infiltratiekrat: request.field_infiltratiekrat,
          infiltratieveld: request.field_aanleggen_infiltratieveld,
          afkoppelen: request.field_afkoppelen_m3
        }
      },
      {
        part: 3,
        fields: {
          vergroenen: request.field_vergroenen,
        }
      },
      {
        part: 4,
        fields: {
          boom_een: request.field_boom_een,
          boom_twee: request.field_boom_twee
        }
      },
      {
        part: 5,
        fields: {
          groendak: request.field_groendak,
          groendak_laagdikte_8: request.field_groendak_laagdikte_8,
          groendak_laagdikte_20: request.field_groendak_laagdikte_20,
          groendak_laagdikte_40: request.field_groendak_laagdikte_40,
          groenblauwdak: request.field_groenblauwdak,
          groenblauwdak_laagdikte_8: request.field_groenblauwdak_laagdikte_8,
          groenblauwdak_laagdikte_20: request.field_groenblauwdak_laagdikte_20,
          groenblauwdak_laagdikte_40: request.field_groenblauwdak_laagdikte_40,
          waterberging_18: request.field_waterberging_18_liter_m2,
          waterberging_30: request.field_waterberging_30_liter_m2,
          waterberging_50: request.field_waterberging_50_liter_m2,
          waterberging_30_grassen: request.field_waterberging_30l_grassen,
          waterberging_50_grassen: request.field_waterberging_50l_grassen,
          bouwkundige_keuring: request.field_dak_keuringskosten
        }
      },
      {
        part: 6,
        fields: {
          hemelwatervoorziening: request.field_hemelwatervoorziening,
        }
      },
    ];

    return computations.map(x => this.calculateSubsidy(request.field_gemeente, x.fields, x.part));
  }

  exportPDF(request: SubsidyRequest) {

    const computations$ = this.getRequestComputations(request);

    const readImage = (url: string) => new Observable(obs => {
      const image = new Image();
      image.onload = () => {
        obs.next(image);
        obs.complete();
      };
      image.onerror = err => obs.error(err);
      image.onabort = err => obs.error(err);
      image.src = url;
      image.crossOrigin = 'Anonymous';
    });

    return this.loadImages(request.drupal_internal__id).pipe(
      map(images => {
        images = {logo: [{largeUrl: 'assets/logo.png'}], ...images};
        return keys(images).map(key => {
          const imageReads$ = (images[key] || []).map(imgUrls => {
            if (imgUrls.largeUrl.toLowerCase().indexOf('.pdf?') !== -1) {
              return loadPDFAsImage(this.http, imgUrls.largeUrl);
            }
            // return readImage('assets/logo.png');
            return readImage(imgUrls.largeUrl);
          });
          return forkJoin(compact(imageReads$)).pipe(defaultIfEmpty([]), map(imgs => [key, flatten(imgs)]));
        });
      }),
      flatMap(imageLoads$ => forkJoin(imageLoads$)),
      map(images => fromPairs(images)),
      flatMap(images => forkJoin(computations$).pipe(map(calc => ({images, calc})))),
      tap(data => {
        const state = {
          step1FormData: request,
          step2FormData: request,
          step3UserFormData: {},
          emailAddress: request.uid?.mail,
          images: [],
          isNewRequest: false
        };
        const displayFlags = getDisplayFlags(state) as any;

        displayFlags.showGroendakDetailFields =
          parseFloat(request.field_groendak_laagdikte_8) > 0 ||
          parseFloat(request.field_groendak_laagdikte_20) > 0 ||
          parseFloat(request.field_groendak_laagdikte_40) > 0 ||
          parseFloat(request.field_groenblauwdak_laagdikte_8) > 0 ||
          parseFloat(request.field_groenblauwdak_laagdikte_20) > 0 ||
          parseFloat(request.field_groenblauwdak_laagdikte_40) > 0;

        if (displayFlags.showGroendakDetailFields) {
          data.calc[4].groendak.value =
            data.calc[4].groendak_laagdikte_8.value +
            data.calc[4].groendak_laagdikte_20.value +
            data.calc[4].groendak_laagdikte_40.value;
          data.calc[4].groenblauwdak.value =
            data.calc[4].groenblauwdak_laagdikte_8.value +
            data.calc[4].groenblauwdak_laagdikte_20.value +
            data.calc[4].groenblauwdak_laagdikte_40.value;
        }
        this.createPDF(request, data.calc, displayFlags, data.images);
      })
    );
  }

  private createPDF(request: SubsidyRequest, requestData: any, displayFlags: any, images: any) {

    const r = request;
    const u = request.uid;
    const rd = requestData;
    const df = displayFlags;

    const h1Size = 17;
    const h1Height = 15;
    const h2Size = 15;
    const h2Height = 15;
    const h3Size = 12;
    const h3Height = 7;
    const textSize = 9;
    const textHeight = 6;
    const leftPadding = 20;
    const pageWidth = 165;
    const colWidth4 = [28, 55];
    const dataWidth = 30;
    const dataPadding = 80;
    const dateColWidth = 45;
    const hrColor = '#cccccc';
    const textColor = '#707070';
    const fillColor = '#fafafa';
    const catPadding = 5;
    const font = 'Helvetica';
    const topPadding = 20;
    const catHeaderSpacing = 0.05;
    const dataSectionSpacing = 3.0;
    const dataSubsectionSpacing = 0.4;
    const imageWidth = 54;
    const imageHeight = 45;
    const imageMargin = 5;

    const doc = new jsPDF();
    doc.setFont(font);
    doc.setTextColor(textColor);
    doc.setFillColor(fillColor);

    let y = topPadding;

    const h1 = (str: string) => {
      doc.setFontSize(h1Size);
      doc.setFont(font, 'bold');
      doc.text(str, leftPadding, y);
      y += h1Height;
    };
    const h2 = (str: string) => {
      doc.setFontSize(h2Size);
      doc.setFont(font, 'bold');
      doc.text(str, leftPadding, y);
      y += h2Height;
    };
    const h3 = (str: string) => {
      doc.setFontSize(h3Size);
      doc.setFont(font, 'bold');
      doc.text(str, leftPadding, y);
      y += h3Height;
    };
    const table = (strs: string | string[], overrideColWidth?: number) => {
      if (!isArray(strs)) strs = [strs];
      strs.forEach((str, i) => {
        if (!str) return;
        doc.setFont(font, (i % 2 == 0) ? 'normal' : 'bold');
        doc.setFontSize(textSize);
        doc.text(str, leftPadding + range(0, i).reduce((acc, n) => acc + (overrideColWidth || colWidth4[n % 2]), 0), y);
      });
      y += textHeight;
    };
    const text = (text: string) => {
      doc.setFontSize(textSize);
      doc.setFont(font, 'normal');
      doc.text(text, leftPadding, y);
      y += 2;
    };
    const data = (field: string, values: (number | string)[], width: number, isHeader?: boolean) => {
      doc.setFontSize(isHeader ? textSize - 1 : textSize);
      doc.setFont(font, isHeader ? 'bold' : 'normal');
      doc.text(field, leftPadding, y);
      doc.setFont(font, 'bold');
      values.forEach((val, i) => {
        if (val === '') return;
        doc.text((val || 0).toString(), leftPadding + dataPadding + width + i * dataWidth, y);
      });
      if (isHeader) {
        y += 2;
        doc.setDrawColor(textColor);
        doc.line(leftPadding, y, 185, y);
        y += textHeight - 1;
      } else {
        y += textHeight;
      }
    };
    const cat = (name: string, amountDesc: string, amount: string) => {
      doc.setFillColor(fillColor);
      doc.rect(leftPadding, y - textHeight, pageWidth, textHeight * 1.6, 'F');
      doc.setFontSize(textSize);
      doc.setFont(font, 'normal');
      doc.text(amountDesc, leftPadding + pageWidth - 55 - catPadding, y);
      doc.setFont(font, 'bold');
      doc.text(name, leftPadding + catPadding, y);
      doc.text(amount, leftPadding + pageWidth - catPadding, y, {align: 'right'});
      y += textHeight * 1.6 + 0.5;
    };
    const hr = () => {
      doc.setDrawColor(hrColor);
      doc.line(leftPadding, y, leftPadding + pageWidth, y);
      y += textHeight * 1.4;
    };
    const pad = (fac?: number) => {
      y += textHeight * (fac || 1.3);
    };
    const newPage = () => {
      doc.addPage();
      y = topPadding;
    };
    const showSection = (keys: string[]) => {
      return !every(keys.map(key => !images?.[key]?.length));
    };
    const img = (key: string, label?: string, height?: number, width?: number) => {

      if (!images[key]) return;

      if (label) text(label);

      const fixedHeight = !isUndefined(height);
      const fixedWidth = !isUndefined(width);
      const maxWidth = fixedHeight ? imageWidth * 3 + imageMargin * 2 : imageWidth;
      const maxHeight = 250;
      height = height || imageHeight;
      width = width || imageWidth;

      let accumY = height + imageMargin;

      images[key].forEach((image, i: number) => {

        if (y >= 240) newPage();

        const x = fixedWidth ? 0 : (i % 3) * (imageWidth + imageMargin);
        const newLine = i % 3 === 2 || fixedWidth || fixedHeight || i === (images[key].length - 1);

        if (!fixedHeight && !fixedWidth) {
          doc.setDrawColor(fillColor);
          doc.rect(leftPadding + x, y, maxWidth, imageHeight, 'S');
        }

        let w = image.width * (height / image.height);
        let h = height;
        let dx = fixedHeight ? 0 : (maxWidth - w) / 2;
        let dy = 0;
        if (w > maxWidth) {
          w = maxWidth;
          const h_ = image.height * (w / image.width);
          dx = 0;
          dy = (h - h_) / 2;
          h = h_;
        }

        if (fixedWidth) {
          h = image.height * (width / image.width);
          w = width;
          dx = 0;
          dy = 0;
          if (h > maxHeight) {
            h = maxHeight;
            const w_ = image.width * (h / image.height);
            dy = 0;
            dx = (w - w_) / 2;
            w = w_;
          }
          if (y + h >= 285) newPage();
          accumY = h + imageMargin;
        }

        doc.addImage(image, 'JPEG', leftPadding + x + dx, y + dy, w, h, undefined, 'MEDIUM', 0);
        if (newLine) {
          y += accumY + imageMargin;
        }
      });
    };

    const ligging = r.field_binnen_bebouwde_kom ? 'Binnen bebouwde kom' : 'Buiten bebouwde kom';
    const bouwjaar = r.field_gebouwd_voor_2015 ? 'Voor 2015' : 'Na 2015';
    const totalSubsidy =
      parseFloat(r.field_hemelwater_totale_subsidie) +
      parseFloat(r.field_groendak_totale_subsidie) +
      parseFloat(r.field_boom_totale_subsidie) +
      parseFloat(r.field_vergroenen_totale_subsidie) +
      parseFloat(r.field_afkoppelen_totale_subsidie) +
      parseFloat(r.field_regenton_totale_subsidie);

    img('logo', undefined, 20);
    pad();
    h1(`Overzicht aanvraag ${r.field_achternaam}`);
    h3('Situatie gegevens');
    table(['Aanvraagtype', r.field_type_aanvraag, 'Ligging', ligging]);
    table(['Gemeente', r.field_gemeente, 'Bouwjaar', bouwjaar]);
    table(['Situatie', r.field_situatie, 'Opmerkingen', r.field_extra_opmerkingen]);
    hr();
    h3('Algemene gegevens');
    table(['Voorletter(s)', u?.field_initials, 'Gemeente', r.field_gemeente]);
    table(['Voornaam', r.field_voornaam, 'Straatnaam', u?.field_address_line1]);
    table(['Achternaam', r.field_achternaam, 'Huisnummer', u?.field_address_line2]);
    table(['E-mailadres', u?.mail, 'Postcode', u?.field_postal_code]);
    table(['Telefoonnummer', u?.field_phone_number, 'Plaats', u?.field_locality]);
    table(['IBAN', r?.field_iban, 'Ten name van', r?.field_iban_tnv]);
    hr();
    const d = x => x ? moment(x).format('DD-MM-YYYY') : 'niet ingevuld';
    table(['Datum eerste aanvraag', d(request.field_indiendatum)], 38);
    hr();
    pad();

    h3('Subsidie onderdelen');
    pad();
    const sb = 'Subsidiebedrag: ';
    const m = x => this.currencyPipe.transform(parseFloat(x || 0), 'EUR', 'symbol', undefined, 'nl-NL');
    const i = x => parseInt(x).toString();
    const f = x => parseFloat(x).toFixed(2).replace('.', ',');
    cat('Afkoppelen regenwater', sb, m(r.field_afkoppelen_totale_subsidie));
    cat('Voorziening nuttig gebruik regenwater', sb, m(r.field_hemelwater_totale_subsidie));
    cat('Regenton, zuil- of -schutting', sb, m(r.field_regenton_totale_subsidie));
    cat('Vergroenen', sb, m(r.field_vergroenen_totale_subsidie));
    cat('Planten van een boom', sb, m(r.field_boom_totale_subsidie));
    cat('Groene daken', sb, m(r.field_groendak_totale_subsidie));
    pad(0.5);
    cat('', 'Totaal subsidiebedrag: ', m(totalSubsidy));

    // --- Section 1

    newPage();
    let w = df.showPart2TypeA ? 38 : 20;
    cat('Afkoppelen regenwater', sb, m(r.field_afkoppelen_totale_subsidie));
    pad(catHeaderSpacing);
    data('Maatregel', ['Aantal', 'Totaal'], w, true);
    if (df.showPart2TypeA) {
      data('Regenpijp doorgezaagd zonder voorziening', [i(r.field_regenpijp_doorgezaagd), m(rd[1].regenpijp?.value)], w);
      data('Infiltratiekrat', [i(r.field_infiltratiekrat), m(rd[1].infiltratiekrat.value)], w);
      data('Aanleggen infiltratieveld, wad of regenwatervijver (aantal m³ afgegraven grond)', [f(r.field_aanleggen_infiltratieveld) + 'm³', m(rd[1].infiltratieveld?.value)], w);
      pad(dataSubsectionSpacing);
    }
    if (df.showPart2TypeB) {
      data('Afkoppelen', [i(r.field_afkoppelen_m3), m(rd[1].afkoppelen?.value)], w);
      pad(dataSubsectionSpacing);
    }
    df.showPart2TypeA && data('Totaal aantal liters per infiltratie krat', [f(r.field_infiltratiekrat_liters)], w);
    data('Aantal m² verhard oppervlakte dat wordt afgekoppeld', [f(r.field_afkoppelen_aantal_m2) + 'm²'], w);
    data('Ik voer deze maatregel uit in mijn', [SubsidyGardenLocationLabels[r.field_afkoppelen_uitvoeren_in]], w);
    pad(dataSubsectionSpacing);
    df.showPart2TypeA && r.field_minimale_berging && data('• Er is minimaal 20 mm (=20 liter per afgekoppelde m²) berging op eigen terrein gerealiseerd.', [], w);
    df.showPart2TypeB && r.field_niet_aangesloten_drukriool && data('• Mijn pand/woning is niet aangelosten op een drukriool.', [], w);
    pad(dataSubsectionSpacing);
    table(['Datum van uitvoering', d(r.field_afkoppelen_datum_uitvoeren), 'Datum van aankoop producten', d(r.field_afkoppelen_datum_aankoop)], dateColWidth);
    table(['Foto\'s en bonnen: ', 'bijlage 1'], dateColWidth);
    pad(dataSectionSpacing);

    // --- Section 2

    w = 20;
    cat('Voorziening nuttig gebruik hemelwater', sb, m(r.field_hemelwater_totale_subsidie));
    pad(catHeaderSpacing);
    data('Maatregel', ['Investeringskosten', 'Subsidiebedrag'], w, true);
    data('Regenwatervoorziening', [r.field_hemelwatervoorziening, m(r.field_hemelwater_totale_subsidie)], w);
    pad(dataSubsectionSpacing);
    data('Aantal m² verhard oppervlakte dat wordt afgekoppeld', [f(r.field_hemelwater_m2_afgekoppeld) + 'm²'], w);
    data('Inhoud voorziening in liters', [f(r.field_inhoud_voorziening_liters)], w);
    data('Ik voer deze maatregel uit in mijn', [SubsidyGardenLocationLabels[r.field_hemelwater_uitvoeren_in]], w);
    pad(dataSubsectionSpacing);
    r.field_hemelwater_gebruik && data('• Het regenwater wordt alleen gebruikt voor watervoeding van toilet en/of wasmachines en/of tuinbesproeiing.', [], w);
    df.showPart6TypeB && r.field_hemelwater_afkoppel_riool && data('• Er wordt minimaal 20 m² verhard dakoppervlak afgekoppeld van de gemengde riolering.', [], w);
    df.showPart6TypeB && r.field_minimaal_1000l_gebuffer && data('• Er wordt minimaal 1000 liter regenwater gebufferd ter vervanging van het gebruik van leidingwater.', [], w);
    pad(dataSubsectionSpacing);
    table(['Datum van uitvoering', d(r.field_hemelwater_datum_uitvoeren), 'Datum van aankoop producten', d(r.field_hemelwater_datum_aankoop)], dateColWidth);
    table(['Foto\'s en bonnen: ', 'bijlage 2'], dateColWidth);
    pad(dataSectionSpacing);

    // --- Section 3
    w = 5;
    cat('Regenton, -zuil of -schutting', sb, m(r.field_regenton_totale_subsidie));
    pad(catHeaderSpacing);
    data('Maatregel', ['Aantal', '', 'Totaal'], w, true);
    data('Regenton', [r.field_regenton, m(r.field_regenton_aanschafkosten), m(rd[0].regenton?.value)], w);
    data('Regenschutting', [r.field_regenschutting, '', m(rd[0].regenschutting?.value)], w);
    pad(dataSubsectionSpacing);
    data('Ik voer deze maatregel uit in mijn', [SubsidyGardenLocationLabels[r.field_regenton_uitvoeren_in]], w);
    pad(dataSubsectionSpacing);
    r.field_regenton_min_m2 && data('• Er is minimaal 5m² dak aangesloten op de regenton.', [], w);
    r.field_regenton_minimaal_inhoud && data('• De regenton/zuil/schutting heeft een inhoud van minimaal 100 liter.', [], w);
    pad(dataSubsectionSpacing);
    table(['Datum van uitvoering', d(r.field_regenton_datum_uitvoering), 'Datum van aankoop producten', d(r.field_regenton_datum_van_aankoop)], dateColWidth);
    table(['Foto\'s en bonnen: ', 'bijlage 3'], dateColWidth);
    pad(dataSectionSpacing);

    // --- Section 4
    w = 20;
    newPage();
    cat('Vergroenen', sb, m(r.field_vergroenen_totale_subsidie));
    pad(catHeaderSpacing);
    data('Maatregel', ['Aantal', 'Totaal'], w, true);
    data('Vergroenen', [r.field_vergroenen + 'm²', m(rd[2].vergroenen?.value)], w);
    pad(dataSubsectionSpacing);
    r.field_vergroenen_verwijderd && data('• Er wordt minimaal 20m² verhard oppervlakte verwijderd.', [], w);
    pad(dataSubsectionSpacing);
    table(['Datum van uitvoering', d(r.field_vergoenen_datum_uitvoering), 'Datum van aankoop producten', d(r.field_vergroenen_datum_aankoop)], dateColWidth);
    table(['Foto\'s en bonnen: ', 'bijlage 4'], dateColWidth);
    pad(dataSectionSpacing);

    // --- Section 5
    cat('Planten van een boom', sb, m(r.field_boom_totale_subsidie));
    const showTree = [r.field_boom_een, r.field_boom_twee].map(x => parseInt(x) !== 0 && parseInt(x) !== -1);
    pad(catHeaderSpacing);
    data('Maatregel', ['Boomsoort', 'Totaal'], w, true);
    showTree[0] && data('Boom één', [r.field_boom_een, m(rd[3].boom_een?.value)], w);
    showTree[1] && data('Boom twee', [r.field_boom_twee, m(rd[3].boom_twee?.value)], w);
    pad(dataSubsectionSpacing);
    data('Oppervlakte van de tuin', [SubsidyGardenAreaLabels[r.field_boom_oppervlakte_tuin]], w);
    pad(dataSubsectionSpacing);
    table(['Datum van uitvoering', d(r.field_boom_datum_uitvoering), 'Datum van aankoop producten', d(r.field_boom_datum_aankoop)], dateColWidth);
    table(['Foto\'s en bonnen: ', 'bijlage 5'], dateColWidth);
    pad(dataSectionSpacing);

    // --- Section 6

    cat('Groene daken', sb, m(r.field_groendak_totale_subsidie));
    pad(catHeaderSpacing);
    data('Maatregel', ['Aantal', 'Totaal'], w, true);
    if (df.showPart5TypeA) {
      data('Groendak', [r.field_groendak, m(rd[4].groendak?.value)], w);
      if (df.showGroendakDetailFields) {
        data(' - Groendak laagdikte 8-20cm', [r.field_groendak_laagdikte_8, m(rd[4].groendak_laagdikte_8?.value)], w);
        data(' - Groendak laagdikte 20-40cm', [r.field_groendak_laagdikte_20, m(rd[4].groendak_laagdikte_20?.value)], w);
        data(' - Groendak laagdikte meer dan 40cm', [r.field_groendak_laagdikte_40, m(rd[4].groendak_laagdikte_40?.value)], w);
      }
      data('Groenblauwdak', [r.field_groenblauwdak, m(rd[4].groenblauwdak?.value)], w);
      if (df.showGroendakDetailFields) {
        data(' - Groenblauwdak laagdikte 8-20cm', [r.field_groenblauwdak_laagdikte_8, m(rd[4].groenblauwdak_laagdikte_8?.value)], w);
        data(' - Groenblauwdak laagdikte 20-40cm', [r.field_groenblauwdak_laagdikte_20, m(rd[4].groenblauwdak_laagdikte_20?.value)], w);
        data(' - Groenblauwdak laagdikte meer dan 40cm', [r.field_groenblauwdak_laagdikte_40, m(rd[4].groenblauwdak_laagdikte_40?.value)], w);
      }
      pad(dataSubsectionSpacing);
      r.field_groendak_minimum_oppervlak && data('• Het groen(blauw)dak heeft een aaneengesloten oppervlakte van minimaal 20m².', [], w);
    }
    if (df.showPart5TypeB) {
      data('Waterberging van 18 liter/m²', [r.field_waterberging_18_liter_m2 + 'm²', m(rd[4].waterberging_18?.value)], w);
      data('Waterberging van 30 liter/m²', [r.field_waterberging_30_liter_m2 + 'm²', m(rd[4].waterberging_30?.value)], w);
      data('Waterberging van 50 liter/m²', [r.field_waterberging_50_liter_m2 + 'm²', m(rd[4].waterberging_50?.value)], w);
      data('Waterberging van minimaal 30 liter/m² met meer dan 50% grassen', [r.field_waterberging_30l_grassen + 'm²', m(rd[4].waterberging_30_grassen?.value)], w);
      data('Waterberging van minimaal 50 liter/m² met meer dan 50% grassen', [r.field_waterberging_50l_grassen + 'm²', m(rd[4].waterberging_50_grassen?.value)], w);
      data('Bouwkundige keuring dak keuringskosten', [r.field_dak_keuringskosten + 'm²', m(rd[4].bouwkundige_keuring?.value)], w);
      pad(dataSubsectionSpacing);
      r.field_groendak_minimum_oppervlak && data('• Het groen(blauw)dak heeft een aaneengesloten oppervlakte van minimaal 6m².', [], w);
    }
    pad(dataSubsectionSpacing);
    table(['Datum van uitvoering', d(r.field_groendak_datum_uitvoering), 'Datum van aankoop producten', d(r.field_groendak_datum_aankoop)], dateColWidth);
    table(['Foto\'s en bonnen: ', 'bijlage 6'], dateColWidth);
    pad(dataSectionSpacing);


    // Images

    const showSection1 = showSection(['field_afkoppelen_foto', 'field_afkoppelen_schets', 'field_infiltratiekrat_foto', 'field_afkoppelen_bon', 'field_afkoppelen_foto_uitvoeren']);
    const showSection2 = showSection(['field_hemelwater_foto', 'field_hemelwater_schets', 'field_hemelwater_bon', 'field_hemelwater_bon']);
    const showSection3 = showSection(['field_regenton_foto', 'field_regenton_schets', 'field_regenton_bon', 'field_regenton_bon']);
    const showSection4 = showSection(['field_vergroenen_foto', 'field_vergroenen_schets', 'field_vergroenen_bon', 'field_vergroenen_bon']);
    const showSection5 = showSection(['field_boom_foto', 'field_boom_schets', 'field_boom_bon', 'field_boom_bon']);
    const showSection6 = showSection(['field_groendak_foto', 'field_groendak_schets', 'field_groendak_bon', 'field_boom_bon']);

    const fullWidth = imageWidth * 3 + imageMargin * 2;
    if (showSection1) {
      newPage();
      h2('Bijlage 1 - Afkoppelen regenwater');
      img('field_afkoppelen_foto', 'Upload foto van locatie inclusief een foto van de regenpijp op dit moment.');
      img('field_afkoppelen_schets', 'Laat zien waar de afkoppelvoorziening komt en hoe groot deze wordt.');
      img('field_infiltratiekrat_foto', 'Upload foto van plaatsing infiltratie kratten.');
      img('field_afkoppelen_bon', 'Bon uploaden.', undefined, fullWidth);
      img('field_afkoppelen_foto_uitvoeren', 'Upload een foto waarop de voorziening goed te zien is.');
    }

    if (showSection2) {
      newPage();
      h2('Bijlage 2 - Voorziening nuttig gebruik regenwater');
      img('field_hemelwater_foto', 'Voeg een foto toe van de locatie waar de regenwatervoorziening wordt geplaatst.');
      img('field_hemelwater_schets', 'Voeg een schets toe waarop te zien is hoe de regenwatervoorziening wordt geplaatst in de tuin.');
      img('field_hemelwater_foto_uitvoeren', 'Voeg een foto toe van de locatie waar de regenwatervoorziening geplaatst is en een foto van tijdens de plaatsing van de voorziening.');
      img('field_hemelwater_bon', 'Bon uploaden.', undefined, fullWidth);
    }

    if (showSection3) {
      newPage();
      h2('Bijlage 3 - Regenton, zuil- of -schutting');
      img('field_regenton_foto', 'Upload foto van locatie inclusief een foto van de regenpijp op dit moment.');
      img('field_regenton_schets', 'Laat zien waar de regenton geplaatst gaat worden.');
      img('field_regenton_foto_uitvoeren', 'Foto uploaden van na de aanpassingen waarop duidelijk is te zien dat de regenton is aangesloten.');
      img('field_regenton_bon', 'Bon uploaden.', undefined, fullWidth);
    }

    if (showSection4) {
      newPage();
      h2('Bijlage 4 - Vergroenen');
      img('field_vergroenen_foto', 'Upload een foto van de locatie waarop te zien is dat deze bestraat is.');
      img('field_vergroenen_schets', 'Voeg een schets toe waarop je laat zien hoe groot het vergroende stuk gaat worden.');
      img('field_vergroenen_foto_uitvoeren', 'Upload een foto van de locatie waarop te zien is dat deze is vergroend en een foto met een meetlint waarop te zien is hoe groot het vergroende deel is.');
      img('field_vergroenen_bon', 'Bon uploaden.', undefined, fullWidth);
    }


    if (showSection5) {
      newPage();
      h2('Bijlage 5 - Planten van een boom');
      img('field_boom_foto', 'Upload een foto van de locatie waar de boom/bomen geplaatst word(t)(en).');
      img('field_boom_schets', 'Voeg een schets toe waarop je aangeeft waar de boom wordt/bomen worden geplaatst.');
      img('field_boom_foto_uitvoeren', 'Upload een foto van de locatie waar de boom/ bomen geplaatst is/zijn.');
      img('field_boom_bon', 'Bon uploaden.', undefined, fullWidth);
    }


    if (showSection6) {
      newPage();
      h2('Bijlage 6 - Groene daken');
      img('field_groendak_foto', 'Upload een foto van het dak zoals voordat deze is vergroend.');
      img('field_groendak_schets', 'Voeg een schets toe waarop te zien is hoe groot het groendak wordt en waar deze wordt geplaatst.');
      img('field_groendak_foto_uitvoeren', 'Upload een foto van het dak dat vergroend is.');
      img('field_groendak_bon', 'Bon uploaden.', undefined, fullWidth);
    }

    doc.save(`Subsidieaanvraag ${r.field_achternaam}.pdf`);
  }

}
