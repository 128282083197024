<nb-card>
    <nb-card-header>
      <h3>Energiebieb locaties</h3>
    </nb-card-header>
    <nb-card-body>
      <button (click)="addNewLocation()" size="small" status="success" outline nbButton>Energiebieb locatie toevoegen</button>
      <section class="filters">
        <nb-form-field>
          <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
          <input id="input-name-filter" fieldSize="small" type="text" fullWidth placeholder="Zoeken op naam" shape="round" nbInput
            [ngModel]="nameFilterModel$ | async" (ngModelChange)="nameFilterModel$.next($event)">
        </nb-form-field>
        <nb-form-field>
          <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
          <input id="input-employee-filter" fieldSize="small" type="text" fullWidth placeholder="Medewerker" shape="round" nbInput
            [ngModel]="employeeFilterModel$ | async" (ngModelChange)="employeeFilterModel$.next($event)">
        </nb-form-field>
      </section>
      <section class="locations" [nbSpinner]="isLoading$ | async" nbSpinnerStatus="basic">
        <ng-container *ngIf="!(isLoading$ | async)">
          <ng-container *ngIf="filteredLocations && filteredLocations.length > 0; else noLocations">
            <table>
              <thead>
                <tr>
                  <th class="col-id">ID</th>
                  <th class="col-name">Naam</th>
                  <th class="col-address">Adres</th>
                  <th class="col-employee">Medewerkers</th>
                  <th class="col-actions"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let location of filteredLocations">
                  <td>{{location.drupal_internal__id}}</td>
                  <td>{{location.title}}</td>
                  <td>
                    {{location.field_energiebieb_adres?.address_line1}}
                    {{location.field_energiebieb_adres?.address_line2}}
                    {{location.field_energiebieb_adres?.postal_code}}
                    {{location.field_energiebieb_adres?.locality}}
                  </td>
                  <td>{{location.employeeNamesView}}</td>
                  <td><button (click)="editLocation(location)" size="small" status="basic" outline nbButton>Bewerken</button></td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </ng-container>
        <ng-template #noLocations>
          <p class="no-results">Er zijn geen resultaten voor de huidige zoekopdracht.</p>
        </ng-template>
      </section>
    </nb-card-body>
