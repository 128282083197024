import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../../services/auth/auth.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MajadaToastrService} from '../../services/toastr/majada-toastr.service';
import {environment} from '../../../environments/environment';
import {UserService} from '../../services/user/user.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  loginForm: FormGroup;
  isAuthenticated: Observable<boolean>;
  isDoneLoading: Observable<boolean>;
  canActivateProtectedRoutes: Observable<boolean>;
  identityClaims;
  project;

  console = console;

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
    this.clearStorage();
  }

  refresh() {
    this.authService.refresh();
  }

  reload() {
    window.location.reload();
  }

  clearStorage() {
    localStorage.clear();
  }


  constructor(private authService: AuthService, private oauthService: OAuthService, private toastrService: MajadaToastrService, private http: HttpClient, @Inject('domainUuid') private domainUuid: string) {

    console.log('login component');

    this.isAuthenticated = this.authService.isAuthenticated$;
    this.isDoneLoading = this.authService.isDoneLoading$;
    //console.log(this.isAuthenticated);
    this.canActivateProtectedRoutes = this.authService.canActivateProtectedRoutes$;
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required)
    });
    //this.authService.loadDocument();

    // Clear errors as soon as any value changes.
    this.loginForm.get('password').valueChanges.subscribe(value => {
      //console.log('this.loginForm.get(\'username\').getError(\'invalidUsername\')', this.loginForm.get('username').getError('invalidUsername'));

      if (this.loginForm.get('username').valid || this.loginForm.get('username').getError('invalidUsername')) {
        this.loginForm.get('username').setErrors(null);
      }
      if (this.loginForm.get('password').valid) {
        this.loginForm.get('password').setErrors(null);
      }
    });

    this.authService.isAuthenticated$.subscribe(authenticated => {
      if (authenticated) {
        this.identityClaims = this.authService.identityClaims;
      }
    });

  }

  clearErrors() {
    this.loginForm.markAllAsTouched();
  }

  doLogin() {
    this.authService.loginPassword(this.loginForm.get('username').value, this.loginForm.get('password').value).then(result => {
      this.loginForm.get('username').setValue('');
      this.loginForm.get('password').setValue('');
      this.loginForm.updateValueAndValidity();
    }, error => {
      //console.log('loginPassword error', error);
      this.loginForm.get('username').setErrors({invalidUsername: true});
      this.loginForm.get('password').setValue('');
      this.loginForm.updateValueAndValidity();
      this.toastrService.error('Ongeldige gebruikersnaam en/of wachtwoord.');
    });
  }

  ngOnInit() {
    this.project = environment.project;
    //console.log('this.project', this.project);
  }

}
