<div class="comments" *ngIf="comments && comments.length > 0 else loadingComments">
  <div class="comment" *ngFor="let commentData of comments">
    <ng-container *ngIf="!commentData.relationships.pid.data">
      <app-comment [commentData]="commentData" [user]="users[commentData.relationships.uid.data.id]"></app-comment>
      <ng-container [ngTemplateOutlet]="childComments" [ngTemplateOutletContext]="{$implicit: commentData.id}">
      </ng-container>
    </ng-container>
  </div>
  <div class="loader" infiniteScroll (scrolled)="loadMoreComments()" *ngIf="pagerData && pagerData.next">
    <span class="loader--icon"><nb-icon icon="loader-outline"></nb-icon></span>
  </div>
</div>
<ng-template #loadingComments>
  <div class="comments">
    <div class="loading-comments" *ngIf="!commentsLoaded">
      <div class="loader" infiniteScroll (scrolled)="loadMoreComments()">
        <span class="loader--icon"><nb-icon icon="loader-outline"></nb-icon></span>
      </div>
    </div>
    <div class="no-comments" *ngIf="commentsLoaded">
      <p>Er zijn nog geen reacties.</p>
    </div>
  </div>
</ng-template>
<div class="comment-form">
  <form [formGroup]="commentForm" (ngSubmit)="submitComment()" class="comment-form">
    <div class="form-item">
      <label class="label" for="subject">Onderwerp</label>
      <input type="text" nbInput id="subject" name="subject" formControlName="subject" fullWidth>
    </div>

    <div class="form-item">
      <label class="label required" for="comment">Bericht</label>
      <textarea id="comment" formControlName="comment" nbInput fullWidth></textarea>
    </div>
    <div class="form-actions">
      <input type="submit" status="success" nbButton [value]="'Reactie plaatsen'" [disabled]="commentForm.invalid">
    </div>
  </form>
</div>
<!-- Base comment template. -->


<!--Recursive logic to determine if there are child comments for current comment -->
<ng-template #childComments let-parentId>
  <ng-container *ngFor="let commentData of comments">
    <ng-container
      *ngIf="commentData.relationships.pid.data && commentData.relationships.pid.data.id && commentData.relationships.pid.data.id == parentId">
      <div class="nested comment">
        <app-comment [commentData]="commentData" [user]="users[commentData.relationships.uid.data.id]"></app-comment>
        <ng-container [ngTemplateOutlet]="childComments"
                      [ngTemplateOutletContext]="{$implicit: commentData.id}"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-template>
