import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Sanitizer, SimpleChanges} from '@angular/core';
import {ApiService} from 'src/app/services/api/api.service';
import {DomSanitizer} from '@angular/platform-browser';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subscription} from 'rxjs';

export interface FileChangedEvent {
  form: string;
  field: string;
  tag?: string;
  ids: string[];
}

export interface HighlightChangedEvent {
  form: string;
  field: string;
  tag?: string;
  id?: string;
}

@Component({
  selector: 'app-upload-dropzone',
  templateUrl: './upload-dropzone.component.html',
  styleUrls: ['./upload-dropzone.component.scss']
})
export class UploadDropzoneComponent implements OnInit, OnChanges, OnDestroy {

  @Input() highlight?: boolean;
  @Input() selectedHighlight?: string;
  @Input() status?: string;
  @Input() tag?: string;
  @Input() multiple?: boolean;
  @Input() form: string;
  @Input() field: string;
  @Input() initialFiles?: { thumbUrl: string, largeUrl: string, uuid: string }[];
  @Input() entity: string;
  @Output() fileChanged = new EventEmitter<FileChangedEvent>();
  @Output() highlightChanged = new EventEmitter<HighlightChangedEvent>();

  files: File[] = [];
  fileIds: string[] = [];
  highlightedId?: string;

  private subscription: Subscription;

  constructor(private apiService: ApiService, private sanitizer: DomSanitizer, private http: HttpClient) {
  }

  ngOnInit(): void {
    this.subscription = new Subscription();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['initialFiles']) {
      return;
    }
    if (!this.subscription) {
      this.subscription = new Subscription();
    }
    if (this.initialFiles && this.files.length === 0) {
      this.highlightedId = undefined;
      this.initialFiles.forEach(initialFile => {
        this.files = [];
        this.fileIds = [];
        const cacheHeaders = new HttpHeaders({
          'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          Pragma: 'no-cache',
          Expires: '0'
        });
        const sub = this.http.get(
          initialFile.thumbUrl,
          {responseType: 'blob', withCredentials: true, headers: cacheHeaders}
        ).subscribe(data => {
          this.files.push(new File([data], initialFile.thumbUrl, {type: data.type}));
          this.fileIds.push(initialFile.uuid);
        });
        this.subscription.add(sub);
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isHighlighted(file: File) {
    return this.selectedHighlight === this.fileIds[this.files.indexOf(file)];
    // return this.highlightedId === this.fileIds[this.files.indexOf(file)];
  }

  onSelect(event) {
    event.addedFiles.forEach(file => {
      this.apiService.postFile(`/jsonapi/${this.entity}/${this.field}`, file).subscribe(res => {
        if (this.multiple === false) {
          this.fileIds = [res.data.id];
          this.files = [file];
        } else {
          this.fileIds.push(res.data.id);
          this.files.push(file);
        }
        this.fileChanged.emit({form: this.form, field: this.field, ids: this.fileIds, tag: this.tag });
      });
    });
  }

  onHighlightChange(file: File) {
    const newId = this.fileIds[this.files.indexOf(file)];
    this.highlightedId = this.highlightedId === newId ? undefined : newId;
    this.highlightChanged.emit({ form: this.form, field: this.field, id: this.highlightedId, tag: this.tag });
  }

  onRemove(event) {
    this.fileIds.splice(this.files.indexOf(event), 1);
    this.files.splice(this.files.indexOf(event), 1);
    this.fileChanged.emit({form: this.form, field: this.field, ids: this.fileIds, tag: this.tag });
  }

}
