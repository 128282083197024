<nb-card *ngIf="isAuthenticated | async; else notLoggedIn">
  <nb-card-header>
    <h1 *ngIf="identityClaims && identityClaims.name">Welkom, {{ identityClaims.name }}</h1>
  </nb-card-header>
  <nb-card-body>
    <p>Gebruik het menu aan de linkerkant om door de site te navigeren.</p>
    <p></p>
  </nb-card-body>
</nb-card>
<ng-template #notLoggedIn>
  <nb-card>
    <nb-card-header>
      <h1>Inloggen</h1>
    </nb-card-header>
    <nb-card-body *ngIf="isDoneLoading | async; else authorizing">
      {{console.log('project', project)}}
      <div class="login-notification" *ngIf="project == 'ndhr' || project == 'tuinenklimaatroute'">
        <div class="warning">!</div>
        <ng-container *ngIf="project == 'tuinenklimaatroute'">
          <p><strong>Let op:</strong> Heb je al een Duurzame Huizen Route account? Dan kun je hier ook met die gegevens
            inloggen.</p>
        </ng-container>
        <ng-container *ngIf="project == 'ndhr'">
          <p><strong>Let op:</strong> Heb je al een Tuin & Klimaat Route account? Dan kun je hier ook met die gegevens
            inloggen.</p>
        </ng-container>
      </div>
      <ng-container *ngIf="project !== 'vd'">
        <p>Log in met uw account, of <a routerLink="/registreren">maak een nieuw account aan</a>.</p>
      </ng-container>
      <ng-container *ngIf="project == 'vd'">
        <p>Log in met uw account, of <a href="https://veluweduurzaam.nl/registreren">maak een nieuw account aan</a>.</p>
      </ng-container>
      <form class="login-form" [formGroup]="loginForm" (ngSubmit)="doLogin()">
        <div class="form-item">
          <label for="username" class="label">E-mailadres</label>
          <input nbInput type="text" id="username" formControlName="username">
        </div>
        <div class="form-item">
          <label for="password" class="label">Wachtwoord</label>
          <input nbInput type="password" id="password" formControlName="password">
        </div>
        <div class="form-item">
          <input nbButton type="submit" value="Log in" status="basic" [disabled]="loginForm.invalid">
        </div>
      </form>
      <p><a routerLink="wachtwoord-vergeten">Wachtwoord vergeten</a>.</p>
    </nb-card-body>
    <ng-template #authorizing>
      <nb-card-body>
        <p>Authoriseren, een ogenblik geduld...</p>
      </nb-card-body>
    </ng-template>
  </nb-card>
</ng-template>
