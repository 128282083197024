import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-submission',
  templateUrl: './form-submission.component.html',
  styleUrls: ['./form-submission.component.scss']
})
export class FormSubmissionComponent implements OnInit {

  @Input() formSubmissionData;
  @Input() valuesOnly: boolean;
  @Input() infoOnly: boolean;
  submittedForm;
  formSubmission;
  formElements;
  console = console;

  private containerTypes = [
    'container',
    'fieldset'
  ];

  // Hack to stop the keyvalue pipe reordering objects.
  public keepOriginalOrder = (a, b) => a.key;

  constructor() {
  }

  ngOnInit() {
    this.submittedForm = this.formSubmissionData.webform;
    this.formSubmission = this.formSubmissionData.submission_data;
    this.formElements = this.formSubmissionData.elements;

    //console.log('FormSubmissionComponent ngOnInit formElements', this.formElements);
  }

  isArray(variable) {
    if (variable instanceof Object) {
      return true;
    }
    return false;
  }

  isContainerElement(element: any) {
    if(element.value) {
      return this.containerTypes.includes(element.value['#type']);
    }
  }
}
