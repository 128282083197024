import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RouteService} from '../../../services/route/route.service';
import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import * as jsonApiUtils from '../../../utils/json-api.utils';
import {ApiService} from '../../../services/api/api.service';
import {AuthService} from '../../../services/auth/auth.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-submit-opening-times',
  templateUrl: './submit-opening-times.component.html',
  styleUrls: ['./submit-opening-times.component.scss']
})
export class SubmitOpeningTimesComponent implements OnInit {

  objectId: number;
  dateTid: number;
  routeConfig;
  openingTimesForm: FormGroup;
  errorMsg;
  openingTimesSaved;

  showCompanyOnLocation = false;

  submitting = false;

  maxVisitorsPerTime: number | null = null;
  maxVisitorsPerDayPart: number | null = null;

  get pdb() {
    return this.project === 'platformduurzamebedrijven';
  }

  get tkr() {
    return this.project === 'tuinenklimaatroute';
  }

  get vd() {
    return this.project === 'vd';
  }

  get ndhr() {
    return this.project === 'ndhr';
  }

  constructor(
    private route: ActivatedRoute,
    private routeService: RouteService,
    private toastrService: MajadaToastrService,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    @Inject('project') private project: string,
    ) {
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.objectId = params.get('objectId') as unknown as number;
      this.dateTid = params.get('dateTid') as unknown as number;
    });

    this.routeService.getRouteConfigForObjectAndDate(this.objectId, this.dateTid).subscribe(result => {
      console.log('result', result);
      this.routeConfig = result;

      if(this.routeConfig.route_config.field_bedrijf_aanwezig_tonen && this.routeConfig.route_config.field_bedrijf_aanwezig_tonen[0]) {
        this.showCompanyOnLocation = this.routeConfig.route_config.field_bedrijf_aanwezig_tonen[0].value;
      }

      console.log('this.routeConfig', this.routeConfig);
      if (this.routeConfig.route_config.field_max_bezoekers_dagdeel[0]) {
        this.maxVisitorsPerDayPart = this.routeConfig.route_config.field_max_bezoekers_dagdeel[0].value;
      }
      if (this.routeConfig.route_config.field_max_bezoekers_tijdstip[0]) {
        this.maxVisitorsPerTime = this.routeConfig.route_config.field_max_bezoekers_tijdstip[0].value;
      }
      this.initForm();
    }, error => {
      console.log(error);
      this.errorMsg = error.error;
      this.toastrService.error('Openingstijden kunnen niet worden doorgegeven.');
    });

    // Get config for specified location and date IF allowed.
  }

  initForm(): void {
    this.openingTimesForm = this.routeService.openingTimesForm;

    // Init a new form group for time fields.
    const timesGroup = new FormGroup({});

    if (this.routeConfig.times.length > 0) {
      // Loop through al times that haven been returned by the api and add form elements to the timesGroup form group.
      this.routeConfig.times.forEach(time => {
        timesGroup.addControl(time.uuid[0].value, new FormControl(8, [Validators.required, Validators.max(this.maxVisitorsPerTime), Validators.min(0)]));
      });
    }

    const claims = this.authService.identityClaims;

    if (claims) {
      if (claims['full_name']) {
        this.openingTimesForm.get('nameOwner').setValue(claims['full_name']);
      }
    }

    if (this.routeConfig.day_parts.length === 0) {
      this.openingTimesForm.get('openingType').setValue('vaste_tijdstippen');
      this.openingTimesForm.get('openingType').updateValueAndValidity();
    }

    // Add the times form group to the existing opening times form.
    this.openingTimesForm.addControl('times', timesGroup);
    this.openingTimesForm.get('company').setValue('nee');

    this.openingTimesForm.get('openingType').valueChanges.subscribe(value => {
      if (value === 'vaste_tijdstippen') {

        // Set all time elements as required.
        // tslint:disable-next-line:forin
        for (const controlsKey in timesGroup.controls) {
          const formElement = timesGroup.get(controlsKey);

          if (formElement instanceof FormControl) {
            formElement.setValidators([Validators.required, Validators.max(this.maxVisitorsPerTime), Validators.min(0)]);
            formElement.updateValueAndValidity();
          }
        }

        // Remove required from all day part elements.
        this.openingTimesForm.get('totalPlaces').clearValidators();
        this.openingTimesForm.get('totalPlaces').updateValueAndValidity();
        this.openingTimesForm.get('dayParts').clearValidators();
        this.openingTimesForm.get('dayParts').updateValueAndValidity();
      } else {

        // Reset requirement state for all times.
        for (const controlsKey in timesGroup.controls) {
          const formElement = timesGroup.get(controlsKey);

          if (formElement instanceof FormControl) {
            formElement.clearValidators();
            formElement.updateValueAndValidity();
          }
        }

        // Set all day part elements as required.
        this.openingTimesForm.get('totalPlaces').setValidators([Validators.required, Validators.max(this.maxVisitorsPerDayPart), Validators.min(0)]);
        this.openingTimesForm.get('totalPlaces').updateValueAndValidity();
        this.openingTimesForm.get('dayParts').setValidators([Validators.required]);
        this.openingTimesForm.get('dayParts').updateValueAndValidity();
      }
    });
  }

  submitOpeningTimesForm() {
    const availability = this.openingTimesForm.get('availability').value;

    this.submitting = true;

    const attributes: any = {};
    const relations: any = {};
    attributes.title = 'Object openingstijd voor ' + this.routeConfig.object.title[0].value + ' voor datumId ' + this.dateTid;

    relations.field_ref_openingstijd_voor = jsonApiUtils.createRelation(this.routeConfig.object.uuid[0].value, 'data--object');
    relations.field_ref_openingstijdstip = jsonApiUtils.createRelation(this.routeConfig.date_config.uuid[0].value, 'route--openingstijdstippen');
    attributes.field_opmerkingen_organisatie = this.openingTimesForm.get('remarksOrganizer').value;

    if (availability == 'notOpened') {
      attributes.field_niet_geopend = true;
      this.createObjectOpeningTimeEntity(attributes, relations);
    } else {
      // First, we need to determine if the type is fixed times or day parts.
      const openingType = this.openingTimesForm.get('openingType').value;

      // Set base attributes that are valid for either opening type.
      attributes.field_voorkeur_bezoek = openingType;
      attributes.field_is_er_een_bedrijf_aanwezig = this.openingTimesForm.get('company').value;
      attributes.field_opmerkingen_bezoekers = this.openingTimesForm.get('remarksVisitors').value;
      attributes.field_naam_van_huiseigenaar = this.openingTimesForm.get('nameOwner').value;
      attributes.field_route_openingsdatum = this.routeConfig.route_date;

      // For fixed times, we first need to create all the opening time entities,
      // then after that has been completed create the opening time entity.
      if (openingType === 'vaste_tijdstippen') {
        const timesGroup = this.openingTimesForm.get('times') as FormGroup;

        const timeEntities = this.routeService.buildTimeEntityData(timesGroup, this.objectId, this.dateTid);
        if (timeEntities.length > 0) {
          this.routeService.createOpeningTimeTimeEntities(timeEntities).then(data => {
            relations.field_ref_tijdstippen = jsonApiUtils.createRelation(data, 'route--tijdstippen');
            this.createObjectOpeningTimeEntity(attributes, relations);
          });
        }
      } else {
        relations.field_ref_tax_dagdelen
          = jsonApiUtils.createRelation(this.openingTimesForm.get('dayParts').value, 'taxonomy_term--route_dagdelen');
        attributes.field_totaal_aantal_bezoekers = this.openingTimesForm.get('totalPlaces').value;
        this.createObjectOpeningTimeEntity(attributes, relations);
      }
    }
  }

  createObjectOpeningTimeEntity(attributes, relations) {
    const body = jsonApiUtils.createBody(undefined, 'route--object_openingstijd', attributes, relations);
    this.apiService.post('/jsonapi/route/object_openingstijd', body).subscribe(result => {
      this.toastrService.success('Openingstijd is opgeslagen.', 'Opgeslagen');
      this.submitting = false;
      const objectType = this.routeConfig.route_config.field_object_soort[0].value;
      this.route.queryParamMap.subscribe(params => {
        if (params.get('redirectUrl')) {
          this.router.navigateByUrl(params.get('redirectUrl'));
        } else {
          this.router.navigateByUrl(`/route/openingstijden/${objectType}`);
        }
      });
    }, error => {
      this.toastrService.error('De openingstijd kon niet worden aangemaakt.');
      this.submitting = false;

    });
    // If openingTimeTimeEntityIds parameter is set, treat it as specific times.
    /*const attributes
    if (openingTimeTimeEntityIds) {

    } else {

    }*/
  }
}
