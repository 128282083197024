<form [formGroup]="form">
  <h3 class="hide-desktop">Situatie gegevens</h3>

  <section *ngIf="displayFlags.showDefinitiveRequestInstructions">
    <p>Uw vooraanvraag is volledig.</p>
    <p>U kunt nu een definitieve aanvraag indienen. Vink hieronder ‘ik heb de maatregelen al toegepast’ aan. Uw eerder ingevulde gegevens blijven dan gewoon staan.</p>
  </section>

  <section>
    <h6>Heeft u de maatregelen al toegepast of wilt u een vooraanvraag doen?</h6>
    <nb-radio-group name="field_type_aanvraag" formControlName="field_type_aanvraag">
      <nb-radio value="vooraanvraag"> Ik wil een vooraanvraag doen.</nb-radio>
      <nb-radio value="definitief"> Ik heb de maatregelen al toegepast.</nb-radio>
    </nb-radio-group>
  </section>

  <section>
    <h6>Gemeente selecteren</h6>
    <nb-select name="field_gemeente" formControlName="field_gemeente" id="gemeente">
      <nb-option *ngFor="let municipality of SubsidyRequestMunicipalityLabelKeys" [value]="municipality">{{ SubsidyRequestMunicipalityLabels[municipality] }}</nb-option>
    </nb-select>
  </section>

  <section>
    <h6>Welke situatie is voor u van toepassing?</h6>
    <nb-radio-group name="field_situatie" formControlName="field_situatie">
      <nb-radio value="koopwoning">Ik vraag de regeling aan voor mijn koopwoning.</nb-radio>
      <nb-radio value="huurhuis">Ik vraag de regeling aan voor mijn huurhuis en heb toestemming van de eigenaar van de woning.</nb-radio>
      <nb-radio value="vve">Ik vraag de regeling aan voor mijn VVE.</nb-radio>
      <nb-radio value="eigen_bedrijfspand">Ik vraag de regeling aan voor mijn eigen bedrijfspand. </nb-radio>
      <nb-radio value="gehuurd_bedrijfspand">Ik vraag de regeling aan voor een bedrijfspand dat ik huur met toestemming van de eigenaar.</nb-radio>
    </nb-radio-group>
  </section>

  <section>
    <h6>Mijn pand staat binnen de bebouwde kom.</h6>
    <nb-radio-group name="field_binnen_bebouwde_kom" formControlName="field_binnen_bebouwde_kom">
      <nb-radio [value]="true">Ja</nb-radio>
      <nb-radio [value]="false">Nee</nb-radio>
    </nb-radio-group>
  </section>

  <section>
    <h6>Is uw huis/pand gebouwd voor 2015?</h6>
    <nb-radio-group name="field_gebouwd_voor_2015" formControlName="field_gebouwd_voor_2015">
      <nb-radio [value]="true">Ja</nb-radio>
      <nb-radio [value]="false">Nee</nb-radio>
    </nb-radio-group>
  </section>

  <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
    <section>
      <h6>Eventuele vergunning uploaden</h6>
      <app-upload-dropzone form="form" entity="subsidieaanvraag/aanvraag" field="field_vergunning" [initialFiles]="images?.field_vergunning" 
        (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.form.get('field_vergunning'))"></app-upload-dropzone>
    </section>

    <section *ngIf="displayFlags.showLandlordStatementField">
      <h6>Uploaden akkoord verklaring van verhuurder</h6>
      <app-upload-dropzone form="form" entity="subsidieaanvraag/aanvraag" field="field_akkoord_verhuurder" [initialFiles]="images?.field_akkoord_verhuurder" 
        (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.form.get('field_akkoord_verhuurder'))"></app-upload-dropzone>
    </section>
  </ng-container>


  <section>
    <h6>Extra opmerkingen</h6>
    <textarea nbInput fullWidth rows="4" formControlName="field_extra_opmerkingen"></textarea>
  </section>

  <section class="nav">
    <div></div>
    <button nbButton nbStepperNext [disabled]="!form.valid">Volgende <nb-icon icon="arrow-ios-forward-outline"></nb-icon></button>
  </section>
</form>