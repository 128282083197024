import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthConfig, OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NbMomentDateModule} from '@nebular/moment';
import {authConfig} from './auth.config';
import {AuthService} from './services/auth/auth.service';
import {MyAppointmentsComponent} from './components/my-appointments/my-appointments.component';
import {environment} from '../environments/environment';
import {FormSubmissionComponent} from './components/form-submission/form-submission.component';
import {CommonModule, CurrencyPipe, registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeNlExtra from '@angular/common/locales/extra/nl';
import {AppointmentDialogComponent} from './components/my-appointments/appointment-dialog/appointment-dialog.component';
import {InboxComponent} from './components/inbox/inbox.component';
import {NodeModule} from './node/node.module';
import {SharedModule} from './shared/shared.module';
import {MyLocationsComponent} from './components/my-locations/my-locations.component';
import {EditLocationComponent} from './components/my-locations/edit-location/edit-location.component';
import {
  RequestOverviewComponent as EnergyLibraryRequestOverviewComponent
} from './components/energy-library/request-overview/request-overview.component';
import {AssignProductDialogComponent} from './components/energy-library/assign-product-dialog/assign-product-dialog.component';
import {PaginationComponent} from './components/widgets/pagination/pagination.component';
import {ProductOverviewComponent} from './components/energy-library/product-overview/product-overview.component';
import {EditProductDialogComponent} from './components/energy-library/edit-product-dialog/edit-product-dialog.component';
import {LocationOverviewComponent} from './components/energy-library/location-overview/location-overview.component';
import {EditLocationDialogComponent} from './components/energy-library/edit-location-dialog/edit-location-dialog.component';
import {SubsidyRequestFormComponent} from './components/subsidy/subsidy-request-form/subsidy-request-form.component';
import {SubsidyRequestFormStep1Component} from './components/subsidy/subsidy-request-form/subsidy-request-form-step1.component';
import {SubsidyRequestFormStep2Component} from './components/subsidy/subsidy-request-form/subsidy-request-form-step2.component';
import {SubsidyRequestFormStep3Component} from './components/subsidy/subsidy-request-form/subsidy-request-form-step3.component';
import {SubsidyRequestFormStep4Component} from './components/subsidy/subsidy-request-form/subsidy-request-form-step4.component';
import {SubsidyRequestReviewComponent} from './components/subsidy/subsidy-request-form/subsidy-request-review.component';
import {ViewSubsidyRequestDialogComponent} from './components/subsidy/view-subsidy-request-dialog/view-subsidy-request-dialog.component';
import {MySubsidyRequestsComponent} from './components/subsidy/my-subsidy-requests/my-subsidy-requests.component';
import {EditSubsidyRequestDialogComponent} from './components/subsidy/edit-subsidy-request-dialog/edit-subsidy-request-dialog.component';
import {
  SubmitSubsidyRequestDialogComponent
} from './components/subsidy/submit-subsidy-request-dialog/submit-subsidy-request-dialog.component';
import {
  CancelSubsidyRequestDialogComponent
} from './components/subsidy/cancel-subsidy-request-dialog/cancel-subsidy-request-dialog.component';
import {
  ViewEnergyLibraryRequestDialogComponent
} from './components/energy-library/view-energy-library-request-dialog/view-energy-library-request-dialog.component';
import {SubsidyRequestLogsDialogComponent} from './components/subsidy/subsidy-request-logs-dialog/subsidy-request-logs-dialog.component';
import {
  AddSubsidyRequestLogComponent
} from './components/subsidy/subsidy-request-logs-dialog/add-subsidy-request-log/add-subsidy-request-log.component';
import {SubsidyRequestStatisticsComponent} from './components/subsidy/subsidy-request-statistics/subsidy-request-statistics.component';
import {ProfileEditFormComponent} from './components/profile-edit-form/profile-edit-form.component';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import {AuthModule} from './auth/auth.module';
import {HttpClientModule} from '@angular/common/http';
import {ShellComponent} from './components/shell/shell.component';
import {RouteModule} from './modules/route/route.module';
import {NbDialogModule} from '@nebular/theme';
import {HomeComponent} from './components/home/home.component';
import { GetTourObjectHighlightedImagePipe} from './pipes/get-tour-object-highlighted-image.pipe';

registerLocaleData(localeNl, 'nl', localeNlExtra);
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  listPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    MyAppointmentsComponent,
    FormSubmissionComponent,
    AppointmentDialogComponent,
    InboxComponent,
    HomeComponent,
    MyLocationsComponent,
    EditLocationComponent,
    EnergyLibraryRequestOverviewComponent,
    AssignProductDialogComponent,
    PaginationComponent,
    ProductOverviewComponent,
    EditProductDialogComponent,
    LocationOverviewComponent,
    EditLocationDialogComponent,
    SubsidyRequestFormComponent,
    SubsidyRequestFormStep1Component,
    SubsidyRequestFormStep2Component,
    SubsidyRequestFormStep3Component,
    SubsidyRequestFormStep4Component,
    SubsidyRequestReviewComponent,
    ViewSubsidyRequestDialogComponent,
    MySubsidyRequestsComponent,
    EditSubsidyRequestDialogComponent,
    SubmitSubsidyRequestDialogComponent,
    CancelSubsidyRequestDialogComponent,
    ViewEnergyLibraryRequestDialogComponent,
    SubsidyRequestLogsDialogComponent,
    AddSubsidyRequestLogComponent,
    SubsidyRequestStatisticsComponent,
    ProfileEditFormComponent,
    ShellComponent,
  ],
  exports: [
  ],
  imports: [
    CommonModule,
    FullCalendarModule,
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    SharedModule,
    NbMomentDateModule,
    AppRoutingModule,
    FormsModule,
    NodeModule,
    RouteModule,
    HttpClientModule,
    NbDialogModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.drupalUrl + '/api', environment.drupalUrl + '/jsonapi'],
        sendAccessToken: true
      }
    }),
  ],
  providers: [
    {
      provide: AuthConfig,
      useValue: authConfig
    },
    {
      provide: LOCALE_ID,
      useValue: 'nl'
    },
    AuthService, {
      provide: OAuthStorage,
      useValue: localStorage
    },
    {
      provide: 'drupalUrl',
      useValue: environment.drupalUrl
    },
    {
      provide: 'isProduction',
      useValue: environment.production
    },
    {
      provide: 'routeName',
      useValue: environment.routeName
    },
    {
      provide: 'domainUuid',
      useValue: environment.domainUuid
    },
    {
      provide: 'project',
      useValue: environment.project
    },
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
