<div class="node {{node.type[0].target_id}}" *ngIf="node">
  <section>
    <img class="header-image border-radius" [src]="headerImages[0]" *ngIf="headerImages">
    <div class="row">
      <div class="col-md-9">
        <nb-card>
          <nb-card-header>
            <h1 class="node--title">{{node.title[0].value}}</h1>
            <div class="node--date">{{node.created[0].value | date: 'd MMMM': 'Europe/Amsterdam': 'nl-NL'}}</div>
          </nb-card-header>
          <nb-card-body>
            <div class="node--body" *ngIf="node.body.length > 0" [innerHTML]="node.body[0].value | safeHtml"></div>
            <ng-container *ngIf="node.field_formulier_embed && node.field_formulier_embed.length > 0">
              <div class="webform--embed" #iframeWrapper [innerHTML]="iframe | safeHtml"
                   *ngIf="iframe !== undefined; else invalidEmbed"></div>
              <ng-template #invalidEmbed>
                <div class="embed-form--iframe">Het formulier wordt geladen...
                </div>
              </ng-template>
            </ng-container>

            <div class="node--comments"
                 *ngIf="node.type[0].target_id !== 'pagina' && node.type[0].target_id !== 'locatie'">
              <h3>Reacties</h3>
              <app-comments [nodeType]="node.type[0].target_id" [uuid]="node.uuid[0].value"></app-comments>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
      <aside class="col-md-3">
        <div class="node--downloads border-radius" *ngIf="files">
          <h3>Downloads</h3>
          <div class="downloads--files">
            <div class="files--file" *ngFor="let file of files">
              <nb-icon icon="file-text-outline"></nb-icon>
              <a [href]="drupalUrl + file.uri[0]['url']" target="_blank">{{file.filename[0]['value']}}</a>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </section>
</div>
