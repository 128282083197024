<div class="row">
  <div class="col-9">
    <h2>Inschrijving voor {{registrationData.object.title[0].value}}
      op {{(registrationData.date * 1000) | date: 'd MMMM yyyy'}}</h2>
    <div class="row">
      <div class="col-12">
        <div class="remarks-home-owner" *ngIf="registrationData.remark_visitor">
          <p>{{registrationData.remark_visitor}}</p>
        </div>
    </div>
    </div>
    <div class="row registration-information">
      <div class="col-md-6 col-12">
        <div>
          <strong>Tijdstip: </strong>
          <span>{{registrationData.time_label}}</span>
        </div>
        <div>
          <strong>Adres: </strong>
          <span [innerHTML]="registrationData.location_address"></span>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div>
          <strong>Aantal personen: </strong>
          <span>{{registrationData.registration.field_aantal_personen[0].value}}</span>
        </div>
        <div>
          <strong>{{contactPersonLabel}}: </strong>
          <span>{{registrationData.name_owner}}</span>
        </div>
      </div>
    </div>
    <div class="company-home-owner" *ngIf="registrationData.object_date_config.field_is_er_een_bedrijf_aanwezig[0]?.value === 'ja'">
      <p>Bij deze woning is een bedrijf aanwezig.</p>
    </div>
  </div>
  <div class="col-3 object-image">
    <img [src]="registrationData.object_image" style="width: 100%">
    <a nbButton [href]="wordpressUrl + registrationData.object.path[0].alias" status="primary" outline>Bekijk {{registrationData.object.field_object_soort[0].value}}</a>
  </div>
</div>
