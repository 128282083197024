import {Input, Output, EventEmitter, Component} from '@angular/core';
import {range} from 'lodash-es';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input() numPages: number;

  @Input('page')
  get page(): number { return this.currentPage; }
  set page(num: number) {
    this.currentPage = num;
  }

  @Output() pageChange = new EventEmitter<number>();

  private currentPage = 1;

  get pages() {
    return range(1, (this.numPages || 0) + 1);
  }

  constructor() { }

  selectPage(page: number) {
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }

}
