import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {LocationsService} from '../../../services/locations/locations.service';
import {includes} from 'lodash-es';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-my-visitors',
  templateUrl: './my-visitors.component.html',
  styleUrls: ['./my-visitors.component.scss']
})
export class MyVisitorsComponent implements OnInit, OnDestroy {

  residences;
  subscription = new Subscription();

  get locationType() {
    switch (this.project) {
      case 'tuinenklimaatroute':
        return 'tuin of project';
      case 'platformduurzamebedrijven':
        return 'bedrijf';
      default:
        return 'woning';
    }
  }

  constructor(
    private locationsService: LocationsService,
    private activatedRoute: ActivatedRoute,
    @Inject('project') private project: string,
    @Inject('domainUuid') private domainUuid: string
  ) {
  }

  ngOnInit(): void {
    const activatedRouteSub = this.activatedRoute.paramMap.subscribe(params => {
      const objectType = params.get('objectType');
      const locationsSub = this.locationsService.getLocations(this.domainUuid, objectType).subscribe(res => {
        this.residences = res;
        //console.log('this.residences', this.residences);
      });
      this.subscription.add(locationsSub);
    });
    this.subscription.add(activatedRouteSub);
  }

  ngOnDestroy(): void {
  }

}
