<nb-card>
  <nb-card-header>Profiel bewerken</nb-card-header>
  <nb-card-body [nbSpinner]="isLoading" nbSpinnerStatus="basic">
    <form [formGroup]="formGroup" id="profile-form" (ngSubmit)="saveProfile()">
      <div class="form-control">
        <div>
          <label for="profile-form--name" class="label required">Gebruikersnaam</label>
          <input id="profile-form--name" nbInput formControlName="name" type="text">
        </div>
      </div>
      <div class="form-control columns">
        <div>
          <label for="profile-form--password" class="label required">Huidig Wachtwoord</label>
          <input id="profile-form--password" nbInput formControlName="old_password" type="password">
        </div>
        <div>
          <label for="profile-form--new-password" class="label required">Nieuw Wachtwoord</label>
          <input id="profile-form--new-password" nbInput formControlName="new_password" type="password">
        </div>
      </div>
      <div class="form-control columns">
        <div>
          <label for="profile-form--name" class="label required">E-mailadres</label>
          <input id="profile-form--mail" nbInput formControlName="mail" type="email"></div>
      </div>
      <div class="form-control columns">
        <div>
          <label for="profile-form--phone" class="label required">Telefoonnummer</label>
          <input id="profile-form--phone" nbInput formControlName="field_phone_number" type="text">
        </div>
      </div>
      <div class="form-control-inline columns">
        <div class="short">
          <label for="profile-form--initials" class="label required">Voorletters</label>
          <input nbInput id="profile-form--initials" formControlName="field_initials" type="text">
        </div>
        <div>
          <label for="profile-form--given-name" class="label required">Voornaam</label>
          <input nbInput id="profile-form--given-name" formControlName="field_given_name" type="text">
        </div>
        <div>
          <label for="profile-form--family-name" class="label required">Achternaam</label>
          <input nbInput id="profile-form--family-name" formControlName="field_family_name" type="text">
        </div>
      </div>
      <div class="form-control-inline columns">
        <div>
          <label for="profile-form--address-line1" class="label required">Straat</label>
          <input nbInput id="profile-form--address-line1" fullWidth formControlName="field_address_line1" type="text">
        </div>
        <div class="short">
          <label for="profile-form--address-line2" class="label required">Huisnummer</label>
          <input nbInput id="profile-form--address-line2" formControlName="field_address_line2" type="text">
        </div>
      </div>
      <div class="form-control-inline columns">
        <div class="short">
          <label for="profile-form--postalcode" class="label required">Postcode</label>
          <input nbInput id="profile-form--postalcode" formControlName="field_postal_code" type="text">
        </div>
        <div>
          <label for="profile-form--locality" class="label required">Plaats</label>
          <input nbInput id="profile-form--locality" fullWidth formControlName="field_locality" type="text">
        </div>
      </div>
      <div class="form-control columns">
        <div>
          <label for="profile-form--age" class="label required">18 jaar of ouder</label>
          <nb-checkbox id="profile-form--age" formControlName="field_18_years_or_older"></nb-checkbox>
        </div>
      </div>
      <div class="form-control columns">
        <input type="submit" value="Opslaan" status="success" nbButton [disabled]="formGroup.invalid">
      </div>
    </form>
  </nb-card-body>
</nb-card>
