import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription, combineLatest, Subject} from 'rxjs';
import {tap, flatMap, map, filter, debounceTime} from 'rxjs/operators';
import {NbDialogService} from '@nebular/theme';
import {EnergyLibraryService} from '../../../services/energy-library/energy-library.service';
import {EnergyLibraryLocation, EnergyLibraryUser} from '../../../models/energy-library.models';
import {EditLocationDialogComponent} from '../edit-location-dialog/edit-location-dialog.component';

type EnergyLibraryLocationListItem = EnergyLibraryLocation & {
  employees: EnergyLibraryUser[];
  employeeNamesView: string;
};

@Component({
  selector: 'app-location-overview',
  templateUrl: './location-overview.component.html',
  styleUrls: ['./location-overview.component.scss']
})
export class LocationOverviewComponent implements OnInit, OnDestroy {

  private subs: Subscription[];

  locations: EnergyLibraryLocationListItem[];
  filteredLocations: EnergyLibraryLocationListItem[];

  employees$ = new BehaviorSubject<EnergyLibraryUser[]>(null);
  nameFilterModel$ = new BehaviorSubject<string>(null);
  employeeFilterModel$ = new BehaviorSubject<string>('');
  isLoading$ = new BehaviorSubject<boolean>(true);

  filters$ = combineLatest([this.nameFilterModel$, this.employeeFilterModel$]);

  apiInputs$ = combineLatest(this.nameFilterModel$, this.employees$);
  apiRequests$ = this.apiInputs$.pipe(
    filter(([name, employees]) => !!employees),
    debounceTime(500),
    tap(() => this.isLoading$.next(true)),
    flatMap(([name]) => this.energyLibraryService.getLocations(name)),
    tap(() => this.isLoading$.next(false)),
  );

  locations$ = new BehaviorSubject<EnergyLibraryLocation[]>(null);
  filteredLocations$ = combineLatest(this.locations$, this.employees$, this.employeeFilterModel$).pipe(
    map(([locations, employees, employeeFilter]) => {
      if (!locations) return null;

      const addEmployees = (loc: EnergyLibraryLocation) => {
        const employeesAtLocation = (employees || []).filter(emp => !!(emp.field_ref_locatie || []).find(x => x.__uuid === loc.__uuid));
        const employeeNamesView = employeesAtLocation.map(emp => emp.name).join(', ');
        return {  ...loc, employees: employeesAtLocation, employeeNamesView } as EnergyLibraryLocationListItem;
      };
      const filterByEmployee = (loc: EnergyLibraryLocationListItem) => {
        return loc.employeeNamesView.indexOf(employeeFilter) !== -1;
      };
      return locations.map(addEmployees).filter(filterByEmployee);
    })
  );

  constructor(
    private dialogService: NbDialogService,
    private energyLibraryService: EnergyLibraryService
  ) {
    this.subs = [
      this.energyLibraryService.locationChanged$.subscribe(this.loadEmployees.bind(this)),
      this.filteredLocations$.subscribe(locations => this.filteredLocations = locations),
      this.apiRequests$.subscribe(locations => this.locations$.next(locations))
    ];
  }

  ngOnInit(): void {
    this.loadEmployees();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.nameFilterModel$.complete();
    this.employeeFilterModel$.complete();
    this.isLoading$.complete();
  }

  private loadEmployees() {
    this.isLoading$.next(true);
    this.energyLibraryService.getEmployees().subscribe(employees => {
      this.employees$.next(employees);
    });
  }

  addNewLocation() {
    this.dialogService.open(EditLocationDialogComponent, {
      closeOnBackdropClick: false,
      context: {
        isNewLocation: true,
        employees: this.employees$.value,
      }
    });
  }

  editLocation(location: EnergyLibraryLocationListItem) {
    this.dialogService.open(EditLocationDialogComponent, {
      closeOnBackdropClick: false,
      context: {
        isNewLocation: false,
        location,
        locationEmployees: location.employees,
        employees: this.employees$.value,
      }
    });
  }
}
