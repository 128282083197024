import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../../services/auth/auth.service';
import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'app-cancel-route-registration-dialog',
  templateUrl: './cancel-route-registration-dialog.component.html',
  styleUrls: ['./cancel-route-registration-dialog.component.scss', '../../../shared/shared.scss']
})
export class CancelRouteRegistrationDialogComponent implements OnInit {

  registrationId;
  canceled = false;
  error = false;
  isSaving = false;

  get showSpinner() {
    return this.isSaving;
  }

  constructor(
    protected ref: NbDialogRef<CancelRouteRegistrationDialogComponent>,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private toastrService: MajadaToastrService,
    @Inject('drupalUrl') private drupalUrl: string) {

  }

  ngOnInit(): void {
  }

  deleteRegistration() {
    this.isSaving = true;
    this.http.post(`${this.drupalUrl}/api/route/cancel-registration`, {registration_id: this.registrationId}).subscribe(result => {
      this.canceled = true;
      this.error = false;
      this.isSaving = false;
      if (this.authService.isAuthenticated$ && this.authService.hasValidToken()) {
        this.router.navigateByUrl('/route/mijn-inschrijvingen');
        this.toastrService.success('Je inschrijving is geannuleerd', 'Geannuleerd');
        this.ref.close(true);
      } else {
        this.canceled = true;
        this.error = false;
        this.toastrService.error('Kon de inschrijving niet annuleren');
        this.ref.close(false);
      }
    }, error => {
      this.canceled = true;
      this.error = true;
      this.toastrService.error('Kon de inschrijving niet annuleren');
      this.ref.close(false);
    });
  }

  close() {
    if (!this.isSaving) {
      this.ref.close(false);
    }
  }

}
