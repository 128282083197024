import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import * as jsonApiUtils from '../../utils/json-api.utils';
import {TaxonomyTerm} from 'src/app/models/drupal-datatypes.models';
import {ApiService} from '../api/api.service';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService {

  termsMap = new Map<string, any>();

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    @Inject('drupalUrl') private drupalUrl: string) {
  }

  public getTermById(name: string, uuid: string) {
    const uri = this.drupalUrl + '/jsonapi/taxonomy_term/' + name + '/' + uuid;
    return this.http.get<any>(uri).pipe(map(res => jsonApiUtils.parseResponseSingle<any>(res)));
  }

  public getTermsBySearchQuery(name: string, searchQuery: string, limit?: number): Observable<Array<TaxonomyTerm>> {
    const defaultLimit = 10;
    const nameFilter = !searchQuery ? undefined :
      `filter[name][condition][path]=name` +
      `&filter[name][condition][operator]=CONTAINS` +
      `&filter[name][condition][value]=${searchQuery}`;
    const uri = `/jsonapi/taxonomy_term/${name}?${nameFilter || ''}&page[limit]=${limit || defaultLimit}`;
    return this.apiService.get(uri, false).pipe(
      map(res => jsonApiUtils.parseResponseMany<TaxonomyTerm>(res)),
    );
  }



  /**
   * Get terms by vocabulary name.
   *
   * @param name
   *  Vocabulary to load the terms for.
   */
  getTermsByVocabulary(name: string): Observable<any> {
    if (!this.termsMap.get(name)) {
      return this.http.get<any>(this.drupalUrl + '/jsonapi/taxonomy_term/' + name).pipe(map(result => {
        if (result.data) {
          this.termsMap.set(name, result.data);
          return result.data;
        }
      }));
    } else {
      return of(this.termsMap.get(name));
    }
  }

}
