import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {HttpClient} from '@angular/common/http';
import * as jsonApiUtils from '../../utils/json-api.utils';
import {SubsidyLog} from '../../models/subsidy.models';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubsidyRequestLogsService {

  constructor(
  	private apiService: ApiService,
    private http: HttpClient) { }

  getLogs(id: string) {
  	const uri = '/jsonapi/logging/subsidie_logging?filter[field_ref_subsidieaanvraag.id]='+id+'&include=uid';
    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseMany<SubsidyLog>(res))
    );
  }

  postLog(id: string, logTitle: string, logText: string) {
    const uri = '/jsonapi/logging/subsidie_logging?include=uid';
    console.log("posting log for ", id, logText);

    const attributes = {
      title: logTitle,
      field_omschrijving: logText,
    };
    const relations = {
      field_ref_subsidieaanvraag: {data: {id: id, type: "subsidieaanvraag--aanvraag"}}
    };
    const body = jsonApiUtils.createBody(null, 'logging--subsidie_logging', attributes, relations);
    return this.apiService.post(uri, body).pipe(
      map(res => jsonApiUtils.parseResponseSingle<SubsidyLog>(res)));
  }
  
}
