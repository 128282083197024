import {Component, ViewChild, OnDestroy, OnInit, AfterViewInit} from '@angular/core';
import {BehaviorSubject, from, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {NbDialogService} from '@nebular/theme';

import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {SubsidyService} from 'src/app/services/subsidy/subsidy.service';
import {SubsidyRequest, SubsidyRequestFields, SubsidyRequestStatus} from 'src/app/models/subsidy.models';
import {UserFields} from 'src/app/models/drupal-datatypes.models';
import {SubsidyRequestFormStep1Component} from './subsidy-request-form-step1.component';
import {SubsidyRequestFormStep2Component} from './subsidy-request-form-step2.component';
import {SubsidyRequestFormStep3Component} from './subsidy-request-form-step3.component';
import {SubsidyRequestFormStep4Component} from './subsidy-request-form-step4.component';
import {ViewSubsidyRequestDialogComponent} from '../view-subsidy-request-dialog/view-subsidy-request-dialog.component';
import {SubsidyFormState} from './subsidy-form-state';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth/auth.service';
import {UserService} from '../../../services/user/user.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-subsidy-request-form',
  templateUrl: './subsidy-request-form.component.html',
  styleUrls: ['./subsidy-request-form.component.scss']
})
export class SubsidyRequestFormComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(SubsidyRequestFormStep1Component, {static: false}) step1: SubsidyRequestFormStep1Component;
  @ViewChild(SubsidyRequestFormStep2Component, {static: false}) step2: SubsidyRequestFormStep2Component;
  @ViewChild(SubsidyRequestFormStep3Component, {static: false}) step3: SubsidyRequestFormStep3Component;
  @ViewChild(SubsidyRequestFormStep4Component, {static: false}) step4: SubsidyRequestFormStep4Component;

  numericRegex = '^-?[0-9]\\d*([.,]\\d{1,2})?$';

  userId: string;
  requestId: string;
  selectedMunicipality = null;
  isComplete = false;
  isLoading = false;

  subs = [] as Subscription[];

  formState$ = new BehaviorSubject<SubsidyFormState>({isNewRequest: false});

  request: SubsidyRequest;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private subsidyService: SubsidyService,
    private userService: UserService,
    private dialogService: NbDialogService,
    private http: HttpClient,
    private router: Router,
    private majadaToastrService: MajadaToastrService) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.isComplete = false;
    this.subs.push(this.route.params.subscribe(params => {
      if (params.id) {
        this.requestId = params.id;
        this.subsidyService.getRequestById(this.requestId).subscribe(res => {
          this.request = res;
          this.subsidyService.loadImages(res.drupal_internal__id).subscribe(images => {
            this.userId = res.uid.__uuid;
            this.step1.fillForm(res, images);
            this.step2.fillForm(res, images);
            this.step3.fillForm(res);
            this.step4.fillForm(res);
            const newFormState = {
              ...this.formState$.value,
              images,
              isNewRequest: false,
              emailAddress: res.uid.mail
            };
            this.formState$.next(newFormState);
            this.isLoading = false;
          });

        });
      } else {
        this.authService.isDoneLoading$.subscribe(async () => {
          //const profile = await (this.authService.userProfile as any);
          let newFormState = {};
          this.userId = this.authService.uuid;
          const sub = this.userService.getUser().subscribe(user => {
            newFormState = {
              ...this.formState$.value,
              isNewRequest: true,
              emailAddress: user.mail
            };
            this.formState$.next(newFormState);
            this.isLoading = false;
          });
          this.subs.push(sub);
        });
      }
    }));
  }

  ngAfterViewInit(): void {
    this.subs.push(
      this.formState$.subscribe(val => this.step1.formState$.next(val)),
      this.formState$.subscribe(val => this.step2.formState$.next(val)),
      this.formState$.subscribe(val => this.step3.formState$.next(val)),
      this.formState$.subscribe(val => this.step4.formState$.next(val)),
      this.step1.form.valueChanges.subscribe(val => this.formState$.next({
        ...this.formState$.value,
        step1FormData: this.step1.exportForm(),
      })),
      this.step2.form.valueChanges.subscribe(val => this.formState$.next({
        ...this.formState$.value,
        step2FormData: this.step2.exportForm(true),
      })),
      this.step3.form.valueChanges.subscribe(val => this.formState$.next({
        ...this.formState$.value,
        step3FormData: this.step3.exportForm(),
        step3UserFormData: this.step3.exportUserForm(),
      })),
      this.step4.onSave$.subscribe(this.save.bind(this)),
      this.step4.onSubmit$.subscribe(this.submit.bind(this))
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private exportForm() {
    return {
      ...this.step1.exportForm(),
      ...this.step2.exportForm(),
      ...this.step3.exportForm(),
      ...this.step4.exportForm(),
    };
  }

  save() {
    const fields: SubsidyRequestFields = {
      ...this.exportForm(),
      field_aanvraag_status: SubsidyRequestStatus.Opgeslagen,
      field_status_beoordeling_vd: null,
      field_jaar: new Date().getFullYear(),
    };
    const userFields = this.step3.exportUserForm() as UserFields;

    this.subsidyService.addOrUpdateRequest(this.requestId, fields, this.userId, userFields).subscribe(result => {
      this.requestId = result;
      this.majadaToastrService.success('Uw aanvraag is opgeslagen', 'Opgeslagen');
      this.step4.onSaveResult$.next(true);
      this.router.navigateByUrl('subsidies/mijn-aanvragen');
    }, error => {
      this.step4.onSaveResult$.next(false);
      this.majadaToastrService.error('Uw aanvraag kon niet worden opgeslagen.', 'Fout');
    });
  }

  submit() {
    const fields: SubsidyRequestFields = {
      ...this.exportForm(),
      field_aanvraag_status: SubsidyRequestStatus.WordtGecontroleerd,
      field_status_beoordeling_vd: null,
      field_indiendatum: moment().format('YYYY-MM-DD'),
      field_jaar: new Date().getFullYear(),
    };
    const userFields = this.step3.exportUserForm() as UserFields;
    this.subsidyService.addOrUpdateRequest(this.requestId, fields, this.userId, userFields).subscribe(result => {
      this.requestId = result;
      this.majadaToastrService.success('Uw aanvraag is ingediend', 'Ingediend');
      this.step4.onSaveResult$.next(true);
      this.isComplete = true;
      this.router.navigateByUrl('subsidies/mijn-aanvragen');
    }, error => {
      this.step4.onSaveResult$.next(false);
      this.majadaToastrService.error('Uw aanvraag kon niet worden ingediend.', 'Fout');
    });
  }






}
