import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';
import {DOCUMENT} from '@angular/common';
import {LinkDefinition} from '../../models/link-definition';

@Injectable({providedIn: 'root'})
export class MetatagsService {

  constructor(private meta: Meta, private titleService: Title,
              @Inject(DOCUMENT) private readonly document: Document,
              @Inject(LOCALE_ID) private locale: string,
              @Inject('drupalUrl') private drupalUrl: string) {
  }

  setMetatags(meta, noSiteName?: boolean) {
    // var tags = this.meta.getTags();
    // console.log(tags);
    const self = this;
    if (meta != undefined) {

      // Unset all Meta tags to prevent them from leaking to different pages
      this.removeMetatags();
      this.removeLinkTag({rel: 'canonical'});
      // Set all new metatags.
      const newTags = meta.value;

      Object.keys(newTags).forEach((key) => {

        if (key === 'canonical_url') {
          let href = '';
          href = newTags[key].replace(this.drupalUrl, environment.frontendUrl);
          self.updateLinkTag({rel: 'canonical', href});
        } else if (key === 'title') {
          if (noSiteName) {
            self.setTitleNoSiteName(newTags[key]);
          } else {
            self.setTitle(newTags[key]);
          }
        } else {
          // If meta tag already exists, update it.
          if (self.meta.getTag('name=' + key)) {
            self.meta.updateTag({name: key, content: newTags[key]});
          } else {
            self.meta.addTag({name: key, content: newTags[key]});
          }
        }
      });
    }
  }

  /**
   * Remove a link tag from DOM
   * @param  tag
   */
  public removeLinkTag(tag: LinkDefinition): void {
    const selector = this._parseSelector(tag);
    const linkElement = this.document.head.querySelector(selector) as HTMLLinkElement;

    if (linkElement) {
      this.document.head.removeChild(linkElement);
    }
  }

  /**
   * Create or update a link tag
   * @param  {LinkDefinition} tag
   */
  public updateLinkTag(tag: LinkDefinition): void {
    const selector = this._parseSelector(tag);
    const linkElement = this.document.head.querySelector(selector) as HTMLLinkElement
      || this.document.head.appendChild(this.document.createElement('link'));

    if (linkElement) {
      Object.keys(tag).forEach((prop: string) => {
        linkElement[prop] = tag[prop];
      });
    }
  }

  removeMetatags() {
    // Hacky.  Unset all existing metatags
    const currentTags = this.meta.getTags('name');
    const self = this;
    if (currentTags) {
      Object.keys(currentTags).forEach((key) => {
        if (currentTags[key].name !== 'viewport' && currentTags[key].name !== 'msapplication-TileColor' && currentTags[key].name !== 'theme-color') {
          self.meta.removeTagElement(currentTags[key]);
        }
      });
    }
  }

  setTitleNoSiteName(title) {

    this.titleService.setTitle(title);
  }

  setTitle(title) {
    this.titleService.setTitle(title + ' | Mijn Majada');
  }

  /**
   * Parse tag to create a selector
   * @param  tag
   * @return {string} selector to use in querySelector
   */
  private _parseSelector(tag: LinkDefinition): string {
    const attr: string = tag.id ? 'id' : tag.rel ? 'rel' : 'hreflang';
    return `link[${attr}="${tag[attr]}"]`;
  }

}
