import {Component, Inject, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../../services/api/api.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-comment-user',
  templateUrl: './comment-user.component.html',
  styleUrls: ['./comment-user.component.scss']
})
export class CommentUserComponent implements OnInit {

  environment = environment;

  @Input() user;
  @Input() image;

  constructor(private apiService: ApiService, @Inject('drupalurl') public drupalUrl: string) {
  }

  ngOnInit(): void {
    //console.log('comment-user user', this.user);
  }

}
