import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {keys} from 'lodash-es';

import {FileChangedEvent} from '../../widgets/upload-dropzone/upload-dropzone.component';
import {SubsidyFormState} from './subsidy-form-state';
import {
  SubsidyRequestMunicipalityLabels,
  SubsidyRequestTypeLabels,
  SubsidyRequestSituationLabels,
  SubsidyRequest,
  SubsidyRequestType,
  SubsidyRequestVdStatus} from '../../../models/subsidy.models';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-subsidy-request-form-step1',
  templateUrl: './subsidy-request-form-step1.component.html',
  styleUrls: ['./subsidy-request-form.component.scss']
})
export class SubsidyRequestFormStep1Component implements OnInit, OnDestroy {

  displayFlags: any = {};
  form: FormGroup;
  subs: Subscription[] = [];

  images: { [field: string]: string[] } = {};

  formState$ = new BehaviorSubject<SubsidyFormState>({});

  SubsidyRequestTypeLabels = SubsidyRequestTypeLabels;
  SubsidyRequestSituationLabels = SubsidyRequestSituationLabels;
  SubsidyRequestMunicipalityLabels = SubsidyRequestMunicipalityLabels;
  SubsidyRequestMunicipalityLabelKeys = keys(SubsidyRequestMunicipalityLabels);

  constructor(
    protected apiService: ApiService,
    protected formBuilder: FormBuilder) {
      this.subs.push(this.formState$.subscribe(this.setDisplayFlags.bind(this)));
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      field_type_aanvraag: [undefined, Validators.required],
      field_gemeente: [undefined, Validators.required],
      field_situatie: [undefined, Validators.required],
      field_binnen_bebouwde_kom: [undefined, Validators.required],
      field_gebouwd_voor_2015: [undefined, Validators.required],
      field_extra_opmerkingen: [''],
      field_vergunning: [undefined],
      field_akkoord_verhuurder: [undefined],
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  fillForm(req: SubsidyRequest, images: { [field: string]: string[] }) {
    this.form.get('field_type_aanvraag').setValue(req.field_type_aanvraag);
    this.form.get('field_gemeente').setValue(req.field_gemeente);
    this.form.get('field_situatie').setValue(req.field_situatie);
    this.form.get('field_binnen_bebouwde_kom').setValue(req.field_binnen_bebouwde_kom);
    this.form.get('field_gebouwd_voor_2015').setValue(req.field_gebouwd_voor_2015);
    this.form.get('field_extra_opmerkingen').setValue(req.field_extra_opmerkingen);
    this.form.get('field_vergunning').setValue(req.field_vergunning?.map(x => x.__uuid));
    this.form.get('field_akkoord_verhuurder').setValue(req.field_akkoord_verhuurder?.map(x => x.__uuid));
    this.images = images;
    this.displayFlags.showDefinitiveRequestInstructions = req.field_type_aanvraag === SubsidyRequestType.Vooraanvraag &&
      req.field_status_beoordeling_vd === SubsidyRequestVdStatus.Volledig;
  }

  private setDisplayFlags(state: SubsidyFormState) {
    if (!state.step1FormData) return;
    this.displayFlags = {
      ...this.displayFlags,
      showLandlordStatementField: state.step1FormData.field_situatie !== 'koopwoning' &&
                                  state.step1FormData.field_situatie !== 'eigen_bedrijfspand',
      showDefinitiveRequestFields: state.step1FormData.field_type_aanvraag === 'definitief'
    };
  }

  private requiredIfValidator(pred) {
    return formControl => formControl.parent && pred() ? Validators.required(formControl) : null;
  }

  fieldStatus(control: AbstractControl) {
    return !control.valid ? 'danger' : 'basic';
  }

  fileChanged(event: FileChangedEvent) {
    this[event.form].get(event.field).setValue(event.ids);
  }

  public exportForm() {
    return this.form.getRawValue();
  }
}
