import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {MajadaToastrService} from '../toastr/majada-toastr.service';

@Injectable({
  providedIn: 'root'
})
export class EnergyLibraryAuthGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    private toastrService: MajadaToastrService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.authService.canActivateProtectedRoutes$
      .pipe(tap(x => {
        if (x) {
          const identityClaims = this.authService.identityClaims;
          if (identityClaims['roles']) {
            if (identityClaims['roles'].includes('bibliotheek_medewerker') || identityClaims['roles'].includes('energiebieb_beheerder') || identityClaims['roles'].includes('administrator')) {
              return true;
            } else {
              this.toastrService.error(
                $localize`:@@accessDeniedInfo:You do not have access to this information.`,
                $localize`:@@accessDenied:Access denied.`
              );
              this.router.navigateByUrl('/');
              return false;
            }
          }
        }
        this.toastrService.error(
          $localize`:@@pleaseLogin:Please log in and try again.`,
          $localize`:@@accessDenied:Access denied.`
        );
        this.router.navigateByUrl('/');
        return false;
      }));
  }
}
