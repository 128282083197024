import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {MajadaToastrService} from '../toastr/majada-toastr.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationAuthGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    private toastrService: MajadaToastrService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    console.log('hasvalidtoken', this.authService.hasValidToken());
    if (this.authService.hasValidToken()) {
      this.toastrService.error('Je bent al ingelogd.');
      this.router.navigateByUrl('/');
      return false;
    } else {
      return true;
    }

  }
}
