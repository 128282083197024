<div class="card closable-dialog">
  <nb-card>
    <nb-card-header>Inschrijving annuleren</nb-card-header>
    <nb-card-body [nbSpinner]="showSpinner" nbSpinnerStatus="basic" >
      <div *ngIf="canceled == false">
        <form (ngSubmit)="deleteRegistration()">
          <p>Weet je zeker dat je deze inschrijving wilt annuleren?</p>
        </form>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="card--actions">
        <input type="button" (click)="deleteRegistration()" value="Bezoek annuleren" status="danger" nbButton outline>
      </div>
    </nb-card-footer>
  </nb-card>
  <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
</div>
