import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Subscription} from 'rxjs';
import {TaxonomyService} from '../../services/taxonomy/taxonomy.service';

@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit, OnDestroy {

  subscription = new Subscription();
  nodes;
  themeTerms;

  constructor(private apiService: ApiService, private taxonomyService: TaxonomyService) {
  }

  ngOnInit(): void {
    const themesSub = this.taxonomyService.getTermsByVocabulary('thema').subscribe(result => {
      this.themeTerms = result;
    });

    const nodesSub = this.apiService.get('/api/inbox/latest-comments?_format=json').subscribe(result => {
      this.nodes = result;
      console.log(this.nodes);
    });
    this.subscription.add(nodesSub);
    this.subscription.add(themesSub);

  }

  getTermByUuid(uuid: string) {
    for (const term of this.themeTerms) {
      if (term.id === uuid) {
        return term.attributes.name;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
