import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Subscription, combineLatest} from 'rxjs';
import {tap, flatMap, debounceTime} from 'rxjs/operators';
import {zipObject, map} from 'lodash-es';
import {NbDialogService} from '@nebular/theme';
import {TaxonomyService} from 'src/app/services/taxonomy/taxonomy.service';
import {EnergyLibraryService} from '../../../services/energy-library/energy-library.service';
import {
  EnergyLibraryProduct,
  EnergyLibraryProductStatusLabels} from '../../../models/energy-library.models';
import {EditProductDialogComponent} from '../edit-product-dialog/edit-product-dialog.component';

@Component({
  selector: 'app-product-overview',
  templateUrl: './product-overview.component.html',
  styleUrls: ['./product-overview.component.scss']
})
export class ProductOverviewComponent implements OnInit, OnDestroy {

  private subs: Subscription[];

  products: EnergyLibraryProduct[];
  types: { [id: string]: string };
  municipalities: { [id: string]: string };

  statusFilterModel$ = new BehaviorSubject<string>(null);
  nameFilterModel$ = new BehaviorSubject<string>('');
  isLoading$ = new BehaviorSubject<boolean>(true);

  filters$ = combineLatest([this.statusFilterModel$, this.nameFilterModel$]);

  apiRequests$ = this.filters$.pipe(
    debounceTime(500),
    tap(() => this.isLoading$.next(true)),
    flatMap(([status, name]) => this.energyLibraryService.getProducts(name, status)),
    tap(() => this.isLoading$.next(false))
  );

  EnergyLibraryProductStatusLabels = EnergyLibraryProductStatusLabels;

  get canEdit() {
    return this.municipalities && this.types;
  }

  constructor(
    private dialogService: NbDialogService,
    private taxonomyService: TaxonomyService,
    private energyLibraryService: EnergyLibraryService
  ) {
    this.subs = [
      this.energyLibraryService.productChanged$.subscribe(this.loadProducts.bind(this)),
    ];
  }

  ngOnInit(): void {
    this.loadProducts();
    this.loadProductTypes();
    this.loadMunicipalities();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.statusFilterModel$.complete();
    this.nameFilterModel$.complete();
    this.isLoading$.complete();
  }

  private loadProducts() {
    this.apiRequests$.subscribe(products => {
      this.products = products;
    });
  }

  private loadProductTypes() {
    this.energyLibraryService.getProductTypes().subscribe(types => {
      this.types = zipObject(types.map(x => x.__uuid), types.map(x => x.name));
    });
  }

  private loadMunicipalities() {
    this.taxonomyService.getTermsByVocabulary('gemeente').subscribe(municipalities => {
      this.municipalities = zipObject(municipalities.map(x => x.id), municipalities.map(x => x.attributes.name));
    });
  }

  addNewProduct() {

    this.dialogService.open(EditProductDialogComponent, {
      context: {
        isNewProduct: true,
        categories: map(this.types, (value, id) => ({ id, value })),
        municipalities: map(this.municipalities, (value, id) => ({ id, value }))
      }
    });
  }

  editProduct(product: EnergyLibraryProduct) {
    this.dialogService.open(EditProductDialogComponent, {
      context: {
        isNewProduct: false,
        categories: map(this.types, (value, id) => ({ id, value })),
        municipalities: map(this.municipalities, (value, id) => ({ id, value })),
        product,
      }
    });
  }
}
