<h1 i18n="@@myAppointmentsHeader">Mijn afspraken</h1>
<nb-card *ngIf="calendarEvents">
  <nb-card-body>
    <div class="products">
      <!--<div class="product" *ngFor="let product of data.products">
        <h2>{{product.label}}</h2>-->
        <full-calendar
          [options]="calendarOptions"
        >
        </full-calendar>
        <!--<div class="reservations">
          &lt;!&ndash;<h4 i18n="@@reservations">Reservations</h4>&ndash;&gt;
          <div class="container">
            <div class="row">
              <div class="reservations&#45;&#45;dates col-xs-12 col-6" *ngFor="let dates of product.reservations | keyvalue">
                <p><strong>{{dates.key | date: 'dd-MM-y' : 'Europe/Amsterdam': 'nl-NL'}}</strong></p>
                <nb-list class="reservations&#45;&#45;times">
                  <nb-list-item class="reservations&#45;&#45;times&#45;&#45;time" *ngFor="let days of dates.value | keyvalue"
                                (click)="openDialog(submissionDialog)">
                    <p><strong>{{days.key}}</strong>&nbsp;<small>Klik voor meer informatie</small></p>
                    <ng-template #submissionDialog let-ref="dialogRef">
                      <nb-card>
                        <nb-card-header>
                          <div class="card-header">
                            <span>Ingevulde gegevens</span>
                            <nb-icon pack="eva" icon="close-outline" (click)="closeDialog(ref)"></nb-icon>
                          </div>
                        </nb-card-header>
                        <nb-card-body>
                          <div class="popup&#45;&#45;reservation" *ngFor="let reservation of days.value">
                            <app-form-submission [formSubmissionData]="reservation.submission_data"
                                                 *ngIf="reservation.submission_data"
                                                 [valuesOnly]="true"></app-form-submission>
                            <div class="popup&#45;&#45;reservation&#45;&#45;no-data" *ngIf="!reservation.submission_data"><p>Geen
                              gegevens
                              beschikbaar.</p></div>
                          </div>
                        </nb-card-body>
                      </nb-card>
                    </ng-template>
                  </nb-list-item>
                </nb-list>
              </div>
            </div>
          </div>
        </div>-->
      </div><!--
    </div>-->
  </nb-card-body>
</nb-card>
