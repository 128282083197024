<div class="form-submission">
  <div class="form-submission--information" *ngIf="!valuesOnly">
    <h5 i18n="@@formSubmissionInfoHeader">Form submission information</h5>
    <div class="form-submission--item">
      <span class="label" i18n="@@submittedFormLabel">Submitted form</span>
      <div class="value">{{submittedForm.title}}</div>
    </div>
    <!--    <div class="form-submission&#45;&#45;item">-->
    <!--      <span class="label" i18n="@@submittedFormDescription">Form description</span>-->
    <!--      <div class="value" [innerHTML]="submittedForm.description"></div>-->
    <!--    </div>-->
    <div class="form-submission--item">
      <span class="label" i18n="@@submittedOnLabel">Form submitted on</span>
      <div class="value">
        {{ formSubmission['created'] * 1000 | date: 'd MMMM y, H:mm:ss' }}
      </div>
    </div>
  </div>
  <div class="form-submission--values" *ngIf="!infoOnly">
    <!--<h5 i18n="@@submittedValuesHeader">Submitted form values</h5>-->
    <div class="form-submission--item" *ngFor="let element of formElements | keyvalue: keepOriginalOrder;">
      <div *ngIf="this.isContainerElement(element) else noContainer">
        <ng-container *ngIf="element.value">
          <div class="form-submission--item" *ngFor="let subElement of element.value | keyvalue: keepOriginalOrder;">
            <span class="label">{{subElement.value['#title']}}</span>
            <div class="values"
                 *ngIf="isArray(formSubmission[subElement.key]) else singleValue;">
              <div class="values--value"
                   *ngFor="let item of formSubmission[subElement.key] | keyvalue: keepOriginalOrder;">
                {{item.value}}
              </div>
            </div>
            <ng-template #singleValue>
              <div class="item">{{formSubmission[subElement.key]}}</div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <ng-template #noContainer>
        <ng-container *ngIf="element.value">
          <span class="label">{{element.value['#title']}}</span>
          <div class="values"
               *ngIf="isArray(formSubmission[element.key]) else singleValueNoContainer;">
            <div class="values--value"
                 *ngFor="let item of formSubmission[element.key] | keyvalue: keepOriginalOrder;">
              {{item.value}}
            </div>
          </div>
          <ng-template #singleValueNoContainer>
            <div class="item">{{formSubmission[element.key]}}</div>
          </ng-template>
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
