<div class="card">
  <nb-card>
    <nb-card-header>
      <div class="card-header">Ingezonden formulier
        <div class="close-button">
          <nb-icon icon="close" (click)="cancelEdit()"></nb-icon>
        </div>
      </div>
    </nb-card-header>
    <div class="card--body">
      <nb-card-body>
        <app-form-submission [formSubmissionData]="submissionData" [valuesOnly]="true"
                             *ngIf="submissionData"></app-form-submission>
        <div class="popup--reservation--no-data" *ngIf="!submissionData"><p>Geen gegevens beschikbaar.</p></div>
      </nb-card-body>
    </div>
    <nb-card-footer>
      <div class="card--actions" *ngIf="canDeleteAppointment">
        <button nbButton status="warning" outline (click)="editAppointment(editDialog)">Tijdstip bewerken</button>
        <button nbButton status="danger" (click)="showDeleteConfirmation(confirmDialog)">Verwijder afspraak</button>
      </div>
    </nb-card-footer>
  </nb-card>
</div>
<ng-template #editDialog let-ref="dialogRef">
  <nb-card>
    <nb-card-header>Tijdstip bewerken</nb-card-header>
    <nb-card-body>
      <p>Let op: de tijd in de originele formulier inzending wordt niet aangepast.</p>
      <form [formGroup]="editForm" (ngSubmit)="submitEditForm(ref)">
        <ng-container *ngIf="bookingTimes">
          <div class="form-item" *ngFor="let bookingTime of bookingTimes | keyvalue">
            <label class="label" for="date-time-selection">Kies een nieuwe tijd</label>
            <nb-select id="date-time-selection" placeholder="Selecteer datum/tijd"
                       formControlName="dateTime">
              <nb-option-group *ngFor="let dates of bookingTimes[bookingTime.key]"
                               [title]="dates.date">
                <nb-option *ngFor="let times of dates.times | keyvalue" [value]="times.key">{{times.value}}</nb-option>
              </nb-option-group>
            </nb-select>
          </div>
        </ng-container>

        <div class="card--actions">
          <button nbButton ghost (click)="cancelEdit()">Annuleren</button>
          <button type="submit" nbButton status="success">Opslaan</button>
        </div>
      </form>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #confirmDialog let-ref="dialogRef">
  <nb-card class="confirm-dialog card">
    <nb-card-header><h3>Verwijderen</h3></nb-card-header>
    <nb-card-body>
      <div class="card--body">
        <p>Weet u zeker dat u deze reservering wilt verwijderen? Deze actie kan niet ongedaan gemaakt worden.</p>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="card--actions">
        <button nbButton status="basic" (click)="cancelDelete(ref)">Terug</button>
        <button nbButton status="danger" (click)="deleteReservation(ref)">Afspraak verwijderen</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>
