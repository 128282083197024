<div class="card closable-dialog">
  <form [formGroup]="form" (ngSubmit)="onSubmit(form)">
    <nb-card>
      <nb-card-header>{{isNewLocation ? 'Locatie toevoegen' : 'Locatie bewerken'}}</nb-card-header>
      <nb-card-body [nbSpinner]="showSpinner" nbSpinnerStatus="basic">
        <div *ngIf="!isLoading" [class.saving]="isSaving">
          <div>
            <label class="label" for="input-name">Naam</label>
            <input id="input-name" type="text" nbInput placeholder="Naam" formControlName="name">
          </div>
          <div>
            <label class="label" for="input-address">Adres</label>
            <input id="input-address" type="text" nbInput placeholder="Adres" formControlName="address">
          </div>              
          <div class="fields-2">
            <div>
                <label class="label" for="input-postal-code">Postcode</label>
                <input id="input-postal-code" type="text" nbInput placeholder="Postcode" formControlName="postalCode">
            </div>
            <div>
                <label class="label" for="input-locality">Plaats</label>
                <input id="input-locality" type="text" nbInput placeholder="Plaats" formControlName="locality">
            </div>
          </div>              
          <div>
            <label class="label" for="select-type">Type locatie</label>
            <nb-select id="select-type" placeholder="Type locatie" formControlName="type">
              <nb-option value="afhalen">Afhalen</nb-option>
              <nb-option value="post">Post</nb-option>
            </nb-select>
          </div>
          <div>
            <label class="label" for="select-employees">Medewerker</label>
            <nb-select multiple id="select-employees" placeholder="Medewerkers" formControlName="employees">
              <nb-option *ngFor="let employee of employees" [value]="employee.__uuid">
                {{employee.name}}</nb-option>
            </nb-select>
          </div>
          <div>
            <label class="label" for="check-active">Locatie is momenteel actief</label>
            <nb-checkbox id="check-active" formControlName="active"></nb-checkbox>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="card--actions">
          <button *ngIf="!isNewLocation" nbButton [disabled]="!canSubmit" status="basic" outline (click)="delete()">Locatie verwijderen</button>
          <button type="submit" outline status="success" [disabled]="!canSubmit" nbButton>Opslaan</button>
        </div>
      </nb-card-footer>
    </nb-card>
  </form>
  <button nbButton status="danger" [disabled]="isSaving" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
</div>