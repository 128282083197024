import {Component, OnInit, Input} from '@angular/core';
import {SubsidyRequestLogsService} from 'src/app/services/subsidy-request-logs/subsidy-request-logs.service';
import {NbDialogRef} from '@nebular/theme';
import {
  SubsidyRequest,
  SubsidyLog,
  SubsidyRequestStatusLabels} from 'src/app/models/subsidy.models';
import {BehaviorSubject} from 'rxjs';


@Component({
  selector: 'app-subsidy-request-logs-dialog',
  templateUrl: './subsidy-request-logs-dialog.component.html',
  styleUrls: ['./subsidy-request-logs-dialog.component.scss', '../../../shared/shared.scss']
})
export class SubsidyRequestLogsDialogComponent implements OnInit {

  SubsidyRequestStatusLabels = SubsidyRequestStatusLabels;
	logs$ = new BehaviorSubject<SubsidyLog[]>(null);
  subsidyId: string;
  request: SubsidyRequest;
  showSpinner: boolean = true;

  constructor(
    protected ref: NbDialogRef<SubsidyRequestLogsDialogComponent>,
  	private subsidyRequestLogsService: SubsidyRequestLogsService
  ) { }

  ngOnInit(): void {
    this.subsidyId = this.request.__uuid;
    this.loadLogs();
  }

  private loadLogs() {
    this.logs$.next(null);
    this.subsidyRequestLogsService.getLogs(this.subsidyId).subscribe(
    	(logs) => {
    		this.showSpinner = false;
    		this.logs$.next(logs.reverse());
    	});
  }

  addLog(event) {
    let newLogs = this.logs$.value;
    newLogs.unshift(event);
    this.logs$.next(newLogs);
  }

  getMessage(log) {
    var msg = log.field_omschrijving ? log.field_omschrijving.value + '\n' : '';
    // Replace html to plaintext.

    msg = msg.replace(/<(?:.|\n)*?>/gm, ' ');
    if (log.field_oude_status) {
      msg += "Oude status: " + SubsidyRequestStatusLabels[log.field_oude_status];
      if (log.field_nieuwe_status) {
        msg += "\n";
      }
    }
    if (log.field_nieuwe_status) {
      msg += "Nieuwe status: " + SubsidyRequestStatusLabels[log.field_nieuwe_status];
    }
    return msg;
  }

  close() {
    this.ref.close();
  }

}
