import {
  Address,
  AttributesBase,
  DrupalFile,
  Link,
  MediaImage,
  TextFormattedLong,
  TaxonomyTermUuid,
  Geofield} from './drupal-datatypes.models';


export enum LocationObjectType {
  Woning = 'woning',
  Bedrijf = 'bedrijf',
  Tuin = 'tuin',
  Schuur = 'schuur',
  Project = 'project',
  Overig = 'overig',
}

export const LocationObjectTypeLabels = {
  woning: 'Woning',
  bedrijf: 'Bedrijf',
  tuin: 'Tuin',
  schuur: 'Schuur',
  project: 'Project',
  overig: 'Overig',
};

// Residence

export enum LocationRentOrBuy {
  Koopwoning = 'koop',
  Huurwoning = 'huur',
}

export const LocationRentOrBuyLabels = {
  koop: 'Koopwoning',
  huur: 'Huurwoning',
};

// Garden

export enum GardenSize {
  KleinerDan50 = 'kleiner_dan_50',
  Tussen50En200 = '50_200',
  GroterDan200 = 'groter_dan_200',
}

export const GardenSizeLabels = {
  kleiner_dan_50: '< 50m2 (o.a. voortuinen)',
  '50_200': '50 - 200m2',
  groter_dan_200: '> 200m2',
};

export enum GardenWater {
  Goed = 'goed',
  Slect = 'slecht',
}

export const GardenWaterLabels = {
  goed: 'Het water kan makkelijk de grond in trekken',
  slecht: 'Het water heeft langer de tijd nodig om de grond in te trekken',
};

export enum GardenType {
  Tuin = 'tuin',
  Dak = 'dak',
  Geveltuin = 'geveltuin',
  Balkon = 'balkon',
  Bedrijfsdak = 'bedrijfsdak',
  Bedrijfstuin = 'bedrijfstuin',
}

export const GardenTypeLabels = {
  tuin: 'Tuin',
  dak: 'Dak',
  geveltuin: 'Geveltuin',
  balkon: 'Balkon',
  bedrijfsdak: 'Bedrijfsdak',
  bedrijfstuin: 'Bedrijfstuin'
};

// Project

export enum ProjectType {
  Gemeentelijk = 'gemeentelijk',
  Schoolplein = 'schoolplein',
  Buurtcollectief = 'buurtcollectief',
}

export const ProjectTypeLabels = {
  gemeentelijk: 'Een gemeentelijk/overheidsproject',
  schoolplein: 'Schoolplein',
  buurtcollectief: 'Een (openbaar) buurtcollectief',
};


// Company

export enum NumberOfEmployees {
  Tot10 = '0_10',
  Tot50 = '10_50',
  Tot250 = '50_250',
  Vanaf250 = '250_of_meer',
}

export const NumberOfEmployeesLabels = {
  '0_10': '0-10 werknemers',
  '10_50': '10-50 werknemers',
  '50_250': '50-250 werknemers',
  '250_of_meer': '250 of meer werknemers',
};

export enum SustainableApplication {
  Huisvesting = 'huisvesting',
  BedrijfsProductieprocessenInnovaties = 'bedrijf',
  Milieu = 'milieu',
  Circulariteit = 'circulariteit',
  Mobiliteit = 'mobiliteit',
  HumanResources = 'hr',
}

export const SustainableApplicationLabels = {
  huisvesting: 'Huisvesting',
  bedrijf: 'Bedrijfs- en productieprocessen & innovaties',
  milieu: 'Milieu (biodiversiteit, ecologische bedrijfsvoering, fijnstofuitstoot, klimaatadaptatie)',
  circulariteit: 'Circulariteit (Cradle to Cradle)',
  mobiliteit: 'Mobiliteit',
  hr: 'Human Resources (Sociale omgang)',
};

export enum BuildingType {
  Kantoorgebouw = 'kantoor',
  BedrijfsIndustriegebouw = 'bedrijf',
  Onderwijsgebouw = 'onderwijs',
  Zorginstelling = 'zorg',
  Horecagebouw = 'horeca',
  Winkelgebouw = 'winkel',
  Geloofsgebouw = 'geloof',
  Sportgebouw = 'sport',
  Recreatiegebouw = 'recreatie',
  Logiesgebouw = 'logies',
  EvenementenCongresgebouw = 'evenementen',
  Overheidsgebouw = 'overheid',
}

export const BuildingTypeLabels = {
  kantoor: 'Kantoorgebouw',
  bedrijf: 'Bedrijfs- /industriegebouw',
  onderwijs: 'Onderwijsgebouw',
  zorg: 'Zorginstelling',
  horeca: 'Horecagebouw',
  winkel: 'Winkelgebouw',
  geloof: 'Geloofsgebouw',
  sport: 'Sportgebouw',
  recreatie: 'Recreatiegebouw',
  logies: 'Logiesgebouw',
  evenementen: 'EvenementenCongresgebouw',
  overheid: 'Overheidsgebouw',
};

export enum BuildingConcept {
  Aardgasvrij = 'aardgasvrij',
  Energieneutraal = 'energieneutraal',
  Energieleverend = 'energieleverend',
  CirculairBouwen = 'circulair_bouwen',
}

export const BuildingConceptLabels = {
  aardgasvrij: 'Aardgasvrij',
  energieneutraal: 'Energieneutraal',
  energieleverend: 'Energieleverend',
  circulair_bouwen: 'Circulair bouwen',
};


export interface ResidenceProperties extends AttributesBase {
  field_bouwjaar: number;
  field_bouwperiode: string;
  field_energielabel: string;
  field_koop_of_huur: LocationRentOrBuy;
  field_legacy_object_id: number;
  field_soort_woning: string;
  field_type_duurzame_woning: string;
  field_website: string;
  field_naisolatie: TextFormattedLong;
  field_energievoorziening: TextFormattedLong;
  field_beschrijving_hoe_is_de_ven: TextFormattedLong;
  field_ervaringen: string;
  field_bedrijfsmatig_betrokken: TextFormattedLong;
}

export interface ProjectProperties extends AttributesBase {
  field_bedrijfsmatig_betrokken: TextFormattedLong;
  field_betrokken_status: boolean;
  field_grootte_van_de_ruimte: GardenSize;
  field_waterdoorlatendheid: GardenWater;
  field_project_dieren: TextFormattedLong;
  field_project_eetbaar: TextFormattedLong;
  field_project_temperatuur: TextFormattedLong;
  field_project_regenwater: TextFormattedLong;
  field_project_partijen: TextFormattedLong;
  field_project_vegetatie: TextFormattedLong;
  field_project_tips: TextFormattedLong;
  field_project_verharding: TextFormattedLong;
  field_soort_project: ProjectType;
}

export interface GardenProperties extends AttributesBase {
  field_bedrijfsmatig_betrokken: TextFormattedLong;
  field_betrokken_status: boolean;
  field_afkoppelen_tip: TextFormattedLong;
  field_daken_tip: TextFormattedLong;
  field_dieren_tip: TextFormattedLong;
  field_temperatuurbeheersing_tip: TextFormattedLong;
  field_eetbare_tuin_tip: TextFormattedLong;
  field_recycling_tip: TextFormattedLong;
  field_verharding_tip: TextFormattedLong;
  field_grootte_van_de_tuin: GardenSize;
  field_waterdoorlatendheid: GardenWater;
  field_afkoppelen_m2: TextFormattedLong;
  field_recycling_beschrijving: TextFormattedLong;
  field_legacy_object_id: number;
  field_soort_tuin: GardenType;
  field_afkoppelen_reden: TextFormattedLong;
  field_daken_reden: TextFormattedLong;
  field_verharding_reden: TextFormattedLong;
  field_eetbare_tuin_reden: TextFormattedLong;
  field_dieren_beschrijving: TextFormattedLong;
}

export interface CompanyProperties extends AttributesBase {
  field_aantal_werknemers: number;
  field_bedrijfslogo: MediaImage;
  field_ref_bedrijfstak: TaxonomyTermUuid[];
  field_bouwjaar: number;
  field_elektraopwekking: number;
  field_elektraverbruik: number;
  field_energielabel: any;
  field_verbruik_toestemming: boolean;
  field_gasverbruik: number;
  field_keurmerken_certificeringen: TaxonomyTermUuid[];
  field_keurmerken_toelichting: TextFormattedLong;
  field_kvk_nummer: string;
  field_naam_organisatie: string;
  field_circulariteit: TextFormattedLong;
  field_processen: TextFormattedLong;
  field_mobiliteit: TextFormattedLong;
  field_milieumaatregelen: TextFormattedLong;
  field_hrm_beleid: TextFormattedLong;
  field_thematoepassingen: SustainableApplication;
  field_publicaties_en_referenties: TextFormattedLong;
  field_type_gebouw: BuildingType;
  field_type_gebouw_toelichting: TextFormattedLong;
  field_website_organisatie: Link;
  field_maatregelen_huisvesting: TextFormattedLong;
  field_betrokken_partijen: TextFormattedLong;
  field_bouwconcept: BuildingConcept;
}

export interface Measure extends AttributesBase {
  field_afbeelding?: MediaImage[];
  field_ervaringen: TextFormattedLong;
  field_tips: TextFormattedLong;
  feeds_item: any;
  field_legacy_locatie_id?: number;
  field_legacy_maatregel_id?: number;
  field_maatregel: string[];
  field_omschrijving: TextFormattedLong;
}

export interface LocationObject extends AttributesBase {
  body: string;
  field_titel_suggestie: any;
  field_afbeeldingen?: DrupalFile[];
  field_media_afbeelding?: MediaImage[];
  field_ref_bedrijf_kenmerken?: CompanyProperties;
  field_ref_contactgegevens?: any;
  field_content?: any;
  field_ervaringen?: TextFormattedLong;
  feeds_item?: any;
  field_legacy_locatie_data_id?: number;
  field_legacy_locatie_id?: number;
  field_maatregelen?: Measure[];
  field_objectsoort?: any;
  field_object_soort?: any;
  field_bezoek_op_afspraak?: any;
  field_ref_tuin_kenmerken?: GardenProperties;
  field_ref_woning_kenmerken?: ResidenceProperties;
  field_ref_project_kenmerken?: ProjectProperties;
  field_toekomstplannen: TextFormattedLong;
  field_tips: TextFormattedLong;
  field_adviezen: TextFormattedLong;
  field_interview: TextFormattedLong;
}

export interface Location extends AttributesBase {
  field_adres: Address;
  field_titel_suggestie: string;
  field_afbeelding: any;
  body: TextFormattedLong;
  field_brondomein: string;
  feeds_item: any;
  field_gemeente: string;
  field_geo: Geofield;
  field_legacy_id?: number;
  field_legacy_url?: string;
  field_ref_location_type: any;
  field_object: LocationObject[];
  field_provincie: any;
}

export interface LocationOverviewItem {
  uuid: string;
  title: string;
  body: string;
  locality: string;
  uitgelichte_afbeelding?: string;
  bouwjaar?: string;
  energielabel?: string;
  type_duurzame_woning?: string[];
  grootte_van_de_tuin?: GardenSize;
  soort_tuin?: GardenType;
  grootte_van_de_ruimte?: GardenSize;
  status: boolean;
  object_id: string;
  object_soort: LocationObjectType;
  path: string;
  registrations: Array<any>;
}
