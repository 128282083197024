<nb-card>
  <nb-card-header>Registreren</nb-card-header>
  <nb-card-body>
    <form class="login-form" [formGroup]="registerForm" (ngSubmit)="doRegister()"
          *ngIf="!registrationSuccess && !verifying">
      <div class="validation-errors" *ngIf="showValidationErrors">
        <p><b>We wijzen je graag op het volgende:</b></p>
        <ul>
          <li>De wachtwoorden zijn niet gelijk aan elkaar</li>
        </ul>
      </div>
      <div class="form-item">
        <label for="givenName" class="label">Voornaam</label>
        <input nbInput type="text" id="givenName" formControlName="givenName">
      </div>
      <div class="form-item">
        <label for="familyName" class="label">Achternaam</label>
        <input nbInput type="text" id="familyName" formControlName="familyName">
      </div>
      <div class="form-item">
        <label for="email" class="label">E-mailadres</label>
        <input nbInput type="email" id="email" formControlName="email">
      </div>
      <div class="form-item">
        <label for="password" class="label">Wachtwoord</label>
        <input nbInput type="password" id="password" formControlName="password">
      </div>
      <div class="form-item">
        <label for="password-confirm" class="label">Wachtwoord bevestigen</label>
        <input nbInput type="password" id="password-confirm" formControlName="passwordConfirm">
        <div class="description">
          <p>Herhaal ter bevestiging je gekozen wachtwoord.</p>
        </div>
      </div>
      <div class="form-item">
        <label for="phoneNumber" class="label">Telefoonnummer</label>
        <input nbInput type="text" id="phoneNumber" formControlName="phoneNumber">
      </div>
      <div class="form-item">
        <nb-checkbox formControlName="privacy" id="privacy">Ik ga akkoord met de <a [href]="privacyUrl">privacyvoorwaarden</a></nb-checkbox>
      </div>
      <div class="form-item">
        <input nbButton type="submit" value="Registreren" [disabled]="registerForm.invalid" status="success">
      </div>
    </form>
    <p><a routerLink="/" nbButton appearance="ghost" size="small" status="primary">Terug naar inloggen</a></p>
    <div class="registration-confirm" *ngIf="registrationSuccess && !verifying">
      <p>Uw account is aangemaakt. U ontvangt een mail met daar in een link om uw account te bevestigen. Nadat u uw
        account heeft bevestigd kunt u hiermee <a routerLink="/">inloggen</a> </p>
    </div>
  </nb-card-body>
</nb-card>
