import {merge, mapValues, isArray, omitBy, isUndefined, without, compact, startsWith} from 'lodash-es';

import {
  JsonApiResponseEntity, JsonApiResource, JsonApiResponseSingle,
  JsonApiResponseMany, JsonApiResponse
} from '../models/json-api.models';

function getIncluded(res: JsonApiResponse, data: JsonApiResource, field: string) {
  if (!res.included) {
    return undefined;
  }
  if (!data.relationships[field]?.data) {
    return undefined;
  }

  const isMany = isArray(data.relationships[field]?.data);
  let ids = isMany ? data.relationships[field]?.data.map(x => x.id) : [data.relationships[field]?.data.id];
  ids = without(ids, 'virtual');

  const result = ids.map(id => res.included.find(x => x.id === id));
  return isMany ? compact(result) : result[0];
}

function parseResource(res: JsonApiResponse, data: JsonApiResource) {
  const relations = mapValues(data.relationships ?? {} as any, (x, field) => {

    if (field === 'roles' && data.type === 'user--user') {
      if (!x.data) {
        return [];
      }
      return x.data.map((role: any) => role.id);
    }
    if (isArray(x.data) && startsWith(x.data[0]?.type, 'taxonomy_term--')) {
      return x.data.map(d => d.id);
    }

    if (x.data && startsWith(x.data.type, 'taxonomy_term--')) {
      return x.data.id;
    }
    const included = getIncluded(res, data, field);
    if (!included) {
      return undefined;
    }
    if (isArray(included)) {
      return included.map(inc => parseResource(res, inc));
    }
    return parseResource(res, included);
  });

  return merge({__uuid: data.id}, data.attributes, relations);
}


// API response parsing functions. Returns the object attributes, with all included relations recursively added to it.

export function parseResponseSingle<T extends JsonApiResponseEntity>(res: JsonApiResponseSingle): T {
  return parseResource(res, res.data) as T;
}

export function parseResponseMany<T extends JsonApiResponseEntity>(res: JsonApiResponseMany): T[] {
  return res.data.map(x => parseResource(res, x)) as T[];
}


// Helper functions to create structures used in POST and PATCH requests.

export function createBody(id: string | undefined, type: string, attributes?: any, relationships?: any) {
  return {
    data: omitBy({
      id,
      type,
      attributes,
      relationships
    }, isUndefined)
  };
}

export function createRelation(ids: string | string[], type: string) {
  if (isArray(ids)) {
    return {
      data: (ids as string[]).map(id => ({id, type}))
    };
  }

  return {
    data: {
      id: ids,
      type
    }
  };
}
