import {TextFormattedLong, AttributesBase, Address, User, DateRange, TaxonomyTerm} from './drupal-datatypes.models';

export interface EnergyLibraryUser extends User {
  field_ref_locatie?: EnergyLibraryLocation[];
}

export interface EnergyLibraryRequestView {
  product?: string;
  product_id?: number;
  product_uuid?: string;
  field_inventarisnummer?: string;
  gemeente_id: number;
  gemeente_uuid: string;
  gemeente: string;
  product_categorie_id?: number;
  product_categorie_uuid?: string;
  product_categorie?: string;
  aanvraag_id: number;
  aanvraag_uuid: string;
  aanvraag_created: string;
  toewijzing_id?: number;
  toewijzing_uuid?: string;
  toewijzing_created?: string;
  locatie_id?: number;
  locatie_uuid?: string;
  locatie?: string;
  start_datum?: string;
  eind_datum?: string;
  status: string;
  status_name: string;
  naam: string;
  emailadres: string;
  telefoonnummer: string;
  opmerking: string;
  submission_id?: string;
  adres: string;
  adres_raw: {
    langcode?: string,
    country_code?: string,
    administrative_area?: string,
    locality?: string,
    dependent_locality?: string,
    postal_code?: string,
    sorting_code?: string,
    address_line1?: string,
    address_line2?: string,
    organization?: string,
    given_name?: string,
    additional_name?: string,
    family_name?: string,
  };
  user_actions: string;
  title?: string;
  toegewezen_status: string;
}

export interface EnergyLibraryLocation extends AttributesBase {
  field_energiebieb_adres: Address;
  field_type_locatie: 'afhalen' | 'post';
  field_actief: boolean;
}

export enum EnergyLibraryProductStatus {
  Beschikbaar = '1',
  Toegewezen = '2',
  Verzonden = '3',
  CO2MeterDefect = '4',
}

export const EnergyLibraryProductStatusLabels = {
  1: 'Beschikbaar',
  2: 'Toegewezen',
  3: 'Verzonden',
  4: 'CO2-meter defect',
};

export interface EnergyLibraryProduct extends AttributesBase {
  field_ref_energiebieb_locatie?: EnergyLibraryLocation;
  field_gemeente: string;
  field_inventarisnummer?: number;
  field_product_categorie: string;
  field_schade?: TextFormattedLong;
  field_status: EnergyLibraryProductStatus;
}

export interface EnergyLibraryRequest extends AttributesBase {
  field_adres: Address;
  field_e_mailadres: string;
  field_form_submission_id: string;
  field_gemeente: TaxonomyTerm;
  field_geselecteerd_product: EnergyLibraryProduct;
  field_naam: string;
  field_schade?: TextFormattedLong;
}

export enum EnergyLibraryAssignmentStatus {
  Wachtrij = '1',
  ProductInGebruik = '2',
  Afgerond = '3',
  Geannuleerd = '4',
  OnHold = '5',
}

export const EnergyLibraryAssignmentStatusLabels = {
  1: 'Wachtrij',
  2: 'Product in gebruik',
  3: 'Afgerond',
  4: 'Geannuleerd',
  5: 'On hold',
};

export interface EnergyLibraryAssignment extends AttributesBase {
  field_aanvraag: EnergyLibraryRequest;
  field_start_en_einddatum: DateRange;
  field_toewijzing_status: EnergyLibraryAssignmentStatus;
  field_toegewezen_product: EnergyLibraryProduct;
}
