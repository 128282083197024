import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubmitOpeningTimesComponent} from './submit-opening-times/submit-opening-times.component';
import {OpeningTimesOverviewComponent} from './opening-times-overview/opening-times-overview.component';
import {EditOpeningTimesComponent} from './edit-opening-times/edit-opening-times.component';
import {RouteVisitorRegistrationComponent} from './route-visitor-registration/route-visitor-registration.component';
import {MyRegistrationsComponent} from './my-registrations/my-registrations.component';
import {RouteRegistrationInformationComponent} from './route-registration-information/route-registration-information.component';
import {RouteRoutingModule} from './route-routing.module';
import {SharedModule} from 'src/app/shared/shared.module';
import {ConfirmRouteRegistrationComponent} from './confirm-route-registration/confirm-route-registration.component';
import {CancelRouteRegistrationDialogComponent} from './cancel-route-registration/cancel-route-registration-dialog.component';
import {ObjectVisitorsComponent} from './object-visitors/object-visitors.component';
import {MyVisitorsComponent} from './my-visitors/my-visitors.component';
import {MyVisitorInformationComponent} from './my-visitor-information/my-visitor-information.component';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {CancelRouteRegistrationPageComponent} from './cancel-route-registration-page/cancel-route-registration-page.component';


@NgModule({
  declarations: [
    SubmitOpeningTimesComponent,
    OpeningTimesOverviewComponent,
    EditOpeningTimesComponent,
    RouteVisitorRegistrationComponent,
    MyRegistrationsComponent,
    RouteRegistrationInformationComponent,
    ConfirmRouteRegistrationComponent,
    CancelRouteRegistrationDialogComponent,
    ObjectVisitorsComponent,
    MyVisitorsComponent,
    MyVisitorInformationComponent,
    CancelRouteRegistrationPageComponent
  ],
  exports: [
    ObjectVisitorsComponent
  ],
  imports: [
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SharedModule,
    RouteRoutingModule
  ]
})
export class RouteModule {
}
