<h1>Mijn Bezoekers</h1>
<nb-card [nbSpinner]="!residences" class="card--placeholder" *ngIf="!residences"></nb-card>

<nb-card *ngFor="let residence of residences">
  <nb-card-header>
    {{residence.title}}
  </nb-card-header>
  <nb-card-body>
    <app-object-visitors [registrations]="residence.registrations" *ngIf="residence.registrations else noRegistrations"></app-object-visitors>
    <ng-template #noRegistrations>
      <p>Er heeft zich nog niemand aangemeld voor een bezoek aan uw {{locationType}}.</p>
    </ng-template>
  </nb-card-body>
</nb-card>
