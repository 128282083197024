import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {MyAppointmentsComponent} from './components/my-appointments/my-appointments.component';
import {MyLocationsComponent} from './components/my-locations/my-locations.component';
import {EditLocationComponent} from './components/my-locations/edit-location/edit-location.component';
import {InboxComponent} from './components/inbox/inbox.component';
import {AdvisorAuthGuardService} from './services/auth/advisor-auth-guard.service';
import {MunicipalityAuthGuardService} from './services/auth/municipality-auth-guard.service';
import {RequestOverviewComponent} from './components/energy-library/request-overview/request-overview.component';
import {ProductOverviewComponent} from './components/energy-library/product-overview/product-overview.component';
import {LocationOverviewComponent} from './components/energy-library/location-overview/location-overview.component';
import {MunicipalityCoachAuthGuardService} from './services/auth/municipality-coach-auth-guard.service';
import {EnergyLibraryAuthGuardService} from './services/auth/energy-library-auth-guard.service';
import {EnergyLibraryAdminAuthGuardService} from './services/auth/energy-library-admin-auth-guard.service';
import {SubsidyRequestFormComponent} from './components/subsidy/subsidy-request-form/subsidy-request-form.component';
import {MySubsidyRequestsComponent} from './components/subsidy/my-subsidy-requests/my-subsidy-requests.component';
import {SubsidyRequestsAuthGuardService} from './services/auth/subsidy-requests-auth-guard.service';
import {SubsidyRequestStatisticsComponent} from './components/subsidy/subsidy-request-statistics/subsidy-request-statistics.component';
import {ProfileEditFormComponent} from './components/profile-edit-form/profile-edit-form.component';
import {AuthGuardService} from './services/auth/auth-guard.service';
import {ShellComponent} from './components/shell/shell.component';
import {HomeComponent} from './components/home/home.component';


const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import( './dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'inbox',
        component: InboxComponent,
        canActivate: [MunicipalityCoachAuthGuardService]
      },
      {
        path: 'mijn-afspraken',
        canActivate: [AdvisorAuthGuardService],
        component: MyAppointmentsComponent
      },
      /*{
        path: 'mijn-locaties',
        canActivate: [MunicipalityAuthGuardService],
        component: MyLocationsComponent
      },
      {
        path: 'mijn-locaties/:id',
        canActivate: [MunicipalityAuthGuardService],
        component: EditLocationComponent
      },*/
      {
        path: 'energiebieb/aanvragen',
        canActivate: [EnergyLibraryAuthGuardService],
        component: RequestOverviewComponent
      },
      {
        path: 'energiebieb/producten',
        canActivate: [EnergyLibraryAdminAuthGuardService],
        component: ProductOverviewComponent
      },
      {
        path: 'energiebieb/locaties',
        canActivate: [EnergyLibraryAdminAuthGuardService],
        component: LocationOverviewComponent
      },
      {
        path: 'subsidies/aanvraagformulier',
        component: SubsidyRequestFormComponent,
        canActivate: [SubsidyRequestsAuthGuardService],
        data: {
          excludeRole: 'gemeente_medewerker'
        }
      },
      {
        path: 'subsidies/aanvraagformulier/:id',
        component: SubsidyRequestFormComponent,
        canActivate: [SubsidyRequestsAuthGuardService],
      },
      {
        path: 'subsidies/mijn-aanvragen',
        component: MySubsidyRequestsComponent,
        canActivate: [SubsidyRequestsAuthGuardService],
      },
      {
        path: 'subsidies/statistieken',
        component: SubsidyRequestStatisticsComponent,
        canActivate: [SubsidyRequestsAuthGuardService],
        data: {
          specificOnly: 'gemeente_medewerker'
        }
      },
      {
        path: 'profiel',
        component: ProfileEditFormComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'node/:id',
        loadChildren: () => import( './node/node.module').then(m => m.NodeModule),
        pathMatch: 'full'
      },
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
