import {Component, Inject, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {StatusMessagesService} from '../../services/status-messages.service';
import {filter, map} from 'rxjs/operators';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-status-messages',
  templateUrl: './status-messages.component.html',
  styleUrls: ['./status-messages.component.scss']
})
export class StatusMessagesComponent implements OnInit {

  @Input()
  region;

  @Input()
  path;

  messages;

  constructor(private statusMessagesService: StatusMessagesService, private router: Router, @Inject('domainUuid') private domainUuid: string, private authService: AuthService) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.region) {
          this.path = event.url;
          this.getStatusMessages();
        }
      }
    });
  }

  getStatusMessages() {
    console.log('this.region', this.region);
    if (this.path === '/') {
      console.log('getStatusMessages path this.path');
      if (!this.authService.hasValidToken()) {
        this.path = '<login>';
      } else {
        this.path = '<front>';
      }
    }
    this.statusMessagesService.getStatusMessages(this.domainUuid, this.region, this.path).subscribe(messages => {
      console.log('getStatusMessages messages', messages);
      if (messages) {
        this.messages = messages;
      } else {
        this.messages = null;
      }
    });
  }

  ngOnInit(): void {
    this.path = this.router.url;
    this.getStatusMessages();
  }

}
