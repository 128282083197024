import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../../services/api/api.service';

@Component({
  selector: 'app-subsidy-request-statistics',
  templateUrl: './subsidy-request-statistics.component.html',
  styleUrls: ['./subsidy-request-statistics.component.scss']
})
export class SubsidyRequestStatisticsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  subsidyStats;
  years;
  selectedYear;

  get selectedSubsidyStats() {
    if (!this.subsidyStats || !this.selectedYear) return undefined;
    return this.subsidyStats[this.selectedYear];
  }

  get length() {
    if (!this.selectedSubsidyStats) return 0;
    return Object.keys(this.selectedSubsidyStats).length;
  }


  ngOnInit(): void {
    this.apiService.get('/api/subsidy/statistics?_format=json').subscribe(result => {
      this.selectedYear = new Date().getFullYear().toString();
      this.subsidyStats = result;
      this.years = Object.keys(result);
      console.log(this.subsidyStats);
    });
  }

}
