<div>
  <table *ngIf="!isEmpty; else noRegistrations">
    <thead>
      <tr class="header">
        <th>Datum</th>
        <th>Tijd</th>
        <th>Aantal</th>
        <th>Naam</th>
        <th *ngIf="!pdb">Gemeente</th>
        <th *ngIf="pdb">Woonplaats</th>
        <th>E-mail</th>
        <th>Opmerking</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <ng-container class="visitors" *ngFor="let date of registrations">
        <ng-container *ngIf="date.times && date.times.length > 0">
          <ng-container class="registrations-date" *ngFor="let time of date.times">
            <tr *ngFor="let registration of time.registrations">
              <td>{{date.date * 1000 | date: 'd MMMM yyyy'}}</td>
              <td>{{time.time_label}}</td>
              <td>{{registration.visitors}}</td>
              <td>{{registration.name}}</td>
              <td *ngIf="!pdb">{{registration.municipality}}</td>
              <td *ngIf="pdb">{{registration.locality}}</td>
              <td>{{registration.mail}}</td>
              <td>{{registration.remarksOwner ? 'Ja' : '-'}}</td>
              <td>
                <button nbButton status="basic" outline size="small" (click)="showDialog(registration, time, date)">Bekijken</button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </tbody>
  </table>

  <ng-template #noRegistrations>
    <p>Er zijn nog geen registraties voor deze datum.</p>
  </ng-template>
</div>
