<nb-card>
  <nb-card-header>Openingstijden opgeven <span class="date"
                                               *ngIf="routeConfig">voor {{routeConfig.route_date | date: 'dd MMMM yyyy'}}</span>
  </nb-card-header>
  <nb-card-body [nbSpinner]="submitting">
    <ng-container *ngIf="routeConfig else noConfig;">
      <div class="col-md-6">
        <form [formGroup]="openingTimesForm" (ngSubmit)="submitOpeningTimesForm()"
              *ngIf="!openingTimesSaved else openingTimesSavedMessage">
          <div class="form-item">
            <label class="label required">Beschikbaarheid</label>
            <nb-radio-group formControlName="availability" id="availability" name="availability">
              <nb-radio value="opened">Geopend</nb-radio>
              <nb-radio value="notOpened">Niet geopend</nb-radio>
            </nb-radio-group>
          </div>
          <div class="form-elements" [hidden]="openingTimesForm.get('availability').value !== 'opened'">
            <div class="form-item" *ngIf="this.routeConfig.times?.length > 0 && this.routeConfig.day_parts?.length > 0">
              <label class="label required" for="opening-type">Voorkeuren bezoek</label>
              <nb-radio-group formControlName="openingType" id="opening-type" name="opening-type">
                <nb-radio value="vaste_tijdstippen">Vaste tijden</nb-radio>
                <nb-radio value="vrije_inloop">Vrije inloop (dagdelen)</nb-radio>
              </nb-radio-group>
            </div>
            <div class="times-form" formGroupName="times"
                 [hidden]="openingTimesForm.get('openingType').value !== 'vaste_tijdstippen'"
                 *ngIf="this.routeConfig.times?.length > 0">
              <div><strong>Aantal bezoekers per tijdstip</strong></div>
              <p class="description">Als u op een tijdstip geen bezoekers wilt ontvangen, vult u een 0 in.<br/>
              <div class="form-item" *ngFor="let time of routeConfig['times']">
                <label class="label required">{{time.name[0].value}}</label>
                <input type="number" nbInput [formControlName]="time.uuid[0].value" [max]="maxVisitorsPerTime" min="0">
              </div>
            </div>
            <div class="day-parts-form" [hidden]="openingTimesForm.get('openingType').value !== 'vrije_inloop'"
                 *ngIf="this.routeConfig.day_parts?.length > 0">

              <div class="form-item">
                <label class="label required">Dagdeel</label>
                <nb-radio-group formControlName="dayParts" id="day-parts" name="day-parts">
                  <nb-radio *ngFor="let dayPart of routeConfig['day_parts']"
                            [value]="dayPart.uuid[0].value">{{dayPart.name[0].value}}</nb-radio>
                </nb-radio-group>
              </div>
              <div class="form-item">
                <label class="label required" for="totalPlaces">Totaal aantal bezoekers</label>
                <input nbInput type="number" formControlName="totalPlaces" id="totalPlaces" [max]="maxVisitorsPerDayPart" min="0">
              </div>
            </div>
            <div class="general-form-items">
              <div class="form-item">
                <label for="name" class="label">Naam van de eigenaar/contactpersoon</label>
                <input type="text" nbInput id="name" formControlName="nameOwner">
              </div>
              <div class="form-item">
                <div class="label">Adres</div>
                <div class="address">
                  <span>
                    {{routeConfig.location_address.address_line1}} {{routeConfig.location_address.address_line2}}<br/>
                    {{routeConfig.location_address.postal_code}} {{routeConfig.location_address.locality}}
                  </span>
                </div>
                <div class="description">
                  Uw naam en adres worden na inschrijving per e-mail naar de bezoeker gestuurd.
                </div>
              </div>
              <div class="form-item" *ngIf="showCompanyOnLocation">
                <div class="checkbox">
                  <label class="label" for="company">Is er een bedrijf aanwezig?</label>
                  <nb-radio-group formControlName="company" id="company">
                    <nb-radio value="ja">Ja</nb-radio>
                    <nb-radio value="nee">Nee</nb-radio>
                  </nb-radio-group>
                </div>
                <p class="description">Soms vraagt een huiseigenaar een bedrijf om uitleg te geven over de duurzame
                  maatregelen in huis. Voor bezoekers is het prettig hiervan op de hoogte te zijn. U kunt dit ook
                  vermelden bij ‘Evt. opmerking voor de bezoekers’
                </p>
              </div>
              <div class="form-item">
                <label class="label" for="remarks-visitors">Evt. opmerking voor bezoekers</label>
                <textarea formControlName="remarksVisitors" id="remarks-visitors" nbInput></textarea>
                <p *ngIf="ndhr" class="description">Bijvoorbeeld de route naar de woning.</p>
                <p *ngIf="tkr" class="description">Bijvoorbeeld de route de tuin of project.</p>
                <p *ngIf="pdb" class="description">Bijvoorbeeld de route naar het bedrijfspand.</p>
              </div>
            </div>
          </div>
          <div class="form-item">
            <label class="label" for="remarks-organizers">Evt. opmerking voor organisatie</label>
            <textarea formControlName="remarksOrganizer" id="remarks-organizers" nbInput fullWidth="false"></textarea>
          </div>
          <div class="form-item">
            <input type="submit" value="Opslaan" nbButton status="success"
                   [disabled]="openingTimesForm.invalid && openingTimesForm.get('availability').value !== 'notOpened'">
          </div>
        </form>

      </div>
      <ng-template #openingTimesSavedMessage>
        <p>Hartelijk bedankt voor het doorgeven van uw openingstijden!</p>
      </ng-template>
    </ng-container>
    <ng-template #noConfig>
      <p>Er kunnen voor dit object en datum momenteel geen openingstijden opgegeven worden.</p>
      <p>{{errorMsg}}</p>
    </ng-template>
  </nb-card-body>
</nb-card>
