<form [formGroup]="form" [nbSpinner]="isSaving" nbSpinnerStatus="primary">
  <h3>Controleer gegevens</h3>
  <p>Zijn alle gegevens correct ingevuld?</p>

  <hr>

  <app-subsidy-request-review [data]="reviewData"></app-subsidy-request-review>
  <nb-checkbox formControlName="field_aanmelden_nieuwsbrief">Ik wil de nieuwsbrief ontvangen</nb-checkbox><br>
  <nb-checkbox formControlName="field_algemene_voorwaarden">Ik ga akkoord met de algemene (privacy) voorwaarden</nb-checkbox>

  <section class="nav">
    <button nbButton nbStepperPrevious><nb-icon icon="arrow-ios-back-outline"></nb-icon> Vorige</button>
    <div>
      <button nbButton (click)="save()">Tussentijds opslaan</button>
      <button nbButton [disabled]="!canSubmit" status="success" (click)="submit()">Indienen ter controle <nb-icon icon="arrow-ios-forward-outline"></nb-icon></button>
    </div>
  </section>
</form>