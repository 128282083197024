import {Component, Inject, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MajadaToastrService} from '../../services/toastr/majada-toastr.service';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  constructor(private http: HttpClient, private toastrService: MajadaToastrService, private route: ActivatedRoute, @Inject('drupalUrl') private drupalUrl: string) {
  }

  forgotPasswordForm: FormGroup;
  newPasswordForm: FormGroup;
  resetSuccess;
  state = 'request';
  token;
  updateSuccess;

  public static matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
        ? null
        : {isMatching: false};
    };
  }

  ngOnInit(): void {
    this.forgotPasswordForm = new FormGroup({
      resetUsername: new FormControl('', Validators.required)
    });

    this.newPasswordForm = new FormGroup({
      password: new FormControl(undefined, Validators.required),
      passwordConfirm: new FormControl(undefined, [Validators.required, PasswordResetComponent.matchValues('password')])
    });

    this.route.queryParamMap.subscribe(params => {
      if (params.get('token')) {
        this.token = params.get('token');
        this.state = 'reset';
      }
    });
  }

  submitNewPassword() {
    const body = {
      password: this.newPasswordForm.get('password').value,
      token: this.token
    };
    this.http.post(this.drupalUrl + '/user/lost-password-reset?_format=json', body).subscribe(() => {
      this.toastrService.success('Uw wachtwoord is bijgewerkt!');
      this.updateSuccess = true;
    }, error => {
      this.toastrService.error('Uw wachtwoord kon niet worden veranderd, probeer het later nog eens.');
    });
  }

  submitReset() {
    const body = {
      name: this.forgotPasswordForm.get('resetUsername').value
    };
    this.http.post(this.drupalUrl + '/user/lost-password?_format=json', body).subscribe(() => {
      this.resetSuccess = true;
    }, error => {
      this.toastrService.error('Er is iets mis gegaan, probeer het later nog eens.');
    });
  }

}
