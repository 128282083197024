import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import * as jsonApiUtils from '../../utils/json-api.utils';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs';
import {first} from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(private http: HttpClient, private apiService: ApiService, @Inject('drupalUrl') private drupalUrl: string, @Inject('domainUuid') private domainUuid: string) {
  }

  public static matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
        ? null
        : {isMatching: false};
    };
  }

  getAvailableTimesForObject(objectId) {
    return this.http.get<any>(this.drupalUrl + '/api/get-available-times-for-object/' + objectId).pipe(
      map(data => {
        return data;
      })
    );
  }

  getRouteConfigForObjectAndDate(objectId, dateTid) {
    return this.http.get(this.drupalUrl + '/api/get-route-configuration/' + objectId + '/' + dateTid).pipe(
      map(data => {
        return data;
      })
    );
  }

  getOpeningTimesForObjectOpeningTime(objectOpeningTimeId: string) {
    return this.http.get<any>(`${this.drupalUrl}/api/get-opening-time/${objectOpeningTimeId}`).pipe(map(data => {
      return data;
    }));
  }

  getOpeningTimesForUser(locationType: string) {
    return this.http.get<any>(`${this.drupalUrl}/api/user-opening-times/${locationType}`).pipe(map(data => {
      return data;
    }));
  }

  public getRegistration(id: string) {
    const idFilter = `filter[drupal_internal__id]=${id}`;
    const include = `include=field_ref_tax_tijdstippen,field_ref_object_openingsdatum,` +
      `field_ref_object_openingsdatum.field_ref_openingstijd_voor,field_inschrijving_gemeente`;
    const uri = `/jsonapi/route/inschrijving?${include}&${idFilter}`;
    return this.apiService.get(uri).pipe(map(res => first(jsonApiUtils.parseResponseMany<any>(res))));
  }

  public getOpeningDateConfig(id: string) {
    const idFilter = `filter[drupal_internal__id]=${id}`;
    const include = `include=field_ref_tax_tijdstippen`;
    const uri = `/jsonapi/route/openingstijdstippen?${include}&${idFilter}`;
    return this.apiService.get(uri).pipe(map(res => first(jsonApiUtils.parseResponseMany<any>(res))));
  }

  get openingTimesForm() {
    return new FormGroup({
      openingType: new FormControl(undefined, Validators.required),
      dayParts: new FormControl(undefined),
      totalPlaces: new FormControl(undefined),
      company: new FormControl(undefined),
      nameOwner: new FormControl(undefined),
      availability: new FormControl(undefined),
      remarksVisitors: new FormControl(undefined),
      remarksOrganizer: new FormControl(undefined),
      stopRegistrations: new FormControl(undefined)
    });
  }

  buildTimeEntityData(timesGroup: FormGroup, objectId, dateTid) {
    const timeEntities = [];

    for (const controlsKey in timesGroup.controls) {
      const formElement = timesGroup.get(controlsKey);

      if (formElement instanceof FormControl) {
        const visitors = formElement.value;
        const openingTimeEntityAttributes = {
          field_tijdstippen_bezoekers: visitors,
          title: 'Tijdstip voor objectID ' + objectId + ' voor datumId ' + dateTid
        };
        const relations = {
          field_ref_tax_tijdstippen: jsonApiUtils.createRelation(controlsKey, 'taxonomy_term--route_tijdstippen'),
        };
        timeEntities.push(jsonApiUtils.createBody(null, 'route--tijdstippen', openingTimeEntityAttributes, relations));
      }
    }
    return timeEntities;
  }

  async createOpeningTimeTimeEntities(data) {
    const timeEntityIds = [];
    for (const body of data) {
      const response = await this.apiService.post('/jsonapi/route/tijdstippen', body).toPromise();
      if (response) {
        timeEntityIds.push(response.data.id);
      }
    }
    return timeEntityIds;
  }

  get visitorRegistrationForm(): FormGroup {
    const group = new FormGroup({
      email: new FormControl(undefined, [Validators.required, Validators.email]),
      emailConfirm: new FormControl(undefined, [Validators.required, Validators.email, RouteService.matchValues('email')]),
      name: new FormControl(undefined, Validators.required),
      visitors: new FormControl("", [Validators.required, Validators.min(1)]),
      phone: new FormControl(undefined),
      date: new FormControl(undefined, [Validators.required]),
      time: new FormControl(undefined, [Validators.required]),
      questionsOwner: new FormControl(undefined),
      generalRemarks: new FormControl(undefined),
      privacy: new FormControl(undefined, [Validators.requiredTrue]),
      newsletter: new FormControl(undefined),
      locality: new FormControl(undefined),
      municipalityId: new FormControl(undefined),
      unlistedMunicipality: new FormControl(undefined),
      gasFreeMunicipality: new FormControl(undefined),
      captcha: new FormControl(undefined, [Validators.required])
    });

    group.get('unlistedMunicipality').valueChanges.subscribe(() => {
      group.get('municipalityId').updateValueAndValidity();
    });

    return group;
  }

  getUserRegistrations(): Observable<any> {
    return this.apiService.get(`/api/majada-rest-get-user-route-registrations?_format=json&domain_id=${this.domainUuid}`).pipe(
      map(data => {
        return data;
      })
    );
  }

}
