import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Subscription} from 'rxjs';
import {NbDialogService} from '@nebular/theme';

import {AppointmentDialogComponent} from './appointment-dialog/appointment-dialog.component';
import {AppointmentsService} from '../../services/appointments/appointments.service';
import { CalendarOptions } from '@fullcalendar/angular';

@Component({
  selector: 'app-my-appointments',
  templateUrl: './my-appointments.component.html',
  styleUrls: ['./my-appointments.component.scss'],
})
export class MyAppointmentsComponent implements OnInit, OnDestroy {

  data = null;

  subscription = new Subscription();
  console = console;

  calendarPlugins = ['dayGridPlugin', 'listPlugin'];
  calendarEvents = [];
  calendarButtonText = {
    today: 'Vandaag',
    month: 'Maand',
    week: 'Week',
    day: 'Dag',
    list: 'Lijst'
  };
  calendarHeader = {
    left: 'today prev,next',
    center: 'title',
    right: 'dayGridMonth,listMonth',
  };
  calendarEventFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: false,
    meridiem: 'narrow'
  };
  calendarViews = {
    listMonth: {
      buttonText: 'Lijst',
      type: 'list',
      duration: {months: 3}
    },
  };


  calendarOptions: CalendarOptions = {
    views: this.calendarViews,
    initialView: 'listMonth',
    events: this.calendarEvents,
    locale: 'nl',
    headerToolbar: this.calendarHeader,
    eventClick: this.showEventDetails.bind(this),
    buttonText: this.calendarButtonText
    //eventTimeFormat: this.calendarEventFormat,
    //format
  };



  constructor(private apiService: ApiService, private dialogService: NbDialogService, private appointmentsService: AppointmentsService) {

    const sub = this.appointmentsService.getProcessedEvents().subscribe(events => {
      this.calendarEvents = events;
      this.calendarOptions.events = this.calendarEvents;
    });
    this.subscription.add(sub);

    const changeSub = this.appointmentsService.appointmentsChanged$.subscribe(_ => {
      const changedNewEventsSub = this.appointmentsService.getProcessedEvents().subscribe(events => {
        this.calendarEvents = events;
        this.calendarOptions.events = this.calendarEvents;
      });
      this.subscription.add(changedNewEventsSub);
    });

    this.subscription.add(changeSub);

  }

  ngOnInit(): void {
    const self = this;
  }

  showEventDetails(event) {
    let submissionData;
    if (typeof event.event._def.extendedProps.submissionData !== 'undefined') {
      console.log(event.event._def.extendedProps.submissionData);
      submissionData = {
        ...event.event._def.extendedProps.submissionData,
        elements: {
          energieadvies_tijdstip: event.event._def.extendedProps.submissionData.elements.energieadvies_tijdstip,
          flexbox: event.event._def.extendedProps.submissionData.elements.flexbox,
        }
      };
    } else {
      submissionData = {
        ...event.event._def.extendedProps.submissionData,
      };
    }

    this.dialogService.open(AppointmentDialogComponent, {
      context: {
        submissionData,
        reservationId: event.event._def.extendedProps.reservationId
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
