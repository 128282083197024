import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RouteService} from '../../../services/route/route.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {debounceTime, tap, map, flatMap, shareReplay} from 'rxjs/operators';
import {AuthService} from '../../../services/auth/auth.service';
import {environment} from '../../../../../projects/mijn-ndhr/src/environments/environment';
import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {TaxonomyService} from 'src/app/services/taxonomy/taxonomy.service';


@Component({
  selector: 'app-route-visitor-registration',
  templateUrl: './route-visitor-registration.component.html',
  styleUrls: ['./route-visitor-registration.component.scss']
})
export class RouteVisitorRegistrationComponent implements OnInit, OnDestroy {

  visitorRegistrationForm: FormGroup;
  subscription = new Subscription();
  availableDates = [];
  dateOptions = [];
  availableTimes = [];
  visitationType;
  availablePlaces;
  objectId;
  showError = false;
  object;
  objectAttributes;
  termProperties;
  location;
  dateId;
  sourceUrl;
  imageUrl = null;
  registrationSubmitted = false;
  environment = environment;
  currentDateRemark = null;
  currentDateHasCompany = false;
  isSearchingMunicipalities = false;
  filteredMunicipalities$: Observable<{ id: string, value: string }[]>;
  errorMessage = '';

  doneLoading = false;

  captchaResolved = false;

  municipalitySearchQuery$ = new BehaviorSubject<string>(undefined);
  municipalitySearchResults$ = this.municipalitySearchQuery$.pipe(
    debounceTime(500),
    flatMap(q => this.taxonomyService.getTermsBySearchQuery('gemeente', q, 20)),
    map(terms => terms.map(x => ({id: x.__uuid, value: x.name}))),
    shareReplay()
  );

  get pdb() {
    return this.project === 'platformduurzamebedrijven';
  }

  get tkr() {
    return this.project === 'tuinenklimaatroute';
  }

  get vd() {
    return this.project === 'vd';
  }

  get ndhr() {
    return this.project === 'ndhr';
  }

  get recpatchaKey() {

    if (this.isProduction) {
      return '6Ld9kz8hAAAAABFlufkydYmuL7K2JFGPGP4aTxvB';
    } else {
      return '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
    }
  }

  constructor(
    private routeService: RouteService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private authService: AuthService,
    private toastrService: MajadaToastrService,
    private taxonomyService: TaxonomyService,
    @Inject('wordpressUrl') public wordpressUrl: string,
    @Inject('drupalUrl') private drupalUrl: string,
    @Inject('domainUuid') private domainId: string,
    @Inject('project') public project: string,
    @Inject('isProduction') public isProduction: string
  ) {
    this.visitorRegistrationForm = routeService.visitorRegistrationForm;

    if (this.pdb) {
      this.visitorRegistrationForm.get('locality').setValidators(Validators.required);
      this.visitorRegistrationForm.get('locality').updateValueAndValidity();
    }

    const queryParamsSub = this.route.queryParamMap.subscribe(queryParams => {
      if (queryParams.get('date-id')) {
        this.dateId = queryParams.get('date-id');
      }
      if (queryParams.get('source')) {
        this.sourceUrl = queryParams.get('source');
      }
    });
    this.subscription.add(queryParamsSub);

    this.subscription.add(this.municipalitySearchResults$.subscribe(municipalities => {
      this.filteredMunicipalities$ = of(municipalities);
      this.isSearchingMunicipalities = false;
    }));

    const paramsSub = this.route.paramMap.subscribe(params => {

      this.objectId = params.get('objectId');
      // Get the available times you can register for.
      const timesSub = this.routeService.getAvailableTimesForObject(this.objectId).subscribe(result => {
        this.doneLoading = true;
        if (result.image) {
          this.imageUrl = result.image;
        }
        if (result.object) {
          this.object = result.object;
        }
        if (result.attributes) {
          this.objectAttributes = result.attributes;
        }
        if (result.term_properties) {
          this.termProperties = result.term_properties;
        }
        if (result.location) {
          this.location = result.location;
        }

        if (result.available_dates && result.object) {
          this.availableDates = result.available_dates;
          const ownerInformation = [];
          this.availableDates.forEach(availableDate => {
            console.log('availableDate', availableDate);

            const timeOptions = [];


            if (availableDate.type === 'vaste_tijdstippen') {
              availableDate.times.forEach(time => {
                //console.log('time', time);
                this.availablePlaces = time.available_places;
                //if (time.available_places > 0) {
                let timeLabel = '';
                if (time.available_places === 1) {
                  timeLabel = time.time_term_label + ' - nog ' + time.available_places + ' plaats vrij';
                } else {
                  timeLabel = time.time_term_label + ' - nog ' + time.available_places + ' plaatsen vrij';
                }

                timeOptions.push({
                  id: time.time_term_uuid,
                  label: timeLabel,
                  type: availableDate.type,
                  availablePlaces: time.available_places
                });
                //}
              });
            } else {
              //if (availableDate.available_places > 0) {
              let dayPartLabel = '';
              if (availableDate.available_places === 1) {
                dayPartLabel = availableDate.part_term_label + ' - nog ' + availableDate.available_places + ' plaats vrij';
              } else if (availableDate.available_places > 0) {
                dayPartLabel = availableDate.part_term_label + ' - nog ' + availableDate.available_places + ' plaatsen vrij';
              } else {
                dayPartLabel = availableDate.part_term_label + ' (vol)';
              }
              timeOptions.push({
                id: availableDate.part_term_uuid,
                label: dayPartLabel,
                type: availableDate.type,
                availablePlaces: availableDate.available_places
              });
              //}
            }


            if (timeOptions.length > 0) {
              this.dateOptions.push({
                id: availableDate.route_date.uuid[0].value,
                label: availableDate.route_date.field_route_openingsdatum[0].value,
                timeOptions,
                type: availableDate.type,
                company: availableDate.object_opening_date_object.field_is_er_een_bedrijf_aanwezig[0]?.value,
                remarksVisitors: availableDate.object_opening_date_object.field_opmerkingen_bezoekers[0]?.value
              });

              //if (availableDate.route_date.uuid[0].value == this.dateId) {
              this.visitorRegistrationForm.get('date').setValue(this.dateId);
              this.visitorRegistrationForm.get('date').updateValueAndValidity();
              //}
            }
          });
        }
      }, error => {
        console.log('error', error);
        if (error.error.message == 'Registration not allowed') {
          this.errorMessage = '<p>U kunt niet meer inschrijven voor deze locatie. <a href="' + this.wordpressUrl + '/locaties">Ga terug naar de lijst</a>.</p>';
        } else {
          this.errorMessage = '<p>Er is iets mis gegaan, probeer het later nog eens.</p>';
        }
        this.doneLoading = true;
        this.object = error.error.object;
        this.showError = true;
      });
      this.subscription.add(timesSub);
    });
    this.subscription.add(paramsSub);

    const dateChangeSub = this.visitorRegistrationForm.get('date').valueChanges.subscribe(value => {
      for (const dateOption of this.dateOptions) {
        if (dateOption.id === value) {
          this.visitationType = dateOption.type;
          this.availableTimes = dateOption.timeOptions;
          this.currentDateRemark = dateOption.remarksVisitors;
          this.currentDateHasCompany = dateOption.company === 'ja';

          break;
        }
      }
    });

    this.visitorRegistrationForm.get('time').valueChanges.subscribe(value => {
      for (const availableTime of this.availableTimes) {
        //console.log('availableTime', availableTime);
        if (availableTime.id == value) {
          if (availableTime.availablePlaces > 3) {
            this.availablePlaces = 3;
          } else {
            this.availablePlaces = availableTime.availablePlaces;
          }
          this.visitorRegistrationForm.get('visitors').setValidators([Validators.required, Validators.min(1), Validators.max(this.availablePlaces)]);
          this.visitorRegistrationForm.get('visitors').updateValueAndValidity();
          break;
        }
      }
      //console.log('value', value);
    });

    this.subscription.add(dateChangeSub);

  }

  ngOnInit(): void {
  }

  onChangeMunicipality(e: any) {
    this.filteredMunicipalities$ = of([]);
    const q = e?.target?.value?.toLowerCase() || '';
    this.isSearchingMunicipalities = true;
    this.municipalitySearchQuery$.next(q);
    this.visitorRegistrationForm.get('municipalityId').setValue(null);
  }

  onSelectMunicipality(e: any) {
    this.visitorRegistrationForm.get('municipalityId').setValue(e.id);
  }

  municipalityViewHandle(municipality: any) {
    console.log('municipalityViewHandle: municipality', municipality);
    if (!municipality.value) return municipality;
    return municipality.value;
  }

  submitRegistration() {

    const attributes = {
      name: this.visitorRegistrationForm.get('name').value,
      email: this.visitorRegistrationForm.get('email').value,
      phone: this.visitorRegistrationForm.get('phone').value,
      visitors: this.visitorRegistrationForm.get('visitors').value,
      type: this.visitationType,
      objectId: this.objectId,
      timeId: this.visitorRegistrationForm.get('time').value,
      dateId: this.visitorRegistrationForm.get('date').value,
      questionsOwner: this.visitorRegistrationForm.get('questionsOwner').value,
      generalRemarks: this.visitorRegistrationForm.get('generalRemarks').value,
      privacy: this.visitorRegistrationForm.get('privacy').value,
      newsletter: this.visitorRegistrationForm.get('newsletter').value,
      gasFreeMunicipality: this.visitorRegistrationForm.get('gasFreeMunicipality').value,
      municipalityId: this.visitorRegistrationForm.get('municipalityId').value,
      domainId: this.domainId,
      locality: this.visitorRegistrationForm.get('locality').value
    };

    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this.http.post(`${this.drupalUrl}/api/majada-rest-submit-route-registration`, attributes, {headers: httpHeaders}).subscribe(result => {
      this.toastrService.success('Bedankt voor uw aanmelding!');
      this.registrationSubmitted = true;
    }, error => {
      this.toastrService.error('Aanmelden is mislukt. Probeer het later nog eens.');
      console.log('registration error', error);
    });


  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


  recaptchaResolved($event) {
    this.captchaResolved = ($event && $event.length > 0) ? true : false;
  }
}
