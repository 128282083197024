import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';
import {concatMap, defaultIfEmpty, flatMap, map, take, tap, toArray} from 'rxjs/operators';
import {forkJoin, from, Observable, of, Subject} from 'rxjs';
import {compact, concat, omit, partial, pick} from 'lodash-es';

import {ApiService} from '../api/api.service';
import {AuthService} from '../auth/auth.service';
import {Location, LocationOverviewItem, Measure, LocationObjectType} from '../../models/location.models';
import {TaxonomyTerm} from 'src/app/models/drupal-datatypes.models';
import {TaxonomyService} from '../taxonomy/taxonomy.service';
import * as jsonApiUtils from '../../utils/json-api.utils';
import {MediaImageUpdate} from 'src/app/utils/image-upload.utils';
import {HttpClient} from '@angular/common/http';

interface LocationUpdate {
  title: string;
  address: string;
  postalCode: string;
  locality: string;
  website?: string;
  image?: File;
}

interface PostalCodeAPIResult {
  id: string;
  province: string;
  municipality: string;
  city: string;
  postcode: string;
  street: string;
  streetnumber: number;
  streetnumber_additions: string;
  lat: string;
  lng: string;
  municipality_id: 21;
}

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  public locationTypes: Array<TaxonomyTerm>;
  public sourceDomains: TaxonomyTerm[];
  public provinces: Array<TaxonomyTerm>;
  public municipalities: TaxonomyTerm[];
  public constructionPeriods: TaxonomyTerm[];
  public residenceTypes: TaxonomyTerm[];
  public sustainableResidenceTypes: TaxonomyTerm[];
  public energyLabels: TaxonomyTerm[];
  public industries: TaxonomyTerm[];
  public certifications: TaxonomyTerm[];
  public measures: TaxonomyTerm[];

  private locationChangedSubj = new Subject();
  locationChanged$ = this.locationChangedSubj.asObservable();
  private clearMarkers = new Subject();
  clearMarkers$ = this.clearMarkers.asObservable();

  constructor(
    private taxonomyService: TaxonomyService,
    protected datePipe: DatePipe,
    private apiService: ApiService,
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  locationChanged() {
    this.locationChangedSubj.next();
  }

  private loadTaxonomy(type: string, filter?: string, id?: string): Observable<Array<TaxonomyTerm>> {
    const nameFilter = !filter ? undefined :
      `&filter[name][condition][path]=name` +
      `&filter[name][condition][operator]=CONTAINS` +
      `&filter[name][condition][value]=${filter}` +
      `&filter[selectable-group][group][conjunction]=OR&filter[selectable-true][condition][path]=field_niet_selecteerbaar&filter[selectable-true][condition][value]=0&filter[selectable-true][condition][memberOf]=selectable-group&filter[selectable-null][condition][path]=field_niet_selecteerbaar&filter[selectable-null][condition][operator]=IS NULL&filter[selectable-null][condition][memberOf]=selectable-group`;
    const limit = !filter ? undefined : `page[limit]=10`;
    const filters = compact([nameFilter, limit]).join('&');
    if (id) {
      const uri = `/jsonapi/taxonomy_term/${type}/${id}`;
      return this.apiService.get(uri, !filters).pipe(
        map(res => [jsonApiUtils.parseResponseSingle<TaxonomyTerm>(res)]),
      );
    } else {
      const uri = `/jsonapi/taxonomy_term/${type}?${filters}`;
      return this.apiService.get(uri, !filters).pipe(
        map(res => jsonApiUtils.parseResponseMany<TaxonomyTerm>(res)),
      );
    }
  }

  getSourceDomains() {
    if (this.sourceDomains) {
      return of(this.sourceDomains);
    }
    return this.loadTaxonomy('brondomein').pipe(
      tap(terms => this.sourceDomains = terms)
    );
  }

  getSourceDomainByObjectType(objectType: LocationObjectType) {
    let name = 'Veluwe Duurzaam';
    switch (objectType) {
      case LocationObjectType.Bedrijf:
        name = 'Platform Duurzame Bedrijven';
        break;
      case LocationObjectType.Project:
        name = 'Tuin en Klimaat Route';
        break;
      case LocationObjectType.Tuin:
        name = 'Tuin en Klimaat Route';
        break;
      case LocationObjectType.Woning:
        name = 'Duurzame Huizen Route';
        break;
    }
    return this.sourceDomains.find(x => x.name === name);
  }

  getProvinces() {
    if (this.provinces) {
      return of(this.provinces);
    }
    return this.loadTaxonomy('provincie').pipe(
      tap(terms => this.provinces = terms)
    );
  }

  getMunicipalities(filter?: string, id?: string) {
    if (!filter && !id && this.municipalities) {
      return of(this.municipalities);
    }
    return this.loadTaxonomy('gemeente', filter, id).pipe(
      tap(terms => !filter && (this.municipalities = terms))
    );
  }

  getMunicipalityByName(name: string) {
    const nameFilter =
      `&filter[name][condition][path]=name` +
      `&filter[name][condition][operator]=CONTAINS` +
      `&filter[name][condition][value]=${name}`;
    const uri = `/jsonapi/taxonomy_term/gemeente?${nameFilter}`;
    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseMany<TaxonomyTerm>(res)?.[0]),
    );
  }

  getConstructionPeriods() {
    if (this.constructionPeriods) {
      return of(this.constructionPeriods);
    }
    return this.loadTaxonomy('bouwperiode').pipe(
      tap(terms => this.constructionPeriods = terms)
    );
  }

  getResidenceTypes() {
    if (this.residenceTypes) {
      return of(this.residenceTypes);
    }
    return this.loadTaxonomy('soort_woning').pipe(
      tap(terms => this.residenceTypes = terms)
    );
  }

  getSustainableResidenceTypes() {
    if (this.sustainableResidenceTypes) {
      return of(this.sustainableResidenceTypes);
    }
    return this.loadTaxonomy('type_duurzame_woning').pipe(
      tap(terms => this.sustainableResidenceTypes = terms)
    );
  }

  getEnergyLabels() {
    if (this.energyLabels) {
      return of(this.energyLabels);
    }
    return this.loadTaxonomy('energielabel').pipe(
      tap(terms => this.energyLabels = terms)
    );
  }

  getIndustries() {
    if (this.industries) {
      return of(this.industries);
    }
    return this.loadTaxonomy('bedrijfstak').pipe(
      tap(terms => this.industries = terms)
    );
  }

  getCertifications() {
    if (this.certifications) {
      return of(this.certifications);
    }
    return this.loadTaxonomy('keurmerken_certificeringen').pipe(
      tap(terms => this.certifications = terms)
    );
  }

  getObjectMeasures(filter?: string, id?: string) {
    if (!filter && !id && this.measures) {
      return of(this.measures);
    }
    return this.loadTaxonomy('maatregel', filter, id).pipe(
      tap(terms => !filter && (this.measures = terms))
    );
  }

  getObjectMeasuresByType(type: string) {
    const typeFilter =
      `filter[type][condition][path]=field_object_soort` +
      `&filter[type][condition][operator]=CONTAINS` +
      `&filter[type][condition][value]=${type}`;
    const uri = `/jsonapi/taxonomy_term/maatregel?${typeFilter}`;
    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseMany<TaxonomyTerm>(res)),
    );
  }

  loadImages(drupalInternalId: number, objectIndex?: number) {
    const uri = `/api/location/images?_format=json&request_id=${drupalInternalId}&object_index=${objectIndex || 0}`;
    return this.apiService.get(uri);
  }

  postalCodeLookup(postalCode: string, houseNumber: string) {
    const uri = `https://postcode.elonisas.nl/get_postcode.php?huisnr=${houseNumber}&postcode=${postalCode}&key=Elonisas-Postcode-public`;
    return this.http.get<PostalCodeAPIResult>(uri).pipe(
      take(1),
      flatMap(res => this.getProvinces().pipe(map(provinces => ({provinces, res})))),
      flatMap(({res, provinces}) => res?.municipality ? this.getMunicipalityByName(res?.municipality).pipe(
        map(municipality => ({municipality, provinces, res}))) : of({provinces, res, municipality: null})),
      map(({res, provinces, municipality}) => {
        const province = provinces?.find(x => x.name === res?.province);
        return {province, municipality, city: res.city, street: res.street};
      })
    );
  }

  get() {
    const uidFilter = `filter[uid.id]=${this.authService.uuid}`;
    const locationTypeFilter = 'filter[field_ref_location_type.name]=Bedrijf';
    const sourceFilter = 'filter[field_brondomein.name]=Week%20van%20de%20Duurzaamheid';
    const uri = `/jsonapi/node/locatie?${uidFilter}&${locationTypeFilter}&${sourceFilter}`;
    return this.apiService.get(uri);
  }

  getExistingLocationsForUser() {
    const uidFilter = `filter[uid.id]=${this.authService.uuid}`;
    const includeStr = `include=field_object`;
    const uri = `/jsonapi/node/locatie?${includeStr}&${uidFilter}`;
    return this.apiService.get(uri).pipe(map(res => jsonApiUtils.parseResponseMany<Location>(res)));
  }

  getLocations(domainUuid = null, objectType = null): Observable<LocationOverviewItem[]> {

    this.clearMarkers.next(true);

    let uri = '/api/location/overview?_format=json';
    if (domainUuid) {
      uri = uri + '&domain=' + domainUuid;
    }
    if (objectType) {
      uri = uri + '&object_type=' + objectType;
    }
    console.log('objectType', objectType);
    return this.apiService.get(uri);
  }

  getById(id: string) {
    const prefix = (pre: string, xs: string[]) => xs.map(x => `${pre}.${x}`);
    const prpIncludes = ['field_bedrijfslogo', 'field_bedrijfslogo.field_media_image_1'];
    const msrIncludes = ['field_maatregel', 'field_afbeelding', 'field_afbeelding.field_media_image'];
    const objIncludes = ['field_media_afbeelding', 'field_media_afbeelding.field_media_image', 'field_ref_contactgegevens',
      'field_ref_woning_kenmerken', 'field_ref_tuin_kenmerken', 'field_ref_project_kenmerken', 'field_ref_bedrijf_kenmerken',
      'field_maatregelen', ...prefix('field_maatregelen', msrIncludes), ...prefix('field_ref_bedrijf_kenmerken', prpIncludes)];
    const includes = ['field_brondomein', /*'field_gemeente', 'field_provincie',*/ ...prefix('field_object', objIncludes)];
    const includeStr = `include=${includes.join(',')}`;
    const uri = `/jsonapi/node/locatie/${id}?${includeStr}`;
    return this.apiService.get(uri).pipe(map(res => {
      const loc = jsonApiUtils.parseResponseSingle<Location>(res);
      const img = (loc?.field_object?.[0]?.field_ref_bedrijf_kenmerken?.field_bedrijfslogo as any)?.field_media_image_1;
      if (img) {
        loc.field_object[0].field_ref_bedrijf_kenmerken.field_bedrijfslogo.field_media_image = img;
      }
      return loc;
    }));
  }

  private addToCleanUp(type: string, cleanUp: Map<string, string>, res: any) {
    if (!res?.data?.id) {
      return;
    }
    cleanUp.set(res?.data?.id, type);
  }

  private postMediaObjects(
    fileIds: string[],
    highlighted: string | undefined,
    cleanUp: Map<string, string>,
    addHighlightField: boolean,
    mediaType?: string
  ) {

    if (!fileIds) {
      return of(null);
    }

    mediaType = mediaType || 'afbeelding';
    const field = mediaType === 'afbeelding' ? 'field_media_image' : 'field_media_image_1';

    const postImage$ = id => {
      const attributes = {
        name: 'Afbeelding',
        field_uitgelicht: highlighted === id
      };
      if (!addHighlightField) delete attributes.field_uitgelicht;
      const relations = {
        [field]: jsonApiUtils.createRelation(id, 'file--file')
      };
      const body = jsonApiUtils.createBody(undefined, `media--${mediaType}`, attributes, relations);
      return this.apiService.post(`/jsonapi/media/${mediaType}`, body)
        .pipe(tap(partial(this.addToCleanUp, `media--${mediaType}`, cleanUp)));
    };

    return from(fileIds).pipe(
      concatMap(postImage$),
      toArray(),
      map(res => res.map((r: any) => r.data?.id)),
      defaultIfEmpty([])
    );
  }

  private patchMediaObjects(mediaObjectIds: string[], highlighted: string | undefined, addHighlightField: boolean, mediaType?: string) {

    if (!mediaObjectIds) {
      return of(null);
    }

    mediaType = mediaType || 'afbeelding';

    const patchImage$ = id => {
      const attributes = addHighlightField ? {field_uitgelicht: highlighted === id} : {};
      const body = jsonApiUtils.createBody(id, `media--${mediaType}`, attributes);
      return this.apiService.patch(`/jsonapi/media/${mediaType}/${id}`, body);
    };
    return from(mediaObjectIds).pipe(
      concatMap(patchImage$),
      toArray(),
      map(res => res.map((r: any) => r.data?.id)),
      defaultIfEmpty([])
    );
  }

  addOrUpdateResidence(
    uuid: string | undefined,
    fields: any,
    sourceDomainName?: string,
    existingSourceDomainUuids?: string[],
  ) {
    const postLocation$ = () => {
      const relationFieldNames = ['field_media_afbeelding', 'field_provincie', 'field_object', 'field_gemeente'];
      const relationFields = pick(fields, relationFieldNames);
      fields = omit(fields, [...relationFieldNames, 'highlighted', 'field_titel_suggestie', 'field_bezoek_op_afspraak']);
      const field_adres = {
        langcode: '',
        country_code: 'NL',
        ...fields.field_adres
      };
      const attributes = {
        ...fields,
        field_adres,
      };

      if (!uuid) {
        attributes.title = `Nieuwe aanmelding ${this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm')}`;
      }
      const relations = {
        field_object: jsonApiUtils.createRelation(relationFields.field_object, 'data--object'),
      } as any;
      if (sourceDomainName) {
        const sourceDomain = this.sourceDomains.find(x => x.name === sourceDomainName);
        const sourceDomainUuids = [...(existingSourceDomainUuids || []), sourceDomain.__uuid];
        relations.field_brondomein = jsonApiUtils.createRelation(sourceDomainUuids, 'taxonomy_term--brondomein');
        console.log('yep', relations);
      }
      if (relationFields.field_provincie) {
        relations.field_provincie = jsonApiUtils.createRelation(relationFields.field_provincie.__uuid, 'taxonomy_term--provincie');
      }
      if (relationFields.field_gemeente) {
        relations.field_gemeente = jsonApiUtils.createRelation(relationFields.field_gemeente.__uuid, 'taxonomy_term--gemeente');
      }
      if (!relationFields.field_object) {
        delete relations.field_object;
      }

      const body = jsonApiUtils.createBody(uuid, 'node--locatie', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/node/locatie', body);
      } else {
        return this.apiService.patch(`/jsonapi/node/locatie/${uuid}`, body);
      }
    };

    return this.getSourceDomains().pipe(
      flatMap(postLocation$),
      tap(() => this.locationChanged())
    );
  }

  addOrUpdateMeasures(
    uuid: string | undefined,
    fields: any,
    highlightedMediaObjectId: string,
    currentMediaObjectIds: string[],
    newImageFileIds: string[],
    cleanUp: Map<string, string>
  ) {

    const patchImages$ = () => this.patchMediaObjects(currentMediaObjectIds, highlightedMediaObjectId, true);

    const postImages$ = () => this.postMediaObjects(newImageFileIds, fields.highlighted, cleanUp, true);

    const postMeasure$ = (newMediaObjectIds?: string[]) => {
      const mediaObjectIds = concat(currentMediaObjectIds || [], newMediaObjectIds || []);
      const relationFieldNames = ['field_afbeelding', 'field_maatregel'];
      const relationFields = pick(fields, relationFieldNames);
      const title = fields.maatregel_name;
      fields = omit(fields, [...relationFieldNames, 'highlighted', 'maatregel_name']);
      const attributes = {title, ...fields};
      const relations = relationFields.field_maatregel ? {
        field_maatregel: jsonApiUtils.createRelation(relationFields.field_maatregel, 'taxonomy_term--maatregel'),
      } : {} as any;
      if (mediaObjectIds && mediaObjectIds.length) {
        relations.field_afbeelding = jsonApiUtils.createRelation(mediaObjectIds, 'media--afbeelding');
      }
      const body = jsonApiUtils.createBody(uuid, 'data--maatregel', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/data/maatregel', body);
      } else {
        return this.apiService.patch(`/jsonapi/data/maatregel/${uuid}`, body);
      }
    };

    return patchImages$().pipe(
      flatMap(postImages$),
      flatMap(postMeasure$),
      tap(() => this.locationChanged())
    );
  }

  addOrUpdateResidenceProperties(
    uuid: string | undefined,
    objectUuid: string | undefined,
    fields: any,
    measureUuids: string[],
    highlightedMediaObjectId: string,
    currentMediaObjectIds: string[],
    newImageFileIds: string[],
    cleanUp: Map<string, string>
  ) {
    const fieldsHighlighted = fields.highlighted;

    const patchImages$ = () => this.patchMediaObjects(currentMediaObjectIds, highlightedMediaObjectId, true);
    const postImages$ = () => this.postMediaObjects(newImageFileIds, fieldsHighlighted, cleanUp, true);

    const objectFields = ['field_toekomstplannen', 'field_tips', 'field_adviezen', 'field_interview', 'field_content', 'field_ervaringen', 'field_bezoek_op_afspraak'];

    const postProperties$ = () => {
      const relationFieldNames = ['field_media_afbeelding', 'field_energielabel', 'field_soort_woning', 'field_type_duurzame_woning', 'field_bouwperiode'];
      const relationFields = pick(fields, relationFieldNames);
      const propertiesFields = omit(fields, [...relationFieldNames, 'highlighted', ...objectFields, '']);
      const attributes = {
        title: `Kenmerken`,
        ...propertiesFields,
      };
      let relations = {
        field_soort_woning: jsonApiUtils.createRelation(relationFields.field_soort_woning, 'taxonomy_term--soort_woning')
      } as any;
      if (relationFields.field_energielabel) {
        relations.field_energielabel = jsonApiUtils.createRelation(relationFields.field_energielabel, 'taxonomy_term--energielabel');
      }
      if (relationFields.field_bouwperiode) {
        relations.field_bouwperiode = jsonApiUtils.createRelation(relationFields.field_bouwperiode, 'taxonomy_term--bouwperiode');
      }
      if (relationFields.field_type_duurzame_woning) {
        relations.field_type_duurzame_woning = jsonApiUtils.createRelation(relationFields.field_type_duurzame_woning, 'taxonomy_term--type_duurzame_woning');
      }

      const body = jsonApiUtils.createBody(uuid, 'object_kenmerken--woning_kenmerken', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/object_kenmerken/woning_kenmerken', body);
      } else {
        return this.apiService.patch(`/jsonapi/object_kenmerken/woning_kenmerken/${uuid}`, body);
      }
    };

    interface PostObjectParam {
      propertiesUuid: string;
      newMediaObjectIds?: string[];
    }

    const postObject$ = (params: PostObjectParam) => {
      const mediaObjectIds = concat(currentMediaObjectIds || [], params.newMediaObjectIds || []);
      const attributes = {
        field_object_soort: 'woning',
        ...pick(fields, ...objectFields)
      } as any;
      if (!uuid) {
        attributes.title = `${fields.residenceTypeLabel} - ${fields.field_bouwjaar ? fields.field_bouwjaar : ''}, ${fields.locality}`;
      }

      const relations = {
        field_maatregelen: jsonApiUtils.createRelation(measureUuids, 'data--maatregel'),
        field_ref_woning_kenmerken: jsonApiUtils.createRelation(params.propertiesUuid, 'object_kenmerken--woning_kenmerken')
      } as any;
      if (mediaObjectIds && mediaObjectIds.length) {
        relations.field_media_afbeelding = jsonApiUtils.createRelation(mediaObjectIds, 'media--afbeelding');
      }
      const body = jsonApiUtils.createBody(objectUuid, 'data--object', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/data/object', body);
      } else {
        return this.apiService.patch(`/jsonapi/data/object/${objectUuid}`, body);
      }
    };

    return postProperties$().pipe(
      map((res: any) => res?.data?.id),
      flatMap((propertiesUuid: string) => patchImages$().pipe(map(() => propertiesUuid))),
      flatMap((propertiesUuid: string) => postImages$().pipe(map((newMediaObjectIds?: string[]) => ({propertiesUuid, newMediaObjectIds})))),
      flatMap(postObject$),
      tap(() => this.locationChanged())
    );
  }

  addOrUpdateGardenProperties(
    uuid: string | undefined,
    objectUuid: string | undefined,
    fields: any,
    measureUuids: string[],
    highlightedMediaObjectId: string,
    currentMediaObjectIds: string[],
    newImageFileIds: string[],
    cleanUp: Map<string, string>
  ) {
    const fieldsHighlighted = fields.highlighted;

    const patchImages$ = () => this.patchMediaObjects(currentMediaObjectIds, highlightedMediaObjectId, true);
    const postImages$ = () => this.postMediaObjects(newImageFileIds, fieldsHighlighted, cleanUp, true);

    const objectFields = ['field_tips', 'field_ervaringen', 'field_content', 'field_titel_suggestie', 'field_bezoek_op_afspraak'];

    const postProperties$ = () => {

      const relationFieldNames = ['field_media_afbeelding'];
      const relationFields = pick(fields, relationFieldNames);
      const propertiesFields = omit(fields, [...relationFieldNames, 'highlighted', ...objectFields, '']);
      const attributes = {
        title: `Kenmerken`,
        ...propertiesFields,
      };
      const body = jsonApiUtils.createBody(uuid, 'object_kenmerken--tuin_kenmerken', attributes, {});
      if (!uuid) {
        return this.apiService.post('/jsonapi/object_kenmerken/tuin_kenmerken', body);
      } else {
        return this.apiService.patch(`/jsonapi/object_kenmerken/tuin_kenmerken/${uuid}`, body);
      }
    };

    interface PostObjectParam {
      propertiesUuid: string;
      newMediaObjectIds?: string[];
    }

    const postObject$ = (params: PostObjectParam) => {
      const mediaObjectIds = concat(currentMediaObjectIds || [], params.newMediaObjectIds || []);
      const attributes = {
        field_object_soort: 'tuin',
        ...pick(fields, ...objectFields)
      } as any;
      if (!uuid) {
        attributes.title = `${fields.field_titel_suggestie}, ${fields.locality}`;
      }

      const relations = {
        field_maatregelen: jsonApiUtils.createRelation(measureUuids, 'data--maatregel'),
        field_ref_tuin_kenmerken: jsonApiUtils.createRelation(params.propertiesUuid, 'object_kenmerken--tuin_kenmerken')
      } as any;
      if (mediaObjectIds && mediaObjectIds.length) {
        relations.field_media_afbeelding = jsonApiUtils.createRelation(mediaObjectIds, 'media--afbeelding');
      }
      const body = jsonApiUtils.createBody(objectUuid, 'data--object', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/data/object', body);
      } else {
        return this.apiService.patch(`/jsonapi/data/object/${objectUuid}`, body);
      }
    };

    return postProperties$().pipe(
      map((res: any) => res?.data?.id),
      flatMap((propertiesUuid: string) => patchImages$().pipe(map(() => propertiesUuid))),
      flatMap((propertiesUuid: string) => postImages$().pipe(map((newMediaObjectIds?: string[]) => ({propertiesUuid, newMediaObjectIds})))),
      flatMap(postObject$),
      tap(() => this.locationChanged())
    );
  }


  addOrUpdateCompanyProperties(
    uuid: string | undefined,
    objectUuid: string | undefined,
    fields: any,
    measureUuids: string[],
    highlightedMediaObjectId: string,
    currentMediaObjectIds: string[],
    newImageFileIds: string[],
    propertiesImageUpdate: MediaImageUpdate,
    cleanUp: Map<string, string>
  ) {
    const fieldsHighlighted = fields.highlighted;

    const patchImages$ = () => this.patchMediaObjects(currentMediaObjectIds, highlightedMediaObjectId, true);
    const postImages$ = () => this.postMediaObjects(newImageFileIds, fieldsHighlighted, cleanUp, true);

    const patchPropertiesImages$ = () => this.patchMediaObjects(propertiesImageUpdate.currentMediaObjectIds, propertiesImageUpdate.highlightedMediaObject?.__uuid, false, 'logo');
    const postPropertiesImages$ = () => this.postMediaObjects(propertiesImageUpdate.newImageFileIds, undefined, cleanUp, false, 'logo');

    const objectFields = ['field_tips', 'field_ervaringen', 'field_content'];

    const postProperties$ = (newMediaObjectIds?: string[]) => {

      const mediaObjectIds = concat(propertiesImageUpdate.currentMediaObjectIds || [], newMediaObjectIds || []);

      const relationFieldNames = ['field_bedrijfslogo', 'field_ref_bedrijfstak', 'field_energielabel', 'field_keurmerken_certificeringen'];
      const relationFields = pick(fields, relationFieldNames);
      const propertiesFields = omit(fields, [...relationFieldNames, 'highlighted', ...objectFields, '']);
      const attributes = {
        title: `Kenmerken`,
        ...propertiesFields,
      };
      let relations = {} as any;
      if (relationFields.field_ref_bedrijfstak) {
        relations.field_ref_bedrijfstak = jsonApiUtils.createRelation(relationFields.field_ref_bedrijfstak, 'taxonomy_term--bedrijfstak');
      }
      if (relationFields.field_energielabel) {
        relations.field_energielabel = jsonApiUtils.createRelation(relationFields.field_energielabel, 'taxonomy_term--energielabel');
      }
      if (relationFields.field_keurmerken_certificeringen) {
        relations.field_keurmerken_certificeringen = jsonApiUtils.createRelation(relationFields.field_keurmerken_certificeringen, 'taxonomy_term--keurmerken_certificeringen');
      }
      if (mediaObjectIds && mediaObjectIds.length) {
        relations.field_bedrijfslogo = jsonApiUtils.createRelation(mediaObjectIds, 'media--logo');
      }
      const body = jsonApiUtils.createBody(uuid, 'object_kenmerken--bedrijf_kenmerken', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/object_kenmerken/bedrijf_kenmerken', body);
      } else {
        return this.apiService.patch(`/jsonapi/object_kenmerken/bedrijf_kenmerken/${uuid}`, body);
      }
    };

    interface PostObjectParam {
      propertiesUuid: string;
      newMediaObjectIds?: string[];
    }

    const postObject$ = (params: PostObjectParam) => {
      console.log('company postobject fields', fields);
      console.log('company postobject params', params);
      const mediaObjectIds = concat(currentMediaObjectIds || [], params.newMediaObjectIds || []);
      const attributes = {
        field_object_soort: 'bedrijf',
        ...pick(fields, ...objectFields)
      } as any;
      if (!uuid) {
        attributes.title = `${fields.field_naam_organisatie}, ${fields.locality}`;
      }
      const relations = {
        field_maatregelen: jsonApiUtils.createRelation(measureUuids, 'data--maatregel'),
        field_ref_bedrijf_kenmerken: jsonApiUtils.createRelation(params.propertiesUuid, 'object_kenmerken--bedrijf_kenmerken')
      } as any;
      if (mediaObjectIds && mediaObjectIds.length) {
        relations.field_media_afbeelding = jsonApiUtils.createRelation(mediaObjectIds, 'media--afbeelding');
      }
      const body = jsonApiUtils.createBody(objectUuid, 'data--object', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/data/object', body);
      } else {
        return this.apiService.patch(`/jsonapi/data/object/${objectUuid}`, body);
      }
    };

    return patchPropertiesImages$().pipe(
      flatMap(() => postPropertiesImages$()),
      flatMap(postProperties$),
      map((res: any) => res?.data?.id),
      flatMap((propertiesUuid: string) => patchImages$().pipe(map(() => propertiesUuid))),
      flatMap((propertiesUuid: string) => postImages$().pipe(map((newMediaObjectIds?: string[]) => ({propertiesUuid, newMediaObjectIds})))),
      flatMap(postObject$),
      tap(() => this.locationChanged())
    );
  }

  addOrUpdateProjectProperties(
    uuid: string | undefined,
    objectUuid: string | undefined,
    fields: any,
    measureUuids: string[],
    highlightedMediaObjectId: string,
    currentMediaObjectIds: string[],
    newImageFileIds: string[],
    cleanUp: Map<string, string>
  ) {
    const fieldsHighlighted = fields.highlighted;

    const patchImages$ = () => this.patchMediaObjects(currentMediaObjectIds, highlightedMediaObjectId, true);
    const postImages$ = () => this.postMediaObjects(newImageFileIds, fieldsHighlighted, cleanUp, true);

    const objectFields = ['field_tips', 'field_ervaringen', 'field_content', 'field_bezoek_op_afspraak'];

    const postProperties$ = () => {

      const relationFieldNames = ['field_media_afbeelding'];
      const relationFields = pick(fields, relationFieldNames);
      const propertiesFields = omit(fields, [...relationFieldNames, 'highlighted', ...objectFields, '']);
      const attributes = {
        title: `Kenmerken`,
        ...propertiesFields,
      };
      const body = jsonApiUtils.createBody(uuid, 'object_kenmerken--project_kenmerken', attributes, {});
      if (!uuid) {
        return this.apiService.post('/jsonapi/object_kenmerken/project_kenmerken', body);
      } else {
        return this.apiService.patch(`/jsonapi/object_kenmerken/project_kenmerken/${uuid}`, body);
      }
    };

    interface PostObjectParam {
      propertiesUuid: string;
      newMediaObjectIds?: string[];
    }

    const postObject$ = (params: PostObjectParam) => {
      const mediaObjectIds = concat(currentMediaObjectIds || [], params.newMediaObjectIds || []);
      const attributes = {
        field_object_soort: 'project',
        ...pick(fields, ...objectFields)
      } as any;
      if (!uuid) {
        attributes.title = `Nieuwe aanmelding ${this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm')}`;
      }

      const relations = {
        field_maatregelen: jsonApiUtils.createRelation(measureUuids, 'data--maatregel'),
        field_ref_project_kenmerken: jsonApiUtils.createRelation(params.propertiesUuid, 'object_kenmerken--project_kenmerken')
      } as any;
      if (mediaObjectIds && mediaObjectIds.length) {
        relations.field_media_afbeelding = jsonApiUtils.createRelation(mediaObjectIds, 'media--afbeelding');
      }
      const body = jsonApiUtils.createBody(objectUuid, 'data--object', attributes, relations);
      if (!uuid) {
        return this.apiService.post('/jsonapi/data/object', body);
      } else {
        return this.apiService.patch(`/jsonapi/data/object/${objectUuid}`, body);
      }
    };

    return postProperties$().pipe(
      map((res: any) => res?.data?.id),
      flatMap((propertiesUuid: string) => patchImages$().pipe(map(() => propertiesUuid))),
      flatMap((propertiesUuid: string) => postImages$().pipe(map((newMediaObjectIds?: string[]) => ({propertiesUuid, newMediaObjectIds})))),
      flatMap(postObject$),
      tap(() => this.locationChanged())
    );
  }

  deleteMeasures(uuids: string[]) {
    const deletes$ = uuids.map(uuid => this.apiService.delete(`/jsonapi/data/maatregel/${uuid}`));
    return forkJoin(deletes$).pipe(defaultIfEmpty([]));
  }

  deleteMeasureFromLocation(measure: Measure, objectUuid: string, currentMeasures: Measure[]) {
    const measureUuids = currentMeasures.filter(x => x.__uuid !== measure.__uuid).map(x => x.__uuid);

    const fileIds = measure.field_afbeelding.map(x => x.field_media_image?.__uuid);
    const mediaObjectIds = measure.field_afbeelding.map(x => x.__uuid);

    return this.deleteImages(fileIds, mediaObjectIds).pipe(
      flatMap(() => this.deleteMeasures([measure.__uuid])),
      flatMap(() => {
        const relations = {
          field_maatregelen: jsonApiUtils.createRelation(measureUuids, 'data--maatregel'),
        } as any;
        const body = jsonApiUtils.createBody(objectUuid, 'data--object', {}, relations);
        return this.apiService.patch(`/jsonapi/data/object/${objectUuid}`, body);
      })
    );
  }


  // ---

  update(location: any, update: LocationUpdate) {

    const object = location.field_object?.[0];
    const contact = object?.field_ref_contactgegevens;

    let imageId;
    let contactId = contact?.__uuid;
    let objectId = object?.__uuid;

    const hasContact = !!contactId;
    const hasObject = !!objectId;

    const attributes = {
      title: update.title,
      field_adres: {
        ...location.field_adres,
        address_line1: update.address,
        postal_code: update.postalCode,
        locality: update.locality,
      }
    };

    const contactAttributes = {
      title: contact?.title ?? update.title,
      field_e_mail_adres: contact?.field_e_mail_adres,
      field_telefoon: contact?.field_telefoon,
      field_website: {
        title: contact?.field_website?.title ?? update.website,
        options: contact?.field_website?.options ?? [],
        uri: update.website
      }
    };

    const maybePostImage$ = update.image ? this.apiService.postFile('/jsonapi/node/locatie/field_afbeelding', update.image) : of({});

    const updateContact$ = () => {
      if (hasContact) {
        const contactBody = jsonApiUtils.createBody(contactId, 'data--contactgegevens', contactAttributes);
        return this.apiService.patch(`/jsonapi/data/contactgegevens/${contactId}`, contactBody);
      } else {
        const contactBody = jsonApiUtils.createBody(undefined, 'data--contactgegevens', contactAttributes);
        return this.apiService.post(`/jsonapi/data/contactgegevens`, contactBody);
      }
    };

    const updateObject$ = () => {
      const objectRelations = {field_ref_contactgegevens: jsonApiUtils.createRelation([contactId], 'data--contactgegevens')};
      if (hasObject) {
        if (hasContact) {
          return of({});
        }
        const objectBody = jsonApiUtils.createBody(objectId, 'data--object', undefined, objectRelations);
        return this.apiService.patch(`/jsonapi/data/object/${objectId}`, objectBody);
      } else {
        const objectAttributes = {
          title: update.title,
          field_object_soort: 'bedrijf',
        };
        const objectBody = jsonApiUtils.createBody(undefined, 'data--object', objectAttributes, objectRelations);
        return this.apiService.post(`/jsonapi/data/object`, objectBody);
      }
    };

    const updateLocation$ = () => {
      const relations = {} as any;
      if (!hasObject) {
        relations.field_object = jsonApiUtils.createRelation([objectId], 'data--object');
      }
      if (update.image) {
        relations.field_afbeelding = jsonApiUtils.createRelation([imageId], 'file--file');
      }

      const body = jsonApiUtils.createBody(location.__uuid, 'node--locatie', attributes, relations);
      console.log('body', body);
      if (body.data.attributes.field_titel_suggestie) {
        delete (body.data.attributes.field_titel_suggestie);
      }
      return this.apiService.patch('/jsonapi/node/locatie/' + location.__uuid, body);
    };

    return maybePostImage$.pipe(
      tap((res: any) => imageId = imageId ?? res?.data?.id),
      flatMap(updateContact$),
      tap((res: any) => contactId = contactId ?? res?.data?.id),
      flatMap(updateObject$),
      tap((res: any) => objectId = objectId ?? res?.data?.id),
      flatMap(updateLocation$),
      tap(() => this.locationChanged())
    );
  }

  public deleteImages(fileIds: string[], medioObjectIds: string[], mediaType?: string) {
    mediaType = mediaType || 'afbeelding';
    const deleteFiles$ = compact(fileIds).map(id => this.apiService.delete(`/jsonapi/file/file/${id}`));
    const deleteMediaObjects$ = compact(medioObjectIds).map(id => this.apiService.delete(`/jsonapi/media/${mediaType}/${id}`));
    return forkJoin(concat(deleteFiles$, deleteMediaObjects$)).pipe(defaultIfEmpty([]));
  }
}
