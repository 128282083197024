<div class="dropzone">
    <ngx-dropzone (change)="onSelect($event)" #drop disableClick="true" [multiple]="multiple !== false"
        accept="image/jpeg,image/jpg,image/png,image/gif,application/pdf" [class.danger]="status === 'danger'">
        <ngx-dropzone-label>Sleep bestand<ng-container *ngIf="multiple">en</ng-container> om te uploaden of <a href="javascript:void(0)"
                (click)="drop.showFileSelector()">selecteer een bestand</a></ngx-dropzone-label>
    </ngx-dropzone>
    <span *ngIf="multiple" [class]="highlight ? 'info info-highlight' : 'info'">Een onbeperkt aantal bestanden kan naar dit veld worden geupload. 8 MB limiet. Toegestane types:
        gif, jpg, jpeg, png, pdf. <ng-container *ngIf="highlight">De afbeelding die je graag wilt tonen in het overzicht kun je 'uitlichten'.</ng-container></span>
    <span *ngIf="!multiple" class="info">8 MB limiet. Toegestane types: gif, jpg, jpeg, png, pdf.</span>
    <div class="previews">
        <app-upload-dropzone-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f"
            [removable]="true" [highlight]="highlight" [isHighlighted]="isHighlighted(f)" (highlightChanged)="onHighlightChange($event)" (removed)="onRemove(f)">
        </app-upload-dropzone-preview>
    </div>
</div>