import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit {

  @Input()
  commentData;

  @Input()
  user;

  showReplyForm = false;

  constructor() {
  }

  ngOnInit(): void {
    //console.log(this.user);
  }

  toggleReplyForm() {
    if (!this.showReplyForm) {
      this.showReplyForm = true;
    } else {
      this.showReplyForm = false;
    }
  }

}
