import {Component, Inject, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'app-my-visitor-information',
  templateUrl: './my-visitor-information.component.html',
  styleUrls: ['./my-visitor-information.component.scss']
})
export class MyVisitorInformationComponent implements OnInit {
  get pdb() {
    return this.project === 'platformduurzamebedrijven';
  }

  get tkr() {
    return this.project === 'tuinenklimaatroute';
  }

  get vd() {
    return this.project === 'vd';
  }

  get ndhr() {
    return this.project === 'ndhr';
  }

  registrationData;
  date;
  time;

  constructor(protected dialogRef: NbDialogRef<any>, @Inject('project') private project: string) {
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
