<div class="comment--wrapper row" *ngIf="commentData">
  <div class="col-md-2 left">
    <app-comment-user [user]="user"></app-comment-user>
  </div>
  <div class="col-md-9 right" *ngIf="commentData.attributes">
    <div class="comment--header">
      <p class="comment--subject">{{commentData.attributes.subject}}</p>
      <p
        class="comment--date">{{commentData.attributes.created | date: 'd MMMM y H:mm': 'Europe/Amsterdam': 'nl-NL'}}</p>
    </div>
    <div class="comment--body" [innerHTML]="commentData.attributes.comment_body.processed"></div>
    <div class="comment--actions">
      <a class="actions--reply" (click)="toggleReplyForm()">
        <span *ngIf="!showReplyForm">Beantwoorden</span>
        <span *ngIf="showReplyForm">Annuleren</span>
      </a>
    </div>
  </div>
  <div class="reply-form" *ngIf="showReplyForm">
    <app-comment-reply [repliedComment]="commentData"></app-comment-reply>
  </div>
</div>
