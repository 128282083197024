<nb-card>
  <nb-card-header>Account verifiëren</nb-card-header>
  <nb-card-body>
    <div class="verifying-account" *ngIf="!verified && !error">
      <p>Uw account wordt geverifieerd...</p>
    </div>
    <div class="verified-account" *ngIf="verified">
      <p>Uw account is geactiveerd, u kunt nu <a routerLink="/">inloggen</a>.</p>
    </div>
    <div class="error" *ngIf="error">
      <p>{{errorMessage}}</p>
    </div>
  </nb-card-body>
</nb-card>
