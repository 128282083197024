import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

import {
  SubsidyRequest,
  SubsidyRequestStatus,
  SubsidyRequestVdStatus
  } from 'src/app/models/subsidy.models';
import {AuthService} from 'src/app/services/auth/auth.service';
import {SubsidyService} from 'src/app/services/subsidy/subsidy.service';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';

@Component({
  selector: 'app-submit-subsidy-request-dialog',
  templateUrl: './submit-subsidy-request-dialog.component.html',
  styleUrls: ['./submit-subsidy-request-dialog.component.scss', '../../../shared/shared.scss']
})
export class SubmitSubsidyRequestDialogComponent implements OnInit, OnDestroy {

  request: SubsidyRequest;

  isSaving = false;
  hasSubmitted = false;
  checkTruthful = false;
  checkCorrectData = false;

  get canSubmit() {
    return !this.isSaving && this.checkTruthful && this.checkCorrectData;
  }

  get showDisclaimer() {
    return this.request.field_status_beoordeling_vd === SubsidyRequestVdStatus.Onvolledig;
  }

  constructor(
    protected ref: NbDialogRef<SubmitSubsidyRequestDialogComponent>,
    private authService: AuthService,
    private subsidyService: SubsidyService,
    private majadaToastrService: MajadaToastrService
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  submit() {
    this.isSaving = true;
    this.subsidyService.updateRequestStatus(
      this.request,
      SubsidyRequestStatus.InBehandelingBijGemeente,
      this.request.field_status_beoordeling_vd,
      this.request.field_status_afhandeling_gem,
      this.request.field_jaar
    ).subscribe(() => {
      this.hasSubmitted = true;
      this.isSaving = false;
      this.majadaToastrService.success('Aanvraag is ingediend', 'Ingediend');
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Aanvraag kon niet ingediend worden');
    });
  }

  close() {
    this.ref.close();
  }

}
