import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Subject, Subscription} from 'rxjs';
import {CommentService} from '../../comment.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MajadaToastrService} from '../../services/toastr/majada-toastr.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {

  commentForm: FormGroup;

  @Input()
  uuid: string;
  @Input()
  nodeType: string;

  @Input()
  comments;
  commentsLoaded = false;
  subscription = new Subscription();

  @Input()
  parentComment;

  pagerData;

  console = console;

  users = [];

  constructor(private apiService: ApiService, private commentService: CommentService, private majadaToastrService: MajadaToastrService) {
  }

  initForm() {
    // Init comment form
    this.commentForm = new FormGroup({
      subject: new FormControl(),
      comment: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {

    // Initialize comment form.
    this.initForm();

    // Get comments on load
    const sub = this.commentService.getComments(this.nodeType, this.uuid).subscribe(comments => {
      // Set commentsLoaded to true to remove loader in template.
      this.commentsLoaded = true;
      // If any user data has been included, map it.
      if (comments.included) {
        comments.included.forEach(inclusion => {
          if (inclusion.type === 'user--user') {
            this.users[inclusion.id] = inclusion;
          } else if (inclusion.type === 'file--file') {
            this.users[inclusion.relationships.uid.data.id].image = inclusion;
          }
        });
      }

      // Update the pager data to the new pagers.
      this.pagerData = comments.links;
      this.comments = comments.data;

    });
    this.subscription.add(sub);

    // Subscribe to changes in comments.
    const commentsChangedSub = this.commentService.commentsChanged$.subscribe(_ => {
      console.log('commentsChanged$ triggered');
      this.getAllComments();
    });
    this.subscription.add(commentsChangedSub);
  }

  private getAllComments() {
    const subCommentsUpdate = this.commentService.getComments(this.nodeType, this.uuid).subscribe(comments => {
      this.commentsLoaded = true;
      this.pagerData = comments.links;
      this.mapComments(comments, true);
      if (this.pagerData.next) {
        this.getNextCommentsPageRecursive();
      }
    });
    this.subscription.add(subCommentsUpdate);
  }

  private getNextCommentsPageRecursive() {
    const nextCommentsPageSub = this.commentService.getNextCommentsPage(this.pagerData.next.href).subscribe(comments => {
      this.mapComments(comments);
      if (this.pagerData.next) {
        this.getNextCommentsPageRecursive();
      }
    });
    this.subscription.add(nextCommentsPageSub);
  }

  private mapComments(comments, resetComments = false) {
    this.pagerData = comments.links;
    if (comments.included) {
      comments.included.forEach(inclusion => {
        if (inclusion.type === 'user--user') {
          this.users[inclusion.id] = inclusion;
        } else if (inclusion.type === 'file--file') {
          this.users[inclusion.relationships.uid.data.id].image = inclusion;
        }
      });
      if (!resetComments) {
        this.comments = this.comments.concat(comments.data);
      } else {
        this.comments = comments.data;
      }
    }
  }

  submitComment() {
    //console.log('uuid', this.uuid);
    const body = {
      data: {
        type: 'comment--reactie',
        attributes: {
          subject: this.commentForm.get('subject').value,
          comment_body: {
            value: this.commentForm.get('comment').value,
            format: 'plain_text'
          },
          entity_type: 'node',
          field_name: 'field_comments'
        },
        relationships: {
          entity_id: {
            data: [
              {
                type: 'node--' + this.nodeType,
                id: this.uuid
              }
            ]
          }
        }
      }
    };

    const sub = this.commentService.postComment(body).subscribe(result => {
      this.majadaToastrService.success('Uw reactie is geplaatst.', 'Opgeslagen');
      this.commentService.commentsChanged();
      this.initForm();
    }, error => {
      this.majadaToastrService.error('Uw reactie kon niet worden geplaatst', 'Fout');
    });
    this.subscription.add(sub);
  }

  loadMoreComments() {
    console.log('try to load more comments');
    console.log(this.pagerData);
    if (this.pagerData && this.pagerData.next) {
      const sub = this.commentService.getNextCommentsPage(this.pagerData.next.href).subscribe(comments => {
        this.mapComments(comments);
      });
      this.subscription.add(sub);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
