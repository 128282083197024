<nb-card>
  <nb-card-header>
    <h3>Subsidie aanvraagformulier</h3>
  </nb-card-header>
  <nb-card-body [nbSpinner]="isLoading" nbSpinnerStatus="basic" class="content">

    <nb-stepper orientation="horizontal" *ngIf="!isComplete" disableStepNavigation="true" [style.visibility]="isLoading ? 'hidden' : 'visible'">
      <nb-step [label]="step1">
        <ng-template #step1><span class="hide-mobile">Situatie gegevens</span></ng-template>
        <app-subsidy-request-form-step1></app-subsidy-request-form-step1>
      </nb-step>

      <nb-step [label]="step2">
        <ng-template #step2><span class="hide-mobile">Subsidie onderdelen</span></ng-template>
        <app-subsidy-request-form-step2></app-subsidy-request-form-step2>
      </nb-step>

      <nb-step [label]="step3">
        <ng-template #step3><span class="hide-mobile">Persoonlijke gegevens</span></ng-template>
        <app-subsidy-request-form-step3></app-subsidy-request-form-step3>
      </nb-step>

      <nb-step [label]="step4">
        <ng-template #step4><span class="hide-mobile">Controle</span></ng-template>
        <app-subsidy-request-form-step4></app-subsidy-request-form-step4>
      </nb-step>
    </nb-stepper>

    <div *ngIf="isComplete" class="complete">
      <nb-icon class="complete-icon" status="primary" icon="checkmark-circle-2-outline"></nb-icon>
      <h3>Uw subsidie aanvraag is voltooid!</h3>
      <p>Binnen 8 weken ontvangt u bericht over uw aanvraag.<br>Check uw mailbox voor de bevestiging.</p>
      <p>U wordt doorgestuurd naar het overzicht met uw aanvraag.</p>
    </div>
  </nb-card-body>
</nb-card>
