<div class="card closable-dialog">
  <nb-card>
    <nb-card-header>Subsidieaanvraag bewerken</nb-card-header>
      <nb-card-body [nbSpinner]="isSaving$ | async" nbSpinnerStatus="basic">
        <div [class.saving]="isSaving$ | async">
          <ng-container *ngIf="showStatus">
            <label class="label" for="select-status">Status</label>
            <nb-select id="select-status" placeholder="Status"[ngModel]="status$ | async" (ngModelChange)="statusModel$.next($event)"
              [disabled]="statusDisabled$ | async">
              <nb-option *ngFor="let i of SubsidyRequestStatusKeys" [value]="i.toString()">
                {{SubsidyRequestStatusLabels[i.toString()]}}</nb-option>
            </nb-select>
          </ng-container>

          <ng-container *ngIf="showVdStatus">
            <label class="label" for="select-review-status">Beoordeling VD</label>
            <nb-select id="select-review-status" placeholder="Beoordeling VD" [ngModel]="vdStatusModel$ | async" (ngModelChange)="vdStatusModel$.next($event)">
              <nb-option [value]="null">Niet beoordeeld</nb-option>
              <nb-option *ngFor="let i of SubsidyRequestVdStatusKeys" [value]="i.toString()">
                {{SubsidyRequestVdStatusLabels[i.toString()]}}</nb-option>
            </nb-select>
          </ng-container>

          <ng-container *ngIf="showMunicipalityStatus">
            <label class="label" for="select-status">Afhandeling Gemeente</label>
            <nb-select id="select-status" placeholder="Afhandeling Gemeente" [ngModel]="municipalityStatusModel$ | async" (ngModelChange)="municipalityStatusModel$.next($event)">
              <nb-option [value]="null">Niet afgehandeld</nb-option>
              <nb-option *ngFor="let i of SubsidyRequestMunicipalityStatusKeys" [value]="i.toString()">
                {{SubsidyRequestMunicipalityStatusLabels[i.toString()]}}</nb-option>
            </nb-select>
          </ng-container>

          <ng-container *ngIf="showYear">
            <label class="label" for="select-status">Subsidie toekennen voor</label>
            <nb-select id="select-status" placeholder="Jaar" [ngModel]="yearModel$ | async" (ngModelChange)="yearModel$.next($event)">
              <nb-option *ngFor="let year of years" [value]="year">
                {{year}}</nb-option>
            </nb-select>
          </ng-container>

        </div>
        <h6>Aanvraaggegevens</h6>
        <dl>
          <dt>Type:</dt><dd>{{SubsidyRequestTypeLabels[request.field_type_aanvraag]}}</dd>
          <dt>Naam:</dt><dd>{{request.field_voornaam}} {{request.field_achternaam}}</dd>
          <dt>Gemeente:</dt><dd>{{SubsidyRequestMunicipalityLabels[request.field_gemeente]}}</dd>
          <dt>Datum:</dt><dd>{{request.created | date : 'd-M-y'}}</dd>
        </dl>
      </nb-card-body>
      <nb-card-footer>
        <div class="card--actions">
          <button *ngIf="showSaveButton" type="button" outline status="success" [disabled]="!(canSave$ | async)" nbButton (click)="save()">Opslaan</button>
          <button *ngIf="showSubmitButton" type="button" outline status="success" [disabled]="!(canSubmit$ | async)" nbButton (click)="submit()">Indienen</button>
        </div>
      </nb-card-footer>
    </nb-card>
    <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
  </div>