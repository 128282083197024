// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  drupalUrl: 'https://dev.cms.veluweduurzaam.nl', // Enter without trailing slash.
  issuerUrl: 'https://dev.cms.veluweduurzaam.nl', // Enter without trailing slash.
  mainMenu: 'main',
  version: '0.0.0',
  project: 'vd',
  buildDate: null,
  googleMapsApiKey: 'AIzaSyB7qaH1qh7ZXD36d-lkw5nK-K2QTV96Xps',
  routeName: 'Duurzame Huizen Route',
  domainUuid: '9209e2da-66a0-48ea-a6c7-00a793165315',
  dashboardCategories: {
    energie: '8c93e8fd-031e-4a06-a868-dfd5b0709b8d', // Energietransitie
    klimaat: '1f228e8e-1399-44de-b8b1-9a1bd215183c',
    algemeen: '0fcd59fb-1997-4322-b42e-4eff3cbdcc19',
    energieCoach: '18e799bb-d7a5-499c-b2fb-7df88e3a31b6',
    procesbegeleider: 'fbeafac0-c62b-4edc-8869-edc8adf2a177',
    advisor: 'cc57d12b-b5a2-4c43-a1c9-46cbf440eb10',
    tuinCoach: '9424a553-fb70-461f-aaa7-28fdfe308d25'
  },
  frontendUrl: 'https://dev.mijn.veluweduurzaam.nl',
  wordpressUrl: 'https://veluweduurzaam.nl',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
