<div class="card closable-dialog">
  <nb-card>
    <nb-card-header>Notities voor aanvraag</nb-card-header>
      <nb-card-body [nbSpinner]="showSpinner" nbSpinnerStatus="basic">
				<app-add-subsidy-request-log 
					[subsidyId]="subsidyId"
					[internalDrupalId]="request.drupal_internal__id"
					(newLogEvent)="addLog($event)"
					*ngIf="!showSpinner"></app-add-subsidy-request-log>
				<div *ngIf="logs$ | async as logs">
					<nb-chat *ngIf="logs.length; else noLogs" [scrollBottom]="false">  
					  <nb-chat-message *ngFor="let log of logs"
							[title]="null"
							[message]="getMessage(log)"
							[sender]="log.uid.display_name"
							[date]="log.created"
							dateFormat="dd-MM-yyyy hh:mm"
							>
						</nb-chat-message>
					</nb-chat>					
					<ng-template #noLogs>
						<p class="no-messages-placeholder">
							Er zijn nog geen notities voor deze aanvraag.
						</p>
					</ng-template>
				</div>
      </nb-card-body>
    </nb-card>
  <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
</div>


