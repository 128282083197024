import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {UserService} from '../../services/user/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import * as jsonApiUtils from '../../utils/json-api.utils';
import {difference, isDate, keys, mapValues, omit} from 'lodash-es';
import {MajadaToastrService} from '../../services/toastr/majada-toastr.service';

@Component({
  selector: 'app-profile-edit-form',
  templateUrl: './profile-edit-form.component.html',
  styleUrls: ['./profile-edit-form.component.scss',]
})
export class ProfileEditFormComponent implements OnInit {

  formGroup: FormGroup;
  userData;
  isLoading = false;

  constructor(private authService: AuthService, private userService: UserService, private majadaToastrService: MajadaToastrService) {
    this.formGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      mail: new FormControl('', Validators.required),
      field_18_years_or_older: new FormControl(false, Validators.requiredTrue),
      field_address_line1: new FormControl(null, Validators.required),
      field_address_line2: new FormControl(null, Validators.required),
      field_family_name: new FormControl(null, Validators.required),
      field_given_name: new FormControl(null, Validators.required),
      field_initials: new FormControl(null, Validators.required),
      field_locality: new FormControl(null, Validators.required),
      field_phone_number: new FormControl(null, Validators.required),
      field_postal_code: new FormControl(null, Validators.required),
      new_password: new FormControl(null, Validators.required),
      old_password: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.authService.identityClaims && this.authService.isAuthenticated$) {
      this.userService.getUser().subscribe(user => {
        this.formGroup.get('mail').setValue(user.mail);
        this.formGroup.get('name').setValue(user.display_name);
        this.formGroup.get('field_address_line1').setValue(user.field_address_line1);
        this.formGroup.get('field_address_line2').setValue(user.field_address_line2);
        this.formGroup.get('field_family_name').setValue(user.field_family_name);
        this.formGroup.get('field_given_name').setValue(user.field_given_name);
        this.formGroup.get('field_initials').setValue(user.field_initials);
        this.formGroup.get('field_locality').setValue(user.field_locality);
        this.formGroup.get('field_phone_number').setValue(user.field_phone_number);
        this.formGroup.get('field_postal_code').setValue(user.field_postal_code);
        this.formGroup.get('field_18_years_or_older').setValue(user.field_18_years_or_older);
        this.formGroup.updateValueAndValidity();
        this.userData = user;
        this.isLoading = false;
      });
    }
  }

  saveProfile() {
    const data = mapValues(omit(this.formGroup.getRawValue(), 'field_iban', 'field_iban_tnv', 'field_email')) as any;
    // Manually process passwords, these have a different structure from other fields.
    if(data.new_password && data.old_password) {
      data['pass'] = {value: data.new_password, existing: data.old_password};
      delete data.new_password;
      delete data.old_password;
    }
    this.isLoading = true;
    this.userService.updateCurrentUser(data).subscribe(() => {
      this.isLoading = false;
      this.majadaToastrService.success('Uw profiel is bijgewerkt', 'Bijgewerkt');
    }, err => {
      console.error(err);
      this.isLoading = false;
      this.majadaToastrService.error('Uw profiel kon niet worden bijgewerkt');
    });
  }


}
