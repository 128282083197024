import {AfterContentChecked, Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {isPlatformBrowser, Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NodeService} from '../../services/node/node.service';
import {MetatagsService} from '../../services/metatag/metatags.service';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-drupal-node',
  templateUrl: './drupal-node.component.html',
  styleUrls: ['./drupal-node.component.scss']
})
export class DrupalNodeComponent implements OnInit, OnDestroy, AfterContentChecked {

  private keyName;

  found: boolean;
  node = null;
  nodeData = null;
  highlight = null;
  headerImages = null;
  files = null;
  environment = environment;
  scripts = null;
  iframe = '';

  private subscription = new Subscription();
  nodeType: string;

  breadcrumbs = [];

  // currentTags = new Map<string>();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private nodeService: NodeService,
    private metaService: MetatagsService,
    @Inject('drupalUrl') public drupalUrl: string
  ) {

    this.route.url.subscribe(url => {
      this.breadcrumbs = [];
      this.getNode();

    });

  }

  getNode() {

    this.breadcrumbs.push({label: 'Home', url: '/'});


    const subscription = this.nodeService.getNode().subscribe(data => {
        // console.log(data);
        this.node = null;
        this.nodeData = data;

        this.mapData(data);
      },
      error => {
        console.log(error);
        // if (error.status == 404) {
        // console.log('404, redirect to 404 component');
        // this.router.navigateByUrl('/404');

        this.found = false;
        this.node = null;

        // }
      });
    // this.breadcrumbProvider.addItem(this.node.title[0]['value']);

    this.subscription.add(subscription);
  }

  private mapData(data) {
    this.found = true;
    this.node = data.node;

    if (this.node.field_formulier_embed && this.node.field_formulier_embed[0].value) {
      this.parseFormEmbedScripts();
    }

    this.nodeType = data.node.type[0].target_id;

    if (this.nodeData.header_images) {
      this.headerImages = this.nodeData.header_images;
    }

    if (this.nodeData.file_downloads) {
      this.files = this.nodeData.file_downloads;
    }

    this.breadcrumbs.push({label: this.node.title[0].value});

    // Set meta title.
    this.metaService.setTitleNoSiteName(this.node.title[0]['value']);

    // Set meta tags if provided.
    // TODO: Define default values for description / title etc if not set.
    if (this.node != undefined && this.node.metatag != undefined) {
      this.metaService.setMetatags(this.node.metatag, true);
    }
  }

// This gets handled by the resolver.
  ngOnInit() {


    // this.breadcrumbProvider.addItem(this.node.title[0]['value'], '/');
  }

  ngAfterContentChecked() {

    // console.log('change');
    // this.breadcrumbProvider.addItem(this.node.title[0]['value'] + 'AfterViewInit', '/');
  }

  ngOnDestroy() {
    // console.log('destroy Node Component');
    // this.removeMetatags();
    //this.metaService.removeMetatags();
    this.subscription.unsubscribe();
  }

  parseFormEmbedScripts() {
    this.iframe = '';
    this.scripts = '';
    const scriptRegex = new RegExp('<script.*?src="(.*?)"');
    const scriptRegexResult = scriptRegex.exec(this.node.field_formulier_embed[0].value);
    if (scriptRegexResult) {
      this.scripts = 'https:' + scriptRegexResult[1];
    }
    const iframeRegex = new RegExp('<iframe[^>]*>(.*?)<\/iframe>');
    const iframeRegexResult = iframeRegex.exec(this.node.field_formulier_embed[0].value);
    if (iframeRegexResult && document) {
      console.log('iframeRegexResult', iframeRegexResult);
      const temp = document.createElement('div');
      // @ts-ignore
      temp.innerHTML = iframeRegexResult;
      const iframe = temp.getElementsByTagName('iframe')[0];
      const htmlObject = temp.firstChild;
      // @ts-ignore
      //htmlObject.src = htmlObject.src + '?source=' + this.document.location.hostname;
      // @ts-ignore
      this.iframe = htmlObject.outerHTML;
      console.log('this.iframe', this.iframe);
    }
  }

  /*    private removeMetatags() {
          // Hacky.  Unset all existing metatags
          var current_tags = this.meta.getTags('name');
          let self = this;
          if (current_tags) {
              Object.keys(current_tags).forEach(function (key) {
                  if (current_tags[key].name != 'viewport') {
                      self.meta.removeTagElement(current_tags[key]);
                  }
              });
          }
      }

      setMetatags() {
          //var tags = this.meta.getTags();
          //console.log(tags);
          var self = this;
          if (this.node != undefined && this.node.metatag != undefined) {

              this.removeMetatags();

              // Set all new metatags.
              var new_tags = this.node.metatag.value;
              Object.keys(new_tags).forEach(function (key) {
                  if (self.meta.getTag('name=' + key)) {
                      self.meta.updateTag({name: key, content: new_tags[key]});
                  } else {
                      self.meta.addTag({name: key, content: new_tags[key]});
                  }
              });
          }
      }*/
}
