import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {MajadaToastrService} from '../../services/toastr/majada-toastr.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private http: HttpClient, private toastrService: MajadaToastrService, @Inject('drupalUrl') private drupalUrl: string, @Inject('project') private project: string) {
    this.registerForm = new FormGroup({
      // username: new FormControl('', Validators.required),
      givenName: new FormControl('', Validators.required),
      familyName: new FormControl('', Validators.required),
      phoneNumber: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', Validators.required),
      passwordConfirm: new FormControl('', [Validators.required, RegisterComponent.matchValues('password')]),
      privacy: new FormControl(false, [Validators.requiredTrue])
    });
    this.registerForm.get('password').valueChanges.subscribe(() => {
      this.registerForm.get('passwordConfirm').updateValueAndValidity();
    });

    switch (this.project) {
      case 'vd':
        this.privacyUrl = 'https://veluweduurzaam.nl/privacybeleid';
        break;
      case 'ndhr':
        this.privacyUrl = 'https://duurzamehuizenroute.nl/privacyvoorwaarden';
        break;
      case 'tuinenklimaatroute':
        this.privacyUrl = 'https://tuinenklimaatroute.nl/privacyvoorwaarden';
        break;
      case 'platformduurzamebedrijven':
        this.privacyUrl = 'https://platformduurzamebedrijven.nl/privacybeleid/';
        break;
    }
  }

  registerForm: FormGroup;
  registrationSuccess = false;
  verifying = false;
  privacyUrl = '';

  public static matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
        ? null
        : {isMatching: false};
    };
  }

  get showValidationErrors() {
    const touched = this.registerForm.get('password').touched && this.registerForm.get('passwordConfirm').touched;
    return touched && !this.registerForm.get('passwordConfirm').valid;
  }

  doRegister(): void {
    const body = {
      field_given_name: this.registerForm.get('givenName').value,
      field_family_name: this.registerForm.get('familyName').value,
      field_phone_number: this.registerForm.get('phoneNumber').value,
      pass: this.registerForm.get('password').value,
      mail: this.registerForm.get('email').value,
      privacy_akkoord: this.registerForm.get('privacy').value,
      field_brondomein: environment.domainUuid
    };

    this.http.post(this.drupalUrl + '/rest/create-account?_format=json', body).subscribe(result => {
      console.log('register user result', result);
      this.registrationSuccess = true;
    }, error => {
      this.toastrService.error(error.error.message, 'Account kon niet worden aangemaakt');
      console.log('register user error', error);
    });

  }


  ngOnInit(): void {
  }

}
