
import {AttributesBase, DrupalFile, User} from './drupal-datatypes.models';
import {concat, isNil, isNumber, mapValues} from 'lodash-es';

export enum SubsidyRequestSituation {
  Koopwoning = 'koopwoning',
  HuurhuisMetToestemming = 'huurhuis',
  VVE = 'vve',
  EigenBedrijfspand = 'eigen_bedrijfspand',
  GehuurdBedrijfspandMetToestemming = 'gehuurd_bedrijfspand'
}

export const SubsidyRequestSituationLabels = {
  koopwoning: 'Koopwoning',
  huurhuis: 'Huurhuis met toestemming',
  vve: 'VVE',
  eigen_bedrijfspand: 'Eigen bedrijfspand',
  gehuurd_bedrijfspand: 'Gehuurd bedrijfspand met toestemming',
};

export enum SubsidyRequestMunicipality {
  Elburg = 'elburg',
  Ermelo = 'ermelo',
  Harderwijk = 'harderwijk',
  Hattem = 'hattem',
  Nunspeet = 'nunspeet',
  Oldebroek = 'oldebroek',
  Putten = 'putten',
}

export const SubsidyRequestMunicipalityLabels = {
  elburg:     'Elburg',
  ermelo:     'Ermelo',
  harderwijk: 'Harderwijk',
  hattem:     'Hattem',
  nunspeet:   'Nunspeet',
  oldebroek:  'Oldebroek',
  putten:     'Putten',
};

export enum SubsidyRequestStatus {
  Opgeslagen = 'ingediend',
  WordtGecontroleerd = 'in_behandeling_vd',
  InBehandelingBijGemeente = 'in_behandeling_gemeente',
  Geannuleerd = 'geannuleerd',
  Afgehandeld = 'afgehandeld',
  Beoordeeld = 'beoordeeld'
}

export const SubsidyRequestStatusLabels = {
  ingediend: 'Opgeslagen',
  in_behandeling_vd: 'Wordt gecontroleerd',
  in_behandeling_gemeente: 'In behandeling bij gemeente',
  geannuleerd: 'Geannuleerd',
  afgehandeld: 'Afgehandeld',
  beoordeeld: 'Beoordeeld'
};

export enum SubsidyRequestVdStatus {
  Volledig = 'volledig',
  Onvolledig = 'onvolledig',
}

export const SubsidyRequestVdStatusLabels = {
  volledig: 'Volledig',
  onvolledig: 'Onvolledig',
};

export const SubsidyRequestVdStatusDescriptions = {
  volledig: 'Aanvraag is volledig',
  onvolledig: 'Aanvraag is onvolledig'
};

export enum SubsidyRequestMunicipalityStatus {
  Uitbetaald = 'uitbetaald',
  Afgewezen = 'afgewezen',
}

export const SubsidyRequestMunicipalityStatusLabels = {
  uitbetaald: 'Uitbetaald',
  afgewezen: 'Afgewezen',
};

export const SubsidyRequestVdControlStatuses = {
  empty: 'basic',
  volledig: 'success',
  onvolledig: 'warning'
};

export const SubsidyRequestMunicipalityControlStatuses = {
  empty: 'basic',
  uitbetaald: 'success',
  afgewezen: 'warning'
};

export const SubsidyRequestMunicipalityStatusDescriptions = {
  afgewezen: 'De aanvraag is afgewezen door de gemeente',
  uitbetaald: 'De subsidie is uitbetaald'
};

export const SubsidyRequestMunicipalityStatusIcons = {
  uitbetaald: 'credit-card-outline',
  afgewezen: 'close-outline'
};

export enum SubsidyRequestType {
  Vooraanvraag = 'vooraanvraag',
  Definitief = 'definitief',
}

export const SubsidyRequestTypeLabels = {
  vooraanvraag: 'Vooraanvraag',
  definitief: 'Definitief',
};


export enum SubsidyGardenLocation {
  Voortuin = 'voortuin',
  Achtertuin = 'achtertuin',
  VoorEnAchtertuin = 'voor_en_achtertuin',
}

export const SubsidyGardenLocationLabels = {
  voortuin: 'Voortuin',
  achtertuin: 'Achtertuin',
  voor_en_achtertuin: 'Voor- en achtertuin',
};


export enum SubsidyGardenArea {
  LessThan50 = 'minder_dan_50',
  Between50And200 = '50_200',
  MoreThan200 = 'meer_dan_200',
}

export const SubsidyGardenAreaLabels = {
  minder_dan_50: '< 50m² (o.a. voortuinen)',
  '50_200': '50 – 200 m²',
  meer_dan_200: '> 200 m²',
};



export interface SubsidyRequestFields {
  uid?: User;
  field_jaar: number;
  field_aanvraag_status: SubsidyRequestStatus;
  field_status_afhandeling_gem: SubsidyRequestMunicipalityStatus;
  field_status_beoordeling_vd: SubsidyRequestVdStatus;
  field_indiendatum: Date;

  // Step 1
  field_type_aanvraag: SubsidyRequestType;
  field_gemeente: string;
  field_situatie: SubsidyRequestSituation;
  field_binnen_bebouwde_kom: boolean;
  field_gebouwd_voor_2015: boolean;
  field_extra_opmerkingen?: string;
  field_vergunning?: DrupalFile[];
  field_akkoord_verhuurder?: DrupalFile[];

  // Step 2.1
  field_regenton: string;
  field_regenschutting: string;
  field_regenton_aanschafkosten: string;
  field_regenton_totale_subsidie: string;
  field_regenton_min_m2: boolean;
  field_regenton_minimaal_inhoud: boolean;
  field_regenton_uitvoeren_in: string;
  field_regenton_foto?: DrupalFile[];
  field_regenton_schets?: DrupalFile[];
  field_regenton_bon?: DrupalFile[];
  field_regenton_datum_van_aankoop: Date;
  field_regenton_foto_uitvoeren?: DrupalFile[];
  field_regenton_datum_uitvoering: Date;

  // Step 2.2
  field_regenpijp_doorgezaagd: number;
  field_infiltratiekrat: string;
  field_aanleggen_infiltratieveld: string;
  field_afkoppelen_m3: string;
  field_afkoppelen_totale_subsidie: string;
  field_infiltratiekrat_liters: string;
  field_afkoppelen_aantal_m2: string;
  field_minimale_berging: boolean;
  field_afgekoppelde_oppervlakte: boolean;
  field_niet_aangesloten_drukriool: boolean;
  field_afkoppelen_uitvoeren_in: string;
  field_infiltratiekrat_foto?: DrupalFile[];
  field_afkoppelen_foto?: DrupalFile[];
  field_afkoppelen_schets?: DrupalFile[];
  field_afkoppelen_bon?: DrupalFile[];
  field_afkoppelen_datum_aankoop: Date;
  field_afkoppelen_foto_uitvoeren?: DrupalFile[];
  field_afkoppelen_datum_uitvoeren: Date;

  // Step 2.3
  field_vergroenen: string;
  field_vergroenen_totale_subsidie: string;
  field_vergroenen_verwijderd: boolean;
  field_vergroenen_foto?: DrupalFile[];
  field_vergroenen_schets?: DrupalFile[];
  field_vergroenen_bon?: DrupalFile[];
  field_vergroenen_datum_aankoop: Date;
  field_vergroenen_foto_uitvoeren?: DrupalFile[];
  field_vergoenen_datum_uitvoering: Date;

  // Step 2.4
  field_boom_oppervlakte_tuin: string;
  field_boom_een: string;
  field_boom_twee: string;
  field_boom_totale_subsidie: string;
  field_boom_foto?: DrupalFile[];
  field_boom_schets?: DrupalFile[];
  field_boom_bon?: DrupalFile[];
  field_boom_datum_aankoop: Date;
  field_boom_foto_uitvoeren?: DrupalFile[];
  field_boom_datum_uitvoering: Date;

  // Step 2.5
  field_groendak: string;
  field_groendak_laagdikte_8: string;
  field_groendak_laagdikte_20: string;
  field_groendak_laagdikte_40: string;
  field_groenblauwdak: string;
  field_groenblauwdak_laagdikte_8: string;
  field_groenblauwdak_laagdikte_20: string;
  field_groenblauwdak_laagdikte_40: string;
  field_waterberging_18_liter_m2: string;
  field_waterberging_30_liter_m2: string;
  field_waterberging_50_liter_m2: string;
  field_waterberging_30l_grassen: string;
  field_waterberging_50l_grassen: string;
  field_dak_keuringskosten: string;
  field_groendak_totale_subsidie: string;
  field_groendak_minimum_oppervlak: boolean;
  field_groendak_foto?: DrupalFile[];
  field_groendak_schets?: DrupalFile[];
  field_groendak_bon?: DrupalFile[];
  field_groendak_datum_aankoop: Date;
  field_groendak_foto_uitvoeren?: DrupalFile[];
  field_groendak_datum_uitvoering: Date;

  // Step 2.6
  field_hemelwatervoorziening: string;
  field_hemelwater_m2_afgekoppeld: string;
  field_inhoud_voorziening_liters: string;
  field_hemelwater_totale_subsidie: string;
  field_hemelwater_gebruik: boolean;
  field_hemelwater_afkoppel_riool: boolean;
  field_minimaal_1000l_gebuffer: boolean;
  field_hemelwater_uitvoeren_in: string;
  field_hemelwater_foto?: DrupalFile[];
  field_hemelwater_schets?: DrupalFile[];
  field_hemelwater_bon?: DrupalFile[];
  field_hemelwater_datum_aankoop: Date;
  field_hemelwater_foto_uitvoeren?: DrupalFile[];
  field_hemelwater_datum_uitvoeren: Date;

  // Step 3
  field_voornaam: string;
  field_achternaam: string;
  field_iban: string;
  field_iban_tnv: string;

  // Step 4
  field_naar_waarheid_ingevuld: boolean;
  field_onjuiste_gegevens: boolean;
  field_aanmelden_nieuwsbrief: boolean;
  field_algemene_voorwaarden: boolean;
}

export function parseSubsidyRequestAPIResult(req: SubsidyRequest) {
  const formatNumber = (x: number) => x.toString().replace('.', ',');
  const parseAsFormattedNumber = (x: number | string) => isNil(x) ? '0' : formatNumber(isNumber(x) ? x : parseFloat(x as string));
  const parseAsNumber = (x: number | string) => isNil(x) ? 0 : (isNumber(x) ? x : parseFloat(x as string));
  const parseBoom = (x: number | string) => x === '-1' || x === '0' ? parseFloat(x) : isNil(x) ? -1 : x;

  const numberFieldsStep1 = ['field_regenton', 'field_regenton_aanschafkosten', 'field_regenschutting', 'field_regenton_totale_subsidie'];
  const numberFieldsStep2 = ['field_regenpijp_doorgezaagd', 'field_infiltratiekrat', 'field_aanleggen_infiltratieveld',
    'field_afkoppelen_m3', 'field_afkoppelen_totale_subsidie', 'field_infiltratiekrat_liters', 'field_afkoppelen_aantal_m2'];
  const numberFieldsStep3 = ['field_vergroenen', 'field_vergroenen_totale_subsidie'];
  const numberFieldsStep4 = ['field_boom_totale_subsidie'];
  const boomFieldsStep4 = ['field_boom_een', 'field_boom_twee'];
  const numberFieldsStep5 = ['field_groendak', 'field_groendak_laagdikte_8', 'field_groendak_laagdikte_20', 'field_groendak_laagdikte_40',
    'field_groenblauwdak', 'field_groenblauwdak_laagdikte_8', 'field_groenblauwdak_laagdikte_20', 'field_groenblauwdak_laagdikte_40',
    'field_waterberging_18_liter_m2', 'field_waterberging_30_liter_m2', 'field_waterberging_50_liter_m2', 'field_waterberging_30l_grassen',
    'field_waterberging_50l_grassen', 'field_dak_keuringskosten', 'field_groendak_totale_subsidie'];
  const numberFieldsStep6 = ['field_hemelwatervoorziening', 'field_hemelwater_m2_afgekoppeld', 'field_inhoud_voorziening_liters',
    'field_hemelwater_totale_subsidie'];
  const numberFields = concat(numberFieldsStep1, numberFieldsStep2, numberFieldsStep3,
    numberFieldsStep4, numberFieldsStep5, numberFieldsStep6);

  return mapValues(req, (val, field) => {
    if (numberFields.includes(field) && field.endsWith('_subsidie')) return parseAsNumber(val as number | string);
    if (numberFields.includes(field) && !field.endsWith('_subsidie')) return parseAsFormattedNumber(val as number | string);
    if (subsidyRequestImageFields.includes(field)) return (val as DrupalFile[])?.map(x => x.__uuid);
    if (boomFieldsStep4.includes(field)) return parseBoom(val as string);
    return val;
  }) as any;
}

export type SubsidyRequest = AttributesBase & SubsidyRequestFields;

export const subsidyRequestStep2Fields = {
  part1: [
    'field_regenton',
    'field_regenton_aanschafkosten',
    'field_regenschutting',
    'field_regenton_totale_subsidie',
    'field_regenton_min_m2',
    'field_regenton_minimaal_inhoud',
    'field_regenton_uitvoeren_in',
    'field_regenton_foto',
    'field_regenton_schets',
    'field_regenton_bon',
    'field_regenton_datum_van_aankoop',
    'field_regenton_foto_uitvoeren',
    'field_regenton_datum_uitvoering',
  ],
  part2: [
    'field_regenpijp_doorgezaagd',
    'field_infiltratiekrat',
    'field_aanleggen_infiltratieveld',
    'field_afkoppelen_m3',
    'field_afkoppelen_totale_subsidie',
    'field_infiltratiekrat_liters',
    'field_afkoppelen_aantal_m2',
    'field_minimale_berging',
    'field_afgekoppelde_oppervlakte',
    'field_niet_aangesloten_drukriool',
    'field_afkoppelen_uitvoeren_in',
    'field_infiltratiekrat_foto',
    'field_afkoppelen_foto',
    'field_afkoppelen_schets',
    'field_afkoppelen_bon',
    'field_afkoppelen_datum_aankoop',
    'field_afkoppelen_foto_uitvoeren',
    'field_afkoppelen_datum_uitvoeren',
  ],
  part3: [
    'field_vergroenen',
    'field_vergroenen_totale_subsidie',
    'field_vergroenen_verwijderd',
    'field_vergroenen_foto',
    'field_vergroenen_schets',
    'field_vergroenen_bon',
    'field_vergroenen_datum_aankoop',
    'field_vergroenen_foto_uitvoeren',
    'field_vergoenen_datum_uitvoering',
  ],
  part4: [
    'field_boom_oppervlakte_tuin',
    'field_boom_een',
    'field_boom_twee',
    'field_min_plantmaat',
    'field_erfgrensregels',
    'field_boom_totale_subsidie',
    'field_boom_foto',
    'field_boom_schets',
    'field_boom_bon',
    'field_boom_datum_aankoop',
    'field_boom_foto_uitvoeren',
    'field_boom_datum_uitvoering',
  ],
  part5: [
    'field_groendak',
    'field_groendak_laagdikte_8',
    'field_groendak_laagdikte_20',
    'field_groendak_laagdikte_40',
    'field_groenblauwdak',
    'field_groenblauwdak_laagdikte_8',
    'field_groenblauwdak_laagdikte_20',
    'field_groenblauwdak_laagdikte_40',
    'field_waterberging_18_liter_m2',
    'field_waterberging_30_liter_m2',
    'field_waterberging_50_liter_m2',
    'field_waterberging_30l_grassen',
    'field_waterberging_50l_grassen',
    'field_dak_keuringskosten',
    'field_groendak_totale_subsidie',
    'field_groendak_minimum_oppervlak',
    'field_groendak_foto',
    'field_groendak_schets',
    'field_groendak_bon',
    'field_groendak_datum_aankoop',
    'field_groendak_foto_uitvoeren',
    'field_groendak_datum_uitvoering',
  ],
  part6: [
    'field_hemelwatervoorziening',
    'field_hemelwater_m2_afgekoppeld',
    'field_inhoud_voorziening_liters',
    'field_hemelwater_totale_subsidie',
    'field_hemelwater_gebruik',
    'field_hemelwater_afkoppel_riool',
    'field_minimaal_1000l_gebuffer',
    'field_hemelwater_uitvoeren_in',
    'field_hemelwater_foto',
    'field_hemelwater_schets',
    'field_hemelwater_bon',
    'field_hemelwater_datum_aankoop',
    'field_hemelwater_foto_uitvoeren',
    'field_hemelwater_datum_uitvoeren',
  ]
};

export const subsidyRequestImageFields = [
  'field_regenton_foto',
  'field_regenton_schets',
  'field_regenton_bon',
  'field_regenton_foto_uitvoeren',
  'field_afkoppelen_foto',
  'field_afkoppelen_schets',
  'field_infiltratiekrat_foto',
  'field_afkoppelen_bon',
  'field_afkoppelen_foto_uitvoeren',
  'field_vergroenen_foto',
  'field_vergroenen_schets',
  'field_vergroenen_bon',
  'field_vergroenen_foto_uitvoeren',
  'field_boom_foto',
  'field_boom_schets',
  'field_boom_bon',
  'field_boom_foto_uitvoeren',
  'field_groendak_foto',
  'field_groendak_schets',
  'field_groendak_bon',
  'field_groendak_foto_uitvoeren',
  'field_hemelwater_foto',
  'field_hemelwater_schets',
  'field_hemelwater_bon',
  'field_hemelwater_foto_uitvoeren',
  'field_vergunning',
  'field_akkoord_verhuurder',
];

export const subsidyRequestNumberFields = [
  'field_aanleggen_infiltratieveld',
  'field_afkoppelen_aantal_m2',
  'field_hemelwater_m2_afgekoppeld',
  'field_afkoppelen_m3',
  'field_afkoppelen_totale_subsidie',
  'field_boom_totale_subsidie',
  'field_dak_keuringskosten',
  'field_groen_blauw_of_bruindak',
  'field_groendak',
  'field_groendak_laagdikte_8',
  'field_groendak_laagdikte_20',
  'field_groendak_laagdikte_40',
  'field_groenblauwdak',
  'field_groenblauwdak_laagdikte_8',
  'field_groenblauwdak_laagdikte_20',
  'field_groenblauwdak_laagdikte_40',
  'field_groen_dak_m2',
  'field_groendak_totale_subsidie',
  'field_hemelwater_totale_subsidie',
  'field_hemelwatervoorziening',
  'field_infiltratiekrat',
  'field_infiltratiekrat_liters',
  'field_inhoud_voorziening_liters',
  'field_regenpijp_doorgezaagd',
  'field_regenschutting',
  'field_regenton',
  'field_regenton_aanschafkosten',
  'field_regenton_totale_subsidie',
  'field_tochtstrips_meter',
  'field_totale_subsidie',
  'field_vergroenen',
  'field_vergroenen_totale_subsidie',
  'field_waterberging_18_liter_m2',
  'field_waterberging_30_liter_m2',
  'field_waterberging_50_liter_m2',
  'field_waterberging_30l_grassen',
  'field_waterberging_50l_grassen',
];

interface SubsidyLogDescription {
  format: string;
  processed: string;
  value: string;
}

export interface SubsidyLogFields {
  field_oude_status: string;
  field_nieuwe_status: string;
  field_omschrijving: SubsidyLogDescription;
  title: string;
}

export type SubsidyLog = AttributesBase & SubsidyLogFields;
