import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {User} from 'src/app/models/drupal-datatypes.models';
import {LocationOverviewItem} from 'src/app/models/location.models';
import {LocationsService} from 'src/app/services/locations/locations.service';
import {UserService} from 'src/app/services/user/user.service';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  residences: LocationOverviewItem[];
  user: User;

  isAuthenticated: Observable<boolean>;
  isDoneLoading: Observable<boolean>;
  identityClaims;

  private sub = new Subscription();

  get isLoading() {
    return !this.residences || !this.user;
  }

  get profilePhoto() {
    if (!this.user?.field_profielfoto?.uri?.url) {
      return undefined;
    }
    return `${this.drupalUrl}/${this.user?.field_profielfoto?.uri?.url}`;
  }

  constructor(
    protected router: Router,
    protected locationsService: LocationsService,
    protected userService: UserService,
    private authService: AuthService,
    @Inject('drupalUrl') private drupalUrl: string
  ) {
    this.isAuthenticated = this.authService.isAuthenticated$;
    this.isDoneLoading = this.authService.isDoneLoading$;

    this.authService.isAuthenticated$.subscribe(authenticated => {
      if (authenticated && this.authService.identityClaims) {
        const identityClaims = this.authService.identityClaims;
        this.sub.add(this.locationsService.locationChanged$.subscribe(this.loadResidences.bind(this)));
        this.loadUser();
        this.loadResidences();
      }
    });
  }

  ngOnInit(): void {
    /*this.authService.isAuthenticated$.subscribe(authenticated => {
      if (authenticated && this.authService.identityClaims) {
        this.loadUser();
        this.loadResidences();
      }
    });*/
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private loadUser() {
    this.userService.getUser().subscribe(res => {
      this.user = res;
    });
  }

  private loadResidences() {
    if (!this.residences) {
      this.locationsService.getLocations().subscribe(res => {
        this.residences = res;
      });
    }
  }

  public addNewResidence() {
    this.router.navigate([`/mijn-woningen/nieuw`]);
  }

  public viewResidence(residence: LocationOverviewItem) {
    this.router.navigate([`/mijn-woningen/${residence.uuid}`]);
  }

  public editResidence(residence: LocationOverviewItem) {
    this.router.navigate([`/mijn-woningen/bewerken/${residence.uuid}`]);
  }
}
