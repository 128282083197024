import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {compact, isArray, memoize} from 'lodash-es';

import {SubsidyService} from '../../../services/subsidy/subsidy.service';
import {SubsidyFormState, getDisplayFlags} from './subsidy-form-state';
import {
  SubsidyRequestMunicipalityLabels,
  SubsidyRequestTypeLabels,
  SubsidyGardenAreaLabels,
  SubsidyGardenLocationLabels,
  SubsidyRequestSituationLabels,
  SubsidyRequest} from '../../../models/subsidy.models';
import {ApiService} from 'src/app/services/api/api.service';

@Component({
  selector: 'app-subsidy-request-form-step4',
  templateUrl: './subsidy-request-form-step4.component.html',
  styleUrls: ['./subsidy-request-form.component.scss']
})
export class SubsidyRequestFormStep4Component implements OnInit, OnDestroy {

  displayFlags: any = {};
  form: FormGroup;
  subs: Subscription[] = [];

  SubsidyRequestTypeLabels = SubsidyRequestTypeLabels;
  SubsidyRequestSituationLabels = SubsidyRequestSituationLabels;
  SubsidyRequestMunicipalityLabels = SubsidyRequestMunicipalityLabels;
  SubsidyGardenLocationLabels = SubsidyGardenLocationLabels;
  SubsidyGardenAreaLabels = SubsidyGardenAreaLabels;

  formState$ = new BehaviorSubject<SubsidyFormState>({});
  reviewData: any;

  onSubmit$ = new Subject();
  onSave$ = new Subject();
  onSaveResult$ = new Subject();
  isSaving = false;

  getImagePreviews = memoize((uuids: string[]) => {
    return compact(uuids.map(x => this.apiService.getFile(x)));
  }, x => JSON.stringify(x));


  get canSubmit() {
    return this.form.valid && !this.isSaving;
  }

  constructor(
    protected apiService: ApiService,
    protected subsidyService: SubsidyService,
    protected formBuilder: FormBuilder) {
      this.subs.push(this.formState$.subscribe(this.processFormStateUpdate.bind(this)));
  }

  private requiredTrueIfValidator(pred) {
    return formControl => formControl.parent && pred() ? Validators.requiredTrue(formControl) : null;
  }

  ngOnInit(): void {
    this.onSaveResult$.subscribe(this.processSaveResult.bind(this));
    this.form = this.formBuilder.group({
      field_aanmelden_nieuwsbrief: [false],
      field_algemene_voorwaarden: [false, Validators.requiredTrue],
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  fillForm(req: SubsidyRequest) {
    this.form.get('field_aanmelden_nieuwsbrief').setValue(req.field_aanmelden_nieuwsbrief);
    this.form.get('field_algemene_voorwaarden').setValue(req.field_algemene_voorwaarden);
  }

  private processFormStateUpdate(state: SubsidyFormState) {
    if (!state.step1FormData) return;
    this.displayFlags = getDisplayFlags(state);

    this.displayFlags.showGroendakDetailFields =
      parseFloat(state.step2FormData?.groendak_laagdikte_8_subsidie) > 0 ||
      parseFloat(state.step2FormData?.groendak_laagdikte_20_subsidie) > 0 ||
      parseFloat(state.step2FormData?.groendak_laagdikte_40_subsidie) > 0 ||
      parseFloat(state.step2FormData?.groenblauwdak_laagdikte_8_subsidie) > 0 ||
      parseFloat(state.step2FormData?.groenblauwdak_laagdikte_20_subsidie) > 0 ||
      parseFloat(state.step2FormData?.groenblauwdak_laagdikte_40_subsidie) > 0;

    const totalSubsidy = state.step2FormData?.field_hemelwater_totale_subsidie +
                        state.step2FormData?.field_groendak_totale_subsidie +
                        state.step2FormData?.field_boom_totale_subsidie +
                        state.step2FormData?.field_vergroenen_totale_subsidie +
                        state.step2FormData?.field_afkoppelen_totale_subsidie +
                        state.step2FormData?.field_regenton_totale_subsidie;
    this.reviewData = {
      emailAddress: state.emailAddress,
      images: state.images,
      totalSubsidy,
      displayFlags: this.displayFlags,
      municipality: SubsidyRequestMunicipalityLabels[state.step1FormData.field_gemeente],
      ...state.step1FormData,
      ...state.step2FormData,
      ...state.step3FormData,
      user: state.step3UserFormData,
    };
  }

  imageContext(uuid: string | string[]) {
    const uuids = isArray(uuid) ? uuid : [uuid];
    return { images:  this.getImagePreviews(uuids) };
  }

  openImage(url: string) {
    window.open(url);
  }

  formatDate(val?: string) {
    if (!val) return 'niet ingevuld';
    const date = new Date(val);
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }

  save() {
    this.isSaving = true;
    this.onSave$.next();
  }

  submit() {
    this.isSaving = true;
    this.onSubmit$.next();
  }

  private processSaveResult(success: boolean) {
    this.isSaving = false;
  }

  public exportForm() {
    return this.form.getRawValue();
  }

}
