<h1>Mijn inschrijvingen</h1>
<p *ngIf="!registrations && isDoneLoading">Je hebt je nog niet aangemeld voor een bezoek.</p>
<ng-container *ngIf="isDoneLoading && registrations">
  <nb-card *ngFor="let registration of registrations">
    <nb-card-body>
      <app-route-registration-information [registrationData]="registration"></app-route-registration-information>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton status="danger" type="button" (click)="cancel(registration)">Bezoek annuleren</button>
    </nb-card-footer>
  </nb-card>
</ng-container>
