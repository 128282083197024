<div class="card closable-dialog">
  <nb-card>
    <nb-card-header>Subsidieaanvraag indienen bij gemeente</nb-card-header>
      <nb-card-body [nbSpinner]="isSaving" nbSpinnerStatus="basic">
        <ng-container *ngIf="!isSaving && !hasSubmitted">
          <p *ngIf="showDisclaimer"><b>Let op! Uw aanvraag is onvolledig.</b> Wij raden u aan uw aanvraag eerst aan te passen voor u deze verstuurd naar uw gemeente. Wilt u het toch versturen ondanks dat deze onvolledig is, dan kan dit wel en zal de gemeente uw aanvraag beoordelen.</p>
          <nb-checkbox [(checked)]="checkTruthful">Ik heb het formulier naar waarheid ingevuld</nb-checkbox><br>
          <nb-checkbox [(checked)]="checkCorrectData">Het is mij bekend dat opzettelijk vermelden van onjuiste gegevens strafbaar kan zijn</nb-checkbox><br>
        </ng-container>
        <ng-container *ngIf="hasSubmitted && !isSaving">
          <h4>Uw subsidieaanvraag is voltooid!</h4>
          <p>Binnen 4 weken ontvangt u bericht over uw aanvraag.</p>
          <p>Bekijk uw mailbox voor de bevestiging.</p>
        </ng-container>
      </nb-card-body>
      <nb-card-footer>
        <div class="card--actions">
          <button *ngIf="!hasSubmitted" type="button" outline status="success" [disabled]="!canSubmit" nbButton (click)="submit()">Indienen</button>
          <button *ngIf="hasSubmitted" type="button" outline status="basic" nbButton (click)="close()">Sluiten</button>
        </div>
      </nb-card-footer>
    </nb-card>
    <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
  </div>
