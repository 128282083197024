import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RegisterComponent} from './register/register.component';
import {RegistrationAuthGuardService} from '../services/auth/registration-auth-guard.service';
import {VerifyAccountComponent} from './verify-account/verify-account.component';
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {environment} from '../../environments/environment';

let routes: Routes;
if (environment.project !== 'vd') {
  routes = [
    {
      path: 'registreren',
      component: RegisterComponent,
      canActivate: [RegistrationAuthGuardService]
    },
    {
      path: 'registreren/bevestiging',
      component: VerifyAccountComponent,
      canActivate: [RegistrationAuthGuardService]
    },
    {
      path: 'wachtwoord-vergeten',
      component: PasswordResetComponent,
      canActivate: [RegistrationAuthGuardService]
    }];
} else {
  routes = [
    {
      path: 'wachtwoord-vergeten',
      component: PasswordResetComponent,
      canActivate: [RegistrationAuthGuardService]
    }
  ];
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
