import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  httpOptions;

  constructor(private http: HttpClient, @Inject('drupalUrl') private drupalUrl: string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      })
    };
  }

  private commentsChangedSubj = new Subject();
  commentsChanged$ = this.commentsChangedSubj.asObservable();

  getComments(nodeType, uuid): Observable<any> {
    return this.http.get(this.drupalUrl + '/jsonapi/node/' + nodeType + '/' + uuid + '/field_comments?page[limit]=25&include=uid&include=uid.field_profielfoto');
  }

  getNextCommentsPage(href): Observable<any> {
    return this.http.get(href);
  }

  createCommentReply(body: object, url: string) {
    return this.http.post(url, body, this.httpOptions);
  }

  postComment(body: object) {
    return this.http.post(this.drupalUrl + '/jsonapi/comment/reactie', body, this.httpOptions);
  }

  public commentsChanged() {
    this.commentsChangedSubj.next();
  }

}
