<nb-layout>

  <nb-layout-header fixed>
    <!-- Insert header here -->
    <app-header></app-header>
  </nb-layout-header>
  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <nb-menu [items]="items">
    </nb-menu>
  </nb-sidebar>
  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <!--<nb-layout-footer fixed>
    &lt;!&ndash; Insert footer here &ndash;&gt;
  </nb-layout-footer>-->

</nb-layout>
