import {Injectable} from '@angular/core';
import {Subject, of} from 'rxjs';
import {tap, map, reduce, flatMap} from 'rxjs/operators';
import {compact, intersection, without} from 'lodash-es';

import {ApiService} from '../api/api.service';
import {TaxonomyTerm, User, UserRole} from '../../models/drupal-datatypes.models';
import {
  EnergyLibraryUser,
  EnergyLibraryAssignment,
  EnergyLibraryRequestView,
  EnergyLibraryProduct,
  EnergyLibraryProductStatus,
  EnergyLibraryAssignmentStatus,
  EnergyLibraryLocation
  } from '../../models/energy-library.models';
import * as jsonApiUtils from '../../utils/json-api.utils';
import { UserService } from '../user/user.service';

interface AssignmentUpdate {
  startDate?: string;
  endDate?: string;
  status: EnergyLibraryAssignmentStatus;
}

interface ProductUpdate {
  title: string;
  categoryId: string;
  status: string;
  municipalityId: string;
  locationId: string;
  number: string;
  damage: string;
}

interface LocationUpdate {
  active: boolean;
  name: string;
  address: string;
  postalCode: string;
  locality: string;
  type: string;
  employees: EnergyLibraryUser[];
}

@Injectable({
  providedIn: 'root'
})
export class EnergyLibraryService {

  private requestChangedSubj = new Subject();
  private productChangedSubj = new Subject();
  private locationChangedSubj = new Subject();
  requestChanged$ = this.requestChangedSubj.asObservable();
  productChanged$ = this.productChangedSubj.asObservable();
  locationChanged$ = this.locationChangedSubj.asObservable();

  constructor(
    private apiService: ApiService,
    private userService: UserService
    ) {
  }

  productChanged() {
    this.productChangedSubj.next();
  }

  requestChanged() {
    this.requestChangedSubj.next();
  }

  locationChanged() {
    this.locationChangedSubj.next();
  }

  getProductTypes() {
    const uri = `/jsonapi/taxonomy_term/energiebieb_product_categorie`;
    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseMany<TaxonomyTerm>(res))
    );
  }

  getRequests() {
    const sanitizeRequestView = (req: EnergyLibraryRequestView) => ({
      ...req,
      title: req.product,
      product_categorie: req.product_categorie || 'Overige producten'
    });
    const uri = `/api/userlocation/aanvragenoverzicht`;
    return this.apiService.get(uri).pipe(
      map((reqs: EnergyLibraryRequestView[]) => reqs.map(sanitizeRequestView))
    );
  }

  getEmployees() {
    return this.userService.getUsersByRoles(['bibliotheek_medewerker', 'energiebieb_beheerder']);
  }

  getLocations(name?: string) {
    const nameFilter = !name ? '' :
      `&filter[name][condition][path]=title` +
      `&filter[name][condition][operator]=CONTAINS` +
      `&filter[name][condition][value]=${name}`;
    const include = 'include=field_bibliotheek_medewerker';
    const uri = `/jsonapi/energiebieb/energiebieb_locatie?${include}${nameFilter}`;

    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseMany<EnergyLibraryLocation>(res))
    );
  }

  getLocationsForUser(uuid: string) {
    return this.userService.getUser().pipe(
      map(user => user.field_ref_locatie)
    );
  }

  addOrUpdateLocation(fields: LocationUpdate, location?: EnergyLibraryLocation, locationEmployees?: EnergyLibraryUser[]) {

    const saveLocation$ = () => {
      const address = location?.field_energiebieb_adres;
      const attributes = {
        title: fields.name,
        field_energiebieb_adres: {
          additional_name: address?.additional_name || null,
          address_line1: fields.address,
          address_line2: address?.address_line2 || '',
          administrative_area: address?.administrative_area || null,
          country_code: address?.country_code || 'NL',
          dependent_locality: address?.dependent_locality || null,
          family_name: address?.family_name || fields.name,
          given_name: address?.given_name || fields.name,
          langcode: address?.langcode || '',
          organization: address?.organization || '',
          sorting_code: address?.sorting_code || null,
          postal_code: fields.postalCode,
          locality: fields.locality,
        },
        field_actief: fields.active,
        field_type_locatie: fields.type
      };
      const uuid = location ? location.__uuid : undefined;
      const body = jsonApiUtils.createBody(uuid, 'energiebieb--energiebieb_locatie', attributes);
      if (location) {
        return this.apiService.patch(`/jsonapi/energiebieb/energiebieb_locatie/${location.__uuid}`, body);
      } else {
        return this.apiService.post(`/jsonapi/energiebieb/energiebieb_locatie`, body);
      }
    };


    locationEmployees = locationEmployees || [];
    const usersToRemoveLocationFrom = locationEmployees.filter(x => !fields.employees.find(y => y.__uuid === x.__uuid));
    const usersToAddLocationTo = fields.employees.filter(x => !locationEmployees.find(y => y.__uuid === x.__uuid));
    const usersToPatch = [...usersToRemoveLocationFrom, ...usersToAddLocationTo];

    const updateUsersAddLocation$ = (res: any) => {
      if (!res?.data?.id) throw new Error('No location id');
      if (usersToPatch.length === 0) return of({});

      const locationUuid = res.data.id;

      return of(...usersToPatch).pipe(
          flatMap(employee => {
            const removeLocation = usersToRemoveLocationFrom.find(x => x.__uuid === employee.__uuid);
            let locationIds = (employee.field_ref_locatie || []).map(x => x.__uuid);
            if (removeLocation) {
              locationIds = without(locationIds, locationUuid);
            } else {
              locationIds.push(locationUuid);
            }
            const userFields = { ...employee, field_ref_locatie: locationIds };
            return this.userService.updateUser(employee.__uuid, userFields, undefined);
          }),
          reduce(x => x)
        );
    };

    return saveLocation$().pipe(
      flatMap(updateUsersAddLocation$),
      tap(this.locationChanged.bind(this))
    );
  }


  deleteLocation(location: EnergyLibraryLocation) {
    const deleteLocation$ = () => {
      return this.apiService.delete(`/jsonapi/energiebieb/energiebieb_locatie/${location.__uuid}`);
    };

    return deleteLocation$().pipe(
      tap(this.locationChanged.bind(this))
    );
  }


  getAssignmentById(id: string) {
    const include = 'include=field_toegewezen_product';
    const uri = `/jsonapi/energiebieb/toewijzing/${id}?${include}`;
    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseSingle<EnergyLibraryAssignment>(res))
    );
  }

  getProducts(name?: string, status?: string) {
    const include = 'include=field_ref_energiebieb_locatie,field_product_categorie';
    const nameFilter = !name ? null :
      `filter[name][condition][path]=title&` +
      `filter[name][condition][operator]=CONTAINS&` +
      `filter[name][condition][value]=${name}`;
    const statusFilter = !status ? null : `filter[field_status]=${status}`;
    const filters = compact([nameFilter, statusFilter]).join('&');
    const uri = `/jsonapi/energiebieb/product?${include}${filters ? `&${filters}` : ''}`;

    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseMany<EnergyLibraryProduct>(res))
    );
  }

  getAvailableProducts(productType: string, locationId?: string) {
    const statusFilter = 'filter[field_status]=1';
    const productTypeFilter = `filter[field_product_categorie.name]=${productType}`;
    const locationFilter = locationId ? `filter[field_ref_energiebieb_locatie.id]=${locationId}` : null;
    const filters = compact([statusFilter, productTypeFilter, locationFilter]).join('&');
    const uri = `/jsonapi/energiebieb/product?${filters}`;

    return this.apiService.get(uri).pipe(
      map(res => jsonApiUtils.parseResponseMany<EnergyLibraryProduct>(res))
    );
  }

  addOrUpdateProduct(fields: ProductUpdate, product?: EnergyLibraryProduct) {

    const saveProduct$ = () => {
      const attributes = {
        title: fields.title,
        field_inventarisnummer: fields.number,
        field_schade: fields.damage,
        field_status: fields.status
      };
      const relations = {
        field_gemeente: jsonApiUtils.createRelation(fields.municipalityId, 'taxonomy_term--gemeente'),
        field_ref_energiebieb_locatie: jsonApiUtils.createRelation(fields.locationId, 'energiebieb--energiebieb_locatie'),
        field_product_categorie: jsonApiUtils.createRelation(fields.categoryId, 'taxonomy_term--energiebieb_product_categorie')
      };
      const body = jsonApiUtils.createBody(product ? product.__uuid : undefined, 'energiebieb--product', attributes, relations);
      if (product) {
        return this.apiService.patch(`/jsonapi/energiebieb/product/${product.__uuid}`, body);
      } else {
        return this.apiService.post(`/jsonapi/energiebieb/product`, body);
      }
    };

    return saveProduct$().pipe(
      tap(this.productChanged.bind(this))
    );
  }

  deleteProduct(product: EnergyLibraryProduct) {

    const deleteProduct$ = () => {
      return this.apiService.delete(`/jsonapi/energiebieb/product/${product.__uuid}`);
    };

    return deleteProduct$().pipe(
      tap(this.productChanged.bind(this))
    );
  }

  private mapAssignmentStatusToProductStatus(assignmentStatus: EnergyLibraryAssignmentStatus) {
    switch (assignmentStatus) {
      case EnergyLibraryAssignmentStatus.Wachtrij: return EnergyLibraryProductStatus.Beschikbaar;
      case EnergyLibraryAssignmentStatus.ProductInGebruik: return EnergyLibraryProductStatus.Toegewezen;
      case EnergyLibraryAssignmentStatus.Afgerond: return EnergyLibraryProductStatus.Beschikbaar;
      case EnergyLibraryAssignmentStatus.Geannuleerd: return EnergyLibraryProductStatus.Beschikbaar;
      case EnergyLibraryAssignmentStatus.OnHold: return EnergyLibraryProductStatus.Toegewezen;
    }
  }

  assignProductToRequest(
    product: EnergyLibraryProduct | undefined,
    requestNumber: string,
    requestId: string,
    startDate: string,
    endDate: string,
    status: EnergyLibraryAssignmentStatus
  ) {

    const addAssignment$ = () => {

      const title = status === EnergyLibraryAssignmentStatus.Geannuleerd ? `Aanvraag ${requestNumber} geannuleerd` :
        `Product (${product.field_inventarisnummer}) toegewezen aan aanvraag (${requestNumber})`;
      const attributes = {
        title,
        field_start_en_einddatum: {
          value: startDate,
          end_value: endDate
        },
        field_toewijzing_status: status
      };
      const relations: any = {
        field_aanvraag: jsonApiUtils.createRelation(requestId, 'energiebieb--aanvraag'),
      };
      if (product) {
        relations.field_toegewezen_product = jsonApiUtils.createRelation(product.__uuid, 'energiebieb--product');
      }
      const body = jsonApiUtils.createBody(undefined, 'energiebieb--toewijzing', attributes, relations);
      return this.apiService.post(`/jsonapi/energiebieb/toewijzing`, body);
    };

    const updateProduct$ = () => {
      if (!product) return of({});
      const attributes = {
        field_status: this.mapAssignmentStatusToProductStatus(status)
      };
      const body = jsonApiUtils.createBody(product.__uuid, 'energiebieb--product', attributes);
      return this.apiService.patch(`/jsonapi/energiebieb/product/${product.__uuid}`, body);
    };

    return addAssignment$().pipe(
      flatMap(updateProduct$),
      tap(this.requestChanged.bind(this))
    );
  }

  updateAssignment(assignment: EnergyLibraryAssignment, update: AssignmentUpdate) {

    const updateAssignment$ = () => {
      const dateAttributes = update.startDate && update.endDate ? {
        value: update.startDate,
        end_value: update.endDate,
      } : {};
      const attributes = {
        field_toewijzing_status: update.status,
        ...dateAttributes
      };
      const body = jsonApiUtils.createBody(assignment.__uuid, 'energiebieb--toewijzing', attributes);
      return this.apiService.patch(`/jsonapi/energiebieb/toewijzing/${assignment.__uuid}`, body);
    };

    const updateProduct$ = () => {
      const attributes = {
        field_status: this.mapAssignmentStatusToProductStatus(update.status)
      };
      const body = jsonApiUtils.createBody(assignment.field_toegewezen_product.__uuid, 'energiebieb--product', attributes);
      return this.apiService.patch(`/jsonapi/energiebieb/product/${assignment.field_toegewezen_product.__uuid}`, body);
    };

    const deleteAssignment$ = () => {
      return this.apiService.delete(`/jsonapi/energiebieb/toewijzing/${assignment.__uuid}`);
    };

    const updateOrDeleteAssignment$ = update.status === EnergyLibraryAssignmentStatus.Wachtrij ? deleteAssignment$ : updateAssignment$;

    return updateOrDeleteAssignment$().pipe(
      flatMap(updateProduct$),
      tap(this.requestChanged.bind(this))
    );
  }

  deleteRequestAndAssignment(request?: EnergyLibraryRequestView, assignment?: EnergyLibraryAssignment) {

    const updateProduct$ = () => {
      if (!assignment) return of({});

      const attributes = {
        field_status: EnergyLibraryProductStatus.Beschikbaar
      };
      const body = jsonApiUtils.createBody(assignment.field_toegewezen_product?.__uuid, 'energiebieb--product', attributes);
      return this.apiService.patch(`/jsonapi/energiebieb/product/${assignment.field_toegewezen_product?.__uuid}`, body);
    };

    const deleteAssignment$ = () => {
      if (!assignment) return of({});

      return this.apiService.delete(`/jsonapi/energiebieb/toewijzing/${assignment.__uuid}`);
    };

    const deleteRequest$ = () => {
      if (!request) return of({});

      return this.apiService.delete(`/jsonapi/energiebieb/aanvraag/${request.aanvraag_uuid}`);
    };

    return updateProduct$().pipe(
      flatMap(deleteAssignment$),
      flatMap(deleteRequest$),
      tap(this.requestChanged.bind(this))
    );
  }
}
