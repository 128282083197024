<nb-card>
  <nb-card-header>
    <h3>Energiebieb aanvragen</h3>
  </nb-card-header>
  <nb-card-body>
    <section class="filters">
      <button
        *ngFor="let i of [1,5,2,3,4]"
        (click)="statusFilterModel$.next(statusFilterModel == i ? '0' : i.toString())"
        size="small"
        [status]="statusFilterModel == i ? 'info' : 'basic'"
        nbButton>{{ EnergyLibraryAssignmentStatusLabels[i.toString()] }}</button>
    </section>
    <section class="filters">
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input id="input-name-filter" fieldSize="small" type="text" fullWidth placeholder="Naam"
          shape="round" nbInput [ngModel]="nameFilterModel$ | async"
          (ngModelChange)="nameFilterModel$.next($event)">
      </nb-form-field>
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
          <nb-select  id="select-location" placeholder="Locatie" [ngModel]="locationFilterModel$ | async" (ngModelChange)="locationFilterModel$.next($event)">
            <nb-option [value]="undefined">Alle locaties</nb-option>
              <nb-option *ngFor="let location of locations$ | async" [value]="location.__uuid">
                {{ location.title }}
            </nb-option>
          </nb-select>
      </nb-form-field>
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input id="input-product-number-filter" fieldSize="small" type="text" fullWidth placeholder="Productnummer"
          shape="round" nbInput [ngModel]="productNumberFilterModel$ | async"
          (ngModelChange)="productNumberFilterModel$.next($event)">
      </nb-form-field>
    </section>
    <section class="requests" [nbSpinner]="isLoading$ | async" nbSpinnerStatus="basic">
      <div *ngIf="!(isLoading$ | async)">
        <ng-container *ngIf="productTypes$ | async as productTypes">
          <ng-container *ngIf="numResultsLabel$ | async as numResultsLabel">
            <nb-accordion *ngIf="productTypes.length > 0; else noProductTypes">
              <nb-accordion-item *ngFor="let productType of productTypes; let first = first" [expanded]="first">
                <nb-accordion-item-header>{{productType}} {{numResultsLabel[productType]}}</nb-accordion-item-header>
                <nb-accordion-item-body>
                  <ng-container *ngIf="paginatedRequests$ | async as paginatedRequests">
                    <ng-container *ngIf="(paginatedRequests[productType] || []).length > 0; else noRequests">
                      <table>
                        <thead>
                          <tr>
                            <th class="col-id">ID</th>
                            <th class="col-status">Status</th>
                            <th class="col-name">Naam</th>
                            <th class="col-date">Datum</th>
                            <th class="col-email">E-mailadres</th>
                            <th class="col-municipality">Gemeente</th>
                            <th class="col-location">Locatie</th>
                            <th class="col-product-number">Productnummer</th>
                            <th class="col-assigned">Toegewezen?</th>
                            <th class="col-actions"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let request of paginatedRequests[productType]"
                            [class.new]="request.status_toegewezen === 'Nee'">
                            <td>{{request.aanvraag_id}}</td>
                            <td>{{EnergyLibraryAssignmentStatusLabels[request.status]}}</td>
                            <td>{{request.naam}}</td>
                            <td>{{request.aanvraag_created}}</td>
                            <td>{{request.emailadres}}</td>
                            <td [innerHtml]="request.gemeente"></td>
                            <td>{{request.locatie}}</td>
                            <td>{{request.title}}</td>
                            <td>{{request.status_toegewezen}}</td>
                            <td>
                              <button nbButton (click)="viewRequest(request)" size="small" status="basic" outline>Bekijken</button>
                              <button (click)="assign(request)" size="small"
                                [status]="request.status_toegewezen === 'Ja' ? 'basic' : 'success'" outline
                                nbButton>{{request.status_toegewezen === 'Ja' ? 'Bewerken' : 'Toewijzen'}}</button></td>
                          </tr>
                        </tbody>
                      </table>
                      <app-pagination *ngIf="numPages$ | async as numPages" [numPages]="numPages[productType]"
                        [page]="currentPage$ | async" (pageChange)="currentPage$.next($event)"></app-pagination>
                    </ng-container>
                    <ng-template #noRequests>
                      <p *ngIf="isFiltering$ | async; then noRequestsWithFilter else noRequestsWithoutFilter;">
                      </p>
                      <ng-template #noRequestsWithFilter>Er zijn geen resultaten voor de huidige zoekopdracht.</ng-template>
                      <ng-template #noRequestsWithoutFilter>Er zijn nog geen aanvragen voor dit producttype.</ng-template>
                    </ng-template>
                  </ng-container>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #noProductTypes>
        <p>Er zijn nog geen product types.</p>
      </ng-template>
    </section>
  </nb-card-body>
</nb-card>
