import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {RouteService} from '../../../services/route/route.service';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-opening-times-overview',
  templateUrl: './opening-times-overview.component.html',
  styleUrls: ['./opening-times-overview.component.scss']
})
export class OpeningTimesOverviewComponent implements OnInit, OnDestroy {

  availableDates;
  locations;

  objectType: string;
  formattedObjectTypes: string;
  redirectUrl = '';
  isDoneLoading = new Subject<boolean>();
  isDoneLoading$ = this.isDoneLoading.asObservable();
  private subscription = new Subscription();

  environment = environment;

  constructor(private routeService: RouteService, private activatedRoute: ActivatedRoute, @Inject('routeName') public routeName: string, private router: Router) {
    this.availableDates = [];
    this.isDoneLoading.next(false);


    const routeParamMapSub = this.activatedRoute.paramMap.subscribe(params => {
      this.objectType = params.get('objectType') as string;

    });

    const openingTimesForUserSub = this.routeService.getOpeningTimesForUser(this.objectType).subscribe(result => {
      //console.log('result', result.route_config);
      if (result.route_config) {
        Object.keys(result.route_config).forEach(objectType => {
          const objectTypeDates = [];
          result.route_config[objectType].dates.forEach(date => {
            if (date.field_route_openingsdatum) {
              if (date.field_route_openingsdatum[0]?.value) {
                const dateStr = date.field_route_openingsdatum[0].value;
                objectTypeDates.push({
                  date: new Date(`${dateStr}T00:00:00`),
                  date_raw: dateStr,
                  id: date.id[0].value
                });
              }
            }
          });
          this.availableDates[objectType] = objectTypeDates;
        });
        this.isDoneLoading.next(true);

        this.locations = result.locations;
        //console.log('this.locations', this.locations);
      }
    });

    this.subscription.add(routeParamMapSub);
    this.subscription.add(openingTimesForUserSub);
  }

  ngOnInit(): void {
    this.formattedObjectTypes = this.objectType.replace('-', ' en/of ');
    this.redirectUrl = this.router.url;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
