import {Component} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {
  SubsidyRequest,
  SubsidyRequestMunicipalityLabels,
  SubsidyRequestStatus,
  SubsidyRequestTypeLabels
  } from 'src/app/models/subsidy.models';
import {SubsidyService} from 'src/app/services/subsidy/subsidy.service';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';

@Component({
  selector: 'app-cancel-subsidy-request-dialog',
  templateUrl: './cancel-subsidy-request-dialog.component.html',
  styleUrls: ['./cancel-subsidy-request-dialog.component.scss', '../../../shared/shared.scss']
})
export class CancelSubsidyRequestDialogComponent {

  isSaving = false;
  request: SubsidyRequest;
  SubsidyRequestTypeLabels = SubsidyRequestTypeLabels;
  SubsidyRequestMunicipalityLabels = SubsidyRequestMunicipalityLabels;

  get canSubmit() {
    return !this.isSaving;
  }

  get showSpinner() {
    return this.isSaving;
  }

  constructor(
    protected ref: NbDialogRef<CancelSubsidyRequestDialogComponent>,
    private subsidyService: SubsidyService,
    private majadaToastrService: MajadaToastrService
  ) {
  }

  save() {
    this.isSaving = true;
    this.subsidyService.updateRequestStatus(
      this.request,
      SubsidyRequestStatus.Geannuleerd,
      this.request.field_status_beoordeling_vd,
      this.request.field_status_afhandeling_gem,
      this.request.field_jaar).subscribe(() => {
      this.isSaving = false;
      this.ref.close();
      this.majadaToastrService.success('Aanvraag is bijgewerkt', 'Bijgewerkt');
    }, err => {
      console.error(err);
      this.isSaving = false;
      this.majadaToastrService.error('Aanvraag kon niet bijgewerkt worden');
    });
  }

  close() {
    this.ref.close();
  }

}
