<img [src]="imageSrc" />
<ng-content select="ngx-dropzone-label"></ng-content>
<div class="highlighted" *ngIf="highlight">
    <div class="check-container">
        <input type="checkbox" (click)="pickHighlight()" [checked]="checked()" />
        <span class="checkmark" (click)="pickHighlight()" ></span>
        <label>Uitgelicht</label>
    </div>
</div>
<ngx-dropzone-remove-badge *ngIf="removable" (click)="_remove($event)">
</ngx-dropzone-remove-badge>