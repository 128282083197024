import {Component, Inject, OnInit} from '@angular/core';
import {RouteService} from '../../../services/route/route.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import * as jsonApiUtils from '../../../utils/json-api.utils';
import {ApiService} from '../../../services/api/api.service';
import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';

@Component({
  selector: 'app-edit-opening-times',
  templateUrl: './edit-opening-times.component.html',
  styleUrls: ['./edit-opening-times.component.scss']
})
export class EditOpeningTimesComponent implements OnInit {

  openingTimesForm: FormGroup;
  openingTimeEntity: any;
  originalVisitorsPerTime: any;

  routeConfig: any = [];
  object: any = [];
  locationAddress: any = [];
  dateId;
  hasRegistrations = false;

  showCompanyOnLocation = false;

  maxVisitorsPerTime: number | null = null;
  maxVisitorsPerDayPart: number | null = null;

  submitting = false;

  isDoneLoading = new Subject<boolean>();
  isDoneLoading$ = this.isDoneLoading.asObservable();

  get pdb() {
    return this.project === 'platformduurzamebedrijven';
  }

  get tkr() {
    return this.project === 'tuinenklimaatroute';
  }

  get vd() {
    return this.project === 'vd';
  }

  get ndhr() {
    return this.project === 'ndhr';
  }

  constructor(
    private routeService: RouteService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private toastrService: MajadaToastrService,
    private router: Router,
    @Inject('project') private project: string,
  ) {
    this.openingTimesForm = this.routeService.openingTimesForm;

    this.isDoneLoading.next(false);

    this.activatedRoute.paramMap.subscribe(params => {
      const objectOpeningTimeUuid = params.get('objectOpeningTimeId');
      this.routeService.getOpeningTimesForObjectOpeningTime(objectOpeningTimeUuid).subscribe(result => {

        // Set route config variable.
        this.routeConfig = result.route_config;
        console.log('result', result);
        this.hasRegistrations = result.has_registrations;

        if (this.routeConfig.route_config.field_bedrijf_aanwezig_tonen && this.routeConfig.route_config.field_bedrijf_aanwezig_tonen[0]) {
          this.showCompanyOnLocation = this.routeConfig.route_config.field_bedrijf_aanwezig_tonen[0].value;
        }

        if (this.routeConfig.route_config.field_max_bezoekers_dagdeel[0]) {
          this.maxVisitorsPerDayPart = this.routeConfig.route_config.field_max_bezoekers_dagdeel[0].value;
        }
        if (this.routeConfig.route_config.field_max_bezoekers_tijdstip[0]) {
          this.maxVisitorsPerTime = this.routeConfig.route_config.field_max_bezoekers_tijdstip[0].value;
        }

        this.openingTimeEntity = result.opening_time_data.opening_time;
        this.object = result.opening_time_data.object;
        this.locationAddress = result.location_address;
        this.dateId = result.opening_time_data.opening_time.field_ref_openingstijdstip[0]?.target_id;

        if (result.opening_time_data.visitors_per_time) {
          this.originalVisitorsPerTime = result.opening_time_data.visitors_per_time;
        }

        // Update form group with values for current opening time.
        let availability = '';
        if (this.openingTimeEntity.field_niet_geopend[0].value === false) {
          availability = 'opened';
        } else {
          availability = 'notOpened';
        }

        this.openingTimesForm.get('nameOwner').setValue(result.opening_time_data.opening_time.field_naam_van_huiseigenaar[0]?.value);
        this.openingTimesForm.get('stopRegistrations').setValue(result.opening_time_data.opening_time.field_inschrijven_stopzetten[0]?.value);

        this.openingTimesForm.get('availability').setValue(availability);
        this.openingTimesForm.get('totalPlaces').setValue(result.opening_time_data.opening_time.field_totaal_aantal_bezoekers[0]?.value);
        if(this.hasRegistrations) {
          this.openingTimesForm.get('totalPlaces').disable();
        }
        if (result.opening_time_data.opening_time.field_voorkeur_bezoek[0]) {
          this.openingTimesForm.get('openingType').setValue(result.opening_time_data.opening_time.field_voorkeur_bezoek[0].value);
        } else {
          if (this.routeConfig.day_parts.length === 0) {
            this.openingTimesForm.get('openingType').setValue('vaste_tijdstippen');
          } else if (this.routeConfig.times.length === 0) {
            this.openingTimesForm.get('openingType').setValue('vrije_inloop');
          }
        }

        if (result.opening_time_data.opening_time.field_is_er_een_bedrijf_aanwezig[0]) {
          this.openingTimesForm.get('company').setValue(result.opening_time_data.opening_time.field_is_er_een_bedrijf_aanwezig[0].value);
        }
        if (this.routeConfig.day_parts.length > 0) {
          this.openingTimesForm.get('dayParts').setValue(result.opening_time_data.opening_time.field_ref_tax_dagdelen[0]?.target_uuid);
        }
        this.openingTimesForm.get('remarksVisitors').setValue(result.opening_time_data.opening_time.field_opmerkingen_bezoekers[0]?.value);
        this.openingTimesForm.get('remarksOrganizer').setValue(result.opening_time_data.opening_time.field_opmerkingen_organisatie[0]?.value);


        // Init a new form group for time fields.
        const timesGroup = new FormGroup({});
        if (this.routeConfig.times.length > 0) {
          // Loop through al times that haven been returned by the api and add form elements to the timesGroup form group.
          this.routeConfig.times.forEach(time => {
            if (result.opening_time_data.visitors_per_time && result.opening_time_data.visitors_per_time[time.uuid[0].value]?.allowed_visitors) {
              timesGroup.addControl(time.uuid[0].value, new FormControl({
                value: result.opening_time_data.visitors_per_time[time.uuid[0].value].allowed_visitors,
                disabled: this.hasRegistrations
              }, [Validators.max(this.maxVisitorsPerTime), Validators.min(0)]));
            } else {
              timesGroup.addControl(time.uuid[0].value, new FormControl(8));
            }
          });
          // Add the times form group to the existing opening times form.
          this.openingTimesForm.addControl('times', timesGroup);
        }


        // Subscribe to value changes.
        this.openingTimesForm.get('openingType').valueChanges.subscribe(value => {
          if (value === 'vaste_tijdstippen') {

            // Set all time elements as required.
            // tslint:disable-next-line:forin
            for (const controlsKey in timesGroup.controls) {
              console.log(controlsKey);
              const formElement = timesGroup.get(controlsKey);

              if (formElement instanceof FormControl) {
                formElement.setValidators([Validators.required, Validators.max(this.maxVisitorsPerTime), Validators.min(0)]);
                formElement.updateValueAndValidity();
              }
            }

            // Remove required from all day part elements.
            this.openingTimesForm.get('totalPlaces').clearValidators();
            this.openingTimesForm.get('totalPlaces').updateValueAndValidity();
            this.openingTimesForm.get('dayParts').clearValidators();
            this.openingTimesForm.get('dayParts').updateValueAndValidity();
          } else {

            // Reset requirement state for all times.
            for (const controlsKey in timesGroup.controls) {
              const formElement = timesGroup.get(controlsKey);

              if (formElement instanceof FormControl) {
                formElement.clearValidators();
                formElement.updateValueAndValidity();
              }
            }

            // Set all day part elements as required.
            this.openingTimesForm.get('totalPlaces').setValidators([Validators.required, Validators.max(this.maxVisitorsPerDayPart), Validators.min(0)]);
            this.openingTimesForm.get('totalPlaces').updateValueAndValidity();
            this.openingTimesForm.get('dayParts').setValidators([Validators.required]);
            this.openingTimesForm.get('dayParts').updateValueAndValidity();
          }
        });
        this.isDoneLoading.next(true);
      });
    });
  }

  ngOnInit(): void {

  }

  submitOpeningTimesForm() {
    //
    const availability = this.openingTimesForm.get('availability').value;

    const attributes: any = {};
    const relations: any = {};
    this.submitting = true;
    if (availability === 'notOpened') {
      attributes.field_niet_geopend = true;
      // Manually call this because otherwise you can get some issues with race conditions when having to create a new times entity.
      this.patchOpeningTimeEntity(attributes, relations);
    } else {

      // First, we need to determine if the type is fixed times or day parts.
      const openingType = this.openingTimesForm.get('openingType').value;

      // Set base attributes that are valid for either opening type.
      attributes.field_voorkeur_bezoek = openingType;
      attributes.field_is_er_een_bedrijf_aanwezig = this.openingTimesForm.get('company').value;
      attributes.field_opmerkingen_bezoekers = this.openingTimesForm.get('remarksVisitors').value;
      attributes.field_opmerkingen_organisatie = this.openingTimesForm.get('remarksOrganizer').value;
      attributes.field_naam_van_huiseigenaar = this.openingTimesForm.get('nameOwner').value;
      attributes.field_inschrijven_stopzetten = this.openingTimesForm.get('stopRegistrations').value;
      let closedStatus = false;
      if (this.openingTimesForm.get('availability').value === 'notOpened') {
        closedStatus = true;
      }

      attributes.field_niet_geopend = closedStatus;

      if (openingType === 'vaste_tijdstippen') {
        // Existing visitors per time data, so update that.
        if (this.originalVisitorsPerTime !== undefined) {
          const visitorsPerTimePatch = [];
          Object.keys(this.originalVisitorsPerTime).forEach(key => {
            const timeAttributes = {
              field_tijdstippen_bezoekers: this.openingTimesForm.get('times').get(key).value
            };
            const body = jsonApiUtils.createBody(this.originalVisitorsPerTime[key].tijdstippen_id, 'route--tijdstippen', timeAttributes);
            visitorsPerTimePatch.push(body);
          });
          this.updateExistingVisitorsPerTime(visitorsPerTimePatch).then(data => {
            this.patchOpeningTimeEntity(attributes, relations);
          });
        } else {
          // Create new visitors per time entities.
          const timesGroup = this.openingTimesForm.get('times') as FormGroup;

          const timeEntities = this.routeService.buildTimeEntityData(timesGroup, this.object.id[0].value, this.dateId);
          if (timeEntities.length > 0) {
            this.routeService.createOpeningTimeTimeEntities(timeEntities).then(data => {
              relations.field_ref_tijdstippen = jsonApiUtils.createRelation(data, 'route--tijdstippen');

              this.patchOpeningTimeEntity(attributes, relations);
            });
          }
        }
      } else {
        relations.field_ref_tax_dagdelen
          = jsonApiUtils.createRelation(this.openingTimesForm.get('dayParts').value, 'taxonomy_term--route_dagdelen');
        attributes.field_totaal_aantal_bezoekers = this.openingTimesForm.get('totalPlaces').value;

        this.patchOpeningTimeEntity(attributes, relations);
      }

    }
  }

  async updateExistingVisitorsPerTime(jsonapiBodies) {
    for (const jsonapiBody of jsonapiBodies) {
      console.log(jsonapiBody);
      let response = await this.apiService.patch(`/jsonapi/route/tijdstippen/${jsonapiBody.data.id}`, jsonapiBody).toPromise();
    }
  }

  patchOpeningTimeEntity(attributes, relations) {
    const body = jsonApiUtils.createBody(this.openingTimeEntity.uuid[0].value, 'route--object_openingstijd', attributes, relations);

    this.apiService.patch(`/jsonapi/route/object_openingstijd/${this.openingTimeEntity.uuid[0].value}?XDEBUG_SESSION_START=phpstorm`, body).subscribe(result => {
      this.toastrService.success('Openingstijd is bijgewerkt.', 'Opgeslagen');
      const objectType = this.routeConfig.route_config.field_object_soort[0].value;

      this.activatedRoute.queryParamMap.subscribe(params => {
        if (params.get('redirectUrl')) {
          this.router.navigateByUrl(params.get('redirectUrl'));
        } else {
          this.router.navigateByUrl(`/route/openingstijden/${objectType}`);
        }
      });

      this.submitting = false;
    }, error => {
      this.toastrService.error('Openingstijd kon niet worden bijgewerkt, probeer het later nog eens.');
      this.submitting = false;
    });

  }

}
