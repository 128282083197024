import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {publishReplay, refCount, take} from 'rxjs/operators';
import {Location} from '@angular/common';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NodeService {

  nodes = new Map<string, Observable<any>>();
  isLoaded = false;

  /*
      private url = this.router.url;
  */

  constructor(
    private http: HttpClient,
    private location: Location,
    @Inject('drupalUrl') private drupalUrl: string
  ) {
  }

  getNode(path = null): Observable<any> {
    if (!path) {
      path = this.location.path();
    }

    let node;
    if (!this.nodes.get(path)) {
      /*$log('test');*/
      node = this.http.get<any>(this.drupalUrl + '/api/node?path=' + path + '&_format=json').pipe(
        publishReplay(1),
        refCount(),
        take(1),
      );
      this.nodes.set(path, node);
    }

    //console.log( this.nodes.get(path));
    return this.nodes.get(path);
    /*return DrupalNode;*/
  }

  getNodeHeaderImage(path = null): Array<{ url, href }> {

    let headerImages = [];

    const node = this.nodes.get(path);
    //console.log('node', node);
    if (node) {
      node.subscribe(data => {
        // console.log(data);

        if (data.header_images.length > 0) {
          headerImages = data.header_images.map(item => {
            return {url: item, href: '#'};
          });
        }
      });
    }

    return headerImages;
  }

  getNodeTerms(path = null): string {

    let nodeTerms = [];

    let node = this.nodes.get(path);

    if (node) {
      node.subscribe(data => {
        // console.log(data);
        if (typeof data.node.field_technologische_innovatie !== 'undefined') {
          //console.log('getNodeTerms data', data.node.field_technologische_innovatie);
          nodeTerms = data.node.field_technologische_innovatie.map(item => {
            return item.target_id;
          });
        }
      });
    }

    return nodeTerms.join(',');
  }

  getNodeImage(path = null): Array<{ url }> {

    let nodeImages = [];

    const node = this.nodes.get(path);
    //console.log('node', node);
    if (node) {
      node.subscribe(data => {
        // console.log(data);

        if (data.node_images.length > 0) {
          nodeImages = data.node_images.map(item => {
            return {url: item};
          });
        }
      });
    }

    return nodeImages;
  }
}
