// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  drupalUrl: 'http://majadacms.lndo.site', // Enter without trailing slash.
  issuerUrl: 'http://majadacms.lndo.site', // Enter without trailing slash.
  mainMenu: 'main',
  frontendUrl: 'http://localhost:4200',
  version: '0.0.0',
  project: 'ndhr',
  buildDate: null,
  wordpressUrl: 'https://dev.wp.duurzamehuizenroute.nl',
  googleMapsApiKey: 'AIzaSyB7qaH1qh7ZXD36d-lkw5nK-K2QTV96Xps',
  routeName: 'Duurzame Huizen Route',
  domainUuid: 'ed325714-9d33-45d6-a25d-282380a82b75',
  dashboardCategories: {
    energie: '8c93e8fd-031e-4a06-a868-dfd5b0709b8d', // Energietransitie
    klimaat: '1f228e8e-1399-44de-b8b1-9a1bd215183c', //
    algemeen: 'ceb4819b-ee7e-4b53-a45c-f273c5a78199',
    energieCoach: 'c9b2422c-ee99-432e-96bd-56bb1e0370af',
    tuinCoach: '7a3b8294-5541-4df4-8aa5-3c6f9c0dfe4e'
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
