import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { AuthGuardService } from '../../services/auth/auth-guard.service';
import { EditOpeningTimesComponent } from './edit-opening-times/edit-opening-times.component';
import { MyRegistrationsComponent } from './my-registrations/my-registrations.component';
import { OpeningTimesOverviewComponent } from './opening-times-overview/opening-times-overview.component';
import { RouteVisitorRegistrationComponent } from './route-visitor-registration/route-visitor-registration.component';
import { SubmitOpeningTimesComponent } from './submit-opening-times/submit-opening-times.component';
import {ConfirmRouteRegistrationComponent} from './confirm-route-registration/confirm-route-registration.component';
import {MyVisitorsComponent} from './my-visitors/my-visitors.component';
import {AnonymousAuthGuardService} from '../../services/auth/anonymous-auth-guard.service';
import { MyVisitorInformationComponent } from './my-visitor-information/my-visitor-information.component';
import {CancelRouteRegistrationPageComponent} from './cancel-route-registration-page/cancel-route-registration-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'openingstijden/doorgeven/:objectId/:dateTid',
        component: SubmitOpeningTimesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'openingstijden/bewerken/:objectOpeningTimeId',
        component: EditOpeningTimesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'openingstijden/:objectType',
        component: OpeningTimesOverviewComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'inschrijven/:objectId',
        component: RouteVisitorRegistrationComponent,
        canActivate: [AnonymousAuthGuardService]
      },
      {
        path: 'inschrijving-bevestigen/:registrationId',
        component: ConfirmRouteRegistrationComponent,
        canActivate: [AnonymousAuthGuardService]
      },
      {
        path: 'mijn-inschrijvingen',
        component: MyRegistrationsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'mijn-bezoekers/:objectType',
        component: MyVisitorsComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'mijn-bezoekers/inschrijving/:registrationId/:dateId/:timeId',
        component: MyVisitorInformationComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'inschrijving-annuleren/:registrationId',
        component: CancelRouteRegistrationPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RouteRoutingModule {
}
