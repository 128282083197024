<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <img src="/assets/logo.png" class="logo">
    <!--<a class="logo" routerLink="/"><img src="../../../../assets/images/Solarif-Group-logo.png"></a>-->
  </div>
</div>

<div class="header-container">
  <nb-actions size="small" *ngIf="isAuthenticated | async; else notAuthenticated">

    <nb-action class="control-item">
      <!--<nb-search type="rotate-layout"></nb-search>-->
    </nb-action>
    <!--<nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>-->
    <nb-action class="control-item" icon="person-outline" [nbContextMenu]="loggedInMenu" [nbContextMenuTag]="'user-menu'" [nbContextMenuPlacement]="'bottom'"></nb-action>
  </nb-actions>
  <ng-template #notAuthenticated>
    <nb-actions size="small">
      <nb-action class="control-item" icon="person-outline" [nbContextMenu]="loggedOutMenu" [nbContextMenuTag]="'user-menu'" [nbContextMenuPlacement]="'bottom'"></nb-action>
    </nb-actions>
  </ng-template>
</div>
