import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DrupalNodeComponent} from './drupal-node/drupal-node.component';
import {CommentComponent} from './comments/comment/comment.component';
import {CommentsComponent} from './comments/comments.component';
import {CommentReplyComponent} from './comments/comment/comment-reply/comment-reply.component';
import {CommentUserComponent} from './comments/comment/comment-user/comment-user.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ReactiveFormsModule} from '@angular/forms';
import {DrupalNodeResolver} from '../services/node/drupal-node-resolver.service';
import {NodeRoutingModule} from './node-routing.module';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
    DrupalNodeComponent,
    CommentComponent,
    CommentsComponent,
    CommentReplyComponent,
    CommentUserComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    NodeRoutingModule,
  ],
  exports: [
  ],
  providers: [
    DrupalNodeResolver
  ]
})
export class NodeModule {
}
