<nb-card>
  <nb-card-header>
    <h3>Statistieken</h3>
  </nb-card-header>
  <nb-card-body *ngIf="subsidyStats">
    <nb-select id="select-year" placeholder="Jaar" [(ngModel)]="selectedYear">
      <nb-option *ngFor="let year of years" [value]="year">{{ year }}</nb-option>
    </nb-select>
    <ng-container *ngIf="length > 1 else singleItem">
      <nb-card *ngFor="let stats of selectedSubsidyStats | keyvalue">
        <nb-card-header>
          <h4>{{stats.key | titlecase}}</h4>
        </nb-card-header>
        <nb-card-body>
          <p *ngIf="stats.value.budget"><strong>Subsidieplafond:</strong> {{stats.value.budget | currency: 'EUR': 'symbol':'.2-2': 'nl-NL'}}</p>
          <p><strong>Aantal aanvragen:</strong> {{stats.value.total_subsidy_requests}}</p>
          <p><strong>Totaal aangevraagd
            subsidiebedrag:</strong> {{stats.value.total_subsidy_requested | currency: 'EUR': 'symbol':'.2-2': 'nl-NL'}}
          </p>
          <p><strong>Totaal uitbetaald
            subsidiebedrag:</strong> {{stats.value.total_subsidy_paid | currency: 'EUR': 'symbol':'.2-2': 'nl-NL'}}</p>
        </nb-card-body>
      </nb-card>
    </ng-container>
    <ng-template #singleItem>
      <ng-container *ngFor="let stats of selectedSubsidyStats | keyvalue">
        <p *ngIf="stats.value.budget"><strong>Subsidieplafond:</strong> {{stats.value.budget | currency: 'EUR': 'symbol':'.2-2': 'nl-NL'}}</p>
        <p><strong>Aantal aanvragen:</strong> {{stats.value.total_subsidy_requests}}</p>
        <p><strong>Totaal aangevraagd
          subsidiebedrag:</strong> {{stats.value.total_subsidy_requested | currency: 'EUR': 'symbol':'.2-2': 'nl-NL'}}
        </p>
        <p><strong>Totaal uitbetaald
          subsidiebedrag:</strong> {{stats.value.total_subsidy_paid | currency: 'EUR': 'symbol':'.2-2': 'nl-NL'}}</p>
      </ng-container>
    </ng-template>
  </nb-card-body>
</nb-card>
