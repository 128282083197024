import {includes} from 'lodash-es';

export interface SubsidyFormState {
    emailAddress?: string;
    images?: { url: string, data: File }[];
    isNewRequest?: boolean;
    step1FormData?: any;
    step2FormData?: any;
    step3FormData?: any;
    step3UserFormData?: any;
}

export function getDisplayFlags(state: SubsidyFormState) {
    if (!state?.step1FormData) return {};

    const { field_gemeente, field_binnen_bebouwde_kom, field_gebouwd_voor_2015, field_type_aanvraag, field_situatie } = state.step1FormData;

    return {
        showPart1: true,
        showPart2: (field_gemeente !== 'nunspeet' && field_binnen_bebouwde_kom && field_gebouwd_voor_2015) ||
                   (field_gemeente === 'nunspeet' && field_gebouwd_voor_2015),
        showPart3: field_binnen_bebouwde_kom && field_gebouwd_voor_2015,
        showPart4: true,
        showPart5: (field_gemeente !== 'nunspeet' && field_binnen_bebouwde_kom && field_gebouwd_voor_2015) ||
                   (field_gemeente === 'nunspeet' && includes(['koopwoning', 'huurhuis', 'vve'], field_situatie)),
        showPart6: (field_gemeente !== 'nunspeet') ||
                   (field_gemeente === 'nunspeet' && field_gebouwd_voor_2015),

        showPart1TypeA: field_gemeente !== 'elburg',
        showPart1TypeB: field_gemeente === 'elburg',

        showPart2TypeA: field_gemeente !== 'nunspeet',
        showPart2TypeB: field_gemeente === 'nunspeet',

        showPart5TypeA: field_gemeente !== 'nunspeet',
        showPart5TypeB: field_gemeente === 'nunspeet' && includes(['koopwoning', 'huurhuis', 'vve'], field_situatie),

        showPart6TypeA: field_gemeente !== 'harderwijk',
        showPart6TypeB: field_gemeente === 'harderwijk',

        showDefinitiveRequestFields: field_type_aanvraag === 'definitief'
    };
}
