<nb-card>
  <nb-card-header>
    <h3>Mijn subsidieaanvragen</h3>
  </nb-card-header>
  <nb-card-body>
    <section class="filters" *ngIf="showStatusFilter">
      <button
        *ngFor="let i of subsidyRequestStatusKeys"
        (click)="statusFilterModel$.next(statusFilterModel == i ? null : i)"
        size="small"
        [status]="statusFilterModel == i ? 'info' : 'basic'"
        nbButton>{{ SubsidyRequestStatusLabels[i] }}</button>
    </section>
    <section class="filters" *ngIf="showAdminFilter">
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <nb-select id="select-municipality" placeholder="Gemeente"
                   [ngModel]="municipalityFilterModel" (ngModelChange)="municipalityFilterModel$.next($event)">
          <nb-option *ngIf="showAllMunicipalitiesFilter" [value]="null">Alle gemeenten</nb-option>
          <nb-option *ngFor="let i of filteredMunicipalityKeys" [value]="i">
            {{ SubsidyRequestMunicipalityLabels[i] }}
          </nb-option>
        </nb-select>
      </nb-form-field>
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input nbInput id="search-name" placeholder="Naam" [ngModel]="nameFilterModel"
               (ngModelChange)="nameFilterModel$.next($event)">
      </nb-form-field>
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input nbInput id="search-email" placeholder="E-mailadres" [ngModel]="emailFilterModel"
               (ngModelChange)="emailFilterModel$.next($event)">
      </nb-form-field>
      <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input nbInput id="search-address" placeholder="Adres" [ngModel]="addressFilterModel"
               (ngModelChange)="addressFilterModel$.next($event)">
      </nb-form-field>
    </section>
    <button nbButton [status]="'primary'" (click)="exportSelection()" *ngIf="canExport">Exporteer pagina</button>
    <section class="requests" [nbSpinner]="isLoading$ | async" nbSpinnerStatus="basic">
      <div *ngIf="!(isLoading$ | async)">
        <div *ngIf="showDefinitiveRequestInstructions$ | async" class="explanation">
          <p>Uw vooraanvraag is volledig.</p>
          <p>Bent u klaar met het uitvoeren van uw plannen, dan kunt u nu een definitieve aanvraag indienen. Een
            definitieve aanvraag kunt u
            indienen door op dit icoontje te klikken.
            <button size="small" status="success" outline nbButton nbTooltipStatus="basic">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
          </p>
          <p>Uw eerder ingevulde gegevens blijven dan gewoon staan. Vink vervolgens
            in de 1e stap ‘ik heb de maatregelen al toegepast’ aan om verder te gaan met uw definitieve aanvraag.</p>
        </div>
        <ng-container *ngIf="requests$ | async as paginatedRequests">
          <ng-container *ngIf="(paginatedRequests || []).length > 0; else noRequests">
            <table id="requests">
              <thead>
              <tr>
                <th col="col-empty">&nbsp;</th>
                <th class="col-status">Status</th>
                <th class="col-id">ID</th>
                <th class="col-type">Type</th>
                <th class="col-name">Naam/e-mailadres</th>
                <th class="col-name" style="display: none">Naam</th>
                <th class="col-mail" style="display: none">E-mailadres</th>
                <th class="col-municipality">Gemeente</th>
                <th class="col-address" style="display: none">Adres</th>
                <th class="col-postalcode" style="display: none">Postcode</th>
                <th class="col-city" style="display: none">Plaats</th>
                <th class="col-address" *ngIf="showAdminFilter">Adres</th>
                <th class="col-date">Datum/tijd</th>
                <th class="col-amount">Totaal subsidie bedrag</th>
                <th class="col-export" style="display: none">Doorzagen regenpijp</th>
                <th class="col-export" style="display: none">Infiltratiekrat</th>
                <th class="col-export" style="display: none">Infiltratieveld (M3)</th>
                <th class="col-export" style="display: none">Aantal afgekoppelde m2</th>
                <th class="col-export" style="display: none">Aantal m2 verhard oppervlakte dat wordt afgekoppeld</th>
                <th class="col-export" style="display: none">Subsidiebedrag</th>
                <th class="col-export" style="display: none">Investeringskosten</th>
                <th class="col-export" style="display: none">Aantal afgekoppelde m2</th>
                <th class="col-export" style="display: none">Inhoud voorziening</th>
                <th class="col-export" style="display: none">Subsidiebedrag</th>
                <th class="col-export" style="display: none">Regenton</th>
                <th class="col-export" style="display: none">Regenschutting</th>
                <th class="col-export" style="display: none">Subsidiebedrag</th>
                <th class="col-export" style="display: none">Aantal m2</th>
                <th class="col-export" style="display: none">Subsidiebedrag</th>
                <th class="col-export" style="display: none">Boomsoort</th>
                <th class="col-export" style="display: none">Boomsoort</th>
                <th class="col-export" style="display: none">Subsidiebedrag</th>
                <th class="col-export" style="display: none">Groendak m2</th>
                <th class="col-export" style="display: none">Groendak laagdikte 8-20cm</th>
                <th class="col-export" style="display: none">Groendak laagdikte 20-40cm</th>
                <th class="col-export" style="display: none">Groendak laagdikte meer dan 40cm</th>
                <th class="col-export" style="display: none">Groenblauwdak m2</th>
                <th class="col-export" style="display: none">Groenblauwdak laagdikte 8-20cm</th>
                <th class="col-export" style="display: none">Groenblauwdak laagdikte 20-40cm</th>
                <th class="col-export" style="display: none">Groenblauwdak laagdikte meer dan 40cm</th>
                <th class="col-export" style="display: none">18 liter/m2</th>
                <th class="col-export" style="display: none">30 liter/m2</th>
                <th class="col-export" style="display: none">50 liter/m2</th>
                <th class="col-export" style="display: none">Toeslag grassen, heester 30 liter m2</th>
                <th class="col-export" style="display: none">Toeslag grassen, heester 50 liter m2</th>
                <th class="col-export" style="display: none">Bouwkundige keuring</th>
                <th class="col-export" style="display: none">Subsidiebedrag</th>
                <th class="col-export" style="display: none">IBAN tnv</th>
                <th class="col-export" style="display: none">IBAN</th>
                <th class="col-export" style="display: none">Situatie</th>
                <th class="col-actions"><span style="display: none">Status gebruiker</span></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let request of paginatedRequests"
                  [class.new]="request.field_aanvraag_status === SubsidyRequestStatus.Opgeslagen">
                <td>
                  <div
                    *ngIf="request.field_aanvraag_status === SubsidyRequestStatus.WordtGecontroleerd || request.field_status_beoordeling_vd">
                    <button size="small"
                            [status]="request.field_status_beoordeling_vd ? SubsidyRequestVdControlStatuses[request.field_status_beoordeling_vd]: 'basic'"
                            outline nbButton
                            [nbTooltip]="request.field_status_beoordeling_vd ? SubsidyRequestVdStatusDescriptions[request.field_status_beoordeling_vd] : 'In behandeling bij Veluwe Duurzaam'"
                            nbTooltipStatus="basic"
                            shape="round">
                      <nb-icon icon="checkmark-outline"></nb-icon>
                    </button>
                  </div>
                  <div
                    *ngIf="request.field_aanvraag_status === SubsidyRequestStatus.InBehandelingBijGemeente || request.field_status_afhandeling_gem">
                    <button size="small"
                            [status]="request.field_status_afhandeling_gem ? SubsidyRequestMunicipalityControlStatuses[request.field_status_afhandeling_gem] : 'basic'"
                            outline nbButton
                            [nbTooltip]="request.field_status_afhandeling_gem ? SubsidyRequestMunicipalityStatusDescriptions[request.field_status_afhandeling_gem]: 'In behandeling bij gemeente'"
                            nbTooltipStatus="basic"
                            shape="round">
                      <nb-icon
                        [icon]="SubsidyRequestMunicipalityStatusIcons[request.field_status_afhandeling_gem] || 'checkmark-outline'"></nb-icon>
                    </button>
                  </div>
                </td>
                <td>{{SubsidyRequestStatusLabels[request.field_aanvraag_status]}}</td>
                <td>{{request.drupal_internal__id}}</td>
                <td>{{SubsidyRequestTypeLabels[request.field_type_aanvraag]}}</td>
                <td>{{request.field_voornaam}} {{request.field_achternaam}}<br/><span style="display: none">&nbsp;</span><span *ngIf="showAdminFilter">{{request.uid.mail}}</span></td>
                <td style="display: none">{{request.field_voornaam}} {{request.field_achternaam}}</td>
                <td style="display: none"><span *ngIf="showAdminFilter">{{request.uid.mail}}</span></td>
                <td>{{SubsidyRequestMunicipalityLabels[request.field_gemeente]}}</td>
                <td style="display: none"><span *ngIf="showAdminFilter">{{request.uid.field_address_line1}} {{request.uid.field_address_line2}}</span></td>
                <td style="display: none"><span *ngIf="showAdminFilter">{{request.uid.field_postal_code}}</span></td>
                <td style="display: none"><span *ngIf="showAdminFilter">{{request.uid.field_locality}}</span></td>
                <td *ngIf="showAdminFilter">{{request.uid.field_address_line1}}&nbsp;{{request.uid.field_address_line2}}&nbsp;<br/> {{request.uid.field_postal_code}} {{request.uid.field_locality}}</td>
                <td>{{(request.created * 1000) | date : 'd-M-y H:mm'}}</td>
                <td>{{getTotal(request) | currency: 'EUR':'symbol':'.2-2':'nl-NL'}}</td>
                <!-- EXPORT ONLY COLUMNS -->
                <td style="display: none">{{request.field_regenpijp_doorgezaagd}}</td>
                <td style="display: none">{{request.field_infiltratiekrat}}</td>
                <td style="display: none">{{request.field_aanleggen_infiltratieveld}}</td>
                <td style="display: none">{{request.field_afkoppelen_m3}}</td>
                <td style="display: none">{{request.field_afkoppelen_aantal_m2}}</td>
                <td style="display: none">{{request.field_afkoppelen_totale_subsidie | currency: 'EUR'}}</td>
                <td style="display: none">{{request.field_hemelwatervoorziening | currency: 'EUR'}}</td>
                <td style="display: none">{{request.field_hemelwater_m2_afgekoppeld}}</td>
                <td style="display: none">{{request.field_inhoud_voorziening_liters}}</td>
                <td style="display: none">{{request.field_hemelwater_totale_subsidie | currency: 'EUR'}}</td>
                <td style="display: none">{{request.field_regenton}}</td>
                <td style="display: none">{{request.field_regenschutting}}</td>
                <td style="display: none">{{request.field_regenton_totale_subsidie | currency: 'EUR'}}</td>
                <td style="display: none">{{request.field_vergroenen}}</td>
                <td style="display: none">{{request.field_vergroenen_totale_subsidie | currency: 'EUR'}}</td>
                <td style="display: none">
                  <span *ngIf="request.field_boom_een !== '-1'">{{request.field_boom_een}}</span>
                  <span *ngIf="request.field_boom_een == '-1'">0</span>
                </td>
                <td style="display: none">
                  <span *ngIf="request.field_boom_twee !== '-1'">{{request.field_boom_twee}}</span>
                  <span *ngIf="request.field_boom_twee == '-1'">0</span>
                </td>
                <td style="display: none">{{request.field_boom_totale_subsidie | currency: 'EUR'}}</td>
                <td style="display: none">{{request.field_groendak}}</td>
                <td style="display: none">{{request.field_groendak_laagdikte_8}}</td>
                <td style="display: none">{{request.field_groendak_laagdikte_20}}</td>
                <td style="display: none">{{request.field_groendak_laagdikte_40}}</td>
                <td style="display: none">{{request.field_groenblauwdak}}</td>
                <td style="display: none">{{request.field_groenblauwdak_laagdikte_8}}</td>
                <td style="display: none">{{request.field_groenblauwdak_laagdikte_20}}</td>
                <td style="display: none">{{request.field_groenblauwdak_laagdikte_40}}</td>
                <td style="display: none">{{request.field_waterberging_18_liter_m2}}</td>
                <td style="display: none">{{request.field_waterberging_30_liter_m2}}</td>
                <td style="display: none">{{request.field_waterberging_50_liter_m2}}</td>
                <td style="display: none">{{request.field_waterberging_30l_grassen}}</td>
                <td style="display: none">{{request.field_waterberging_50l_grassen}}</td>
                <td style="display: none">{{request.field_dak_keuringskosten | currency: 'EUR'}}</td>
                <td style="display: none">{{request.field_groendak_totale_subsidie | currency: 'EUR'}}</td>
                <td style="display: none">{{request.field_iban_tnv}}</td>
                <td style="display: none">{{request.field_iban}}</td>
                <td style="display: none">{{request.field_situatie}}</td>
                <!-- END EXPORT ONLY COLUMNS -->
                <td>
                  <div>
                    <button *ngIf="canSubmitRequest(request)" (click)="submit(request)" size="small" status="success"
                            outline nbButton
                            nbTooltip="Indienen bij gemeente" nbTooltipStatus="basic">
                      Indienen bij gemeente
                    </button>
                  </div>
                  <div>
                    <button (click)="view(request)" size="small" status="basic" outline nbButton
                            nbTooltip="Formulier inzien" nbTooltipStatus="basic">
                      <nb-icon icon="eye-outline"></nb-icon>
                    </button>
                  </div>
                  <div>
                    <button *ngIf="canEditRequest(request)" (click)="edit(request)" size="small" status="success"
                            outline nbButton nbTooltip="Formulier bewerken" nbTooltipStatus="basic">
                      <nb-icon icon="edit-outline"></nb-icon>
                    </button>
                  </div>
                  <div>
                    <button *ngIf="canEditRequestStatus(request)" (click)="editStatus(request)" size="small"
                            status="primary" outline nbButton nbTooltip="Status aanpassen" nbTooltipStatus="basic">
                      <nb-icon icon="more-horizontal-outline"></nb-icon>
                    </button>
                  </div>
                  <div>
                    <button *ngIf="canCancelRequest(request)" (click)="cancel(request)" size="small" status="warning"
                            outline nbButton nbTooltip="Annuleren" nbTooltipStatus="basic">
                      <nb-icon icon="close-circle-outline"></nb-icon>
                    </button>
                  </div>
                  <div>
                    <button *ngIf="canViewLogs" (click)="viewLogs(request)" size="small" status="basic" outline nbButton
                            nbTooltip="Notities" nbTooltipStatus="basic">
                      <nb-icon icon="file-text-outline"></nb-icon>
                    </button>
                  </div>
                  <div>
                    <button (click)="exportPDF(request)" size="small" status="basic" outline nbButton
                            nbTooltip="Exporteer PDF" nbTooltipStatus="basic">
                      <nb-icon icon="archive-outline"></nb-icon>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <app-pagination *ngIf="numPages$ | async as numPages" [numPages]="numPages"
                            [page]="currentPage$ | async" (pageChange)="currentPage$.next($event)"></app-pagination>
          </ng-container>
          <ng-template #noRequests>
            <p *ngIf="isFiltering$ | async; then noRequestsWithFilter else noRequestsWithoutFilter;">
            </p>
            <ng-template #noRequestsWithFilter>Er zijn geen resultaten voor de huidige zoekopdracht.</ng-template>
            <ng-template #noRequestsWithoutFilter>Er zijn nog geen aanvragen.</ng-template>
          </ng-template>
        </ng-container>
      </div>
    </section>
  </nb-card-body>
</nb-card>
