<nb-card>
  <nb-card-header>
    <span>Inschrijving bekijken</span>
    <button (click)="close()" nbButton status="danger" size="small"><nb-icon icon="close-outline"></nb-icon></button>
  </nb-card-header>
  <nb-card-body>
    <div class="fields">
      <div class="field">
        <div class="field-label">
          <strong><em>Datum</em></strong>
        </div>
        <div class="field-value">
          {{date.date * 1000 | date: 'd MMMM yyyy'}}
        </div>
      </div>
      <div class="field">
        <div class="field-label">
          <strong><em>Tijdstip</em></strong>
        </div>
        <div class="field-value">
          {{time.time_label}}
        </div>
      </div>
    </div>
    <div class="fields">
      <div class="field">
        <div class="field-label">
          <strong><em>Naam</em></strong>
        </div>
        <div class="field-value">
          {{registrationData.name}}
        </div>
      </div>
      <div class="field" *ngIf="!pdb">
        <div class="field-label">
          <strong><em>Gemeente</em></strong>
        </div>
        <div class="field-value">
          {{registrationData.municipality}}
        </div>
      </div>
      <div class="field" *ngIf="pdb">
        <div class="field-label">
          <strong><em>Woonplaats</em></strong>
        </div>
        <div class="field-value">
          {{registrationData.locality}}
        </div>
      </div>
      <div class="field">
        <div class="field-label">
          <strong><em>E-mailadres</em></strong>
        </div>
        <div class="field-value">
          {{registrationData.mail}}
        </div>
      </div>
      <div class="field">
        <div class="field-label">
          <strong><em>Aantal personen</em></strong>
        </div>
        <div class="field-value">
          {{registrationData.visitors}}
        </div>
      </div>
    </div>
    <div class="fields" *ngIf="registrationData.questionsOwner">
      <div class="field">
        <div class="field-label">
          <strong><em>Vraag van bezoeker</em></strong>
        </div>
        <div class="field-value" [innerHTML]="registrationData.questionsOwner"></div>
      </div>
    </div>
    <div class="fields" *ngIf="registrationData.remarksOwner">
      <div class="field">
        <div class="field-label">
          <strong><em>Toelichting van bezoeker</em></strong>
        </div>
        <div class="field-value" [innerHTML]="registrationData.remarksOwner"></div>
      </div>
    </div>
  </nb-card-body>
</nb-card>
