import {Component, Inject, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss']
})
export class VerifyAccountComponent implements OnInit {

  verified = false;
  error = false;
  errorMessage;

  constructor(private route: ActivatedRoute, private http: HttpClient, @Inject('drupalUrl') private drupalUrl) {
    this.route.queryParamMap.subscribe(params => {
      if (params.get('token')) {
        const token = JSON.parse(atob(params.get('token')));
        console.log('token', token);
        this.http.post(this.drupalUrl + '/rest/verify-account', token).subscribe(result => {
          console.log('verify account result', result);
          this.verified = true;
        }, error => {
          this.error = true;
          this.errorMessage = error.error.message;
          console.log('verify account error', error);
        });
      }
    });
  }

  ngOnInit(): void {
  }

}
