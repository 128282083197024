import {Component, OnInit, TemplateRef} from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import {RouteService} from '../../../services/route/route.service';
import {CancelRouteRegistrationDialogComponent} from '../cancel-route-registration/cancel-route-registration-dialog.component';

@Component({
  selector: 'app-my-registrations',
  templateUrl: './my-registrations.component.html',
  styleUrls: ['./my-registrations.component.scss']
})
export class MyRegistrationsComponent implements OnInit {

  registrations;
  isDoneLoading = false;

  constructor(
    protected router: Router,
    private routeService: RouteService,
    private dialogService: NbDialogService
    ) {
      this.loadRegistrations();
  }

  ngOnInit(): void {
  }

  private loadRegistrations() {
    this.isDoneLoading = false;
    this.routeService.getUserRegistrations().subscribe(results => {
      this.registrations = results;
      this.isDoneLoading = true;
    });

  }

  cancel(reg: any) {
    const registrationId = reg?.registration.uuid?.[0]?.value;
    this.dialogService.open(CancelRouteRegistrationDialogComponent, {
      closeOnBackdropClick: false,
      context: {
        registrationId
      }
    }).onClose.subscribe(result => {
      if (result) {
        this.loadRegistrations();
      }
    });

  }

}
