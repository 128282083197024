import {Component, Inject, OnInit} from '@angular/core';
import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-cancel-route-registration-page',
  templateUrl: './cancel-route-registration-page.component.html',
  styleUrls: ['./cancel-route-registration-page.component.scss']
})
export class CancelRouteRegistrationPageComponent implements OnInit {

  registrationId;

  canceled = false;
  error = false;

  environment = environment;

  get pdb() {
    return this.project === 'platformduurzamebedrijven';
  }

  get tkr() {
    return this.project === 'tuinenklimaatroute';
  }

  get vd() {
    return this.project === 'vd';
  }

  get ndhr() {
    return this.project === 'ndhr';
  }

  constructor(@Inject('project') private project: string, @Inject('drupalUrl') private drupalUrl: string, private toastrService: MajadaToastrService, private activatedRoute: ActivatedRoute, private http: HttpClient) {
  }

  isSaving = false;
  missingParameter = false;

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      console.log('params', params);
      this.registrationId = params.get('registrationId');
      if (!this.registrationId) {
        this.missingParameter = true;
      }
    });
  }

  cancelRegistration() {
    this.isSaving = true;
    this.http.post(`${this.drupalUrl}/api/route/cancel-registration`, {registration_id: this.registrationId}).subscribe(result => {
      this.canceled = true;
      this.error = false;
      this.isSaving = false;
      this.toastrService.success('De inschrijving is geannuleerd.');
    }, error => {
      this.canceled = false;
      this.error = true;
      this.toastrService.error('Kon de inschrijving niet annuleren');
    });
  }

}
