import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {MajadaToastrService} from '../toastr/majada-toastr.service';

@Injectable({
  providedIn: 'root'
})
export class AnonymousAuthGuardService {

  constructor(public authService: AuthService,
              public router: Router,
              private toastrService: MajadaToastrService) {
  }


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    if (this.authService.hasValidToken()) {
      return of(true);
    } else {
      localStorage.clear();
      return of(true);
    }
    return this.authService.canActivateProtectedRoutes$;
  }

}
