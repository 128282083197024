import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {ApiService} from '../../../services/api/api.service';
import {environment} from '../../../../environments/environment';
import {MajadaToastrService} from '../../../services/toastr/majada-toastr.service';
import {AppointmentsService} from '../../../services/appointments/appointments.service';
import {AuthService} from '../../../services/auth/auth.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Component({
  selector: 'app-appointment-dialog',
  templateUrl: './appointment-dialog.component.html',
  styleUrls: ['./appointment-dialog.component.scss'],
})
export class AppointmentDialogComponent implements OnInit, OnDestroy {

  submissionData;
  reservationId;
  editForm: FormGroup;

  bookingTimes = {};
  reservedTimeslot;

  subscription = new Subscription();

  constructor(
    protected ref: NbDialogRef<AppointmentDialogComponent>,
    private apiService: ApiService,
    private dialogService: NbDialogService,
    private majadaToastrService: MajadaToastrService,
    private appointmentsService: AppointmentsService,
    private authService: AuthService,
    private http: HttpClient,
    @Inject('drupalurl') private drupalUrl: string) {
  }

  ngOnInit(): void {
    //console.log('reservationId dialog', this.reservationId);
    this.getReservation();
  }

  getReservation() {
    const sub = this.http.get<any>(this.drupalUrl + '/jsonapi/reservation/reservation/' + this.reservationId).subscribe(result => {
        //console.log(result);
        this.reservedTimeslot = result.data.attributes.field_reserved_timeslot[0].split('+')[0];
        this.resetForm();
      }
    );
    this.subscription.add(sub);
  }

  cancelEdit() {
    this.ref.close();
  }

  resetForm() {


    this.editForm = new FormGroup({});

    // Try to determine all booking fields and build an array of times.
    if (this.submissionData.elements) {
      for (const fieldKey in this.submissionData.elements) {
        const element = this.submissionData.elements[fieldKey];

        if (element && element['#type'] === 'webform_booking') {
          //console.log('element', element);

          this.editForm.addControl('dateTime', new FormControl(this.reservedTimeslot, Validators.required));
          this.getAvailablePlacesPerField(fieldKey);
        }
      }
    }
  }

  getAvailablePlacesPerField(fieldKey) {
    const sub = this.apiService.get('/booking-available-times/' + this.submissionData.webform.id + '/' + fieldKey + '/' + this.submissionData.submission_data[fieldKey].product).subscribe(result => {

      const explodedSelectedDateTime = this.reservedTimeslot.split('T');
      const explodedDate = explodedSelectedDateTime[0].split('-');
      const formattedSelectedDate = explodedDate[2] + '-' + explodedDate[1] + '-' + explodedDate[0];
      const explodedSelectedTime = explodedSelectedDateTime[1].split(':');
      const formatedSelectedTime = explodedSelectedTime[0] + ':' + explodedSelectedTime[1];
      //console.log('explodedSelectedTime', explodedSelectedTime);
      const dates = [];
      // Loop through all dates.
      //console.log('datetime', result);
      result.forEach(datetime => {
        const times = {};
        if (datetime.date === formattedSelectedDate) {
          if (datetime.times[formatedSelectedTime] === undefined) {
            datetime.times[formatedSelectedTime] = formatedSelectedTime;
          }
        }

        // Change date to Y-m-d
        const explodedDate = datetime.date.split('-');
        const ymdDate = explodedDate[2] + '-' + explodedDate[1] + '-' + explodedDate[0];

        for (const time in datetime.times) {
          times[ymdDate + 'T' + time + ':00'] = time;
        }
        dates.push({date: datetime.date, times});
      });
      // Push the new dates to bookingTimes with field key.
      this.bookingTimes[fieldKey] = dates;
      //console.log('dates', dates);
    });
    this.subscription.add(sub);
  }

  showDeleteConfirmation(templateRef: TemplateRef<any>) {
    // Todo: implement confirmation dialog.
    this.dialogService.open(templateRef);
    // Temp direct call to deleteReservation()
    //this.deleteReservation();
  }

  get canDeleteAppointment() {
    return this.authService.canDeleteAppointments();
  }

  cancelDelete(ref: NbDialogRef<any>) {
    ref.close();
  }

  deleteReservation(ref: NbDialogRef<any>) {
    this.apiService.delete('/jsonapi/reservation/reservation/' + this.reservationId ).subscribe(result => {
      ref.close();
      this.ref.close();
      this.appointmentsService.appointmentsChanged();
      this.majadaToastrService.success('De reservering is verwijderd.', 'Verwijderd');
    }, error => {
      this.majadaToastrService.error('De reservering kon niet worden verwijderd.');
    });
  }

  editAppointment(ref: TemplateRef<any>) {
    this.dialogService.open(ref);
  }

  submitEditForm(ref: NbDialogRef<any>) {

    const body = {
      data: {
        id: this.reservationId,
        type: 'reservation--reservation',
        attributes: {
          field_reserved_timeslot: this.editForm.get('dateTime').value
        }
      }
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        'Accept': 'application/vnd.api+json'
      })
    };

    this.http.patch(this.drupalUrl + '/jsonapi/reservation/reservation/' + this.reservationId, body, httpOptions).subscribe(result => {
      this.majadaToastrService.success('De reservering is bijgewerkt met de nieuwe tijd.', 'Bijgewerkt');
      ref.close();
    }, error => {
      this.majadaToastrService.error('De reservering kon niet worden bijgewerkt');
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
