import {Component, Inject, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-route-registration-information',
  templateUrl: './route-registration-information.component.html',
  styleUrls: ['./route-registration-information.component.scss']
})
export class RouteRegistrationInformationComponent implements OnInit {

  @Input()
  registrationData;

  contactPersonLabels = new Map<string, string>([
    ['ndhr', 'Huiseigenaar'],
    ['platformduurzamebedrijven', 'Contactpersoon'],
    ['tuinenklimaatroute', 'Tuinbezitter']
  ]);

  get contactPersonLabel() {
    return this.contactPersonLabels.get(this.project);
  }

  constructor(
    @Inject('project') public project: string,
    @Inject('wordpressUrl') public wordpressUrl: string,
    ) {
  }

  ngOnInit(): void {
    console.log('registrationData', this.registrationData);
  }

}
