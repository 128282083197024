import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {pick} from 'lodash-es';
import { forkJoin } from 'rxjs';

import {
  SubsidyRequest,
  SubsidyRequestStatusLabels,
  SubsidyRequestMunicipalityLabels,
  SubsidyRequestTypeLabels,
  parseSubsidyRequestAPIResult,
  SubsidyGardenLocationLabels,
  } from 'src/app/models/subsidy.models';
import { AuthService } from 'src/app/services/auth/auth.service';
import {SubsidyService} from 'src/app/services/subsidy/subsidy.service';
import {MajadaToastrService} from 'src/app/services/toastr/majada-toastr.service';
import { getDisplayFlags } from '../subsidy-request-form/subsidy-form-state';

@Component({
  selector: 'app-view-subsidy-request-dialog',
  templateUrl: './view-subsidy-request-dialog.component.html',
  styleUrls: ['./view-subsidy-request-dialog.component.scss', '../../../shared/shared.scss']
})
export class ViewSubsidyRequestDialogComponent implements OnInit {

  isLoading: boolean;
  isSaving: boolean;

  request: SubsidyRequest;
  reviewData: any;
  showSpinner = true;

  SubsidyRequestStatusLabels = SubsidyRequestStatusLabels;
  SubsidyRequestMunicipalityLabels = SubsidyRequestMunicipalityLabels;
  SubsidyRequestTypeLabels = SubsidyRequestTypeLabels;

  constructor(
    protected ref: NbDialogRef<ViewSubsidyRequestDialogComponent>,
    private subsidyService: SubsidyService,
  ) {
  }

  ngOnInit(): void {


    this.subsidyService.getRequestById(this.request.__uuid?.toString()).subscribe(request => {

      const req = parseSubsidyRequestAPIResult(request);

      this.subsidyService.loadImages(req.drupal_internal__id).subscribe(images => {

        forkJoin(this.subsidyService.getRequestComputations(request)).subscribe(data => {

          const step1Fields = [
            'field_gemeente',
            'field_binnen_bebouwde_kom',
            'field_gebouwd_voor_2015',
            'field_type_aanvraag',
            'field_situatie'
          ];
          const step1FormData = pick(req, ...step1Fields);

          const displayFlags = getDisplayFlags({ step1FormData }) as any;
          displayFlags.showGroendakDetailFields =
            parseFloat(req.field_groendak_laagdikte_8) > 0 ||
            parseFloat(req.field_groendak_laagdikte_20) > 0 ||
            parseFloat(req.field_groendak_laagdikte_40) > 0 ||
            parseFloat(req.field_groenblauwdak_laagdikte_8) > 0 ||
            parseFloat(req.field_groenblauwdak_laagdikte_20) > 0 ||
            parseFloat(req.field_groenblauwdak_laagdikte_40) > 0;

          const subsidyData = {
            regenton_subsidie: data[0].regenton.value,
            regenschutting_subsidie: data[0].regenschutting.value,
            //
            regenpijp_doorgezaagd_subsidie: data[1].regenpijp.value,
            infiltratiekrat_subsidie: data[1].infiltratiekrat.value,
            aanleggen_infiltratieveld_subsidie: data[1].infiltratieveld.value,
            afkoppelen_m3_subsidie: data[1].afkoppelen.value,
            //
            vergroenen_subsidie: data[2].vergroenen.value,
            //
            boom_een_subsidie: data[3].boom_een.value,
            boom_twee_subsidie: data[3].boom_twee.value,
            //
            groendak_subsidie: data[4].groendak.value,
            groendak_laagdikte_8_subsidie: data[4].groendak_laagdikte_8.value,
            groendak_laagdikte_20_subsidie: data[4].groendak_laagdikte_20.value,
            groendak_laagdikte_40_subsidie: data[4].groendak_laagdikte_40.value,
            groenblauwdak_subsidie: data[4].groenblauwdak.value,
            groenblauwdak_laagdikte_8_subsidie: data[4].groenblauwdak_laagdikte_8.value,
            groenblauwdak_laagdikte_20_subsidie: data[4].groenblauwdak_laagdikte_20.value,
            groenblauwdak_laagdikte_40_subsidie: data[4].groenblauwdak_laagdikte_40.value,
            waterberging_18_liter_m2_subsidie: data[4].waterberging_18.value,
            waterberging_30_liter_m2_subsidie: data[4].waterberging_30.value,
            waterberging_50_liter_m2_subsidie: data[4].waterberging_50.value,
            waterberging_30l_grassen_subsidie: data[4].waterberging_30_grassen.value,
            waterberging_50l_grassen_subsidie: data[4].waterberging_50_grassen.value,
            dak_keuringskosten_subsidie: data[4].bouwkundige_keuring.value,
          };

          if (displayFlags.showGroendakDetailFields) {
            subsidyData.groendak_subsidie =
              data[4].groendak_laagdikte_8.value +
              data[4].groendak_laagdikte_20.value +
              data[4].groendak_laagdikte_40.value;
            subsidyData.groenblauwdak_subsidie =
              data[4].groenblauwdak_laagdikte_8.value +
              data[4].groenblauwdak_laagdikte_20.value +
              data[4].groenblauwdak_laagdikte_40.value;
          }

          const totalSubsidy = req.field_hemelwater_totale_subsidie +
            req.field_groendak_totale_subsidie +
            req.field_boom_totale_subsidie +
            req.field_vergroenen_totale_subsidie +
            req.field_afkoppelen_totale_subsidie +
            req.field_regenton_totale_subsidie;

          this.reviewData = {
            emailAddress: req.uid.mail,
            totalSubsidy,
            images,
            displayFlags,
            municipality: req.field_gemeente,
            ...req,
            ...subsidyData,
            user: req.uid
          };

          console.log('sd', subsidyData);

          this.showSpinner = false;
        });

      });

    });
  }

  close() {
    this.ref.close();
  }

}
