import {Component, OnInit, OnDestroy} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {BehaviorSubject, Subscription, combineLatest} from 'rxjs';
import {map, tap, shareReplay} from 'rxjs/operators';
import {groupBy, mapValues, zipObject, every} from 'lodash-es';

import {EnergyLibraryService} from '../../../services/energy-library/energy-library.service';
import {
  EnergyLibraryAssignmentStatusLabels,
  EnergyLibraryRequestView,
  EnergyLibraryLocation,
  EnergyLibraryAssignmentStatus
} from '../../../models/energy-library.models';
import {AssignProductDialogComponent} from '../assign-product-dialog/assign-product-dialog.component';
import {ViewEnergyLibraryRequestDialogComponent} from '../view-energy-library-request-dialog/view-energy-library-request-dialog.component';
import {AuthService} from 'src/app/services/auth/auth.service';

interface RequestsPerProductType {
  [productType: string]: EnergyLibraryRequestView[];
}

@Component({
  selector: 'app-energy-library',
  templateUrl: './request-overview.component.html',
  styleUrls: ['./request-overview.component.scss']
})
export class RequestOverviewComponent implements OnInit, OnDestroy {

  private subs: Subscription[];
  private pageSize = 10;

  statusFilterModel = '1';

  requests$ = new BehaviorSubject<RequestsPerProductType>(null);
  productTypes$ = new BehaviorSubject<string[]>(null);
  locations$ = new BehaviorSubject<EnergyLibraryLocation[]>(null);

  nameFilterModel$ = new BehaviorSubject<string>(null);
  productNumberFilterModel$ = new BehaviorSubject<string>(null);
  locationFilterModel$ = new BehaviorSubject<string>(null);
  statusFilterModel$ = new BehaviorSubject<string>('1');
  currentPage$ = new BehaviorSubject(1);

  EnergyLibraryAssignmentStatusLabels = EnergyLibraryAssignmentStatusLabels;

  isLoading$ = combineLatest(this.requests$, this.productTypes$, this.locations$).pipe(map(x => !every(x)));
  filters$ = combineLatest(this.nameFilterModel$, this.productNumberFilterModel$, this.statusFilterModel$, this.locationFilterModel$);
  isFiltering$ = this.filters$.pipe(map(([municipality, product, status, location]) =>
    !!(municipality || product || (status && status !== '0') || location)));

  filteredRequests$ = combineLatest(this.requests$, this.filters$).pipe(
    map(([requests, [name, productNumber, status, location]]) => {
      if (!requests) {
        return null;
      }
      const filterMatch = (search: string, field: string) => !search || (field || '').toLowerCase().includes(search.toLowerCase());

      const filter = (req: EnergyLibraryRequestView) => {
        if (location && location !== req.locatie_uuid) {
          return false;
        }
        if (!filterMatch(name, req.naam)) {
          return false;
        }
        if (!filterMatch(productNumber, req.title)) {
          return false;
        }
        if (status && status !== '0' && req.status !== status) {
          return false;
        }
        return true;
      };

      return mapValues(requests, reqs => reqs.filter(filter));
    }),
    tap(() => this.currentPage$.next(1)),
    shareReplay()
  );

  numPages$ = combineLatest(this.filteredRequests$, this.productTypes$).pipe(
    map(([filteredRequests, productTypes]) => {
      if (!productTypes) {
        return null;
      }
      const numPages = productTypes.map(productType => {
        const numEntries = filteredRequests?.[productType]?.length || 0;
        return Math.ceil(numEntries / this.pageSize);
      });
      return zipObject(productTypes, numPages);
    })
  );

  paginatedRequests$ = combineLatest(this.filteredRequests$, this.currentPage$).pipe(
    map(([filteredRequests, currentPage]) => {
      const paginate = (reqs: EnergyLibraryRequestView[]) => {
        const start = this.pageSize * (currentPage - 1);
        const end = Math.min(reqs.length, start + this.pageSize);
        return reqs.slice(start, end);
      };

      return mapValues(filteredRequests, paginate);
    }),
    shareReplay()
  );

  numResultsLabel$ = combineLatest(this.requests$, this.filteredRequests$, this.productTypes$).pipe(
    map(([requests, filteredRequests, productTypes]) => {
      const label = (productType: string) => {
        const numResults = requests?.[productType]?.length || 0;
        if (numResults === 0) {
          return '';
        }

        const numFilteredResults = filteredRequests?.[productType]?.length || 0;
        const filtered = `(${numFilteredResults}/${numResults})`;
        const nonFiltered = `(${numResults})`;
        return numFilteredResults < numResults ? filtered : nonFiltered;
      };
      return zipObject(productTypes, productTypes.map(label));
    })
  );


  constructor(
    private authService: AuthService,
    private dialogService: NbDialogService,
    private energyLibraryService: EnergyLibraryService
  ) {
    this.subs = [
      this.energyLibraryService.requestChanged$.subscribe(this.loadRequests.bind(this)),
      this.statusFilterModel$.subscribe(val => this.statusFilterModel = val)
    ];
  }

  ngOnInit(): void {
    this.loadProductTypes();
    this.loadLocations();
    this.loadRequests();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
    this.requests$.complete();
    this.productTypes$.complete();
    this.nameFilterModel$.complete();
    this.productNumberFilterModel$.complete();
    this.statusFilterModel$.complete();
    this.currentPage$.complete();
  }

  private loadLocations() {
    const identityClaims = this.authService.identityClaims;
    if (identityClaims['roles']) {
      if (identityClaims['roles'].includes('administrator') || identityClaims['roles'].includes('root')) {
        this.energyLibraryService.getLocations().subscribe(locations => {
          this.locations$.next(locations);
        });
      } else {
        this.energyLibraryService.getLocationsForUser(this.authService.uuid).subscribe(locations => {
          this.locations$.next(locations);
        });
      }
    }
  }

  private loadProductTypes() {
    this.energyLibraryService.getProductTypes().pipe(
      map(terms => terms.map(term => term.name).concat('Overige producten'))
    ).subscribe(types => {
      this.productTypes$.next(types);
    });
  }

  private loadRequests() {
    this.requests$.next(null);
    this.energyLibraryService.getRequests().subscribe(requests => {
      this.requests$.next(groupBy(requests, req => req.product_categorie));
    });
  }

  viewRequest(request: EnergyLibraryRequestView) {
    // Todo: implement view logic
    this.dialogService.open(ViewEnergyLibraryRequestDialogComponent, {
      context: {
        request,
        productType: request.product_categorie,
        assignmentId: request.toewijzing_uuid
      }
    });
  }

  assign(request: EnergyLibraryRequestView) {
    this.dialogService.open(AssignProductDialogComponent, {
      context: {
        request,
        productType: request.product_categorie,
        assignmentId: request.toewijzing_uuid
      }
    });
  }
}
