<ng-template #loginForm>
  <app-login></app-login>
</ng-template>
<ng-container *ngIf="isAuthenticated | async else loginForm">
  <h3>Dashboard</h3>
  <div class="content">
    <nb-card class="user-info" nbSpinnerStatus="basic" [nbSpinner]="isLoading">
      <nb-card-body class="user" *ngIf="!isLoading">
        <img *ngIf="!!profilePhoto" [src]="profilePhoto"/>
        <div *ngIf="!profilePhoto" class="no-image"><span>Geen foto</span></div>
        <div class="info">
          <h2>Welkom <strong>{{ user?.field_given_name }} {{ user?.field_family_name }}</strong>,</h2>
          <p>Gebruik het menu aan de linkerkant om door het dashboard te navigeren.</p>
          <a href="/profiel">Profiel bewerken</a>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</ng-container>
