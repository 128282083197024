import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {tap} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {Observable, of} from 'rxjs';
import {MajadaToastrService} from '../toastr/majada-toastr.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router,
    private toastrService: MajadaToastrService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {

    //return of(this.authService.hasValidIdToken());
    return this.authService.canActivateProtectedRoutes$
      .pipe(tap(x => {
        console.log('canActivateProtectedRoutes$ x', x);
        if (x) {
          return true;
        }
        this.toastrService.error(
          $localize`Log eerst in en probeer het dan opnieuw.`,
          $localize`Toegang geweigerd.`
        );
        this.router.navigateByUrl('/');
        return false;
      }));
  }
}
