import {HttpClient, HttpHeaders} from '@angular/common/http';
import {forkJoin, Observable} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import pdfjs from '@bundled-es-modules/pdfjs-dist/build/pdf';
import { range } from 'lodash';


export function loadPDFAsImage(http: HttpClient, url: string) {
  const cacheHeaders = new HttpHeaders({
    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    Pragma: 'no-cache',
    Expires: '0'
  });

  pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/build/pdf.worker.min.js';

  return http.get(url, {responseType: 'blob', withCredentials: true, headers: cacheHeaders}).pipe(flatMap(data => new Observable(obs => {
    (data as any).arrayBuffer().then(ab => pdfjs.getDocument(ab).promise).then(pdf => {

      const getPages$ = range(1, pdf.numPages + 1).map(i => new Observable(page$ => {
        pdf.getPage(i).then((page) => {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const viewport = page.getViewport({ scale: 1.5 });
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport
          };
          page.render(renderContext).promise.then(() => {
            const dataUrl = canvas.toDataURL();
            const image = new Image(viewport.width, viewport.height);
            image.onerror = (err) => {
              console.error(err);
              page$.complete();
            };
            image.onload = () => {
              page$.next(image);
              page$.complete();
            };
            image.src = dataUrl;
          });
        });
      }));

      forkJoin(getPages$).subscribe(pages => {
        obs.next(pages);
        obs.complete();
      });

    }, err => {
      console.error(err);
      obs.error(err);
    });
  })));
}
