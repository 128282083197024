import {Injectable} from '@angular/core';
import {
  Router, Resolve, RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {Location} from '@angular/common';
import {catchError, map} from 'rxjs/operators';

import {NodeService} from './node.service';
import {EMPTY} from 'rxjs';

@Injectable()
export class DrupalNodeResolver implements Resolve<any> {

  constructor(private nodeService: NodeService, private router: Router, private location: Location) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    // console.log('resolver url: ' + state.url);

    const found = false;
    //if (!node) {

    return this.nodeService.getNode(state.url).pipe(
      map(nodeResult => {
        if (nodeResult) {
          return nodeResult;
        } else {
          // this.router.navigateByUrl('/404');
          return null;
        }
      }),
      catchError((err, caught) => {
        /*if (!isPlatformBrowser(this.platformId)) {
            this.res.status(404);
        }*/
        return EMPTY;
      })
    );
    // }
  }

}
