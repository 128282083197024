<div class="card closable-dialog">
  <nb-card>
    <nb-card-header>Subsidieaanvraag annuleren</nb-card-header>
      <nb-card-body [nbSpinner]="showSpinner" nbSpinnerStatus="basic">
        <p>Weet u zeker dat u onderstaande aanvraag wilt annuleren?</p>
        <h6>Aanvraaggegevens</h6>
        <dl>
          <dt>Type:</dt><dd>{{SubsidyRequestTypeLabels[request.field_type_aanvraag]}}</dd>
          <dt>Naam:</dt><dd>{{request.field_voornaam}} {{request.field_achternaam}}</dd>
          <dt>Gemeente:</dt><dd>{{SubsidyRequestMunicipalityLabels[request.field_gemeente]}}</dd>
          <dt>Datum:</dt><dd>{{request.created | date : 'd-M-y'}}</dd>
        </dl>
      </nb-card-body>
      <nb-card-footer>
        <div class="card--actions">
          <button type="button" outline status="warning" nbButton (click)="close()">Nee, aanvraag bewaren</button>
          <button type="button" outline status="danger" [disabled]="!canSubmit" nbButton (click)="save()">Ja, aanvraag annuleren</button>
        </div>
      </nb-card-footer>
    </nb-card>
  <button nbButton status="danger" (click)="close()" class="close-button" size="large" filled="true"><nb-icon icon="close-outline"></nb-icon></button>
</div>
