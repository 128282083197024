import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {NbMenuBag, NbMenuItem, NbMenuService, NbSidebarService} from '@nebular/theme';
import {AuthService} from '../../../services/auth/auth.service';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AuthConfig} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class HeaderComponent implements OnInit {

  loggedInMenu = [
    /*{
      title: 'My profile',
      icon: 'eye-outline',
    },
    {
      title: 'Edit profile',
      icon: 'edit-2-outline',
      data: {
        id: 'edit-profile'
      }
    },*/
    /*{
      title: 'Profiel bewerken',
      icon: 'person-outline',
      link: '/profiel'
    },*/
    {
      title: 'Uitloggen',
      icon: 'log-out-outline',
      data: {
        id: 'logout'
      }
    }];

  loggedOutMenu = [{
    title: 'Inloggen',
    icon: 'log-in-outline',
    link: '/'
  }];

  isAuthenticated: Observable<boolean>;

  constructor(private sidebarService: NbSidebarService, private authService: AuthService, private nbMenuService: NbMenuService, private authConfig: AuthConfig, @Inject('drupalUrl') private drupalUrl: string) {
    this.isAuthenticated = this.authService.isAuthenticated$;
  }

  ngOnInit() {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({tag}) => tag === 'user-menu'),
      )
      .subscribe((item: NbMenuBag) => {
        if (item.item && item.item.data.id === 'logout') {
          this.authService.logout();
        } else if (item.item.data.id === 'edit-profile') {
          window.location.href =
            this.drupalUrl + '/user/edit?redirectUri=' + window.location + '&clientId=' + this.authConfig.clientId;
        } /*else if (item.item.data.id === 'login') {
          this.authService.login();
        }*/
      });
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

}
