
<ng-container>
  <h3 class="hide-desktop">Situatie onderdelen</h3>
  <p>Klik op een subsidie onderdeel en vul de gegevens in.</p>
  <nb-accordion>
    <nb-accordion-item *ngIf="displayFlags.showPart2">
      <nb-accordion-item-header>
        Afkoppelen regenwater <nb-icon *ngIf="!part2.valid" icon="alert-triangle-outline" status="danger"></nb-icon>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <form [formGroup]="part2">
          <section>
            <p>Let er op dat bij het aanleggen van een afkoppelvoorziening je grond goed doorlaatbaar is om wateroverlast te voorkomen.</p>
            <table class="afkoppelen">
              <thead>
                <tr><th>Maatregel</th><th>Aantal</th><th>Totaal</th></tr>
              </thead>
              <tbody *ngIf="displayFlags.showPart2TypeA">
                <tr>
                  <td>Regenpijp doorgezaagd zonder voorziening</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_regenpijp_doorgezaagd"
                    [status]="part2.errors?.subsidy ? 'danger' : 'basic'"></td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part2.get('regenpijp_doorgezaagd_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Infiltratiekrat</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_infiltratiekrat"></td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part2.get('infiltratiekrat_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr> 
                <tr>
                  <td>Aanleggen infiltratieveld, wadi of regenwatervijver (aantal m³ afgegraven grond)</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_aanleggen_infiltratieveld">m³</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part2.get('aanleggen_infiltratieveld_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>               
                <tr>
                  <td colspan="3">
                    <span *ngIf="part2.errors?.subsidy" class="validation-error">Kies maximaal 1 regenpijp</span>                    
                    <b>Subsidiebedrag</b>
                  </td>
                </tr>
                <tr>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part2.get('field_afkoppelen_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
              <tbody *ngIf="displayFlags.showPart2TypeB">
                <tr>
                  <td>Afkoppelen</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_afkoppelen_m3">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part2.get('afkoppelen_m3_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>          
                <tr>
                  <td colspan="3">
                    <span *ngIf="part2.errors?.subsidy" class="validation-error">Kies maximaal 1 regenpijp</span>                    
                    <b>Subsidiebedrag</b>
                  </td>
                </tr>
                <tr>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part2.get('field_afkoppelen_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                  <td colspan="2"></td>
                </tr>                      
              </tbody>
            </table>
            <ng-container *ngIf="displayFlags.showPart2TypeA">
              <ng-container *ngIf="this.part2.get('field_infiltratiekrat').value > 0">
                <input type="text" nbInput class="inline-text-input" formControlName="field_infiltratiekrat_liters" 
                  [status]="fieldStatus(this.part2.get('field_infiltratiekrat_liters'))">Totaal aantal liters per infiltratie krat 
                  <span *ngIf="this.part2.get('field_infiltratiekrat_liters').invalid" class="inline-validation-error">(minimaal {{minimumInfiltratiekrat()}} liter)</span><br>
                </ng-container>
              <input type="text" nbInput class="inline-text-input" formControlName="field_afkoppelen_aantal_m2" 
                [status]="fieldStatus(this.part2.get('field_afkoppelen_aantal_m2'))">Aantal m² verhard oppervlakte dat wordt afgekoppeld
                <span *ngIf="this.part2.get('field_afkoppelen_aantal_m2').invalid" class="inline-validation-error">(minimaal {{minimumVerhardOppervlak()}}m²)</span><br>
              <nb-checkbox formControlName="field_minimale_berging" [status]="fieldStatus(this.part2.get('field_minimale_berging'))">
                Er is minimaal 20 mm (=20 liter per afgekoppelde m²) berging op eigen terrein gerealiseerd.</nb-checkbox><br>
            </ng-container>
            <ng-container  *ngIf="displayFlags.showPart2TypeB">
              <nb-checkbox formControlName="field_afgekoppelde_oppervlakte" [status]="fieldStatus(this.part2.get('field_afgekoppelde_oppervlakte'))">
                Er is minimaal 20 m² verhard oppervlak afgekoppeld van de gemengde riolering.</nb-checkbox><br>
              <nb-checkbox formControlName="field_minimale_berging" [status]="fieldStatus(this.part2.get('field_minimale_berging'))">
                Er is minimaal 20 mm (=20 liter per afgekoppelde m²) berging op eigen terrein gerealiseerd.</nb-checkbox><br>

              <nb-checkbox formControlName="field_niet_aangesloten_drukriool" [status]="fieldStatus(this.part2.get('field_niet_aangesloten_drukriool'))">
                Mijn pand/woning is niet aangesloten op een drukriool.</nb-checkbox><br>
            </ng-container>
          </section>        

          <section>
            <h6>Ik zal deze maatregel gaan uitvoeren in mijn:</h6>
            <nb-radio-group formControlName="field_afkoppelen_uitvoeren_in" [status]="fieldStatus(this.part2.get('field_afkoppelen_uitvoeren_in'))">
              <nb-radio value="voortuin">Voortuin</nb-radio>
              <nb-radio value="achtertuin">Achtertuin</nb-radio>
              <nb-radio value="voor_en_achtertuin">Voor- en achtertuin</nb-radio>
            </nb-radio-group>
          </section>        

          <section>
            <h6>Upload foto van locatie inclusief een foto van de regenpijp op dit moment</h6>
            <app-upload-dropzone form="part2" entity="subsidieaanvraag/aanvraag" field="field_afkoppelen_foto" [initialFiles]="images?.field_afkoppelen_foto" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part2.get('field_afkoppelen_foto'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_afkoppelen_foto_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>

          <section>
            <h6>Schets toevoegen</h6>
            <p>Laat zien waar de afkoppelvoorziening komt en hoe groot deze wordt.</p>
            <app-upload-dropzone form="part2" entity="subsidieaanvraag/aanvraag" field="field_afkoppelen_schets" [initialFiles]="images?.field_afkoppelen_schets" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part2.get('field_afkoppelen_schets'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_afkoppelen_schets_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>        

          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">

            <section *ngIf="displayFlags.showPart2TypeA && this.part2.get('field_infiltratiekrat').value > 0">
              <h6>Upload foto van plaatsing infiltratie kratten</h6>
              <app-upload-dropzone form="part2" entity="subsidieaanvraag/aanvraag" field="field_infiltratiekrat_foto" [initialFiles]="images?.field_infiltratiekrat_foto" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part2.get('field_infiltratiekrat_foto'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_infiltratiekrat_foto_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
              <br>
            </section>            

            <section>
              <h6>Bon uploaden</h6>
              <app-upload-dropzone form="part2" entity="subsidieaanvraag/aanvraag" field="field_afkoppelen_bon" [initialFiles]="images?.field_afkoppelen_bon" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part2.get('field_afkoppelen_bon'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_afkoppelen_bon_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van aankoop producten</h6>
              <input type="text" nbInput formControlName="field_afkoppelen_datum_aankoop" [nbDatepicker]="field_afkoppelen_datum_aankoop_picker"
                [status]="fieldStatus(this.part2.get('field_afkoppelen_datum_aankoop'))">
              <nb-datepicker format="DD-MM-YYYY" #field_afkoppelen_datum_aankoop_picker></nb-datepicker>

            </section>

            <section>
              <h6>Upload een foto waarop de voorziening goed te zien is.</h6>
              <ul>
                <li>Heb je gekozen voor een infiltratiekrat upload dan ook de foto van tijdens de plaatsing.</li>
                <li>Heb je gekozen voor infiltratieveld maak dan ook een foto met een meetlint waarop te zien is hoe groot het infiltratieveld is.</li>
                <li>Heb je je regenpijp doorgezaagd zonder voorziening upload dan een foto van de regenpijp en laat zien waar het water kan infiltreren.</li>
              </ul>
              <app-upload-dropzone form="part2" entity="subsidieaanvraag/aanvraag" field="field_afkoppelen_foto_uitvoeren" [initialFiles]="images?.field_afkoppelen_foto_uitvoeren" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part2.get('field_afkoppelen_foto_uitvoeren'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_afkoppelen_foto_uitvoeren_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van uitvoering</h6>
              <input type="text" nbInput formControlName="field_afkoppelen_datum_uitvoeren" [nbDatepicker]="field_afkoppelen_datum_uitvoeren_picker"
                [status]="fieldStatus(this.part2.get('field_afkoppelen_datum_uitvoeren'))">
              <nb-datepicker format="DD-MM-YYYY" #field_afkoppelen_datum_uitvoeren_picker></nb-datepicker>
            </section>        
          </ng-container>        
        </form>
      </nb-accordion-item-body>
    </nb-accordion-item>     

    <nb-accordion-item *ngIf="displayFlags.showPart6">
        <nb-accordion-item-header>
          Voorziening nuttig gebruik regenwater <nb-icon *ngIf="!part6.valid" icon="alert-triangle-outline" status="danger"></nb-icon>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <form [formGroup]="part6">
            <section>
              <table class="hemelwater">
                <thead>
                  <tr><th>Maatregel</th><th>Investeringskosten</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Regenwatervoorziening</td>
                    <td><input type="text" nbInput status="basic" placeholder="20% tot een maximum van €500" formControlName="field_hemelwatervoorziening"></td>
                  </tr>
                  <tr>
                    <td colspan="3"><b>Subsidiebedrag</b></td>
                  </tr>
                  <tr>
                    <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                      <span *ngIf="!isLoading">{{ this.part6.get('field_hemelwater_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                </tbody>
              </table>
              <nb-checkbox formControlName="field_hemelwater_gebruik"  [status]="fieldStatus(this.part6.get('field_hemelwater_gebruik'))">Het regenwater wordt alleen gebruikt voor watervoeding van toilet en/of wasmachines en/of tuinbesproeiing.</nb-checkbox><br>
              <ng-container *ngIf="displayFlags.showPart6TypeB">
                <nb-checkbox formControlName="field_hemelwater_afkoppel_riool" [status]="fieldStatus(this.part6.get('field_hemelwater_afkoppel_riool'))">Er wordt minimaal 20 m² verhard dakoppervlak afgekoppeld van de gemengde riolering.</nb-checkbox><br>
                <nb-checkbox formControlName="field_minimaal_1000l_gebuffer" [status]="fieldStatus(this.part6.get('field_minimaal_1000l_gebuffer'))">Er wordt minimaal 1000 liter regenwater gebufferd ter vervanging van het gebruik van leidingwater.</nb-checkbox><br>
              </ng-container>
              <br>
              <input type="text" nbInput class="inline-text-input" formControlName="field_hemelwater_m2_afgekoppeld" 
                [status]="fieldStatus(this.part6.get('field_hemelwater_m2_afgekoppeld'))">Aantal m² verhard oppervlakte dat wordt afgekoppeld
                <span *ngIf="this.part6.get('field_hemelwater_m2_afgekoppeld').invalid" class="inline-validation-error">(minimaal 20m²)</span><br>
              <input type="text" nbInput class="inline-text-input" formControlName="field_inhoud_voorziening_liters"
                [status]="fieldStatus(this.part6.get('field_inhoud_voorziening_liters'))">Inhoud voorziening in liters
            </section>         
  
            <section>
              <h6>Ik zal deze maatregel gaan uitvoeren in mijn:</h6>
              <nb-radio-group formControlName="field_hemelwater_uitvoeren_in" [status]="fieldStatus(this.part6.get('field_hemelwater_uitvoeren_in'))">
                <nb-radio value="voortuin">Voortuin</nb-radio>
                <nb-radio value="achtertuin">Achtertuin</nb-radio>
                <nb-radio value="voor_en_achtertuin">Voor- en achtertuin</nb-radio>
              </nb-radio-group>
            </section>        
  
            <section>
              <h6>Voeg een foto toe van de locatie waar de regenwatervoorziening wordt geplaatst.</h6>
              <app-upload-dropzone form="part6" entity="subsidieaanvraag/aanvraag" field="field_hemelwater_foto" [initialFiles]="images?.field_hemelwater_foto" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part6.get('field_hemelwater_foto'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_hemelwater_foto_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>
  
            <section>
              <h6>Voeg een schets toe waarop te zien is hoe de regenwatervoorziening wordt geplaatst in de tuin.</h6>
              <app-upload-dropzone form="part6" entity="subsidieaanvraag/aanvraag" field="field_hemelwater_schets" [initialFiles]="images?.field_hemelwater_schets" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part6.get('field_hemelwater_schets'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_hemelwater_schets_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>        
  
            <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
              <section>
                <h6>Bon uploaden</h6>
                <app-upload-dropzone form="part6" entity="subsidieaanvraag/aanvraag" field="field_hemelwater_bon"  [initialFiles]="images?.field_hemelwater_bon"
                  (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part6.get('field_hemelwater_bon'))"></app-upload-dropzone>
                  <nb-checkbox formControlName="field_hemelwater_bon_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
              </section>
  
              <section>
                <h6>Datum van aankoop producten</h6>
                <input type="text" nbInput formControlName="field_hemelwater_datum_aankoop" [nbDatepicker]="field_hemelwater_datum_aankoop_picker"
                [status]="fieldStatus(this.part6.get('field_hemelwater_datum_aankoop'))">
                <nb-datepicker format="DD-MM-YYYY" #field_hemelwater_datum_aankoop_picker></nb-datepicker>
              </section>
  
              <section>
                <h6>Voeg een foto toe van de locatie waar de regenwatervoorziening geplaatst is en een foto van tijdens de plaatsing van de voorziening.</h6>
                <app-upload-dropzone form="part6" entity="subsidieaanvraag/aanvraag" field="field_hemelwater_foto_uitvoeren"  [initialFiles]="images?.field_hemelwater_foto_uitvoeren"
                  (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part6.get('field_hemelwater_foto_uitvoeren'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_hemelwater_foto_uitvoeren_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
              </section>
  
              <section>
                <h6>Datum van uitvoering</h6>
                <input type="text" nbInput formControlName="field_hemelwater_datum_uitvoeren" [nbDatepicker]="field_hemelwater_datum_uitvoeren_picker"
                [status]="fieldStatus(this.part6.get('field_hemelwater_datum_uitvoeren'))">
                <nb-datepicker format="DD-MM-YYYY" #field_hemelwater_datum_uitvoeren_picker></nb-datepicker>
              </section>        
            </ng-container>               
          </form>
        </nb-accordion-item-body>
    </nb-accordion-item>    

    <nb-accordion-item *ngIf="displayFlags.showPart1">
      <nb-accordion-item-header>
        Regenton, -zuil of -schutting <nb-icon *ngIf="!part1.valid" icon="alert-triangle-outline" status="danger"></nb-icon>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <form [formGroup]="part1">
          <section>
            <table class="regenton">
              <thead>
                <tr><th>Maatregel</th><th>Aantal</th><th *ngIf="displayFlags.showPart1TypeB">Aanschafkosten</th><th>Totaal</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Regenton</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_regenton" name="field_regenton" 
                             [status]="part1.errors?.subsidy ? 'danger' : 'basic'"></td>
                  <td *ngIf="displayFlags.showPart1TypeB"><input type="text" nbInput status="basic" formControlName="field_regenton_aanschafkosten" 
                      [status]="part1.errors?.subsidy ? 'danger' : 'basic'"></td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part1.get('regenton_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Regenschutting</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_regenschutting"
                             [status]="part1.errors?.subsidy ? 'danger' : 'basic'"></td>
                  <td *ngIf="displayFlags.showPart1TypeB"></td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part1.get('regenschutting_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr> 
                <tr>
                  <td colspan="3">
                    <span *ngIf="part1.errors?.subsidy" class="validation-error">Kies maximaal 2 items en kies minimaal 1 regenton wanneer je de aanschafkosten hiervoor invult.</span>
                    <b>Subsidiebedrag</b>
                  </td>
                </tr>
                <tr>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part1.get('field_regenton_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
            <nb-checkbox formControlName="field_regenton_min_m2" [status]="fieldStatus(this.part1.get('field_regenton_min_m2'))">Er is minimaal 5m² dak aangesloten op de regenton.</nb-checkbox><br>
            <nb-checkbox formControlName="field_regenton_minimaal_inhoud" [status]="fieldStatus(this.part1.get('field_regenton_minimaal_inhoud'))">De regenton/zuil/schutting heeft een inhoud van minimaal 100 liter.</nb-checkbox>
          </section>

          <section>
            <h6>Ik zal deze maatregel gaan uitvoeren in mijn:</h6>
            <nb-radio-group formControlName="field_regenton_uitvoeren_in" [status]="fieldStatus(this.part1.get('field_regenton_uitvoeren_in'))">
              <nb-radio value="voortuin">Voortuin</nb-radio>
              <nb-radio value="achtertuin">Achtertuin</nb-radio>
              <nb-radio value="voor_en_achtertuin">Voor- en achtertuin</nb-radio>
            </nb-radio-group>
          </section>

          <section>
            <h6>Upload foto van locatie inclusief een foto van de regenpijp op dit moment.</h6>
            <app-upload-dropzone form="part1" entity="subsidieaanvraag/aanvraag" field="field_regenton_foto" [initialFiles]="images?.field_regenton_foto"
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part1.get('field_regenton_foto'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_regenton_foto_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>

          <section>
            <h6>Schets toevoegen</h6>
            <p>Laat zien waar de regenton geplaatst gaat worden.</p>
            <app-upload-dropzone form="part1" entity="subsidieaanvraag/aanvraag" field="field_regenton_schets" [initialFiles]="images?.field_regenton_schets"
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part1.get('field_regenton_schets'))"></app-upload-dropzone>
          </section>

          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <section>
              <h6>Bon uploaden</h6>
              <app-upload-dropzone form="part1" entity="subsidieaanvraag/aanvraag" field="field_regenton_bon" [initialFiles]="images?.field_regenton_bon"
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part1.get('field_regenton_bon'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_regenton_bon_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>  
            </section>

            <section>
              <h6>Datum van aankoop producten</h6>
              <input nbInput [nbDatepicker]="field_regenton_datum_van_aankoop_picker" 
                formControlName="field_regenton_datum_van_aankoop" [status]="fieldStatus(this.part1.get('field_regenton_datum_van_aankoop'))">
              <nb-datepicker format="DD-MM-YYYY" #field_regenton_datum_van_aankoop_picker></nb-datepicker>
            </section>

            <section>
              <h6>Foto uploaden van na de aanpassingen waarop duidelijk is te zien dat de regenton is aangesloten.</h6>
              <app-upload-dropzone form="part1" entity="subsidieaanvraag/aanvraag" field="field_regenton_foto_uitvoeren" [initialFiles]="images?.field_regenton_foto_uitvoeren"
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part1.get('field_regenton_foto_uitvoeren'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_regenton_foto_uitvoeren_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van uitvoering</h6>
              <input type="text" nbInput [nbDatepicker]="field_regenton_datum_uitvoering_picker" formControlName="field_regenton_datum_uitvoering"
                [status]="fieldStatus(this.part1.get('field_regenton_datum_uitvoering'))">
              <nb-datepicker format="DD-MM-YYYY" #field_regenton_datum_uitvoering_picker></nb-datepicker>
            </section>        
          </ng-container>
        </form>
      </nb-accordion-item-body>
    </nb-accordion-item>

    <nb-accordion-item *ngIf="displayFlags.showPart3">
      <nb-accordion-item-header>
        Vergroenen <nb-icon *ngIf="!part3.valid" icon="alert-triangle-outline" status="danger"></nb-icon>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <form [formGroup]="part3">
          <section>
            <table class="vergroenen">
              <thead>
                <tr><th>Maatregel</th><th>Aantal</th><th>Totaal</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Vergroenen</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_vergroenen"
                    [status]="part3.errors?.subsidy ? 'danger' : 'basic'">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part3.get('vergroenen_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <span *ngIf="part3.errors?.subsidy" class="validation-error">Deze subsidie kan pas aangevraagd worden vanaf 20m²</span>             
                    <b>Subsidiebedrag</b>
                  </td>
                </tr>
                <tr>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part3.get('field_vergroenen_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
            <nb-checkbox formControlName="field_vergroenen_verwijderd" [status]="fieldStatus(this.part3.get('field_vergroenen_verwijderd'))">Er wordt minimaal 20m² verhard oppervlakte verwijderd.</nb-checkbox><br>
          </section>        

          <section>
            <h6>Upload een foto van de locatie waarop te zien is dat deze bestraat is.</h6>
            <app-upload-dropzone form="part3" entity="subsidieaanvraag/aanvraag" field="field_vergroenen_foto" [initialFiles]="images?.field_vergroenen_foto" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part3.get('field_vergroenen_foto'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_vergroenen_foto_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>

          <section>
            <h6>Voeg een schets toe waarop je laat zien hoe groot het vergroende stuk gaat worden.</h6>
            <app-upload-dropzone form="part3" entity="subsidieaanvraag/aanvraag" field="field_vergroenen_schets" [initialFiles]="images?.field_vergroenen_schets" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part3.get('field_vergroenen_schets'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_vergroenen_schets_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>

          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <section>
              <h6>Bon uploaden</h6>
              <app-upload-dropzone form="part3" entity="subsidieaanvraag/aanvraag" field="field_vergroenen_bon" [initialFiles]="images?.field_vergroenen_bon" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part3.get('field_vergroenen_bon'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_vergroenen_bon_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van aankoop producten</h6>
              <input type="text" nbInput formControlName="field_vergroenen_datum_aankoop" [nbDatepicker]="field_vergroenen_datum_aankoop_picker"
              [status]="fieldStatus(this.part3.get('field_vergroenen_datum_aankoop'))">
              <nb-datepicker format="DD-MM-YYYY" #field_vergroenen_datum_aankoop_picker></nb-datepicker>
            </section>

            <section>
              <h6>Upload een foto van de locatie waarop te zien is dat deze is vergroend en een foto met een meetlint waarop te zien is hoe groot het vergroende deel is.</h6>
              <app-upload-dropzone form="part3" entity="subsidieaanvraag/aanvraag" field="field_vergroenen_foto_uitvoeren" [initialFiles]="images?.field_vergroenen_foto_uitvoeren" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part3.get('field_vergroenen_foto_uitvoeren'))"></app-upload-dropzone>
                <nb-checkbox formControlName="field_vergroenen_foto_uitvoeren_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van uitvoering</h6>
              <input type="text" nbInput formControlName="field_vergoenen_datum_uitvoering" [nbDatepicker]="field_vergoenen_datum_uitvoering_picker"
              [status]="fieldStatus(this.part3.get('field_vergoenen_datum_uitvoering'))">
              <nb-datepicker format="DD-MM-YYYY" #field_vergoenen_datum_uitvoering_picker></nb-datepicker>
            </section>        
          </ng-container>               

        </form>

      </nb-accordion-item-body>
    </nb-accordion-item>     
      
    <nb-accordion-item *ngIf="displayFlags.showPart4">
      <nb-accordion-item-header>
        Planten van een boom <nb-icon *ngIf="!part4.valid" icon="alert-triangle-outline" status="danger"></nb-icon>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <form [formGroup]="part4">

          <section>
            <h6>Oppervlakte van de tuin</h6>
            <nb-radio-group formControlName="field_boom_oppervlakte_tuin">
              <nb-radio value="minder_dan_50">&lt; 50m² (o.a. voortuinen)</nb-radio>
              <nb-radio value="50_200">50 – 200 m²</nb-radio>
              <nb-radio value="meer_dan_200">&gt; 200 m²</nb-radio>
            </nb-radio-group>
          </section>

          <section>
            <table class="boom" *ngIf="trees$ | async as trees">
              <thead>
                <tr><th>Boomsoort</th><th>Totaal</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <nb-select name="field_boom_een" formControlName="field_boom_een">
                      <nb-option *ngFor="let tree of trees" [value]="tree.key">{{ tree.val }}</nb-option>
                    </nb-select>
                  </td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part4.get('boom_een_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <nb-select name="field_boom_twee" formControlName="field_boom_twee">
                      <nb-option *ngFor="let tree of trees" [value]="tree.key">{{ tree.val }}</nb-option>
                    </nb-select>                    
                  </td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part4.get('boom_twee_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr> 
                <tr>
                  <td colspan="3"><b>Subsidiebedrag</b></td>
                </tr>
                <tr>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part4.get('field_boom_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>   

          <section>
            <nb-checkbox formControlName="field_min_plantmaat" [status]="fieldStatus(this.part4.get('field_min_plantmaat'))">
              De minimale plantmaat is 12 – 14 cm (voor fruitbomen kan deze maat iets kleiner zijn)
            </nb-checkbox><br>
            <nb-checkbox formControlName="field_erfgrensregels" [status]="fieldStatus(this.part4.get('field_erfgrensregels'))">
              Ik houd bij het plaatsen van een boom rekening met de erfgrensregels. Bomen die te dicht op de erfgrens staan komen niet in aanmerking voor een subsidie.
            </nb-checkbox><br>
          </section>
          
          <section>
            <h6>Upload een foto van de locatie waar de boom/ bomen geplaatst word(t)(en).</h6>
            <app-upload-dropzone form="part4" entity="subsidieaanvraag/aanvraag" field="field_boom_foto" [initialFiles]="images?.field_boom_foto" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part4.get('field_boom_foto'))"></app-upload-dropzone>
            <nb-checkbox formControlName="field_boom_foto_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>

          <section>
            <h6>Voeg een schets toe waarop je aangeeft waar de boom wordt/bomen worden geplaatst.</h6>
            <app-upload-dropzone form="part4" entity="subsidieaanvraag/aanvraag" field="field_boom_schets" [initialFiles]="images?.field_boom_schets" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part4.get('field_boom_schets'))"></app-upload-dropzone>
          </section>        

          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <section>
              <h6>Bon uploaden</h6>
              <app-upload-dropzone form="part4" entity="subsidieaanvraag/aanvraag" field="field_boom_bon" [initialFiles]="images?.field_boom_bon" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part4.get('field_boom_bon'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_boom_bon_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van aankoop producten</h6>
              <input type="text" nbInput formControlName="field_boom_datum_aankoop" [nbDatepicker]="field_boom_datum_aankoop_picker"
              [status]="fieldStatus(this.part4.get('field_boom_datum_aankoop'))">
              <nb-datepicker format="DD-MM-YYYY" #field_boom_datum_aankoop_picker></nb-datepicker>
            </section>

            <section>
              <h6>Upload een foto van de locatie waar de boom/ bomen geplaatst is/zijn.</h6>
              <app-upload-dropzone form="part4" entity="subsidieaanvraag/aanvraag" field="field_boom_foto_uitvoeren" [initialFiles]="images?.field_boom_foto_uitvoeren" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part4.get('field_boom_foto_uitvoeren'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_boom_foto_uitvoeren_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van uitvoering</h6>
              <input type="text" nbInput formControlName="field_boom_datum_uitvoering" [nbDatepicker]="field_boom_datum_uitvoering_picker"
              [status]="fieldStatus(this.part4.get('field_boom_datum_uitvoering'))">
              <nb-datepicker format="DD-MM-YYYY" #field_boom_datum_uitvoering_picker></nb-datepicker>
            </section>        
          </ng-container>                
        </form>
      </nb-accordion-item-body>
    </nb-accordion-item>          

    <nb-accordion-item *ngIf="displayFlags.showPart5">
      <nb-accordion-item-header>
        Groene daken <nb-icon *ngIf="!part5.valid" icon="alert-triangle-outline" status="danger"></nb-icon>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <form [formGroup]="part5">
          <section>
            <table class="groendak" *ngIf="displayFlags.showPart5TypeA">
              <thead>
                <tr><th>Maatregel</th><th>Aantal</th><th>Totaal</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Groendak</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_groendak"
                    [status]="part5.errors?.subsidy ? 'danger' : 'basic'">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('groendak_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>
                <tr class="extra-fields" *ngIf="showGroendakDetailFields">
                  <td colspan="3"><strong>Waarvan:</strong></td>
                </tr>
                <tr class="extra-fields" *ngIf="showGroendakDetailFields">
                  <td colspan="3"><input type="text" nbInput class="inline-text-input" formControlName="field_groendak_laagdikte_8" 
                  [status]="part5.errors?.sum1 ? 'danger' : 'basic'">m²
                  groendak met een laagdikte van 8-20 centimeter</td>
                </tr>
                <tr class="extra-fields" *ngIf="showGroendakDetailFields">
                  <td colspan="3"><input type="text" nbInput class="inline-text-input" formControlName="field_groendak_laagdikte_20" 
                    [status]="part5.errors?.sum1 ? 'danger' : 'basic'">m²
                  groendak met een laagdikte van 20-40 centimeter</td>
                </tr>
                <tr class="extra-fields" *ngIf="showGroendakDetailFields">
                  <td colspan="3"><input type="text" nbInput class="inline-text-input" formControlName="field_groendak_laagdikte_40" 
                    [status]="part5.errors?.sum1 ? 'danger' : 'basic'">m²
                  groendak met een laagdikte van meer dan 40 centimeter</td>
                </tr>
                <tr>
                  <td>Groenblauwdak</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_groenblauwdak"
                    [status]="part5.errors?.subsidy ? 'danger' : 'basic'">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('groenblauwdak_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>              
                <tr class="extra-fields" *ngIf="showGroenblauwdakDetailFields">
                  <td colspan="3"><strong>Waarvan:</strong></td>
                </tr>
                <tr class="extra-fields" *ngIf="showGroenblauwdakDetailFields">
                  <td colspan="3"><input type="text" nbInput class="inline-text-input" formControlName="field_groenblauwdak_laagdikte_8" 
                    [status]="part5.errors?.sum2 ? 'danger' : 'basic'">m²
                  groenblauwdak met een laagdikte van 8-20 centimeter</td>
                </tr>
                <tr class="extra-fields" *ngIf="showGroenblauwdakDetailFields">
                  <td colspan="3"><input type="text" nbInput class="inline-text-input" formControlName="field_groenblauwdak_laagdikte_20" 
                    [status]="part5.errors?.sum2 ? 'danger' : 'basic'">m²
                  groenblauwdak met een laagdikte van 20-40 centimeter</td>
                </tr>
                <tr class="extra-fields" *ngIf="showGroenblauwdakDetailFields">
                  <td colspan="3"><input type="text" nbInput class="inline-text-input" formControlName="field_groenblauwdak_laagdikte_40" 
                    [status]="part5.errors?.sum2 ? 'danger' : 'basic'">m²
                  groenblauwdak met een laagdikte van meer dan 40 centimeter</td>
                </tr>                
                <tr>
                  <td colspan="3">
                    <span *ngIf="part5.errors?.sum1 || part5.errors?.sum2" class="validation-error">De ingevulde centimeters bij laagdiktes komen niet overeen met het totaal opgegeven m²</span>
                    <span *ngIf="part5.errors?.subsidy" class="validation-error">Deze subsidie kan pas aangevraagd worden vanaf {{minimumGroendak}}m²</span>
                    <b>Subsidiebedrag</b>
                  </td>
                </tr>
                <tr>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('field_groendak_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>
            <table class="groendak" *ngIf="displayFlags.showPart5TypeB">
              <thead>
                <tr><th>Maatregel</th><th>Aantal</th><th>Totaal</th></tr>
              </thead>
              <tbody>
                <tr>
                  <td>Waterberging van 18 liter/m²</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_waterberging_18_liter_m2">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('waterberging_18_liter_m2_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>             
                <tr>
                  <td>Waterberging van 30 liter/m²</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_waterberging_30_liter_m2">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('waterberging_30_liter_m2_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>  
                <tr>
                  <td>Waterberging van 50 liter/m²</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_waterberging_50_liter_m2">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('waterberging_50_liter_m2_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>                
                <tr>
                  <td>Waterberging van minimaal 30 liter/m² met meer dan 50% grassen, heesters en of inheemse soorten gebruikt worden en minder dan 50% sedum</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_waterberging_30l_grassen">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('waterberging_30l_grassen_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>  
                <tr>
                  <td>Waterberging van minimaal 50 liter/m² met meer dan 50% grassen, heesters en of inheemse soorten gebruikt worden en minder dan 50% sedum</td>
                  <td><input type="text" nbInput status="basic" formControlName="field_waterberging_50l_grassen">m²</td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('waterberging_50l_grassen_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>                         
                <tr>
                  <td>Bouwkundige keuring dak keuringskosten</td>
                  <td>&euro; <input type="text" nbInput status="basic" formControlName="field_dak_keuringskosten"></td>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('dak_keuringskosten_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                </tr>                                        
                <tr>
                  <td colspan="3">
                    <span *ngIf="part5.errors?.subsidy" class="validation-error">Deze subsidie kan pas aangevraagd worden vanaf 6m², met een maximum van 250m².</span>
                    <b>Subsidiebedrag</b>
                  </td>
                </tr>
                <tr>
                  <td [nbSpinner]="isLoading" nbSpinnerStatus="primary" nbSpinnerSize="tiny">
                    <span *ngIf="!isLoading">{{ this.part5.get('field_groendak_totale_subsidie').value | currency:'EUR':'symbol':undefined:'nl-NL' }}</span>
                  </td>
                  <td colspan="2"></td>
                </tr>
              </tbody>
            </table>  
            <nb-checkbox *ngIf="displayFlags.showPart5TypeA" formControlName="field_groendak_minimum_oppervlak" 
              [status]="fieldStatus(this.part5.get('field_groendak_minimum_oppervlak'))">Het groen(blauw)dak heeft een oppervlakte van minimaal {{minimumGroendak}}m²
            </nb-checkbox><br>
            <nb-checkbox *ngIf="displayFlags.showPart5TypeB" formControlName="field_groendak_minimum_oppervlak" 
              [status]="fieldStatus(this.part5.get('field_groendak_minimum_oppervlak'))">Het groen(blauw)dak heeft een oppervlakte van minimaal 6m²
            </nb-checkbox><br>


          </section>        

          <section>
            <h6>Upload een foto van het dak zoals voordat deze is vergroend.</h6>
            <app-upload-dropzone form="part5" entity="subsidieaanvraag/aanvraag" field="field_groendak_foto" [initialFiles]="images?.field_groendak_foto" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part5.get('field_groendak_foto'))"></app-upload-dropzone>
            <nb-checkbox formControlName="field_groendak_foto_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>

          <section>
            <h6>Voeg een schets toe waarop te zien is hoe groot het groendak wordt en waar deze wordt geplaatst.</h6>
            <app-upload-dropzone form="part5" entity="subsidieaanvraag/aanvraag" field="field_groendak_schets" [initialFiles]="images?.field_groendak_schets" 
              (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part5.get('field_groendak_schets'))"></app-upload-dropzone>
            <nb-checkbox formControlName="field_groendak_schets_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
          </section>        

          <ng-container *ngIf="displayFlags.showDefinitiveRequestFields">
            <section>
              <h6>Bon uploaden</h6>
              <app-upload-dropzone form="part5" entity="subsidieaanvraag/aanvraag" field="field_groendak_bon" [initialFiles]="images?.field_groendak_bon" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part5.get('field_groendak_bon'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_groendak_bon_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van aankoop producten</h6>
              <input type="text" nbInput formControlName="field_groendak_datum_aankoop" [nbDatepicker]="field_groendak_datum_aankoop_picker"
              [status]="fieldStatus(this.part5.get('field_groendak_datum_aankoop'))">
              <nb-datepicker format="DD-MM-YYYY" #field_groendak_datum_aankoop_picker></nb-datepicker>
            </section>

            <section>
              <h6>Upload een foto van het dak dat vergroend is.</h6>
              <app-upload-dropzone form="part5" entity="subsidieaanvraag/aanvraag" field="field_groendak_foto_uitvoeren" [initialFiles]="images?.field_groendak_foto_uitvoeren" 
                (fileChanged)="fileChanged($event)" [status]="fieldStatus(this.part5.get('field_groendak_foto_uitvoeren'))"></app-upload-dropzone>
              <nb-checkbox formControlName="field_groendak_foto_uitvoeren_bypass">Ik kan de afbeelding niet uploaden. U ontvangt via de mail informatie over hoe u de afbeelding kunt toesturen.</nb-checkbox>
            </section>

            <section>
              <h6>Datum van uitvoering</h6>
              <input type="text" nbInput formControlName="field_groendak_datum_uitvoering" [nbDatepicker]="field_groendak_datum_uitvoering_picker"
              [status]="fieldStatus(this.part5.get('field_groendak_datum_uitvoering'))">
              <nb-datepicker format="DD-MM-YYYY" #field_groendak_datum_uitvoering_picker></nb-datepicker>
            </section>        
          </ng-container>            
        </form>
      </nb-accordion-item-body>
    </nb-accordion-item>    
  </nb-accordion>

  <section class="nav">
    <button nbButton nbStepperPrevious><nb-icon icon="arrow-ios-back-outline"></nb-icon> Vorige</button>
    <button nbButton nbStepperNext [disabled]="!formValid">Volgende <nb-icon icon="arrow-ios-forward-outline"></nb-icon></button>
  </section>
</ng-container>